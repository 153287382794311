import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Image, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNote, getNotes } from '../../../../appRedux/actions';
import confirm from 'antd/lib/modal/confirm';
import {
  buttonLabel,
  deleteItemModal,
  modalButtons,
} from '../../../../constants';
import { stringDate } from '../../../../util';

export const NoteHistoryBody = ({ customerId }) => {
  const dispatch = useDispatch();
  const [isNoteView, setIsNoteView] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const { notes, note } = useSelector((state) => state.notes);

  useEffect(() => {
    dispatch(getNotes({ customerId }));
  }, [customerId, dispatch, note]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      async onOk() {
        await dispatch(deleteNote(id));
      },
      onCancel() {},
    });
  };

  const setNote = (note) => {
    setCurrentNote(note);
    setIsNoteView(true);
  };

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      render: (title, note) => (
        <Button type="link" htmlType="button" onClick={() => setNote(note)}>
          {title}
        </Button>
      ),
      path: 'title',
    },
    {
      title: 'Tipo de nota',
      dataIndex: 'noteType',
      key: 'noteType',
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, { created_at }) => <div> {stringDate(created_at)}</div>,
      path: 'created_at',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Foto',
      dataIndex: 'photo',
      key: 'photo',
      render: (photo) =>
        photo?.name ? (
          <Image
            className="photo"
            alt={photo.name}
            width={100}
            src={`${process.env.REACT_APP_API}/v1/note/photo/${photo.name}`}
            preview={`${process.env.REACT_APP_API}/v1/note/photo/thumbnail/${photo.name}`}
          />
        ) : (
          <>-</>
        ),
      path: 'na',
    },
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (_, { user }) => (
        <div>
          {user?.name} {user.lastName} {user.motherLastName}{' '}
        </div>
      ),
      path: ['user.name', 'user.lastName', 'user.motherLastName'],
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <Button type="danger" ghost onClick={() => showDeleteConfirm(id)}>
          <DeleteFilled
            style={{
              verticalAlign: 'middle',
            }}
          />
        </Button>
      ),
    },
  ];

  return (
    <>
      {!isNoteView && notes && (
        <Table
          columns={columns}
          dataSource={notes}
          pagination={5}
          name={'Notas'}
        />
      )}

      {isNoteView && (
        <Card bordered={true}>
          <Row className="row">
            <Col span={8}>
              <h5 className="strong">Titulo</h5>
            </Col>
            <Col span={16}> {currentNote?.title} </Col>
          </Row>
          <Row className="row">
            <Col span={8}>
              <h5 className="strong">Tipo de nota</h5>
            </Col>
            <Col span={16}> {currentNote?.noteType} </Col>
          </Row>
          <Row className="row">
            <Col span={8}>
              <h5 className="strong">Nota</h5>
            </Col>
            <Col span={16}>{currentNote?.note}</Col>
          </Row>
          {notes.photo?.name && (
            <Row className="row">
              <Col span={8}>
                <h5 className="strong">Foto</h5>
              </Col>

              <Image
                className="photo"
                style={{ display: 'center' }}
                height={250}
                src={`${process.env.REACT_APP_API}/v1/photo/${currentNote.photo?.name}`}
              />
            </Row>
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            onClick={() => setIsNoteView(false)} //Hace que el estado se ponga en falso para poder regresar a su punto original
          >
            {buttonLabel.previous}
          </Button>
        </Card>
      )}
    </>
  );
};
