import {
  GET_PURCHASED_PRODUCT_BY_ID,
  UPDATE_PURCHASED_PRODUCT,
  FINISH_PURCHASED_PRODUCT,
  GET_PURCHASED_PRODUCT_BY_CUSTOMER_ID,
} from '../types';
import { put, get } from './Action';

const basePath = '/purchased-product';

export const updatePurchasedProduct =
  (id, purchasedProduct) => async (dispatch) => {
    return await dispatch(
      put(`${basePath}/${id}`, purchasedProduct, updatePurchasedProductDS)
    );
  };

export const getPurchasedProductById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getPurchasedProductByIdDS));
};

export const finishPurchasedProduct = (id, form) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/finish`, form, finishPurchasedProductDS)
  );
};

export const getPurchasedProductsByCustomer =
  (customerId) => async (dispatch) => {
    return await dispatch(
      get(
        `${basePath}/customer/${customerId}`,
        getPurchasedProductsByCustomerDS
      )
    );
  };

const updatePurchasedProductDS = (purchasedProduct) => ({
  type: UPDATE_PURCHASED_PRODUCT,
  payload: purchasedProduct,
});

const getPurchasedProductByIdDS = (purchasedProduct) => ({
  type: GET_PURCHASED_PRODUCT_BY_ID,
  payload: purchasedProduct,
});

const finishPurchasedProductDS = (purchasedProduct) => ({
  type: FINISH_PURCHASED_PRODUCT,
  payload: purchasedProduct,
});

const getPurchasedProductsByCustomerDS = (purchasedProducts) => ({
  type: GET_PURCHASED_PRODUCT_BY_CUSTOMER_ID,
  payload: purchasedProducts?.items ?? [],
});
