import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Select, Switch } from 'antd';
import {
  appointmentConst,
  appointmentRoles,
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  oneColumnFormConfig,
  placeHolders,
  roleConst,
} from '../../../../../../constants';
import { CustomSelect } from '../../../../../uiComponents';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  getCosmetologists,
  getCustomerById,
  getDoctors,
  getStockByBranchAndTreatment,
} from '../../../../../../appRedux/actions';
import { finishPurchasedProduct } from '../../../../../../appRedux/actions/PurchasedProduct';

export const StatusPurchasedProduct = ({
  purchasedProduct,
  setIsModalOpen,
  customerId,
  form,
}) => {
  const dispatch = useDispatch();
  const { branchId } = useSelector((state) => state?.userAuth);
  const { doctors, cosmetologists } = useSelector((state) => state.user);
  const { stockForTreatment } = useSelector((state) => state.stock);
  const [consent, setConsent] = useState(false);
  const [needConsent, setNeedConsent] = useState(false);
  const [roleLabel, setRoleLabel] = useState();
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState();
  const [sessions, setSessions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areasApplied, setAreasApplied] = useState([]);
  const [limit, setLimit] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);

  const { treatment } = purchasedProduct ?? {};
  let filteredProducts = stockForTreatment?.filter(
    (product) => !selectedItems.includes(product.productId)
  );
  let selectedProducts = useRef({});

  useEffect(() => {
    if (purchasedProduct) {
      setRole(
        appointmentRoles[purchasedProduct?.treatment?.treatmentType?.name]
      );
      setLimit(purchasedProduct?.limit);
    }
  }, [purchasedProduct]);

  useEffect(() => {
    if (roleLabel) {
      setUsers(
        roleLabel === appointmentConst.DOCTOR ? doctors : cosmetologists
      );
    }
  }, [roleLabel, doctors, cosmetologists]);

  useEffect(() => {
    if (role === roleConst.medic) {
      dispatch(getDoctors(branchId));
      setRoleLabel(appointmentConst.DOCTOR);
    } else {
      dispatch(getCosmetologists(branchId));
      setRoleLabel(appointmentConst.COSMETOLOGIST);
    }
  }, [dispatch, role, branchId]);

  useEffect(() => {
    if (purchasedProduct?.treatment) {
      setNeedConsent(purchasedProduct?.treatment?.consent === 1);
    }
  }, [purchasedProduct]);

  useEffect(() => {
    if (purchasedProduct?.consent) {
      setConsent(purchasedProduct?.consent);
    }
  }, [purchasedProduct]);

  useEffect(() => {
    if (purchasedProduct) {
      const { sessions, currentSession } = purchasedProduct;
      const leftSessions = sessions - currentSession;

      const session = [];
      for (let index = 1; index <= leftSessions; index++) {
        session.push({
          value: index,
          label: index,
        });
      }
      setSessions(session);
    }
  }, [purchasedProduct]);

  useEffect(() => {
    if (areasApplied?.length === limit) {
      setAreas(areasApplied);
    } else {
      setAreas(purchasedProduct?.treatment?.areas);
    }
  }, [areasApplied, limit, purchasedProduct]);

  useEffect(() => {
    dispatch(getStockByBranchAndTreatment(branchId, treatment?.id));
  }, [dispatch, branchId, treatment]);

  const onAreaChange = (areaId) => {
    const selected = areas?.find((area) => area.id === areaId);
    setAreasApplied((value) => {
      if (selected) {
        const selectedAreas = [...value, selected];
        return removeDuplicates(selectedAreas);
      } else {
        return value;
      }
    });
  };

  const removeDuplicates = (selectedAreas) => {
    return selectedAreas.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
  };

  const onSessionsChange = (sessions) => {
    selectedProducts.current = {};
    setSelectedItems([]);
    let productQuantity = 0;

    for (let i = 0; i < treatment?.treatmentProduct?.length; i++) {
      const product = treatment?.treatmentProduct[i];
      productQuantity += product.quantity;
    }

    const treatmentProducts = [];
    for (let i = 0; i < productQuantity * sessions; i++) {
      treatmentProducts.push('');
    }

    form.setFieldsValue({ treatmentProducts });

    setAreas(purchasedProduct?.treatment?.areas);
    const sessionAreas = [];
    for (let i = 0; i < sessions; i++) {
      sessionAreas.push('');
    }
    form.setFieldsValue({ sessionAreas });
  };

  const onProductChange = (_, product) => {
    const totalSelected = selectedProducts.current[product?.productId] ?? 0;

    if (product) {
      selectedProducts.current[product.productId] = totalSelected + 1;
    }

    const items = treatment?.treatmentProduct?.filter(
      (treatmentProduct) =>
        selectedProducts.current[treatmentProduct.product.id] >=
        treatmentProduct.quantity
    );

    setSelectedItems(items.map((item) => item.product.id));
  };

  const onDeselecteProduct = (product) => {
    const deselected = stockForTreatment.find(
      (item) => item.stockId === product.value
    );

    setSelectedItems((item) =>
      item.filter((productId) => productId !== deselected.productId)
    );
    const qty = selectedProducts.current[deselected.productId];
    selectedProducts.current[deselected.productId] = qty - 1;
  };

  const onSubmit = (formValues) => {
    if (formValues?.treatmentProducts) {
      formValues.stockIds = formValues.treatmentProducts.map(
        (product) => product.stockId.value
      );
    }

    dispatch(finishPurchasedProduct(purchasedProduct?.id, formValues)).then(
      (response) => {
        if (response) {
          setIsModalOpen(false);
          dispatch(getCustomerById(customerId, 'true'));
          form.resetFields();
        }
      }
    );
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} {...formConfig}>
        {needConsent && (
          <Form.Item name="consent" label="Consentimiento">
            <Switch
              checked={consent}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={setConsent}
              disabled={purchasedProduct.consent}
            />
          </Form.Item>
        )}
        {(consent || !needConsent) && (
          <>
            <Form.Item
              name="attendedById"
              label={roleLabel}
              rules={[
                {
                  required: true,
                  message: messages.required(roleLabel),
                },
              ]}
            >
              <CustomSelect
                options={users?.map((user) => ({
                  value: user?.id,
                  label: `${user?.name} ${user?.lastName}`,
                }))}
              />
            </Form.Item>

            <>
              <Form.Item
                {...formConfig}
                name="sessions"
                label="Sesiones"
                rules={[
                  { required: true, message: messages.required('Sesiones') },
                ]}
              >
                <Select
                  allowClear
                  onChange={onSessionsChange}
                  options={sessions}
                ></Select>
              </Form.Item>

              {areas?.length > 0 && (
                <Form.List {...formConfig} disabled name="sessionAreas">
                  {(fields) => (
                    <div>
                      {fields.map((field, key) => (
                        <Form.Item
                          key={key}
                          name={[field.name, 'areaId']}
                          label={`Areas sesión ${field.name + 1}`}
                          rules={[
                            {
                              required: true,
                              message: messages.required('Areas'),
                            },
                          ]}
                        >
                          <CustomSelect
                            allowClear
                            onChange={onAreaChange}
                            options={areas?.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))}
                          ></CustomSelect>
                        </Form.Item>
                      ))}
                    </div>
                  )}
                </Form.List>
              )}

              <Form.List name="treatmentProducts">
                {(fields) => (
                  <div className="products-container">
                    {fields.map((field) => (
                      <>
                        <Form.Item
                          {...oneColumnFormConfig}
                          label="Producto"
                          name={[field.name, 'stockId']}
                          rules={[
                            {
                              required: true,
                              message: messages.required('Producto'),
                            },
                          ]}
                        >
                          <CustomSelect
                            placeholder={placeHolders.product}
                            onChange={onProductChange}
                            onDeselect={onDeselecteProduct}
                            labelInValue={true}
                            options={filteredProducts.map((product) => ({
                              value: product?.stockId,
                              label: `SKU: ${product?.sku} - Lote: ${product?.allotment} - ${product?.name}`,
                              productId: product?.productId,
                              stock: product?.stock,
                            }))}
                          ></CustomSelect>
                        </Form.Item>
                      </>
                    ))}
                  </div>
                )}
              </Form.List>
            </>

            <Button type="primary" htmlType="submit" className="btn-form">
              {buttonLabel.save}
            </Button>
          </>
        )}
      </Form>
    </Card>
  );
};
