import { GET_ALL_JOB_POSITIONS } from '../types';

import { get } from './Action';

const basePath = '/job-position';

// Get All Roles
export const getAllJobPositions = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getJobPositionsDS));
};

const getJobPositionsDS = (jobPositions) => ({
  type: GET_ALL_JOB_POSITIONS,
  payload: jobPositions,
});