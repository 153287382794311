import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_SALE_ORDER,
  GET_SALE_ORDERS,
  GET_SALE_ORDER_BY_ID,
  UPDATE_SALE_ORDER,
  GET_SALE_ORDER_DELETE,
  DELETE_SALE_ORDER,
} from '../types';

import { post, get, put, remove } from './Action';

const basePath = '/sale-order';

// Create Sale Order
export const createSaleOrder = (order) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, order, createSaleOrderDS));
};

// Get Sale Orders
export const getSaleOrders = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getSaleOrdersDS, true)
  );
};

// Get Sale Order By Id
export const getSaleOrderById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getSaleOrderByIdDS));
};

// Edit Sale Order
export const updateSaleOrder = (id, order) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, order, updateSaleOrderDS)
  );
};

// Delete Sale Order
export const deleteSaleOrder = (id) => async (dispatch) => {
  dispatch(getSaleOrderDeleteDS(id));
  return await dispatch(remove(`${basePath}/${id}/delete`, deleteSaleOrderDS));
};

const createSaleOrderDS = (order) => ({
  type: CREATE_SALE_ORDER,
  payload: order,
});

const getSaleOrdersDS = (orders) => ({
  type: GET_SALE_ORDERS,
  payload: orders.items,
});

const getSaleOrderByIdDS = (order) => ({
  type: GET_SALE_ORDER_BY_ID,
  payload: order,
});

const updateSaleOrderDS = (order) => ({
  type: UPDATE_SALE_ORDER,
  payload: order,
});

export const getSaleOrderDeleteDS = (id) => ({
  type: GET_SALE_ORDER_DELETE,
  payload: id,
});

const deleteSaleOrderDS = (order) => ({
  type: DELETE_SALE_ORDER,
  paylad: order,
});
