import React from 'react';
import { Layout } from 'antd';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm';
import './styles.css';
import { Loading } from '../../components/Loading';

export const ForgotPassword = () => {
  return (
    <>
      <Loading />
      <Layout>
        <div className="bg-login">
          <ForgotPasswordForm />
        </div>
      </Layout>
    </>
  );
};
