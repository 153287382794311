import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Modal, Popover, Space } from 'antd';
import { logout } from '../../../appRedux/actions/UserAuth';
import DefaultImage from '../../../assets/images/ICONO-AZUL-100x100.png';
import { ChangePasswordModal } from '../../../app/components/ChangePasswordModal';
import { ChangePinModal } from '../../../app/components/ChangePinModal';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [children, setChildren] = useState(null);
  const [title, setTitle] = useState(null);
  const { fullname, role, photo } = useSelector((state) => state.userAuth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { modalView } = useSelector((state) => state.ui);
  const passwordModal = useRef(false);

  useEffect(() => {
    passwordModal.current = modalView;
  }, [modalView]);

  const logOut = () => {
    navigate('/');
    dispatch(logout());
  };

  const closeModal = (action = null) => {
    setIsModalOpen(false);
  };

  const getModal = (action = null) => {
    setIsModalOpen(true);
    switch (action) {
      case 'change-password':
        setChildren(<ChangePasswordModal closeModal={closeModal} />);
        setTitle('Cambiar Contraseña');
        break;
      case 'reset-code':
        setChildren(<ChangePinModal closeModal={closeModal} />);
        setTitle('Cambiar Pin')
        break;
      default:
        break;
    }
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          getModal('change-password');
        }}
      >
        Cambiar Contraseña
      </li>
      <li
        onClick={() => {
          getModal('reset-code');
        }}
      >
        Cambiar Pin
      </li>
      <li onClick={() => logOut()}>Cerrar Sesión</li>
    </ul>
  );

  return (
    <>
      <div className="gx-flex-row gx-align-items-center gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Avatar
            src={
              photo
                ? `${process.env.REACT_APP_API}/v1/user/photo/thumbnail/${photo}`
                : DefaultImage
            }
            className="gx-size-40 gx-pointer gx-mr-3"
            alt="ProfileImage"
            size="large"
          />
          <Space
            direction="vertical"
            size="small"
            wrap
            className="space-no-gap"
          >
            <span className="gx-avatar-name">{fullname}</span>
            <span className="gx-avatar-role">{role}</span>
          </Space>
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </Popover>
      </div>

      <Modal
        title={title}
        destroyOnClose={true}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(!isModalOpen)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        {children}
      </Modal>
    </>
  );
};

export default UserProfile;
