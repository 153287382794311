import { Button, Calendar, Col, Row, Select, Space, Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { stringDate, getInitCalendar } from '../../../../util/formatTime';
import { appointmentStatusCalendar } from '../../../../constants';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppointmentData } from '../../../../appRedux/actions/General';
moment.locale('es-mx');

export const SiderAgenda = ({ onSelect, value }) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState();
  const { Sider } = Layout;

  useEffect(() => {
    dispatch(
      setAppointmentData({
        date: selectedDate,
      })
    );
  }, [selectedDate, dispatch]);
  return (
    <Sider
      style={{
        overflow: 'auto',
      }}
    >
      <Calendar
        onSelect={onSelect}
        fullscreen={false}
        value={value}
        headerRender={({ value, onChange }) => {
          setSelectedDate(moment(value).format('YYYY-MM-DD'));
          const { month, year, monthOptions, options } = getInitCalendar(value);
          return (
            <div
              style={{
                padding: 8,
              }}
            >
              <h4>{stringDate(value)}</h4>
              <Space direction="vertical" size="small">
                <Button
                  size="middle"
                  htmlType="button"
                  onClick={() => {
                    onChange(moment());
                  }}
                >
                  Hoy
                </Button>
                <Row className="buttons-calendar">
                  <Col>
                    <Button
                      size="small"
                      htmlType="button"
                      onClick={(newDay) => {
                        onChange(value.clone().day(newDay).subtract(1, 'days'));
                      }}
                    >
                      <LeftOutlined />
                    </Button>
                    <Button
                      size="small"
                      htmlType="button"
                      onClick={(newDay) => {
                        onChange(value.clone().day(newDay).add(1, 'days'));
                      }}
                    >
                      <RightOutlined />
                    </Button>
                  </Col>
                  <Col>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                    >
                      {options}
                    </Select>
                  </Col>
                </Row>
              </Space>
            </div>
          );
        }}
      />
      <div className="status-container">
        {Object.keys(appointmentStatusCalendar).map((status) => (
          <Space align="baseline" size="large" key={Math.random()}>
            <div
              style={{
                backgroundColor: `#${appointmentStatusCalendar[status].color}`,
                width: '125px',
                height: '10px',
              }}
            ></div>
            <p>{appointmentStatusCalendar[status].value}</p>
          </Space>
        ))}
      </div>
    </Sider>
  );
};
