import {
  AUTH_SIGNIN,
  AUTH_LOGOUT,
  AUTH_RESET_PASSWORD,
  AUTH_CHANGE_PASSWORD,
  AUTH_RESET_PIN,
  AUTH_LOGOUT_AWAY,
} from '../types';

import { post, put } from './Action';

const basePath = '/auth';

export const signIn = (user) => async (dispatch) => {
  dispatch(post(`${basePath}/sign-in`, user, signInDS));
};

export const logout = () => ({ type: AUTH_LOGOUT });

export const logoutAway = () => ({ type: AUTH_LOGOUT_AWAY });

export const resetPassword = (user) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/reset-password`, user, resetPasswordDS)
  );
};

export const changePasswordCode = (user) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/change-password-code`, user, changePasswordDS)
  );
};

export const changePassword = (passwords) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/change-password`, passwords, changePasswordDS)
  );
};

export const resetPin = (passwords) => async (dispatch) => {
  return await dispatch(put(`${basePath}/reset-pin`, passwords, resetPinDS));
};

const signInDS = (user) => ({
  type: AUTH_SIGNIN,
  payload: {
    token: user.payload.token,
    refresh_token: user.payload.refresh_token,
    refresh_token_exp: null,
    token_exp: null,
    quick_login: false,
  },
});

const resetPasswordDS = (user) => ({
  type: AUTH_RESET_PASSWORD,
  payload: { user },
});
const resetPinDS = (user) => ({
  type: AUTH_RESET_PIN,
  payload: { user },
});

const changePasswordDS = (user) => ({
  type: AUTH_CHANGE_PASSWORD,
  payload: { user },
});
