import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { SubsidiaryTable } from '../../components/Subsidiary/SubsidiaryTable';
import { Button, Card } from 'antd';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Subsidiaries = () => {
  const navigate = useNavigate();

  const {
    privileges: { branch = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      <Button
        type='primary'
        htmlType='submit'
        onClick={() => {
          navigate('/dashboard/sucursales/crear');
        }}
      >
        <PlusOutlined />
        Nuevo
      </Button>
      <SubsidiaryTable privileges={branch} />
    </Card>
  );
};
