import { Card } from 'antd';
import { UserForm } from '../../components/Users/UserForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const User = () => {
  const {
    privileges: { users = [] },
  } = useSelector((state) => state.userAuth);
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <UserForm privileges={users} />
    </Card>
  );
};
