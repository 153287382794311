import { Tag } from 'antd';
import { applianceStatusDiccionary } from '../../../../constants';

export const applianceStatus = (status) => {
  let color = null;

  switch (status) {
    case applianceStatusDiccionary.working:
      color = 'green';
      break;
    case applianceStatusDiccionary.failings:
      color = 'orange';
      break;
    case applianceStatusDiccionary.damege:
      color = 'magenta';
      break;
    case applianceStatusDiccionary.onRepair:
      color = 'cyan';
      break;

    default:
      break;
  }

  return <Tag color={color}>{status}</Tag>;
};
