import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CabainTable } from '../../components/Cabain/CabainTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Cabains = () => {
  const navigate = useNavigate();
  const {
    privileges: { cabain_types: privileges = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <Button
        type="primary"
        onClick={() => {
          navigate('/dashboard/cabinas/crear');
        }}
      >
        <PlusOutlined />
        Nuevo
      </Button>

      {privileges.includes('show') && (
        <Button
          type="ghost"
          className="btn-primary-outline"
          onClick={() => {
            navigate('/dashboard/tipos-cabina');
          }}
        >
          Tipo de cabina
        </Button>
      )}

      <CabainTable />
    </Card>
  );
};
