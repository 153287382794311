import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { React, useState, useEffect } from 'react';

export const UploadPhotos = ({
  limit = 5,
  multiple = true,
  onChange = null,
  files = [],
}) => {
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState(files);
  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview)
      file.preview = await getBase64(file.originFileObj);

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  useEffect(() => {
    setFileList(files);
  }, [files]);


  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onChange(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Subir</div>
    </div>
  );

  return (
    <>
      <Upload
        accept={'.png, .jpg, .jpeg, .webp, .gif'}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple={multiple}
        beforeUpload={() => false}
      >
        {fileList.length >= limit ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
