import { appliances } from '../../constants';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_APPLIANCE,
  DATA_DELETED,
  DELETE_APPLIANCE,
  GET_APPLIANCES,
  GET_APPLIANCE_BY_ID,
  UPDATE_APPLIANCE,
} from '../types';

import { post, get, put, remove } from './Action';

const basePath = '/appliance';

// Create Appliance
export const createAppliance = (appliance) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/create`, appliance, createApplicanceDS)
  );
};

// Get Appliances
export const getAppliances = (params) => async (dispatch) => {
  if (!params || [appliances].includes(params.type))
    return await dispatch(
      get(`${basePath}?type=${params.type}`, getAppliancesDS)
    );

  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}${queryParams}`, getAppliancesDS, true)
  );
};

// Get Appliance By Id
export const getApplianceById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getApplianceByIdDS));
};

// Edit Appliance
export const editAppliance = (id, appliance) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, appliance, editApplianceDS)
  );
};

// Delete Appliance
export const deleteAppliance = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteApplianceDS)
  );
  dispatch(getApplianceDeleteDS(id));
  return response;
};

const createApplicanceDS = (appliance) => ({
  type: CREATE_APPLIANCE,
  payload: appliance,
});

const getAppliancesDS = (appliances) => ({
  type: GET_APPLIANCES,
  payload: appliances.items,
});

const getApplianceByIdDS = (appliance) => ({
  type: GET_APPLIANCE_BY_ID,
  payload: appliance,
});

const editApplianceDS = (appliance) => ({
  type: UPDATE_APPLIANCE,
  payload: appliance,
});

export const getApplianceDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteApplianceDS = () => ({
  type: DELETE_APPLIANCE,
});
