import {
  DeleteFilled,
  ExclamationCircleOutlined,
  RedoOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Space, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLock, getLocks } from '../../../../appRedux/actions';
import confirm from 'antd/lib/modal/confirm';
import { deleteItemModal, modalButtons } from '../../../../constants';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import {
  daysDictionary,
  lockTypeDiccionary,
} from '../../../../constants/Lock.const';
import { LockTime } from '../LockTime';
import { capitalize } from '../../../../util/strings';
import './styles.css';
import { stringDate } from '../../../../util';

export const MyLocksModalBody = ({ privileges }) => {
  const dispatch = useDispatch();
  const { locks } = useSelector((state) => state.lock);
  const { appointmentData } = useSelector((state) => state.generalStates);
  const { setShowModal } = useCustomModal();

  useEffect(() => {
    dispatch(getLocks(appointmentData.branchId));
  }, [dispatch, appointmentData]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(dispatch(deleteLock(id))), 500);
          setShowModal(false);
        });
      },
      onCancel() {},
    });
  };

  const daysTags = (days) => {
    if (!days) {
      return <>-</>;
    }
    return days?.map((day, key) => <Tag key={key}>{daysDictionary[day]}</Tag>);
  };

  const columns = [
    {
      title: 'Tipo de bloqueo',
      dataIndex: 'type',
      key: 'type',
      render: (type) => lockTypeDiccionary[type],
    },
    {
      title: 'Cabina',
      dataIndex: 'cabain',
      key: 'cabain',
      render: (cabain) => cabain?.name || '-',
      path: 'cabain.name',
    },
    {
      title: 'Motivo',
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => capitalize(reason),
    },
    {
      title: 'Días',
      dataIndex: 'days',
      key: 'days',
      render: (days) => daysTags(days),
    },
    {
      title: 'Tiempo de bloqueo',
      dataIndex: 'time',
      key: 'time',
      render: (_, lock) => <LockTime data={lock} />,
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (date) => stringDate(date),
    },
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (user) =>
        `${user?.name} ${user?.lastName} ${user?.motherLastName || ''}`,
    },
    {
      title: 'Recurrente',
      dataIndex: 'recurrent',
      key: 'recurrent',
      render: (recurrent) => (
        <Space
          size={50}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          align="baseline"
        >
          {recurrent ? <RedoOutlined /> : <StopOutlined />}
        </Space>
      ),
    },
  ];

  if (privileges.includes('delete')) {
    columns.push({
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <Space
          className="my-blocks"
          size={50}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          align="baseline"
        >
          <DeleteFilled onClick={() => showDeleteConfirm(id)} />
        </Space>
      ),
    });
  }

  return <Table columns={columns} dataSource={locks} name={'Bloqueos'} />;
};
