import { Col, Row, Avatar, Card } from 'antd';
import DefaultImage from '../../../../../assets/images/ICONO-AZUL-100x100.png';
import { phoneMask } from '../../../../../util/phoneMask';
import { stringDate } from '../../../../../util/formatTime';

export const MainData = ({ data }) => {
  return (
    <Card
      bordered={true}
      cover={
        <Avatar
          className='avatar'
          alt='...'
          src={
            data?.kardex?.photo
              ? `${process.env.REACT_APP_API}/v1/user/photo/thumbnail/${data?.kardex?.photo?.name}`
              : DefaultImage
          }
        />
      }
    >
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'># Empleado</h5>
        </Col>
        <Col span={16}>{`${data?.kardex?.employeeNumber}`}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Nombre</h5>
        </Col>
        <Col
          span={16}
        >{`${data.name} ${data?.lastName} ${data?.motherLastName}`}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Usuario</h5>
        </Col>
        <Col span={16}>{`${data?.username}`}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Teléfono</h5>
        </Col>
        <Col span={16}>{phoneMask(data?.kardex?.phone[0]?.phone) || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Fecha de nacimiento</h5>
        </Col>
        <Col span={16}>{stringDate(data?.kardex?.birthdate)}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Sucursal actual</h5>
        </Col>
        <Col span={16}>{`${data?.kardex?.mainBranch?.name}`}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Puesto</h5>
        </Col>
        <Col span={16}>{`${data?.kardex?.jobPosition?.name}`}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h5 className='strong'>Rol</h5>
        </Col>
        <Col span={16}>{`${data?.role?.role}`}</Col>
      </Row>
    </Card>
  );
};
