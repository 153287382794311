import {
  CREATE_PROVIDER,
  DATA_DELETED,
  DELETE_PROVIDER,
  GET_PROVIDERS,
  GET_PROVIDER_BY_ID,
  UPDATE_PROVIDER,
} from '../types';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import { post, get, put, remove } from './Action';

const basePath = '/provider';

// Create Provider
export const createProvider = (provider) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, provider, createProviderDS));
};

// Get Provider
export const getProviders = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getProvidersDS, true));
};

// Get Provider By Id
export const getProviderById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getProviderByIdDS));
};

// Edit Provider
export const editProvider = (id, provider) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, provider, editProviderDS)
  );
};

// Delete Provider
export const deleteProvider = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteProviderDS)
  );
  dispatch(providerDeleteDS(id));
  return response;
};

const createProviderDS = (provider) => ({
  type: CREATE_PROVIDER,
  payload: provider,
});

const getProvidersDS = (providers) => ({
  type: GET_PROVIDERS,
  payload: providers.items,
});

const getProviderByIdDS = (provider) => ({
  type: GET_PROVIDER_BY_ID,
  payload: provider,
});

const editProviderDS = (provider) => ({
  type: UPDATE_PROVIDER,
  payload: provider,
});

export const providerDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteProviderDS = () => ({
  type: DELETE_PROVIDER,
});
