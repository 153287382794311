import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListTable,
  ModalView,
  TableFilters,
  TableMenuOpt,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { getProducts, deleteProduct } from '../../../../appRedux/actions';
import { Button, Tag } from 'antd';
import { ProductModal } from '../ProductModal';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Product.const';

export const ProductTable = ({ privileges = [] }) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();

  const getModalData = useCallback(() => {
    const [modalData] = products?.filter((product) => product.id === catalogId);
    return modalData;
  }, [products, catalogId]);

  useEffect(() => {
    setModalTitle(getModalData()?.name);
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteProduct(id));
  };

  const columns = [
    {
      title: 'SKU',
      sorter: true,
      dataIndex: 'sku',
      key: 'sku',
      render: (name, { id }) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Categorías',
      sorter: true,
      dataIndex: 'categories',
      key: 'categories',
      render: (categories) =>
        categories?.map((category) => (
          <Tag key={`category-${category.name}`}>{`${category.name}`}</Tag>
        )),
      path: 'categories',
      formatter: (categories) => {
        return categories?.map((category) => category.name).join();
      },
    },
    {
      title: 'Marca',
      sorter: true,
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Contenido',
      sorter: true,
      dataIndex: 'content',
      key: 'content',
      render: (text, record) =>
        `${text} ${record.measureUnit}${text > 1 ? '(s)' : ''} / ${
          record.contentType
        }`,
      path: ['content', 'measureUnit', 'contentType'],
      formatter: (values) =>
        `${values[0]} ${values[1]}${values[0] > 1 ? '(s)' : ''} / ${values[2]}`,
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (_, { __, approved_at, rejected_at }) => (
        <>
          {approved_at ? (
            <Tag color="success">Aprobado</Tag>
          ) : rejected_at ? (
            <Tag color="error">Rechazado</Tag>
          ) : (
            <Tag color="warning">Pendiente</Tag>
          )}
        </>
      ),
      path: ['approved_at', 'rejected_at'],
      formatter: (values) => {
        return values[0] ? 'Aprobado' : values[1] ? 'Rechazado' : 'Pendiente';
      },
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id, approved_at, rejected_at }) => (
        <TableMenuOpt
          id={id}
          path="productos"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          approved={approved_at}
          rejected={rejected_at}
          privileges={privileges}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Productos'}
        getDataFunction={getProducts}
      />
      <ModalView title={modalTitle} width={700} privileges={privileges}>
        <ProductModal data={modalData} />
      </ModalView>
    </>
  );
};
