import React from 'react';
import { Card } from 'antd';
import { CabainForm } from '../../components/Cabain/CabainForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Cabain = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <CabainForm />
    </Card>
  );
};
