import { Card } from 'antd';
import { CalendarAgenda } from '../../components/CalendarAgenda';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Agenda = () => {
  const {
    privileges: { dairy = [], blockers = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <CalendarAgenda dairyPrivileges={dairy} blockersPrivileges={blockers} />
    </Card>
  );
};
