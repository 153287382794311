import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  GET_ALL_CASH_SALES,
  GET_CASH_SALE_BY_ID,
  GET_COSMETOLOGY_COMMISSION_BY_USER,
  GET_COSMETOLOGY_COMMISSIONS,
  UPDATE_SELLER,
} from '../types';
import { get, patch } from './Action';

const basePath = '/cash-order';

export const getCashSales = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getCashSalesDS, true));
};

export const getCashSaleById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getCashSaleByIdDS));
};

// Get Medical commissions Commission By User
export const getCosmetologyCommissionsByUser =
  (id, { params }) =>
  async (dispatch) => {
    const queryParams = queryPathBuilder(params);
    return await dispatch(
      get(
        `${basePath}/${id}/user${queryParams}`,
        getCosmetologyCommissionsByUserDS
      )
    );
  };

export const getCosmetologyCommissions = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(
      `${basePath}/commissions${queryParams}`,
      getCosmetologyCommissionsDS,
      true
    )
  );
};

// Update seller
export const updateSeller = (appointmentId, data) => async (dispatch) => {
  return await dispatch(
    patch(`${basePath}/${appointmentId}/update-seller`, data, updateSellerDS)
  );
};

const getCashSalesDS = (cashSales) => ({
  type: GET_ALL_CASH_SALES,
  payload: cashSales.items,
});

const getCashSaleByIdDS = (cashSales) => ({
  type: GET_CASH_SALE_BY_ID,
  payload: cashSales,
});

const getCosmetologyCommissionsByUserDS = (commissions) => ({
  type: GET_COSMETOLOGY_COMMISSION_BY_USER,
  payload: commissions,
});

const getCosmetologyCommissionsDS = (commissions) => {
  return {
    type: GET_COSMETOLOGY_COMMISSIONS,
    payload: commissions.items,
  };
};

const updateSellerDS = (data) => ({
  type: UPDATE_SELLER,
  payload: data,
});
