import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCustomerOrders } from '../../../../appRedux/actions';
import { Table, Tabs } from 'antd';
import { orderType } from '../../../../constants/SaleOrder.const';
import { stringDate } from '../../../../util';
import { customerConst } from '../../../../constants';

export const MemberModalOrderHistory = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customerOrders } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomerOrders(customerId));
  }, [dispatch, customerId]);

  const columns = [
    {
      title: 'orden',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => stringDate(created_at),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={orderType.order} key="1">
          <Table
            pagination={customerConst?.pagination}
            dataSource={customerOrders?.orders}
            columns={columns}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={orderType?.cashOrder} key="2">
          <Table
            pagination={customerConst?.pagination}
            dataSource={customerOrders?.cashOrders}
            columns={columns}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
