import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  lengthValidation,
  messages,
  placeHolders,
  masks,
  regex,
  payConditionsConst,
} from '../../../../constants';
import { NumericInput, CancelButton } from '../../../uiComponents';
import { unmaskPhone } from '../../../../util/MaskedValues';
import {
  createProvider,
  editProvider,
  getProviderById,
} from '../../../../appRedux/actions';

export const ProviderForm = () => {
  const { id } = useParams();
  const { providerById } = useSelector((state) => state.provider);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    if (id) dispatch(getProviderById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response))
      navigate('/dashboard/proveedores');
  }, [navigate, response]);

  useEffect(() => {
    if (providerById && id) {
      form.setFieldsValue(providerById);
    }
  }, [form, providerById, id]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    values.phone = unmaskPhone(values.phone);
    values.mobilePhone = unmaskPhone(values.mobilePhone);
    if (id) {
      setResponse(await dispatch(editProvider(id, values)));
    } else {
      setResponse(await dispatch(createProvider(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            name="name"
            label="Nombre"
            placeholder={placeHolders.aplication}
            rules={[
              { required: true, message: messages.required('Nombre') },
              { max: 50, message: lengthValidation('Nombre', 50) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tradename"
            label="Razón Social"
            rules={[
              { required: true, message: messages.required('Razón Social') },
              { max: 50, message: lengthValidation('Razón Social', 50) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="rfc"
            label="RFC"
            rules={[
              { required: true, message: messages.required('RFC') },
              { pattern: regex.rfc, message: messages.validation.rfc },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="regime"
            label="Régimen"
            rules={[
              { required: true, message: messages.required('Régimen') },
              { max: 50, message: lengthValidation('Régimen', 50) },
            ]}
          >
            <Select placeholder={placeHolders.regime}>
              <Select.Option value={'Moral'}>Persona Moral</Select.Option>
              <Select.Option value={'Fisica'}>Persona Física</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="address"
            label="Dirección"
            rules={[{ max: 50, message: lengthValidation('Dirección', 50) }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="city"
            label="Ciudad"
            rules={[{ max: 50, message: lengthValidation('Ciudad', 50) }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="state"
            label="Estado"
            rules={[{ max: 50, message: lengthValidation('Estado', 50) }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="country"
            label="País"
            rules={[{ max: 50, message: lengthValidation('País', 50) }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="zipCode"
            label="Código Postal"
            rules={[{ max: 5, message: lengthValidation('Código postal', 5) }]}
          >
            <NumericInput />
          </Form.Item>

          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[
              {
                max: 100,
                message: lengthValidation('Correo electrónico', 100),
              },
              {
                type: 'email',
                message: messages.required('Correo electrónico'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Teléfono"
            rules={[{ required: true, message: messages.required('Teléfono') }]}
          >
            <MaskedInput
              mask={masks.phone}
              defaultValue={id ? providerById?.phone : ''}
            />
          </Form.Item>

          <Form.Item
            name="contactName"
            label="Contacto"
            rules={[
              { required: true, message: messages.required('Contacto') },
              { max: 50, message: lengthValidation('Contacto', 50) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="mobilePhone"
            label="Celular"
            rules={[{ required: true, message: messages.required('Celular') }]}
          >
            <MaskedInput
              mask={masks.phone}
              defaultValue={id ? providerById?.mobilePhone : ''}
            />
          </Form.Item>

          <Form.Item
            name="payConditions"
            label="Condiciones de pago"
            rules={[
              {
                required: true,
                message: messages.required('Condiciones de pago'),
              },
            ]}
          >
            <Select
              placeholder={placeHolders.payConditions}
              options={payConditionsConst.map(({ value, label }) => ({
                value,
                label,
              }))}
            ></Select>
          </Form.Item>

          <Form.Item name="deliveryTime" label="Tiempo de entrega">
            <Input />
          </Form.Item>

          <Form.Item name="bank" label="Banco">
            <Input />
          </Form.Item>

          <Form.Item
            name="clabeAccount"
            label="CLABE"
            rules={[{ max: 18, message: lengthValidation('CLABE', 18) }]}
          >
            <NumericInput />
          </Form.Item>

          <Form.Item
            name="bankAccount"
            label="Cuenta bancaria"
            rules={[
              { max: 20, message: lengthValidation('Cuenta bancaria', 20) },
            ]}
          >
            <NumericInput />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
          <CancelButton path="/dashboard/proveedores" />
        </Form>
      </Card>
    </>
  );
};
