import { Card } from 'antd';
import { PurchaseOrderForm } from '../../components/PurchaseOrder/PurchaseOrderForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const PurchaseOrder = () => {
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      <PurchaseOrderForm />
    </Card>
  );
};
