import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  UPDATE_CUSTOMER,
  GET_CUSTOMER_DELETE,
  DELETE_CUSTOMER,
  GET_CUSTOMERS_ORDERS,
  GET_CUSTOMERS_TOTAL_PURCHASE,
  GET_TUTORS,
  GET_PURCHASED_PRODUCT_BY_CUSTOMER_ID,
  RESET_CUSTOMER,
} from '../types';

const initialState = {
  customers: [],
  tutors: [],
  customerById: {},
  customerOrders: {},
  customerTotalPurchase: [],
  customerDelete: null,
  customerPurchasedProducts: [],
};

export default function Customer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.payload],
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case GET_TUTORS:
      return {
        ...state,
        tutors: action.payload,
      };
    case GET_CUSTOMER_BY_ID:
      return {
        ...state,
        customerById: action.payload,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((customer) =>
          customer.id === action.payload.id
            ? (customer = action.payload)
            : customer
        ),
      };
    case GET_CUSTOMER_DELETE:
      return {
        ...state,
        customerDelete: action.payload,
      };

    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(
          (customer) => customer.id !== state.customerDelete
        ),
      };
    case GET_CUSTOMERS_ORDERS:
      return {
        ...state,
        customerOrders: action.payload,
      };

    case GET_CUSTOMERS_TOTAL_PURCHASE:
      return {
        ...state,
        customerTotalPurchase: action.payload,
      };
    case GET_PURCHASED_PRODUCT_BY_CUSTOMER_ID:
      return {
        ...state,
        customerPurchasedProducts: action.payload,
      };
    case RESET_CUSTOMER:
      return {
        ...state,
        customerById: action.payload,
      };
    default:
      return state;
  }
}
