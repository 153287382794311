import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
  roleConst,
} from '../../../../constants';
import { useCallback } from 'react';
import { CancelButton, CustomSelect } from '../../../uiComponents';
import {
  acceptTransfer,
  approveTransfer,
  createTransfer,
  getTransferById,
  rejectTransfer,
  getSubsidiaries,
  getStockByBranchId,
} from '../../../../appRedux/actions';
import { TransferProducts } from '../TransferProducts';

export const TransferForm = ({
  logedUserName,
  privileges,
  loggedBranchId,
  role,
}) => {
  const { id } = useParams();
  const { transferById } = useSelector((state) => state.transfer);
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { stockByBranch } = useSelector((state) => state.stock);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [action, setAction] = useState('create');
  const [branchId, setBranchId] = useState(loggedBranchId);
  const [formDisabled, setFormDisabled] = useState(false);
  const [orderByName, setOrderByName] = useState();
  const [branchOption, setBranchOption] = useState(null);
  const [branchOptionDestiny, setBranchOptionDestiny] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const setOrderAction = useCallback(() => {
    if (id && !transferById?.approvedAt && privileges.includes('approve')) {
      setAction('approve');
    } else if (id && transferById?.approvedAt) {
      setAction('confirm');
    }
  }, [transferById, id, privileges]);

  useEffect(() => {
    setOrderAction();
  }, [setOrderAction]);

  useEffect(() => {
    if (id) {
      dispatch(getTransferById(id));
    }
    dispatch(getSubsidiaries({ status: '1' }));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response))
      navigate('/dashboard/transferencias');
  }, [navigate, response]);

  const setListItems = useCallback(
    ({ transferProduct }) => {
      transferProduct?.forEach((item) => {
        const stockProduct = stockByBranch?.find(
          (stock) => stock?.stockId === item?.productStock?.id
        );

        item.productS = {
          value: item?.productStock?.id,
          label: `SKU: ${item?.product?.sku} - Lote: ${item?.productStock?.allotment} - ${item?.product?.name}`,
        };
        item.productId = item?.product?.id;
        item.stock = stockProduct?.stock ?? 0;
      });
    },
    [stockByBranch]
  );

  useEffect(() => {
    if ([roleConst.branchManager].includes(role) && !id) {
      form.setFieldsValue({ originBranchId: branchId });
    }
  }, [role, form, branchId, id]);

  useEffect(() => {
    if (transferById && id) {
      setBranchId(transferById?.originBranch?.id);
      transferById.originBranchId = transferById?.originBranch?.id;
      transferById.destinyBranchId = transferById?.destinyBranch?.id;
      form.setFieldsValue(transferById);
      setListItems(transferById);
      form.setFieldsValue({
        transferProducts: transferById.transferProduct,
        withDeleted: 'false',
      });
    }
  }, [form, id, transferById, setListItems]);

  useEffect(() => {
    dispatch(getStockByBranchId(branchId));
  }, [branchId, dispatch]);

  useEffect(() => {
    if (id && transferById) {
      setOrderByName(
        `${transferById?.createdBy?.name} ${transferById?.createdBy?.lastName}`
      );
    }
  }, [transferById, id]);

  useEffect(() => {
    if (id && transferById) {
      const status =
        transferById.acceptedAt ||
        transferById.rejectedAt ||
        transferById?.deleted_at
          ? true
          : false;
      setFormDisabled(status);
    }
  }, [transferById, id]);

  const onReject = async () => {
    setResponse(await dispatch(rejectTransfer(id)));
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    values.transferProducts = values?.transferProducts?.map((item) => ({
      ...item,
      stockId: item.productS.value,
    }));

    if ([roleConst.branchManager].includes(role)) {
      values.originBranchId = branchId;
    }

    if (action === 'create') {
      setResponse(await dispatch(createTransfer(values)));
    } else if (action === 'approve') {
      setResponse(await dispatch(approveTransfer(id, values)));
    } else if (action === 'confirm') {
      setResponse(await dispatch(acceptTransfer(id, values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          id="onSubmit"
          initialValues={{ transferProducts: [''] }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
          disabled={formDisabled}
        >
          {(id || ![roleConst.branchManager].includes(role)) && (
            <Form.Item
              {...formConfig}
              name="originBranchId"
              label="Sucursal de origen"
              rules={[
                {
                  required: true,
                  message: messages.required('Sucursal de origen'),
                },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.branch}
                disabled={id && true}
                onChange={(value) => {
                  setBranchId(value);
                  setBranchOption(value);
                  form.setFieldsValue({ transferProducts: [''] });
                }}
                options={subsidiaries
                  ?.filter(
                    (subsidiary) => subsidiary.id !== branchOptionDestiny
                  )
                  .map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
              ></CustomSelect>
            </Form.Item>
          )}

          <Form.Item
            {...formConfig}
            name="destinyBranchId"
            label="Sucursal de destino"
            rules={[
              {
                required: true,
                message: messages.required('Sucursal de destino'),
              },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.branch}
              disabled={id && true}
              onChange={(value) => setBranchOptionDestiny(value)}
              options={subsidiaries
                ?.filter((subsidiary) => subsidiary.id !== branchOption)
                .map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
            ></CustomSelect>
          </Form.Item>

          {id && (
            <Form.Item {...formConfig} label="Ordeno">
              <Input disabled={true} value={orderByName} />
            </Form.Item>
          )}

          {id && (
            <Form.Item {...formConfig} label="Aprobo">
              <Input disabled={true} defaultValue={logedUserName} />
            </Form.Item>
          )}

          <TransferProducts
            privileges={privileges}
            form={form}
            isCreateMode={action === 'create'}
            isApproveMode={action === 'approve'}
            isConfirmMode={action === 'confirm'}
            stockByBranch={stockByBranch}
            showStock={action === 'create'}
          />

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            form="onSubmit"
            loading={isLoading}
          >
            {action === 'approve' ? buttonLabel.approve : buttonLabel.save}
          </Button>
          {privileges.includes('approve') &&
            !['create', 'confirm'].includes(action) && (
              <Button type="danger" className="btn-form" onClick={onReject}>
                {buttonLabel.reject}
              </Button>
            )}
        </Form>
        <CancelButton path="/dashboard/transferencias" />
      </Card>
    </>
  );
};
