import { Select } from 'antd';
import { phoneMask } from '../../../util';

const filterData = (input, option) => {
  if (isNaN(Number(input))) {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else {
    const found =
      option.label.toLowerCase().indexOf(phoneMask(input.toLowerCase())) >= 0;
    if (found) {
      return found;
    }
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
};

export const CustomSelect = (props) => {
  return (
    <>
      <Select
        {...props}
        showArrow
        showSearch
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) => filterData(input, option)}
      ></Select>
    </>
  );
};
