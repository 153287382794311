import { GET_ALL_SALES, REFUND_SALE } from '../types';

const initialState = {
  sales: [],
  refundSale: {},
};

export default function Sales(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SALES:
      return {
        ...state,
        sales: action.payload,
      };
    case REFUND_SALE:
      return {
        ...state,
        refundSale: action.payload,
      };
    default:
      return state;
  }
}
