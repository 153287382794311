import { Divider, Select, Space } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { confirmAppointment } from '../../../../appRedux/actions';
import {
  appointmentConst,
  appointmentStatus,
  confirmMethodsConst,
  placeHolders,
  timeFormat,
  timeFormat12,
} from '../../../../constants';
import { hexToRGB } from '../../../../util';

export const PopoverBody = ({ appointment, privileges }) => {
  const dispatch = useDispatch();
  const currentDateTime = `${appointment?.date} ${appointment?.endTime}`;
  const isBefore = moment(currentDateTime).isBefore(moment());

  const startTime = moment(appointment?.startTime, [timeFormat]).format(
    timeFormat12
  );
  const endTime = moment(appointment?.endTime, [timeFormat]).format(
    timeFormat12
  );

  const role =
    appointment?.treatmentType?.name === appointmentConst.DOCTOR
      ? appointmentConst.DOCTOR
      : appointmentConst.COSMETOLOGIST;
  const color = `rgb(${hexToRGB(appointmentStatus[appointment?.status].color)}`;

  const handleChange = async (confirmMethod) => {
    await dispatch(confirmAppointment(appointment.id, { confirmMethod }));
  };

  return (
    <div
      style={{
        color,
        maxWidth: '250px',
      }}
    >
      <p className="method">Cita # {appointment?.appointmentNumber}</p>
      <p className="method">{appointment?.treatment?.name}</p>
      <p className="method">
        Tipo de tratamiento: {appointment?.treatmentType?.name}
      </p>
      <p className="method">Horario: {`${startTime} a ${endTime}`}</p>
      {appointment?.attendedBy && (
        <p className="attendedBy">{`${role}:  ${appointment?.attendedBy?.name} ${appointment?.attendedBy?.lastName}`}</p>
      )}

      <p className="method">
        Agendo:{' '}
        {`${appointment?.user?.name} ${appointment?.user?.lastName} ${
          appointment?.user?.motherLastName || ''
        }`}
      </p>
      <p className="method">
        Observaciones: {`${appointment?.observations || ''}`}
      </p>

      {privileges.includes('edit') && (
        <>
          <Divider style={{ backgroundColor: color }} />
          <Space
            style={{
              display: 'flex',
              margin: 0,
              justifyContent: 'center',
              color,
            }}
            align="baseline"
          >
            <Select
              defaultValue={
                appointment.confirmMethod && appointment.confirmMethod
              }
              placeholder={placeHolders.confirmAppointment}
              style={{ width: 150, color }}
              onChange={handleChange}
              options={confirmMethodsConst}
              disabled={isBefore}
              id="selectConfirmAppointment"
            />
          </Space>
        </>
      )}
    </div>
  );
};
