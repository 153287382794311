export const MaritalStatus = [
  { label: 'Soltero(a)', value: 'soltero' },
  { label: 'Casado(a)', value: 'casado' },
  { label: 'Divorciado(a)', value: 'divorciado' },
  {
    label: 'Separación',
    value: 'separación en proceso judicial',
  },
  { label: 'Viudo(a)', value: 'viudo' },
  { label: 'Concubinato', value: 'concubinato' },
];

export const Gender = [
  { label: 'Masculino', value: 'masculino' },
  { label: 'Femenino', value: 'femenino' },
  { label: 'Otro', value: 'otro' },
]

export const WorkShift = [
  { label: 'Matutino', value: 'matutino' },
  { label: 'Intermedio', value: 'intermedio' },
  { label: 'Vespertino', value: 'vespertino' },
];

export const EducationalDegree = [
  { label: 'Inicial', value: 'inicial' },
  { label: 'Básica', value: 'básica' },
  { label: 'Media Superior', value: 'media superior' },
  { label: 'Superior', value: 'superior' },
];

export const BloodType = [
  { label: 'A+', value: 'a+' },
  { label: 'O+', value: 'o+' },
  { label: 'B+', value: 'b+' },
  { label: 'AB+', value: 'ab+' },
  { label: 'A-', value: 'a-' },
  { label: 'O-', value: 'o-' },
  { label: 'B-', value: 'b-' },
  { label: 'AB-', value: 'ab-' },
]
export const searchFilters = [
  { value: 'user.username', label: 'Correo' },
  { value: 'user.name', label: 'Nombre' },
  { value: 'user.lastName', label: 'Apellido Paterno' },
  { value: 'user.status', label: 'Status' },
  { value: 'phone.phone', label: 'Número' },
  { value: 'kardex.employee_number', label: 'Número de empleado'}
];

export const filters = {
  search: true,
  branches: true,
  status: true,
  roles: true,
  jobPositions: true,
};
export const searchInputInitialValues = {
  search: {filter: 'user.username'},
  status: '1',
}