import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UserTable } from '../../components/Users/UserTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Users = () => {
  const {
    privileges: { users = [] },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      {users.includes('create') && (
        <>
          <Button
            type='primary'
            htmlType='submit'
            onClick={() => {
              navigate('/dashboard/personal/crear');
            }}
          >
            <PlusOutlined />
            Nuevo
          </Button>
        </>
      )}
      <UserTable privileges={users} />
    </Card>
  );
};
