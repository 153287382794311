import {
  CREATE_NOTE,
  GET_NOTES,
  GET_NOTE_BY_ID,
  UPDATE_NOTE,
  GET_NOTE_DELETE,
  DELETE_NOTE,
} from '../types';
const initialState = {
  notes: [],
  noteById: {},
  noteDelete: null,
  note: {},
};
export default function Agenda(state = initialState, action) {
  switch (action.type) {
    case CREATE_NOTE:
      return {
        ...state,
        notes: [...state.notes, action.payload],
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case GET_NOTE_BY_ID:
      return {
        ...state,
        noteById: action.payload,
      };
    case UPDATE_NOTE:
      return {
        ...state,
        notes: state.notes.map((note) =>
          note.id === action.payload.id ? (note = action.payload) : note
        ),
      };
    case GET_NOTE_DELETE:
      return {
        ...state,
        note: action.payload,
      };
    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((note) => note.id !== state.noteDelete),
      };
    default:
      return state;
  }
}
