import { Col, Row } from 'antd';
import { categoryTypeDiccionary } from '../../../../constants/Category.const';

export const CategoryModalBody = ({ data }) => {
  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data.name}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Tipo</h4>
        </Col>
        <Col span={16}>{categoryTypeDiccionary[data.type]}</Col>
      </Row>
    </>
  );
};
