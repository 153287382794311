import {
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  GET_PROVIDERS,
  GET_PROVIDER_BY_ID,
  GET_PROVIDER_DELETE,
  UPDATE_PROVIDER,
} from '../types';

const initialState = {
  providers: [],
  providerById: {},
  providerDelete: null,
};

export default function Provider(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROVIDER:
      return {
        ...state,
        providers: [...state.providers, action.payload],
      };
    case GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      };
    case GET_PROVIDER_BY_ID:
      return {
        ...state,
        providerById: action.payload,
      };
    case UPDATE_PROVIDER:
      return {
        ...state,
        providers: state.providers.map((provider) =>
          provider.id === action.payload.id
            ? (provider = action.payload)
            : provider
        ),
      };
    case GET_PROVIDER_DELETE:
      return {
        ...state,
        providerDelete: action.payload,
      };

    case DELETE_PROVIDER:
      return {
        ...state,
        providers: state.providers.filter(
          (provider) => provider.id !== state.providerDelete
        ),
      };

    default:
      return state;
  }
}
