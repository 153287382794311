import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useNavigate, Link } from 'react-router-dom';
import {
  deleteItemModal,
  modalButtons,
  tableMenuOpt,
  buttonLabel,
} from '../../../constants';
import { useSelector } from 'react-redux';

export const TableMenuOpt = ({
  id = null,
  path = null,
  action = {},
  disabled = false,
  approved = true,
  rejected = false,
  privileges = [],
  isStock = false,
  originBranch = null,
  loggedBranch = null,
  confirmed = null,
  transfer = false,
  labels = null,
  distributionCenter = false,
}) => {
  const navigate = useNavigate();
  let options = tableMenuOpt(labels).filter((opt) =>
    privileges.includes(opt.key)
  );
  const { onDelete, onEdit, setMaxMin } = !!action && action;
  const { role } = useSelector((state) => state.userAuth);

  if (isStock && privileges.includes('approve')) {
    options = [
      ...options,
      tableMenuOpt(labels).find((value) => value.key === 'max/min'),
    ];
  }

  if (distributionCenter) {
    options = options.filter((value) => value.key !== 'delete');
  }

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'edit':
        onEdit
          ? onEdit.getModal(...onEdit.params)
          : navigate(`/dashboard/${path}/${id}`);
        break;
      case 'delete':
        showDeleteConfirm();
        break;
      case 'max/min':
        setMaxMin.getModal(...setMaxMin.params);
        break;
      default:
        break;
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(onDelete.action(...onDelete.params)), 1000);
        });
      },
      onCancel() {},
    });
  };

  if (transfer && !confirmed) {
    if (role === 'Super Administrador' && !rejected) {
      return (
        <Link className="link-center" to={`/dashboard/${path}/${id}`}>
          {buttonLabel.approve}
        </Link>
      );
    }

    if (
      !approved &&
      originBranch === loggedBranch &&
      role !== 'Super Administrador'
    ) {
      return <>{buttonLabel.approvePending}</>;
    }

    if (approved && originBranch === loggedBranch) {
      return <>{'En transito'}</>;
    }
  }

  return (
    <>
      {disabled ? (
        '-'
      ) : approved || rejected ? (
        <Dropdown.Button
          disabled={!(options.length > 0)}
          overlay={renderMenu(id)}
          trigger={['click']}
          placement="bottom"
        />
      ) : privileges.includes('approve') && !rejected ? (
        <Link className="link-center" to={`/dashboard/${path}/${id}`}>
          {buttonLabel.approve}
        </Link>
      ) : rejected ? (
        buttonLabel.rejected
      ) : (
        buttonLabel.approvePending
      )}
    </>
  );
};
