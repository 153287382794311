import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  GET_EXPENSES_BY_BRANCH,
  GET_GENERAL_CUSTOMER,
  GET_GENERAL_APPOINTMENTS,
  GET_GENERAL_EXPENSES,
  GET_GENERAL_INVENTORY,
  GET_MEDICAL_COMMISSIONS_BY_BRANCH,
  GET_INDIVIDUAL_COMMISSION_MEDICAL,
  GET_OCCUPANCY_PERCENTAGE_BY_BRANCH,
  GET_PROFITABILITY_BY_BRANCH,
  GET_STOCK_BY_BRANCH,
  GET_GENERAL_COSMETOLOGIC_FEES,
  GET_COSMETOLOGIC_FEE_BY_COSMETOLOGIST,
  GET_SCHEDULED_APPOINTMENTS,
  GET_DAILY_SALES,
  GET_MONTHLY_SALES,
  GET_DAILY_CASH_CLOSING,
} from '../types';
import { get } from './Action';

const basePath = '/report';

// Get stock by branch
export const getStockByBranch = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/get-stock-by-branch${queryParams}`, getStockByBranchDS)
  );
};

// Get expenses by branch
export const getBranchExpenses = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/branch-expenses${queryParams}`, getBranchExpensesDS)
  );
};

// Get general inventory
export const getGeneralInventory = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/general-inventory${queryParams}`, getGeneralInventoryDS)
  );
};

// Get general expenses
export const getGeneralExpenses = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/general-expenses-by-product${queryParams}`,
      getGeneralExpensesDS
    )
  );
};

// Get Medical Comissions by branch
export const getMedicalCommissions = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/general-medical-fees${queryParams}`,
      getMedicalCommissionsByBranch
    )
  );
};

// Get monthly sales
export const getMonthlySales = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/monthly-sales${queryParams}`, getMonthlySalesDB)
  );
};

//Get individual commission medical
export const getIndividualCommissionMedical = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/individual-medical-fees${queryParams}`,
      getIndividualCommissionMedicalDS
    )
  );
};

// Get profitability by branch
export const getProfitabilityByBranch = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/profitability-by-branch${queryParams}`,
      getProfitabilityByBranchDS
    )
  );
};

//Get occupancy percentage by branch
export const getOccupancyPercentageByBranch = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/occupation${queryParams}`,
      getOccupancyPercentageByBranchDS
    )
  );
};

//Get general customer
export const getGeneralCustomer = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/customers${queryParams}`, getGeneralCustomerDS)
  );
};

//Get general appointments
export const getGeneralAppointment = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/appointments${queryParams}`, getGeneralAppointmentDS)
  );
};

//Get general cosmetologic fees
export const getGeneralCosmetologicFees = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/general-cosmetology-fees${queryParams}`,

      getGeneralCosmetologicFeesDS
    )
  );
};

//Get cosmetologic fees by cosmetologist
export const getCosmetologicFeesByComestologist =
  (params) => async (dispatch) => {
    const queryParams = queryPathBuilder(params);

    return await dispatch(
      get(
        `${basePath}/individual-cosmetology-fees${queryParams}`,
        getCosmetologicFeesByComestologistDS
      )
    );
  };

//Get scheduled appointments
export const getScheduledAppointments = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(
      `${basePath}/scheduled-appointments${queryParams}`,
      getScheduledAppointmentsDS
    )
  );
};

//Get daily cash closing
export const getDailyCashClosing = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/daily-cash-closing${queryParams}`, getDailyCashClosingDS)
  );
};

//Get daily sales
export const getDailySales = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(
    get(`${basePath}/daily-sales${queryParams}`, getDailySalesDS)
  );
};

const getStockByBranchDS = (stock) => ({
  type: GET_STOCK_BY_BRANCH,
  payload: stock,
});

const getBranchExpensesDS = (expenses) => ({
  type: GET_EXPENSES_BY_BRANCH,
  payload: expenses,
});

const getGeneralInventoryDS = (inventory) => ({
  type: GET_GENERAL_INVENTORY,
  payload: inventory,
});

const getGeneralExpensesDS = (expenses) => ({
  type: GET_GENERAL_EXPENSES,
  payload: expenses,
});

const getMedicalCommissionsByBranch = (medicalComissions) => ({
  type: GET_MEDICAL_COMMISSIONS_BY_BRANCH,
  payload: medicalComissions,
});

const getProfitabilityByBranchDS = (profitability) => ({
  type: GET_PROFITABILITY_BY_BRANCH,
  payload: profitability,
});

const getOccupancyPercentageByBranchDS = (occupation) => ({
  type: GET_OCCUPANCY_PERCENTAGE_BY_BRANCH,
  payload: occupation,
});

const getGeneralCustomerDS = (customer) => ({
  type: GET_GENERAL_CUSTOMER,
  payload: customer,
});
const getGeneralAppointmentDS = (appointment) => ({
  type: GET_GENERAL_APPOINTMENTS,
  payload: appointment,
});

const getGeneralCosmetologicFeesDS = (cosmetologicFees) => ({
  type: GET_GENERAL_COSMETOLOGIC_FEES,
  payload: cosmetologicFees,
});

const getCosmetologicFeesByComestologistDS = (
  cosmetologicFeesByComsetologist
) => ({
  type: GET_COSMETOLOGIC_FEE_BY_COSMETOLOGIST,
  payload: cosmetologicFeesByComsetologist,
});

const getScheduledAppointmentsDS = (appointment) => ({
  type: GET_SCHEDULED_APPOINTMENTS,
  payload: appointment,
});

const getIndividualCommissionMedicalDS = (commission) => ({
  type: GET_INDIVIDUAL_COMMISSION_MEDICAL,
  payload: commission,
});

const getDailySalesDS = (sales) => ({
  type: GET_DAILY_SALES,
  payload: sales,
});

const getMonthlySalesDB = (sales) => ({
  type: GET_MONTHLY_SALES,
  payload: sales,
});

const getDailyCashClosingDS = (sales) => ({
  type: GET_DAILY_CASH_CLOSING,
  payload: sales,
});
