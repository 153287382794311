export const couponTypeConst = [
  { value: 'Treatment', label: 'Tratamiento' },
  { value: 'Product', label: 'Producto' },
];

export const couponTypeDictionary = {
  Treatment: 'Tratamiento',
  Product: 'Producto',
};

export const searchFilters = [
  { value: 'coupon.code', label: 'Código' },
  { value: 'coupon.name', label: 'Nombre' },
  { value: 'coupon.product', label: 'Producto' },
];

export const filters = {
  search: true,
  status: true,
  branches: true,
  couponType: true,
};

export const searchInputInitialValues = {
  search: { filter: 'coupon.code' },
  status: '1',
};
