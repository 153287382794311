import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkin } from '../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../constants';

export const CheckinForm = ({ setIsModalOpen, initialValues }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();

  useEffect(() => {
    if ([200, 201].includes(response)) setIsModalOpen(false);
  }, [response, dispatch, setIsModalOpen]);

  const onSubmit = async (values) => {
    setResponse(await dispatch(checkin(values)));
  };

  return (
    <>
      <Card size="large" bordered={true} style={cardConfig}>
        <Form
          autoComplete="off"
          onFinish={onSubmit}
          initialValues={initialValues}
          form={form}
          {...formConfig}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: messages.required('Usuario'),
              },
              {
                type: 'email',
                message: messages.required('Email'),
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={placeHolders.email}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: messages.required('Contraseña'),
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={placeHolders.password}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="btn-form">
            {buttonLabel.checkin}
          </Button>
        </Form>
      </Card>
    </>
  );
};
