import { Card } from 'antd';
import { DiariesTable } from '../../components/Diary/DairyTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Diary = () => {
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <DiariesTable />
    </Card>
  );
};
