import React from 'react';
import { Row, Col } from 'antd';
import { twoColumnsFormConfig } from '../../../constants/Forms';
import { random } from 'lodash';

export const TwoColumnsFormItem = ({ children = [React.Component, React.Component] }) => {
  const childrens = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {...twoColumnsFormConfig, key: random()} );
    }
    return child;
  });
  return (
    <Row wrap>
      <Col span={12}>
        { childrens[0] }
      </Col>
      <Col span={12}>
        { childrens[1] }
      </Col>
    </Row>
  );
};
