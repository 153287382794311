import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSaleOrder, getSaleOrders } from '../../../../appRedux/actions';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { ModalView, ListTable, TableFilters } from '../../../uiComponents/';
import {
  filters,
  saleOrderConst,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/SaleOrder.const';
import { SaleOrderModal } from '../SaleOrderModal';
import { phoneMask } from '../../../../util/phoneMask';
import { SaleOrderTableOptions } from '../SaleOrderTableOptions';

export const SaleOrderTable = ({ privileges }) => {
  const { saleOrders } = useSelector((state) => state.saleOrder);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const dispatch = useDispatch();

  const getModalData = useCallback(() => {
    const [modalData] = saleOrders?.filter(
      (cabainType) => cabainType.id === catalogId
    );
    return modalData;
  }, [saleOrders, catalogId]);

  useEffect(() => {
    setModalTitle(
      `${saleOrderConst.modalHeader}: ${saleOrderConst.prefix}${
        getModalData()?.orderNumber
      }`
    );
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteSaleOrder(id));
  };

  const columns = [
    {
      title: 'Número de orden',
      sorter: true,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (number, { id }) => (
        <a
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {saleOrderConst.prefix + number}
        </a>
      ),
    },
    {
      title: 'Número de nota',
      sorter: true,
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => name,
    },
    {
      title: 'RFC',
      sorter: true,
      dataIndex: 'rfc',
      key: 'rfc',
      render: (rfc) => rfc || '-',
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'sucursal',
      render: (text) => text.name,
      path: 'branch.name',
    },
    {
      title: 'Teléfono',
      sorter: true,
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => phoneMask(phone),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id, approvedAt, rejectedAt }) => (
        <SaleOrderTableOptions
          id={id}
          privileges={privileges}
          path="ordenes-de-surtido"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          approved={approvedAt}
          rejected={rejectedAt}
        />
      ),
    },
  ];
  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Ordenes de venta'}
        getDataFunction={getSaleOrders}
      />
      <ModalView title={modalTitle} width={700}>
        <SaleOrderModal data={modalData} />
      </ModalView>
    </>
  );
};
