import { GET_ALL_JOB_POSITIONS } from '../types';

const initialState = {
  jobPositions: [],
};

export default function JobPosition(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_JOB_POSITIONS:
      return {
        ...state,
        jobPositions: action.payload,
      };
    default:
      return state;
  }
}