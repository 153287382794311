export const lockConst = {
  hour: 'hour',
  cabain: 'cabain',
  day: 'day',
  hoursRange: 'hours_range',
  daysRange: 'days_range',
  other: 'other',
  locksLabel: 'Bloqueos',
  lockLabel: 'Bloquear',
};

export const lockTypeDiccionary = {
  hour: 'Hora',
  cabain: 'Cabina',
  day: 'Día',
  hours_range: 'Rango de horas',
  days_range: 'Rango de dias',
};

export const lockReasonsConst = [
  { value: 'comida gerencia', label: 'Comida gerencia' },
  { value: 'comida', label: 'Comida' },
  { value: 'licencia', label: 'Licencia' },
  { value: 'incapacidad', label: 'Incapacidad' },
  { value: 'maestría', label: 'Maestría' },
  { value: 'capacitación', label: 'Capacitación' },
  { value: 'otro', label: 'Otro' },
];

export const lockTypeConst = [
  { value: lockConst.hour, label: 'Hora' },
  { value: lockConst.cabain, label: 'Cabina' },
  { value: lockConst.day, label: 'Día' },
  { value: lockConst.hoursRange, label: 'Rango de horas' },
  { value: lockConst.daysRange, label: 'Rango de días' },
];

export const hoursConst = [
  { value: '06:00:00', label: '6:30 AM' },
  { value: '07:00:00', label: '7:00 AM' },
  { value: '07:30:00', label: '7:30 AM' },
  { value: '08:00:00', label: '8:00 AM' },
  { value: '08:30:00', label: '8:30 AM' },
  { value: '09:00:00', label: '9:00 AM' },
  { value: '09:30:00', label: '9:30 AM' },
  { value: '10:00:00', label: '10:00 AM' },
  { value: '10:30:00', label: '10:30 AM' },
  { value: '11:00:00', label: '11:00 AM' },
  { value: '11:30:00', label: '11:30 AM' },
  { value: '12:00:00', label: '12:00 PM' },
  { value: '12:30:00', label: '12:30 PM' },
  { value: '13:00:00', label: '1:00 PM' },
  { value: '13:30:00', label: '1:30 PM' },
  { value: '14:00:00', label: '2:00 PM' },
  { value: '14:30:00', label: '2:30 PM' },
  { value: '15:00:00', label: '3:00 PM' },
  { value: '15:30:00', label: '3:30 PM' },
  { value: '16:00:00', label: '4:00 PM' },
  { value: '16:30:00', label: '4:30 PM' },
  { value: '17:00:00', label: '5:00 PM' },
  { value: '17:30:00', label: '5:30 PM' },
  { value: '18:00:00', label: '6:00 PM' },
  { value: '18:30:00', label: '6:30 PM' },
  { value: '19:00:00', label: '7:00 PM' },
  { value: '19:30:00', label: '7:30 PM' },
  { value: '20:00:00', label: '8:00 PM' },
  { value: '20:30:00', label: '8:30 PM' },
  { value: '21:00:00', label: '9:00 PM' },
  { value: '21:30:00', label: '9:30 PM' },
  { value: '22:00:00', label: '10:00 PM' },
];

export const daysConst = [
  { value: '0', label: 'Domingo' },
  { value: '1', label: 'Lunes' },
  { value: '2', label: 'Martes' },
  { value: '3', label: 'Miércoles' },
  { value: '4', label: 'Jueves' },
  { value: '5', label: 'Viernes' },
  { value: '6', label: 'Sábado' },
];

export const daysDictionary = {
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
};
