export const actionDictionary = {
  update: 'Actualizar',
  delete: 'Eliminar',
  create: 'Crear',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
};

export const statusDictionary = {
  absence: 'Inasistencia',
  finished: 'Terminada',
  inProcess: 'En proceso',
  scheduled: 'Agendada',
  cancelled: 'Cancelada',
};

export const dateStatus = [
  { value: 'absence', label: 'Inasistencia' },
  { value: 'finished', label: 'Terminada' },
  { value: 'inProcess', label: 'En proceso' },
  { value: 'scheduled', label: 'Agendada' },
  { value: 'cancelled', label: 'Cancelada' },
];

export const searchFilters = [
  { value: 'diary.appointmentNumber', label: 'Cita' },
];

export const searchInputInitialValues = {
  search: { filter: 'diary.appointmentNumber' },
};

export const filters = {
  search: true,
  actions: true,
  date: true,
  branches: true,
  dates: true,
  appointmentStatus: true,
};

export const changesDictionary = {
  appointmentType: 'Tipo de Cita',
  date: 'Fecha',
  startTime: 'Hora de inicio',
  term: 'Duración',
  appointmentMethod: 'Método de cita',
  paymentMethods: 'Método de pago',
  paymentReference: 'Referencia de pago',
  observations: 'Observaciones',
  branchId: 'Sucursal',
  cabainId: 'Cabina',
  customerId: 'Cliente',
  appointmentOption: 'Opción',
  treatmentsIds: 'Tratamiento',
  categoriesIds: 'Categoría',
  packageId: 'Paquete',
  promoId: 'Promoción',
  confirmMethod: 'Método de Confirmación',
  status: 'Status',
  packages: 'Paquetes',
  newTreatments: 'Nuevos Tratamientos',
  finished: 'Finalizado',
  inProcess: 'En proceso',
  promotions: 'Promociones',
  scheduled: 'Agendada',
  treatment: 'Tratamiento',
  name: 'Tratamiento',
  absence: 'Inasistencia',
};
