import { queryPathBuilder } from '../../util/queryPathBuilder';
import { GET_AREAS } from '../types';

import { get } from './Action';

const basePath = '/area';

// Get Data
export const getAreas = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(get(`${basePath}${queryParams}`, getAreasDS));
};

// Get Areas
const getAreasDS = (areas) => ({
  type: GET_AREAS,
  payload: areas,
});
