import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { SaleOrderForm } from '../../components/SaleOrder/SaleOrderForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const SaleOrder = () => {
  const {
    privileges: { saleOrder = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      <SaleOrderForm privileges={saleOrder} />
    </Card>
  );
};
