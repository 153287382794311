import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getDailyCashClosing } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { stringDate } from '../../../../util';
import { formatPrice } from '../../../../util/formatPrice';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import React from 'react';
import {
  dailyCashClosingReportConst,
  monthDictionay,
} from '../../../../constants';

export const DailyCashClosingReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { dailyCashClosing } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(dailyCashClosingReportConst.title),
    sheet: dailyCashClosingReportConst.title,
  });
  const header = dailyCashClosingReportConst.header;
  let dayMxnTx = 0;
  let dayDlsTx = 0;
  let dayDlsToMxTx = 0;
  let dayCashTotalMx = 0;
  let dayOnline = 0;
  let dayApp = 0;
  let dayTdc = 0;
  let dayTdd = 0;
  let dayTotalDccTx = 0;
  let dayMxnProduct = 0;
  let dayDlsProduct = 0;
  let dayDlsToMxnProduct = 0;
  let dayTotalDcccProduct = 0;
  let dayDdcTotal = 0;
  let finalDay = 0;

  useEffect(() => {
    if (filtersData.dates)
      dispatch(getDailyCashClosing({ dates: filtersData.dates }));
  }, [dispatch, filtersData.dates]);

  useEffect(() => {
    if (filtersData.dates) {
      const [date1, date2] = filtersData.dates;

      setPeriod(
        `${moment(date1).format('DD/MM/YYYY')} - ${moment(date2).format(
          'DD/MM/YYYY'
        )}`
      );
    }
  }, [filtersData.dates]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          display: 'block',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={dailyCashClosingReportConst.title}
          period={period}
          col1={15}
          col2={0}
          col3={19}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {dailyCashClosing?.map((array) => {
            finalDay = array.length - 1;
            dayMxnTx = 0;
            dayDlsTx = 0;
            dayDlsToMxTx = 0;
            dayCashTotalMx = 0;
            dayOnline = 0;
            dayApp = 0;
            dayTdc = 0;
            dayTdd = 0;
            dayTotalDccTx = 0;
            dayMxnProduct = 0;
            dayDlsProduct = 0;
            dayDlsToMxnProduct = 0;
            dayTotalDcccProduct = 0;
            dayDdcTotal = 0;

            return array?.map((row, key) => {
              dayMxnTx += Number(row.mxnTX);
              dayDlsTx += Number(row.dlsTX);
              dayDlsToMxTx += Number(row.dlsToMxTx);
              dayCashTotalMx += Number(row.cashTotalMx);
              dayOnline += Number(row.online);
              dayApp += Number(row.app);
              dayTdc += Number(row.tdc);
              dayTdd += Number(row.tdd);
              dayTotalDccTx += Number(row.totalDccTx);
              dayMxnProduct += Number(row.mxnProduct);
              dayDlsProduct += Number(row.dlsProduct);
              dayDlsToMxnProduct += Number(row.dlsToMxnProduct);
              dayTotalDcccProduct += Number(row.totalDcccProduct);
              dayDdcTotal += Number(row.ddcTotal);

              return (
                <React.Fragment key={key}>
                  <tr>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      }}
                    >
                      {row.branch}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {monthDictionay[row.month]}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      }}
                    >
                      {stringDate(row.date)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      }}
                    >
                      {row?.inCharch || '-'}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.mxnTX)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                        backgroundColor: '#e6e9eb',
                      }}
                    >
                      {formatPrice(row.dlsTX)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.dlsToMxTx)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.cashTotalMx)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.online)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.app)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.tdc)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.tdd)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.totalDccTx)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.mxnProduct)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                        backgroundColor: '#e6e9eb',
                      }}
                    >
                      {formatPrice(row.dlsProduct)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.dlsToMxnProduct)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.totalDcccProduct)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.ddcTotal)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        padding: '15px',
                        textAlign: 'center',
                      }}
                    >
                      {formatPrice(row.exchageRate)}
                    </td>
                  </tr>
                  {key === finalDay && (
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#FD0C01',
                          color: '#FFFFFF',
                          padding: '15px',
                        }}
                      >
                        TOTAL POR DIA
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayMxnTx)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayDlsTx)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayDlsToMxTx)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayCashTotalMx)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayOnline)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayApp)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayTdc)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayTdd)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayTotalDccTx)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayMxnProduct)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayDlsProduct)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayDlsToMxnProduct)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayTotalDcccProduct)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        {formatPrice(dayDdcTotal)}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          textAlign: 'center',
                          backgroundColor: '#e6e9eb',
                          padding: '15px',
                        }}
                      >
                        N/A
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
};
