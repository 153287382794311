import { Card } from 'antd';
import { WeMissYouTable } from '../../components/WeMissYou/WeMissYouTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const WeMissYou = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <WeMissYouTable  />
    </Card>
  );
};
