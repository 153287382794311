import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useNavigate } from 'react-router-dom';
import {
  assortmentStatusConst,
  assortmentTableMenuOpt,
  deleteItemModal,
  modalButtons,
  notDeleteableStatus,
  roleConst,
} from '../../../../constants';

export const AssortmentOrderTableOptions = ({
  id = null,
  path = null,
  action = {},
  finished = false,
  status = '',
  privileges = [],
  role = null,
  approved = false,
}) => {
  const navigate = useNavigate();
  const { onDelete } = !!action && action;
  let options = assortmentTableMenuOpt().filter((opt) =>
    privileges.includes(opt.privilege)
  );

  if (approved && !finished && roleConst.branchManager === role) {
    options.push({
      label: 'Editar',
      key: 'edit',
      icon: <EditOutlined />,
      privilege: 'edit',
    });
  } else if (finished || !privileges.includes('approve')) {
    options =
      options.filter((opt) => opt.key !== 'edit') ||
      status.includes(assortmentStatusConst.deleted);
  }

  if (notDeleteableStatus.includes(status) || finished) {
    options = options.filter((opt) => opt.key !== 'delete');
  }

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'edit':
        navigate(`/dashboard/${path}/${id}`);
        break;
      case 'delete':
        showDeleteConfirm();
        break;
      case 'pdf':
        window.open(
          `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/assortment-order/${id}/download`,
          '_blank',
          'noopener,noreferrer'
        );
        break;
      default:
        break;
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(onDelete.action(...onDelete.params)), 1000);
        });
      },
      onCancel() {},
    });
  };

  return (
    <Dropdown.Button
      disabled={!(options.length > 0)}
      overlay={renderMenu(id)}
      trigger={['click']}
      placement="bottom"
    />
  );
};
