import { Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { transferTableMenuOpt } from '../../../../constants/Transfer.const';
import {
  deleteItemModal,
  modalButtons,
  roleConst,
} from '../../../../constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { useSelector } from 'react-redux';

export const TransferTableOptions = ({
  id = null,
  path = null,
  action = {},
  approved = false,
  rejected = false,
  accepted = false,
  deleted = false,
  privileges = [],
  isOriginBranch = false,
}) => {
  const navigate = useNavigate();
  const { onDelete } = !!action && action;
  const { role } = useSelector((state) => state.userAuth);
  let options = transferTableMenuOpt(approved, rejected).filter((opt) =>
    privileges.includes(opt.privilege)
  );

  if (role !== roleConst.superAdmin) {
    if (!isOriginBranch || accepted || rejected || approved)
      options = options.filter((opt) => opt.key !== 'delete');

    if ((!approved || !accepted) && isOriginBranch)
      options = options.filter((opt) => opt.key !== 'edit');
  }

  if (deleted || accepted || rejected || approved)
    options = options.filter((opt) => opt.key !== 'delete');

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'edit':
        navigate(`/dashboard/${path}/${id}`);
        break;
      case 'pdf':
        window.open(
          `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/transfer/${id}/download`,
          '_blank',
          'noopener,noreferrer'
        );
        break;
      case 'delete':
        showDeleteConfirm();
        break;

      default:
        break;
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(onDelete.action(...onDelete.params)), 1000);
        });
      },
      onCancel() {},
    });
  };

  return (
    <Dropdown.Button
      disabled={!(options.length > 0)}
      overlay={renderMenu(id)}
      trigger={['click']}
      placement="bottom"
    />
  );
};
