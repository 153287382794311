import { GET_PRODUCT_TYPES } from '../types';
import { get } from './Action';

const basePath = '/product-type';

export const getProductTypes = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getProductTypessDS, true));
};

// List Data
const getProductTypessDS = (productTypes) => ({
  type: GET_PRODUCT_TYPES,
  payload: productTypes.items,
});
