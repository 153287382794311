import { Card } from 'antd';
import { AppointmentTable } from '../../components/Agenda/AppointmentTable';
import { BackButton } from '../../uiComponents/BackButton';
import { useSelector } from 'react-redux';

export const Appointments = () => {
  const {
    privileges: { dairy = [] },
  } = useSelector((state) => state.userAuth);
  return (
    <Card
      title={
        <BackButton
          path="/dashboard/agenda"
          label="Citas"
          bordered={true}
          className="gx-card"
        />
      }
      bordered={true}
      className="gx-card"
    >
      <AppointmentTable privileges={dairy} />
    </Card>
  );
};
