import {
  AUTH_CHEKIN,
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  GET_DOCTORS,
  GET_COSMETOLOGISTS,
  GET_USER_BY_ID,
  GET_USER_DELETE,
  SAVE_IMAGE_USER_ID,
  UPDATE_USER,
  DATA_DELETED,
} from '../types';

import { unmaskPhone } from '../../util';
import { dateFormat } from '../../constants';
import moment from 'moment';
import { post, put, get, remove } from './Action';
import { queryPathBuilder } from '../../util/queryPathBuilder';

const basePath = '/user';

// Create User
export const createUser = (user) => async (dispatch) => {
  if (user) {
    const photo =
      user?.photos?.length > 0 ? user.photos[0].originFileObj : null;
    user = preparePayload(user);
    const userObj = await dispatch(
      post(`${basePath}/create`, user, createUserDS, true)
    );

    if (userObj && photo) await dispatch(createPhoto(photo, userObj?.data?.id));
    return userObj?.status;
  }
};

// Get Users
export const getUsers = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getUsersDS, true));
};

// Get Doctors
export const getDoctors =
  (branchId = '') =>
  async (dispatch) => {
    return await dispatch(get(`${basePath}/doctors/${branchId}`, getDoctorsDS));
  };

// Get Cosmetologist
export const getCosmetologists =
  (branchId = '') =>
  async (dispatch) => {
    return await dispatch(
      get(`${basePath}/cosmetologists/${branchId}`, getCosmetologistsDS)
    );
  };

// Get User By Id
export const getUserById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getUserByIdDS));
};

// Edit User By Id
export const editUser = (id, user, originalUser) => async (dispatch) => {
  const photo = user?.photos?.length > 0 ? user.photos[0].originFileObj : null;
  user = preparePayload(user, originalUser);

  const userObj = await dispatch(
    put(`${basePath}/${id}/update`, user, editUserDS, true)
  );

  if (userObj && photo) await dispatch(createPhoto(photo, userObj?.data?.id));
  return userObj?.status;
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteUserDS)
  );
  dispatch(getUserDeleteDS(id));
  return response;
};

// Create Photos
export const createPhoto = (file, userId) => async (dispatch) => {
  let photo = new FormData();
  photo.append('image', file);
  return await dispatch(
    post(`${basePath}/${userId}/photo`, photo, createPhotosDS)
  );
};

export const checkin = (values) => async (dispatch) => {
  return await dispatch(post(`${basePath}/checkin`, values, checkinDS));
};

const preparePayload = (values, originalUser = null) => {
  const mainContact = originalUser?.kardex?.contacts?.find(
    (contact) => contact.main === 1
  );
  const mainJobReference = originalUser?.kardex?.jobReferences?.find(
    (jobReference) => jobReference.main === 1
  );
  const firstSign = originalUser?.kardex?.signatures?.find(
    (sign) => sign.number === 1
  );

  const payload = {
    name: values.name,
    lastName: values.lastName,
    motherLastName: values.motherLastName,
    username: values.username,
    roleId: values.roleId,
    status: values.status,
    kardex: {
      kardexId: originalUser?.kardex?.id,
      employeeNumber: values.employeeNumber,
      jobPositionId: values.jobPositionId,
      branchId: values.branchId,
      birthdate: values.birthdate,
      admissionDate: values.admissionDate,
      workShift: values.workShift,
      departureDate: values.departureDate !== 0 ? values.reentryDate : null,
      reentryDate: values.reentryDate !== 0 ? values.reentryDate : null,
    },
    address: {
      addressId: originalUser?.kardex?.address?.id,
      address: values.address,
      state: values.state,
      city: values.city,
      addressINE: values.addressINE,
      stateINE: values.stateINE,
      cityINE: values.cityINE,
    },
    bankInformation: {
      bankInformationId: originalUser?.kardex?.bankInformation?.id,
      salary: values.salary.toString(),
      bankAccount: values.bankAccount,
      isr: parseFloat(values.isr),
    },
    contact: [
      {
        contactId: mainContact?.id,
        name: values.mainContactName,
        relationships: values.mainContactRelationship,
        phone: values.mainContactPhone
          ? unmaskPhone(values.mainContactPhone)
          : '',
        main: 1,
      },
    ],
    jobReference: [
      {
        jobReferenceId: mainJobReference?.id,
        company: values.mainJobReferenceName,
        position: values.mainJobReferencePosition,
        boss: values.mainJobReferenceBoss,
        phone: values.mainJobReferencePhone
          ? unmaskPhone(values.mainJobReferencePhone)
          : '',
        main: 1,
      },
    ],
    personalData: {
      personalDataId: originalUser?.kardex?.personalData?.id,
      gender: values.gender,
      maritalStatus: values.maritalStatus,
      nationality: values.nationality,
      birthState: values.birthState,
      birthCity: values.birthCity,
      nss: values.nss,
      curp: values.curp,
      rfc: values.rfc,
      ocr: values.ocr,
      infonavit: parseInt(values.infonavit),
      educationalDegree: values.educationalDegree,
      profession: values.profession,
      professionalLicense: values.professionalLicense,
      provisionalProfesionalLicense: values.provisionalProfesionalLicense,
      childrens: parseInt(values.childrens),
      email: values.personalEmail,
      bloodType: values.bloodType,
      diseases: values.diseases,
      allergies: values.allergies,
      drugAllergies: values.drugAllergies,
    },
    phone: [
      {
        phoneId: originalUser?.kardex?.phone[0]?.id,
        phone: values.phone ? unmaskPhone(values?.phone) : '',
        main: 1,
      },
    ],
    signature: [
      {
        signatureId: firstSign?.id,
        date: values.sign1,
        number: 1,
      },
    ],
  };

  if (
    values.secondaryContactName ||
    values.secondaryContactRelationship ||
    values.secondaryContactPhone
  ) {
    const secondaryContact = originalUser?.kardex?.contacts?.find(
      (contact) => contact.main !== 1
    );

    payload.contact.push({
      contactId: secondaryContact?.id,
      name: values.secondaryContactName,
      relationships: values.secondaryContactRelationship,
      phone: values.secondaryContactPhone
        ? unmaskPhone(values.secondaryContactPhone)
        : '',
    });
  }

  if (
    values.secondaryJobReferenceName ||
    values.secondaryJobReferencePosition ||
    values.secondaryJobReferenceBoss ||
    values.secondaryJobReferencePhone
  ) {
    const secondaryJobReference = originalUser?.kardex?.jobReferences?.find(
      (jobReference) => jobReference.main !== 1
    );

    payload.jobReference.push({
      jobReferenceId: secondaryJobReference?.id,
      company: values.secondaryJobReferenceName,
      position: values.secondaryJobReferencePosition,
      boss: values.secondaryJobReferenceBoss,
      phone: values.secondaryJobReferencePhone
        ? unmaskPhone(values.secondaryJobReferencePhone)
        : '',
    });
  }

  if (values.sign2) {
    const secondSign = originalUser?.kardex?.signatures?.find(
      (sign) => sign.number === 2
    );
    payload.signature.push({
      signatureId: secondSign?.id,
      date: values.sign2,
      number: 2,
    });
  }

  if (values.sign3) {
    const thirdSign = originalUser?.kardex?.signatures?.find(
      (sign) => sign.number === 3
    );
    payload.signature.push({
      signatureId: thirdSign?.id,
      date: values.sign3,
      number: 3,
    });
  }

  if (values.sign4) {
    const fourthSign = originalUser?.kardex?.signatures?.find(
      (sign) => sign.number === 4
    );
    payload.signature.push({
      signatureId: fourthSign?.id,
      date: values.sign4,
      number: 4,
    });
  }
  return payload;
};

const userObjToForm = (user) => {
  const { kardex } = user;
  /* User Step */
  user.roleId = user?.role?.id;

  /* Init Step */
  user.employeeNumber = kardex?.employeeNumber;
  user.branchId = kardex?.mainBranch?.id;
  user.workShift = kardex?.workShift;
  user.jobPositionId = kardex?.jobPosition?.id;
  user.departmentId = kardex?.jobPosition?.department?.id;
  user.birthdate = moment(kardex?.birthdate, dateFormat);
  user.phone = kardex?.phone[0]?.phone;
  user.salary = kardex?.bankInformation?.salary;
  user.bankAccount = kardex?.bankInformation?.bankAccount;
  user.isr = kardex?.bankInformation?.isr;
  user.admissionDate = moment(kardex?.admissionDate, dateFormat);
  user.reentryDate = moment(kardex?.reentryDate) | '';
  user.departureDate = moment(kardex?.departureDate) | '';

  /* Presonal Data Step */
  user.gender = kardex?.personalData?.gender;
  user.maritalStatus = kardex?.personalData?.maritalStatus;
  user.nationality = kardex?.personalData?.nationality;
  user.birthState = kardex?.personalData?.birthState;
  user.birthCity = kardex?.personalData?.birthCity;
  user.nss = kardex?.personalData?.nss;
  user.curp = kardex?.personalData?.curp;
  user.rfc = kardex?.personalData?.rfc;
  user.ocr = kardex?.personalData?.ocr;
  user.infonavit = kardex?.personalData?.infonavit?.toString();
  user.educationalDegree = kardex?.personalData?.educationalDegree;
  user.profession = kardex?.personalData?.profession;
  user.professionalLicense = kardex?.personalData?.professionalLicense;
  user.provisionalProfesionalLicense =
    kardex?.personalData?.provisionalProfesionalLicense;
  user.childrens = kardex?.personalData?.childrens;
  user.personalEmail = kardex?.personalData?.email;
  user.bloodType = kardex?.personalData?.bloodType;
  user.diseases = kardex?.personalData?.diseases;
  user.allergies = kardex?.personalData?.allergies;
  user.drugAllergies = kardex?.personalData?.drugAllergies;

  /* Addresses Step */
  user.address = kardex?.address?.address;
  user.city = kardex?.address?.city;
  user.state = kardex?.address?.state;
  user.addressINE = kardex?.address?.addressINE;
  user.cityINE = kardex?.address?.cityINE;
  user.stateINE = kardex?.address?.stateINE;

  /* Contacts Step */
  const mainContact = kardex?.contacts?.find((contact) => contact.main === 1);
  user.mainContactName = mainContact?.name;
  user.mainContactRelationship = mainContact?.relationships;
  user.mainContactPhone = mainContact?.phone;

  const secondary = kardex?.contacts?.find((contact) => contact.main !== 1);
  user.secondaryContactName = secondary?.name;
  user.secondaryContactRelationship = secondary?.relationships;
  user.secondaryContactPhone = secondary?.phone;

  /* JobReferences Step */

  const mainJobReference = kardex?.jobReferences?.find(
    (jobReference) => jobReference.main === 1
  );
  user.mainJobReferenceName = mainJobReference?.company;
  user.mainJobReferencePosition = mainJobReference?.position;
  user.mainJobReferenceBoss = mainJobReference?.boss;
  user.mainJobReferencePhone = mainJobReference?.phone;

  const secondaryJobReference = kardex?.jobReferences?.find(
    (jobReference) => jobReference.main !== 1
  );
  user.secondaryJobReferenceName = secondaryJobReference?.company;
  user.secondaryJobReferencePosition = secondaryJobReference?.position;
  user.secondaryJobReferenceBoss = secondaryJobReference?.boss;
  user.secondaryJobReferencePhone = secondaryJobReference?.phone;

  /* Contracts Step */

  const firstSign = kardex?.signatures?.find((sign) => sign.number === 1);
  const secondSign = kardex?.signatures?.find((sign) => sign.number === 2);
  const thirdSign = kardex?.signatures?.find((sign) => sign.number === 3);
  const fourthSign = kardex?.signatures?.find((sign) => sign.number === 4);

  user.sign1 = firstSign ? moment(firstSign.date, dateFormat) : null;
  user.sign2 = secondSign ? moment(secondSign.date, dateFormat) : null;
  user.sign3 = thirdSign ? moment(thirdSign.date, dateFormat) : null;
  user.sign4 = fourthSign ? moment(fourthSign.date, dateFormat) : null;

  return user;
};

const createUserDS = (user) => ({
  type: CREATE_USER,
  payload: user,
});

const getUsersDS = (users) => ({
  type: GET_USERS,
  payload: users.items,
});

const getDoctorsDS = (users) => ({
  type: GET_DOCTORS,
  payload: users,
});

const getCosmetologistsDS = (users) => ({
  type: GET_COSMETOLOGISTS,
  payload: users,
});

const getUserByIdDS = (user) => ({
  type: GET_USER_BY_ID,
  payload: userObjToForm(user),
});

const editUserDS = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const getUserDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteUserDS = () => ({
  type: DELETE_USER,
});

// SAVE ID IMAGE
const createPhotosDS = (id) => ({
  type: SAVE_IMAGE_USER_ID,
  payload: id,
});
// CHECKIN
const checkinDS = (checkin) => ({
  type: AUTH_CHEKIN,
  payload: checkin,
});
