import { Col, Row, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCashSaleById } from '../../../../appRedux/actions';
import { setPaymentStatus, stringDate } from '../../../../util';
import { formatPrice } from '../../../../util/formatPrice';

export const CashSalesModalBody = ({ id }) => {
  const dispatch = useDispatch();
  const { cashSaleById } = useSelector((state) => state.cashSales);

  useEffect(() => {
    dispatch(getCashSaleById(id));
  }, [dispatch, id]);

  const columns = [
    {
      title: 'Articulo',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product?.name,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Precio',
      dataIndex: 'salePrice',
      key: 'salePrice',
      render: (salePrice) => formatPrice(salePrice),
    },
    {
      title: 'Total',
      dataIndex: 'price',
      key: 'price',
      render: (price, item) => formatPrice(price * item?.quantity),
    },
  ];
  return (
    <>
      <Row className="row">
        <Col span={4}>
          <h4>No. Orden</h4>
        </Col>
        <Col span={8}>{cashSaleById?.orderNumber || '-'}</Col>
        <Col span={4}>
          <h4 className="strong"> Vendedor</h4>
        </Col>
        <Col span={8}>{`${cashSaleById?.seller?.name || '-'} ${
          cashSaleById?.seller?.lastName || ''
        } ${cashSaleById?.seller?.motherLastName || ''}`}</Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Fecha</h4>
        </Col>
        <Col span={8}>{stringDate(cashSaleById?.created_at) || '-'}</Col>
        <Col span={4}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={8}>{cashSaleById?.branch?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4 className="strong"> Cliente</h4>
        </Col>
        <Col span={8}>{`${cashSaleById?.customer?.name || '-'} ${
          cashSaleById?.customer?.lastName || ''
        } ${cashSaleById?.customer?.motherLastName || ''}`}</Col>
        <Col span={4}>
          <h4>Descuento</h4>
        </Col>
        <Col span={8}>
          {cashSaleById?.discountTotal
            ? formatPrice(cashSaleById?.discountTotal)
            : '-'}
          {cashSaleById?.coupon && (
            <Tag style={{ marginLeft: 5 }}>{cashSaleById.coupon?.name}</Tag>
          )}
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Puntos</h4>
        </Col>
        <Col span={8}>
          <Tag>{cashSaleById?.points}</Tag>
        </Col>
        <Col span={4}>
          <h4>Subtotal</h4>
        </Col>
        <Col span={8}>
          {cashSaleById?.subtotal ? formatPrice(cashSaleById?.subtotal) : '-'}
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Método de pago</h4>
        </Col>
        <Col span={8}>{cashSaleById?.paymentMethod || 'Efectivo'}</Col>
        <Col span={4}>
          <h4>IVA</h4>
        </Col>
        <Col span={8}>
          {cashSaleById?.taxes ? formatPrice(cashSaleById?.taxes) : '-'}
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Estado de pago</h4>
        </Col>
        <Col span={8}>
          {setPaymentStatus(cashSaleById?.paymentStatus || 'paid')}
        </Col>
        <Col span={4}>
          <h4>Total</h4>
        </Col>
        <Col span={8}>
          {cashSaleById?.total ? formatPrice(cashSaleById?.total) : '-'}
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}></Col>
        <Col span={8}></Col>
        <Col span={4}>
          <h4>Status</h4>
        </Col>
        <Col span={8}>
          <Tag color={cashSaleById?.refundedAt ? 'red' : 'blue'}>
            {cashSaleById?.refundedAt ? 'Devolución' : 'Venta'}
          </Tag>
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}></Col>
        <Col span={8}></Col>
        {cashSaleById?.refundReason && (
          <>
            <Col span={4}>
              <h4>Razón de devolución</h4>
            </Col>
            <Col span={8}>{cashSaleById?.refundReason || '-'}</Col>
          </>
        )}
      </Row>

      <Table
        pagination={{
          defaultPageSize: 3,
        }}
        dataSource={cashSaleById?.cashOrderDetails}
        columns={columns}
      />
    </>
  );
};
