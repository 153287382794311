import {
  GET_ALL_CASH_SALES,
  GET_CASH_SALE_BY_ID,
  GET_COSMETOLOGY_COMMISSIONS,
  GET_COSMETOLOGY_COMMISSION_BY_USER,
  UPDATE_SELLER,
} from '../types';

const initialState = {
  cashSales: [],
  cashSaleById: {},
  cosmetologyCommissionsByUser: [],
  cosmetologyCommissions: [],
};

export default function CashSales(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CASH_SALES:
      return {
        ...state,
        cashSales: action.payload,
      };
    case GET_CASH_SALE_BY_ID:
    case UPDATE_SELLER:
      return {
        ...state,
        cashSaleById: action.payload,
      };
    case GET_COSMETOLOGY_COMMISSION_BY_USER:
      return {
        ...state,
        cosmetologyCommissionsByUser: action.payload,
      };
    case GET_COSMETOLOGY_COMMISSIONS:
      return {
        ...state,
        cosmetologyCommissions: action.payload,
      };
    default:
      return state;
  }
}
