import {
  APPROVE_TRANSFER,
  CREATE_TRANSFER,
  GET_TRANSFERS,
  GET_TRANSFER_BY_ID,
  GET_TRANSFER_DELETE,
  TRANSFER_DELETE,
} from '../types';

const initialState = {
  transfers: [],
  transferById: {},
  assortmentOrderDelete: null,
};

export default function Transfer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TRANSFER:
      return {
        ...state,
        transfers: [...state.transfers, action.payload],
      };
    case GET_TRANSFERS:
      return {
        ...state,
        transfers: action.payload,
      };
    case GET_TRANSFER_BY_ID:
      return {
        ...state,
        transferById: action.payload,
      };
    case APPROVE_TRANSFER:
      return {
        ...state,
        transfers: state.transfers.map((transfer) =>
          transfer.id === action.payload.id
            ? (transfer = action.payload)
            : transfer
        ),
      };

    case GET_TRANSFER_DELETE:
      return {
        ...state,
        transferDelete: action.payload,
      };
    case TRANSFER_DELETE:
      return {
        ...state,
        transfers: state.transfers.filter(
          (transfer) => transfer.id !== state.transferDelete
        ),
      };

    default:
      return state;
  }
}
