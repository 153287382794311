import { Card } from 'antd';
import { CashSalesTable } from '../../components/CashSales/CashSalesTable';
import { BackButton } from '../../uiComponents/BackButton';

export const CashSales = () => {
  return (
    <Card
      title={
        <BackButton
          path="/dashboard/ventas-en-sucursal"
          label="Ventas de Producto"
          bordered={true}
          className="gx-card"
        />
      }
      bordered={true}
      className="gx-card"
    >
      <CashSalesTable />
    </Card>
  );
};
