import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './styles.css';
import { useEffect } from 'react';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

export const Loading = ({ isLoading }) => {
  const { loading } = useSelector((state) => state.loading);

  useEffect(() => {}, [loading]);
  return (
    <>
      {loading ||
        (isLoading && (
          <Layout className="loading-container">
            <Spin indicator={antIcon} size="large" className="loading-spiner" />
          </Layout>
        ))}
    </>
  );
};
