import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Form, Input, Select } from 'antd';
import {
  createCategory,
  getCategoryById,
  updateCategory,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../../constants';
import './styles.css';
import { CancelButton } from '../../../uiComponents';
import { categoryTypeConst } from '../../../../constants/Category.const';

export const CategoryForm = () => {
  const [res, setRes] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const category = useSelector((state) => state.category.categoryById);

  useEffect(() => {
    if (id) dispatch(getCategoryById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (category && id) {
      form.setFieldsValue(category);
    }
  }, [category, form, id]);

  useEffect(() => {
    if ((res && res === 201) || res === 200) {
      navigateTo('/dashboard/categorias');
    } else {
      setRes();
    }
  }, [navigateTo, res]);

  const onHandledSubmit = async (values) => {
    setIsLoading(true);
    if (id) {
      setRes(await dispatch(updateCategory(id, values)));
    } else {
      setRes(await dispatch(createCategory(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onHandledSubmit}
          form={form}
        >
          <Form.Item
            {...formConfig}
            rules={[{ required: true, message: messages.required('Tipo') }]}
            name="type"
            label="Tipo"
          >
            <Select
              placeholder={placeHolders.type}
              options={categoryTypeConst}
            ></Select>
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[{ required: true, message: messages.required('Nombre') }]}
            name="name"
            label="Nombre"
          >
            <Input />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/categorias" />
      </Card>
    </>
  );
};
