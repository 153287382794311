import {
  CREATE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENT_BY_ID,
  CLEAR_APPOINTMENT_BY_ID,
  UPDATE_APPOINTMENT,
  GET_APPOINTMENT_CANCEL,
  CANCEL_APPOINTMENT,
  CONFIRM_APPOINTMENT,
  SET_ON_PROCESS,
  SET_BRANCH,
  FINISH_APPOINTMENT,
  GET_APPOINTMENT_BY_CUSTOMER,
} from '../types';

const initialState = {
  appointments: [],
  appointmentById: {},
  appointmentDelete: null,
  appointmentConfirmation: null,
  selectedBranchId: null,
  updateAgenda: false,
  appointmentByCustomer: [],
};
export default function Agenda(state = initialState, action) {
  switch (action.type) {
    case CREATE_APPOINTMENT:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case GET_APPOINTMENT_BY_ID:
      return {
        ...state,
        appointmentById: action.payload,
      };
    case CLEAR_APPOINTMENT_BY_ID:
      return {
        ...state,
        appointmentById: {},
      };
    case UPDATE_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((appointment) =>
          appointment.id === action.payload.id
            ? (appointment = action.payload)
            : appointment
        ),
      };
    case GET_APPOINTMENT_CANCEL:
      return {
        ...state,
        appointment: action.payload,
      };
    case CANCEL_APPOINTMENT:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
      };
    case SET_ON_PROCESS:
      return {
        ...state,
        appointments: state.appointments.map((appointment) =>
          appointment.id === action.payload.id
            ? (appointment = action.payload)
            : appointment
        ),
      };
    case FINISH_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((appointment) =>
          appointment.id === action.payload.id
            ? (appointment = action.payload)
            : appointment
        ),
      };
    case CONFIRM_APPOINTMENT:
      return {
        ...state,
        appointmentConfirmation: action.payload,
      };
    case SET_BRANCH:
      return {
        ...state,
        selectedBranchId: action.payload.branch,
      };
    case GET_APPOINTMENT_BY_CUSTOMER:
      return {
        ...state,
        appointmentByCustomer: action.payload,
      };
    default:
      return state;
  }
}
