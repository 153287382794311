import { Descriptions } from 'antd';
import moment from 'moment/moment';
import { stringDate } from '../../../../util/formatTime';
import { changesDictionary } from '../../../../constants/Logs.const';

export const ChangesModalBody = ({ data, product }) => {
  const changes = JSON.parse(data.changes);

  const getValue = (value) => {
    let isDate = false;

    if (isNaN(value))
      isDate = moment(new Date(value), 'YYYY-MM-DD', true).isValid();

    return (isDate ? stringDate(value) : value)?.toString().toLowerCase();
  };

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label={'Cambios'} style={{ width: '30%' }}>
          Antes
        </Descriptions.Item>
        <Descriptions.Item>Después</Descriptions.Item>
      </Descriptions>
      {Object.entries(changes).map(([key, value]) => (
        <>
          <Descriptions key={key} bordered>
            <Descriptions.Item
              label={
                product?.name
                  ? `${changesDictionary[key]} ${ 'Cantidad'}`
                  : changesDictionary[key]
              }
              style={{ width: '30%' }}
            >
              {getValue(
                value?.before
                  ? changesDictionary[value?.before]
                    ? changesDictionary[value?.before]
                    : value?.before
                  : '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item>
              {getValue(
                value?.after
                  ? changesDictionary[value?.after]
                    ? changesDictionary[value?.after]
                    : value?.after
                  : '-'
              )}
            </Descriptions.Item>
          </Descriptions>
        </>
      ))}
    </>
  );
};
