import { Card } from 'antd';
import { BranchApplianceForm } from '../../components/BranchAppliances/BranchApplianceForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const BranchAppliance = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <BranchApplianceForm />
    </Card>
  );
};
