import { useEffect, useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralAppointment } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { generalAppointmentReportConst } from '../../../../constants';

export const GeneralAppointmentReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { generalAppointment } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(generalAppointmentReportConst.title),
    sheet: generalAppointmentReportConst.title,
  });
  const header = generalAppointmentReportConst.header;
  let fullTotalInPerson = 0;
  let fullTotalcall = 0;
  let fullTotalSocialMedia = 0;
  let fullTotalApp = 0;
  let fullTotalEcommerce = 0;
  let fullTotalFullOperation = 0;
  let fullTotal = 0;

  useEffect(() => {
    if (filtersData.dates)
      dispatch(getGeneralAppointment({ dates: filtersData.dates }));
  }, [dispatch, filtersData.dates]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          margin: '0 auto',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={generalAppointmentReportConst.title}
          col1={12}
          col2={0}
          col3={16}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                colSpan={2}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {generalAppointment?.map((row, key) => {
            fullTotalInPerson += Number(row.inPerson);
            fullTotalcall += Number(row.call);
            fullTotalSocialMedia += Number(row.socialMedia);
            fullTotalApp += Number(row.app);
            fullTotalEcommerce += Number(row.ecommerce);
            fullTotalFullOperation += Number(row.fullOperation);
            fullTotal += Number(row.total);

            return (
              <tr key={key}>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.inPerson}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.call}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.socialMedia}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.app}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.ecommerce}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.fullOperation}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.total}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              <h4>TOTALES DE CITAS</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {fullTotalInPerson}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotalcall}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotalSocialMedia}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotalApp}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotalEcommerce}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotalFullOperation}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotal}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
