import { Card } from 'antd';
import { appointmentStatus } from '../../../../constants';
import { hexToRGB } from '../../../../util';

export const AppointmentExtension = ({ appointment }) => {
  return (
    <Card
      bordered={true}
      className="appointment-item"
      style={{
        margin: '0 auto',
        backgroundColor: `rgba(${hexToRGB(
          appointmentStatus[appointment?.status].color
        )}, 0.15)`,
        color: `#${appointmentStatus[appointment?.status].color}`,
        width: '100%',
        borderRadius: '0',
      }}
    ></Card>
  );
};
