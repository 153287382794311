import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Form, Input, Switch } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCustomer,
  getCustomerById,
  updateCustomer,
  getTutors,
  resetCustomer,
} from '../../../../appRedux/actions';

import {
  genders,
  maritalStatus,
  buttonLabel,
  cardConfig,
  formConfig,
  lengthValidation,
  masks,
  messages,
  dateFormat,
  placeHolders,
  dateFormatMx,
} from '../../../../constants';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { getAge, phoneMask, unmaskPhone } from '../../../../util';
import { CustomSelect } from '../../../uiComponents';
import { CancelButton } from '../../../uiComponents/CancelButton';

export const CustomerForm = ({ onModal = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerById } = useSelector((state) => state.customer);
  const { tutors } = useSelector((state) => state.customer);

  const { id } = useParams();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();
  const [status, setStatus] = useState(true);
  const [blackList, setBlackList] = useState(false);
  const [age, setAge] = useState();
  const [tutor, setTutor] = useState(false);
  const { closeModal } = useCustomModal();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) dispatch(getCustomerById(id));
    dispatch(getTutors());
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response) && !onModal) {
      dispatch(resetCustomer());
      navigate('/dashboard/clientes');
    } else if (response && [200, 201].includes(response) && onModal) {
      closeModal();
    }
  }, [navigate, response, onModal, closeModal, dispatch]);

  useEffect(() => {
    if (id === customerById.id) {
      const { birthday, status, blackList, isTutor, ...data } = customerById;
      if (birthday != undefined) {
        setAge(getAge(birthday));
      }
      setStatus(status);
      setBlackList(blackList);
      setTutor(isTutor);
      if (birthday != undefined) {
        form.setFieldsValue({
          ...data,
          birthday: moment(birthday),
          tutorId: customerById?.tutor?.id,
        });
      }
    }
  }, [form, id, customerById]);

  const onAge = (value) => {
    const day = moment(value).format(dateFormat);
    const birthday = getAge(day);
    setAge(birthday);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (values.phone) values.phone = unmaskPhone(values.phone);

    if (values.mobilePhone)
      values.mobilePhone = unmaskPhone(values.mobilePhone);

    if (id) {
      setResponse(
        await dispatch(
          updateCustomer(id, {
            ...values,
            name: values?.name,
            lastName: values?.lastName,
            motherLastName: values?.motherLastName,
            status: status,
            blackList: blackList,
            isTutor: tutor,
            email: values.email ? values.email : null,
          })
        )
      );
    } else {
      setResponse(await dispatch(createCustomer(values)));
    }
    setIsLoading(false);
  };
  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form
        {...formConfig}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          rules={[{ required: true, message: messages.required('Nombre') }]}
          name="name"
          label="Nombre"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: messages.required('Apellido paterno') },
          ]}
          name="lastName"
          label="Apellido paterno"
        >
          <Input />
        </Form.Item>
        <Form.Item name="motherLastName" label="Apellido materno">
          <Input />
        </Form.Item>
        <Form.Item name="gender" label="Género">
          <CustomSelect placeholder="Género" options={genders}></CustomSelect>
        </Form.Item>
        <Form.Item
          name="birthday"
          label="Fecha de nacimiento"
          rules={[
            {
              required: true,
              message: messages.required('Fecha de nacimiento'),
            },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={dateFormatMx}
            value={moment('2020-08-01', dateFormat)}
            onChange={(object, dateString) => onAge(object, dateString)}
          />
        </Form.Item>
        <Form.Item name="phone" label="Teléfono">
          {(customerById.id === id || !id) && (
            <MaskedInput
              mask={masks.phone}
              defaultValue={
                id
                  ? customerById?.phone
                    ? phoneMask(customerById.phone)
                    : null
                  : null
              }
            />
          )}
        </Form.Item>
        <Form.Item
          name="mobilePhone"
          label="Celular"
          rules={[
            {
              required: age >= 18 ? true : false,
              message: messages.required('Celular'),
            },
          ]}
        >
          {(customerById.id === id || !id) && (
            <MaskedInput
              mask={masks.phone}
              defaultValue={
                id
                  ? customerById?.mobilePhone
                    ? phoneMask(customerById?.mobilePhone)
                    : null
                  : null
              }
            />
          )}
        </Form.Item>
        <Form.Item
          name="email"
          label="Correo electrónico"
          rules={[
            {
              max: 100,
              message: lengthValidation('Correo electrónico', 100),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="maritalStatus" label="Estado civil">
          <CustomSelect
            placeholder="Estado civil"
            options={maritalStatus}
          ></CustomSelect>
        </Form.Item>
        <Form.Item label="Edad">
          <Input disabled={true} value={age} />
        </Form.Item>
        <Form.Item name="originCountry" label="País de origén">
          <Input />
        </Form.Item>
        <Form.Item name="reference" label="Referencia">
          <Input />
        </Form.Item>
        {age < 18 && (
          <>
            <Form.Item
              {...formConfig}
              name="tutorId"
              label="Tutor"
              rules={[
                {
                  required: true,
                  message: messages.required('Tutor'),
                },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.tutor}
                allowClear
                options={tutors.map(
                  ({ id, name, lastName, motherLastName, mobilePhone }) => ({
                    value: id,
                    label: `${name} ${lastName} ${motherLastName || ''}  - 
                    ${phoneMask(mobilePhone)}`,
                  })
                )}
              ></CustomSelect>
            </Form.Item>

            <Form.Item
              name="relationship"
              label="Parentesco"
              rules={[
                {
                  required: true,
                  message: messages.required('Parentesco'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        {age >= 18 && (
          <>
            <Form.Item name="isTutor" label="Tutor">
              <Switch
                checked={tutor}
                onChange={setTutor}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              ></Switch>
            </Form.Item>
          </>
        )}
        {id && (
          <>
            <Form.Item label="Status">
              <Switch
                checked={status}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setStatus}
              ></Switch>
            </Form.Item>

            <Form.Item label="Lista negra">
              <Switch
                checked={blackList}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setBlackList}
              ></Switch>
            </Form.Item>
          </>
        )}
        <Button
          type="primary"
          htmlType="submit"
          className="btn-form"
          loading={isLoading}
        >
          {buttonLabel.save}
        </Button>
        {onModal && (
          <Button
            type="danger"
            className="danger-button"
            onClick={() => {
              closeModal();
              // dispatch(getCustomers({ blackList: '0' }));
            }}
          >
            {buttonLabel.cancel}
          </Button>
        )}
        {!onModal && <CancelButton path="/dashboard/clientes" />}
      </Form>
    </Card>
  );
};
