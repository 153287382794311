import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

export const ExportButton = ({ onDownload, label }) => {
  return (
    <Space
      size={50}
      style={{
        display: 'flex',
        justifyContent: 'start',
      }}
      align="baseline"
    >
      <Button
        icon={<FileExcelOutlined />}
        onClick={onDownload}
        className="export-btn"
      >
        {label ? label : 'Exportar'}
      </Button>
    </Space>
  );
};
