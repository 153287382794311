import { reportCurrentDateTime } from '../../../util';

export const ReportTableHeader = ({
  title,
  branch,
  period,
  user,
  role,
  col1,
  col2,
  col3,
}) => {
  return (
    <thead>
      <tr>
        <td colSpan={4}>
          <img
            alt="logo moldearte"
            src={`${process.env.REACT_APP_API}/v1/report/blue-logo`}
            style={{ margin: '0 auto' }}
            height={50}
          />
        </td>
        <td colSpan={col1 || 4} style={{ textAlign: 'right' }}>
          FECHA Y HORA DE REPORTE
        </td>
      </tr>
      <tr>
        <td colSpan={col1 || 5}></td>
        <td colSpan={4} style={{ textAlign: 'right' }}>
          {' '}
          {reportCurrentDateTime()}
        </td>
      </tr>
      <tr>
        {col2 !== 0 && <td colSpan={col2 || 2}></td>}
        <td
          colSpan={col3 || 8}
          style={{ textAlign: 'center', padding: '15px' }}
        >
          <h2>{title}</h2>
        </td>
      </tr>
      {branch && (
        <tr>
          <td colSpan={col1 || 5}></td>
          <td
            colSpan={col2 || 4}
            style={{
              textAlign: 'right',
              padding: '5px',
            }}
          >
            <b>SUCURSAL: {branch.toUpperCase()} </b>
          </td>
        </tr>
      )}
      {period && (
        <tr>
          <td colSpan={col1 || 5}></td>
          <td
            colSpan={col2 || 4}
            style={{
              textAlign: 'right',
              padding: '5px',
            }}
          >
            <b>PERIODO: </b> {period}
          </td>
        </tr>
      )}
      <tr>
        <td
          colSpan={col1 || 5}
          style={{
            textAlign: 'center',
            border: '1px solid black',
            padding: '15px',
          }}
        >
          <b>ELABORO:</b> {user}
        </td>
        <td
          colSpan={col2 || 4}
          style={{
            textAlign: 'center',
            border: '1px solid black',
            padding: '15px',
            whiteSpace: 'nowrap',
          }}
        >
          <b>PUESTO:</b> {role}
        </td>
      </tr>
    </thead>
  );
};
