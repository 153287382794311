import { Button, Tag } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSales } from '../../../../appRedux/actions';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
  salesConst,
} from '../../../../constants/Sales';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { stringDate } from '../../../../util/formatTime';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { SalesModalBody } from '../SalesModalBody';
import { formatPrice } from '../../../../util/formatPrice';
import { FileTextOutlined } from '@ant-design/icons';
import { setPaymentStatus } from '../../../../util/orders';
import { SaleTableOptions } from '../SaleTableOptions';
import { SalesRefundModalBody } from '../SalesRefundModalBody';

export const SalesTable = ({ saleType }) => {
  const {
    privileges: { branch: privileges = [] },
  } = useSelector((state) => state.userAuth);

  const { modal } = useCustomModal();
  const [title, setTitle] = useState({});
  const [width, setWidth] = useState(1150);
  const [children, setChildren] = useState(null);

  const getModal = (order, action) => {
    switch (action) {
      case 'refund':
        setTitle(`${salesConst.REFUND}: orden ${order.orderNumber}`);
        setChildren(<SalesRefundModalBody data={order} />);
        setWidth(850);
        break;
      default:
        setTitle(salesConst.DETAIL);
        setChildren(<SalesModalBody data={order} />);
        setWidth(1150);
    }
    modal();
  };

  const columns = [
    {
      title: 'Orden',
      sorter: true,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (orderNumber, order) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            getModal(order);
          }}
        >
          {orderNumber}
        </Button>
      ),
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name || '-',
      path: 'branch.name',
    },
    {
      title: 'Vendedor',
      sorter: true,
      dataIndex: 'seller',
      key: 'seller',
      render: (seller) => (
        <>
          {seller?.name} {seller?.lastName} {seller?.motherLastName}
        </>
      ),
      path: ['seller.name', 'seller.lastName', 'seller.motherLastName'],
      hidden: saleType === 'App' || saleType === 'online',
    },
    {
      title: 'Cliente',
      sorter: true,
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => (
        <>
          {customer?.name} {customer?.lastName} {customer?.motherLastName}
        </>
      ),
      path: ['customer.name', 'customer.lastName', 'customer.motherLastName'],
    },
    {
      title: 'Método de pago',
      sorter: true,
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (paymentMethod) => paymentMethod || '-',
    },
    {
      title: 'Total',
      sorter: true,
      dataIndex: 'total',
      key: 'total',
      render: (total, order) =>
        total ? `${order.refundedAt ? '-' : ''}${formatPrice(total)}` : '-',
    },
    {
      title: 'Fecha',
      sorter: true,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
      path: 'created_at',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'refundedAt',
      key: 'refundedAt',
      render: (refundedAt) => (
        <Tag color={refundedAt ? 'red' : 'blue'}>
          {refundedAt ? 'Devolución' : 'Venta'}
        </Tag>
      ),
      path: 'refundedAt',
      formatter: (refundedAt) => (refundedAt ? 'Devolución' : 'Venta'),
    },
    {
      title: 'Estado de pago',
      sorter: true,
      dataIndex: 'paymentStatus',
      render: (status) => setPaymentStatus(status),
      path: 'paymentStatus',
    },
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      render: (id, order) => {
        if (saleType !== 'App' && saleType !== 'online') {
          return (
            <Button
              icon={<FileTextOutlined />}
              type="link"
              onClick={() => {
                order.refundedAt
                  ? window.open(
                      `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/order/${id}/ticket/refund`,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  : window.open(
                      `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/order/${id}/ticket`,
                      '_blank',
                      'noopener,noreferrer'
                    );
              }}
            />
          );
        } else {
          return (
            <SaleTableOptions
              id={id}
              action={{
                refundOrder: {
                  getModal,
                  params: [order, 'refund'],
                },
              }}
              isRefund={order.refundedAt !== null}
              paymentStatus={order.paymentStatus}
              saleType={saleType}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <TableFilters
        params={{ saleType }}
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns.filter((item) => !item.hidden)}
        name={'Ventas'}
        getDataFunction={getSales}
        params={{ saleType }}
      />
      <ModalView width={width} title={title}>
        {children}
      </ModalView>
    </>
  );
};
