import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, deleteCategory } from '../../../../appRedux/actions';
import {
  ListTable,
  TableMenuOpt,
  ModalView,
  TableFilters,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { CategoryModalBody } from '../CategoryModalBody';
import { Button } from 'antd';
import {
  categoryTypeDiccionary,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Category.const';

export const CategoryTable = () => {
  const {
    privileges: { categories: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();

  const onModalClick = (category) => {
    modal();
    setModalData(category);
  };

  const onDelete = (id) => {
    dispatch(deleteCategory(id));
  };

  const columns = [
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name, category) => (
        <Button
          type="link"
          htmlType="button"
          onClick={() => onModalClick(category)}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Tipo',
      sorter: true,
      dataIndex: 'type',
      key: 'type',
      render: (type) => categoryTypeDiccionary[type],
      path: 'type',
      formatter: (type) => categoryTypeDiccionary[type],
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          path="categorias"
          privileges={privileges}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];
  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Categorías'}
        getDataFunction={getCategories}
      />
      <ModalView title={'Categorías'}>
        <CategoryModalBody data={modalData} />
      </ModalView>
    </>
  );
};
