import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  createAppointment,
  getAppointmentById,
  getCabainTypes,
  getPackageById,
  getPackages,
  updateAppointment,
  getTreatmentTypes,
  getMissedAppointments,
  getCabains,
  getAllSubsidiaries,
  getAllCustomers,
} from '../../../../appRedux/actions';
import {
  agendaAllAccessRoles,
  appointmentConst,
  buttonLabel,
  cardConfig,
  dateFormat,
  dateFormatMx,
  formConfig,
  messages,
  placeHolders,
  timeFormat,
} from '../../../../constants';
import {
  agendaConst,
  agendaOptionsConst,
  appointmentMethodConst,
} from '../../../../constants/Agenda.const';
import { packageConst } from '../../../../constants/Package.const';
import { CancelButton, CustomSelect, ModalView } from '../../../uiComponents';
import { TreatmentCategory } from '../CategoryTreatment';
import { phoneMask, setTimeFormat } from '../../../../util';
import moment from 'moment/moment';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { CustomerForm } from '../../Customer/CustomerForm';
import { getPurchasedProductsByCustomer } from '../../../../appRedux/actions/PurchasedProduct';

export const AppointmentForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { role } = useSelector((state) => state.userAuth);
  const { customers } = useSelector((state) => state.customer);
  const { packages } = useSelector((state) => state.packages);
  const { treatmentTypes } = useSelector((state) => state.treatmentType);
  const { appointmentData } = useSelector((state) => state.generalStates);
  const { appointmentById } = useSelector((state) => state.agenda);
  const { missedAppointments } = useSelector(
    (state) => state.missedAppointment
  );
  const { cabains } = useSelector((state) => state.cabain);
  const [response, setResponse] = useState();
  const [date, setDate] = useState();
  const [startTime, setStartTime] = useState();
  const [option, setOption] = useState(agendaConst.promotion);
  const [packageId, setPackageId] = useState();
  const [promotionId, setPromotionId] = useState();
  const { packageById } = useSelector((state) => state.packages);
  const { allSubsidiaries: subsidiaries } = useSelector(
    (state) => state.subsidiary
  );
  const [branchId] = useState(appointmentData?.branchId);
  const [term, setTerm] = useState();
  const [treatmentTypeId, setTreatmentTypeId] = useState();
  const [isBefore, setIsBefore] = useState(false);
  const [isScheduled, setInScheduled] = useState(true);
  const [customerId, setCustomerId] = useState();
  const [purchasedProducts, setPurchasedProduct] = useState([]);
  const [treatmentPackage, setTreatmentPackage] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [disabledButton, setDisableButton] = useState(false);
  const [remainingSessions, setRemainingSessions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { modal } = useCustomModal();
  const { customerPurchasedProducts } = useSelector((state) => state.customer);

  const promos = packages?.filter(
    (value) => value.type === packageConst.promotion
  );
  const packs = packages?.filter(
    (value) => value.type === packageConst.package
  );

  useEffect(() => {
    if (branchId) {
      dispatch(getCabains({ branchId }));
    }
  }, [branchId, form, dispatch]);

  useEffect(() => {
    if (customerId) {
      dispatch(getPurchasedProductsByCustomer(customerId));
    }
  }, [customerId, form, dispatch]);

  const onCabainChange = useCallback(
    (id) => {
      const cabain = cabains?.find((cabain) => cabain?.id === id);
      form.setFieldsValue({
        treatmentTypeId: cabain?.type?.treatmentType?.id,
      });

      setTreatmentTypeId(cabain?.type?.treatmentType?.id);
      form.setFieldsValue({ treatmentId: '', packageId: '', promoId: '' });
    },
    [cabains, form]
  );

  useEffect(() => {
    if (!id && appointmentData && Object.keys(appointmentData).length > 0) {
      const [time] = appointmentData?.time.split(' ');
      form.setFieldsValue({
        branchId: appointmentData?.branchId,
        cabainId: appointmentData?.cabainId,
        startTime: setTimeFormat(time),
        date: moment(appointmentData?.date),
      });

      setDate(appointmentData?.date);
      setStartTime(time);
      onCabainChange(appointmentData?.cabainId);
    }
  }, [appointmentData, form, id, navigate, cabains, onCabainChange]);

  useEffect(() => {
    if (id) dispatch(getAppointmentById(id));

    dispatch(
      getAllSubsidiaries({
        status: '1',
        agenda: '1',
        date: moment(appointmentData?.date),
      })
    );
    dispatch(
      getAllCustomers({
        blackList: '0',
        widthDeleted: 'false',
      })
    );
    dispatch(getCabainTypes());
    dispatch(getTreatmentTypes());
  }, [id, dispatch, appointmentData.date]);

  useEffect(() => {
    if (option)
      dispatch(
        getPackages({
          status: '1',
          withDeleted: 'false',
          branchesIds: [branchId],
          treatmentTypeId,
        })
      );
  }, [dispatch, option, form, branchId, treatmentTypeId]);

  useEffect(() => {
    if (packageId) dispatch(getPackageById(packageId));
  }, [packageId, dispatch]);

  useEffect(() => {
    if (promotionId) dispatch(getPackageById(promotionId));
  }, [promotionId, dispatch]);

  useEffect(() => {
    if ([200, 201].includes(response)) {
      navigate('/dashboard/agenda');
    }
  }, [navigate, response]);

  useEffect(() => {
    if (id && appointmentById) {
      setCustomerId(appointmentById?.customer?.id);
      setOption(appointmentById?.appointmentOption);
      setPackageId(appointmentById?.pendingProduct?.pkg?.id);
      setPromotionId(appointmentById?.pendingProduct?.pkg?.id);
      setDate(appointmentById?.date);
      setStartTime(appointmentById?.startTime);
      setTerm(appointmentById?.term);
      setTreatmentTypeId(appointmentById?.treatment?.treatmentType?.id);
      setIsBefore(
        moment(`${appointmentById?.date} ${appointmentById?.endTime}`).isBefore(
          moment()
        )
      );

      setInScheduled(appointmentById?.status === 'scheduled');
    }
  }, [id, appointmentById, form]);

  useEffect(() => {
    if (id && appointmentById) {
      form.setFieldsValue({
        ...appointmentById,
        branchId: appointmentById?.branch?.id,
        date: moment(appointmentById?.date),
        startTime: setTimeFormat(appointmentById?.startTime),
        term: appointmentById?.term
          ? setTimeFormat(appointmentById?.term)
          : appointmentById?.term,
        cabainId: appointmentById?.cabain?.id,
        customerId: appointmentById?.customer?.id,
        packageId: appointmentById?.pendingProduct?.pkg?.id,
        promoId: appointmentById?.pendingProduct?.pkg?.id,
        treatmentTypeId: appointmentById?.treatment?.treatmentType?.id,
        purchasedProductId: appointmentById?.purchasedProduct?.id,
        treatmentId: appointmentById?.treatment?.id,
      });
    }
  }, [id, appointmentById, form]);

  useEffect(() => {
    if (customerId && treatmentTypeId) {
      const customer = customers?.find(
        (customer) => customer.id === customerId
      );

      if (customer?.cancellations >= 3) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      }

      if (
        customer?.cancellations >= 3 &&
        !agendaAllAccessRoles.includes(role) &&
        option !== agendaConst.purchasedProducts
      ) {
        setDisableButton(true);
      } else if (
        customer?.cancellations >= 4 &&
        agendaAllAccessRoles.includes(role) &&
        option !== agendaConst.purchasedProducts
      ) {
        setDisableButton(true);
      } else if (['absence', 'finished'].includes(appointmentById?.status)) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  }, [customerId, treatmentTypeId, customers, role, option, appointmentById]);

  useEffect(() => {
    if (!packageId && !promotionId) {
      setTreatmentPackage([]);
      return;
    }

    if (packageById && treatmentTypeId) {
      const treatments = packageById?.treatmentPackage?.filter(
        () => packageById?.treatmentType?.id === treatmentTypeId
      );

      setTreatmentPackage(treatments);
    }
  }, [packageById, treatmentTypeId, packageId, promotionId, option]);

  const confirm = useCallback(() => {
    Modal.confirm({
      title: appointmentConst.MISSED_APPOINTMENTS_ALERT.TITLE,
      icon: <ExclamationCircleOutlined />,
      content: appointmentConst.MISSED_APPOINTMENTS_ALERT.DESCRIPTION,
      okText: appointmentConst.MISSED_APPOINTMENTS_ALERT.OK_BUTTON,
      cancelButtonProps: { style: { display: 'none' } },
      visible: isModalOpen,
      onOk: () => setDisableButton(true),
    });
  }, [isModalOpen]);

  useEffect(() => {
    if (appointmentById)
      dispatch(getMissedAppointments(appointmentById?.purchasedProduct?.id));
  }, [dispatch, appointmentById]);

  useEffect(() => {
    if (missedAppointments?.length >= 2 && !isModalOpen) {
      confirm();
      setIsModalOpen(true);
    }
  }, [missedAppointments, confirm, isModalOpen, appointmentById]);

  useEffect(() => {
    const products = customerPurchasedProducts?.filter(
      ({ treatment }) => treatment?.treatmentType?.id === treatmentTypeId
    );

    setPurchasedProduct(products);
  }, [customerPurchasedProducts, treatmentTypeId]);

  const onOptionChange = (value) => {
    setOption(value);
    setPackageId(null);
    setPromotionId(null);
    form.setFieldsValue({
      treatmentId: '',
      packageId: '',
      promoId: '',
      purchasedProductId: '',
      treatmentCategory: [''],
    });
  };

  const onPurchasedProductChange = (_, purchasedProduct) => {
    const remaining = [];
    const { sessions, currentSession } = !!purchasedProduct && purchasedProduct;

    for (let index = 1; index <= sessions - currentSession; index++) {
      remaining.push({
        value: index,
        label: index,
      });
    }

    setRemainingSessions(remaining);
  };

  const dateFormats = (value) => {
    const day = moment(value).format(dateFormat);
    setDate(day);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { treatmentCategory, ...data } = values;
    data.date = date;
    data.startTime =
      startTime.split(':').length === 2 ? `${startTime}:00` : startTime;
    data.term = term && term.split(':').length === 2 ? `${term}:00` : term;

    data.categoriesIds = treatmentCategory?.map((row) => row?.categoryId);

    if (!id && option === agendaConst.newTreatments) {
      data.treatmentsIds = treatmentCategory?.map((row) => row.treatmentId);
    } else if (!id && !data.treatmentsIds) {
      data.treatmentsIds = [values.treatmentId];
    }

    if (id) {
      data.treatmentId =
        treatmentCategory && treatmentCategory.length > 0
          ? treatmentCategory[0]?.treatmentId
          : data?.treatmentId;

      data.categoriesIds =
        treatmentCategory &&
        treatmentCategory.length > 0 &&
        treatmentCategory[0]?.categoryId;
      setResponse(await dispatch(updateAppointment(id, data)));
    } else {
      setResponse(await dispatch(createAppointment(data)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        {showAlert && (
          <Alert
            message={appointmentConst.ALERT.TITLE}
            description={appointmentConst.ALERT.DESCRIPTION}
            type="warning"
            showIcon
            closable
          />
        )}

        <Form
          disabled={isBefore || !isScheduled}
          {...formConfig}
          initialValues={{
            treatmentCategory: [''],
            appointmentOption: option,
          }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          {id && (
            <Form.Item label="Número de cita" name="appointmentNumber">
              <Input disabled={true} />
            </Form.Item>
          )}

          <Form.Item
            label="Sucursal"
            name="branchId"
            rules={[
              {
                required: true,
                message: messages.required('Sucursal'),
              },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.branch}
              disabled={true}
              options={subsidiaries?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            label="Cabina"
            name="cabainId"
            rules={[
              {
                required: true,
                message: messages.required('Cabina'),
              },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.cabain}
              onChange={onCabainChange}
              options={cabains?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            name="treatmentTypeId"
            label="Tipo de cita"
            rules={[
              {
                required: true,
                message: messages.required('Tipo de cita'),
              },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.appointmentType}
              onChange={setTreatmentTypeId}
              disabled={true}
              options={treatmentTypes?.map(({ id, appointmentName }) => ({
                value: id,
                label: appointmentName,
              }))}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: messages.required('Paciente') }]}
            name="customerId"
            label="Paciente"
          >
            <CustomSelect
              placeholder={placeHolders.patient}
              onChange={setCustomerId}
              disabled={id && true}
              options={customers
                ?.filter((user) => user.status === true)
                .map(({ id, name, lastName, motherLastName, mobilePhone }) => ({
                  value: id,
                  label: `${name} ${lastName} ${motherLastName || ''} ${
                    mobilePhone ? ' - ' + phoneMask(mobilePhone) : ''
                  }`,
                }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider />
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => modal()}
                  >
                    Paciente
                  </Button>
                </>
              )}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            name="date"
            label="Fecha de cita"
            rules={[
              {
                required: true,
                message: messages.required('Fecha de cita'),
              },
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={dateFormatMx}
              onChange={(object, dateString) => dateFormats(object, dateString)}
              disabledDate={(current) => {
                let customDate = moment().format(dateFormat);
                return current && current < moment(customDate, dateFormat);
              }}
            />
          </Form.Item>

          <Form.Item
            name="startTime"
            label="Hora de la cita"
            rules={[
              { required: true, message: messages.required('Hora de la cita') },
            ]}
          >
            <TimePicker
              format={timeFormat}
              minuteStep={30}
              onChange={(_, timeString) => setStartTime(timeString)}
            />
          </Form.Item>

          <Form.Item name="term" label="Duración">
            <TimePicker
              format={timeFormat}
              minuteStep={30}
              onChange={(_, timeString) => setTerm(timeString)}
            />
          </Form.Item>

          <Form.Item
            name="appointmentMethod"
            label="Método de cita"
            rules={[
              {
                required: true,
                message: messages.required('Método de cita'),
              },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.appointmentMethod}
              options={appointmentMethodConst}
            ></CustomSelect>
          </Form.Item>

          <Form.Item name="observations" label="Observaciones">
            <TextArea disabled={isBefore || !isScheduled} />
          </Form.Item>

          <Form.Item
            label="Opciones"
            name="appointmentOption"
            rules={[{ required: true, message: messages.required('Opciones') }]}
          >
            <CustomSelect
              onChange={onOptionChange}
              placeholder={placeHolders.agendaOptions}
              disabled={id && true}
              options={agendaOptionsConst}
            ></CustomSelect>
          </Form.Item>

          {option === agendaConst.promotion && (
            <Form.Item
              label="Promociones"
              name="promoId"
              placeholder={placeHolders.promo}
              disabled={id}
              rules={[
                { required: true, message: messages.required('Promociones') },
              ]}
            >
              <CustomSelect
                allowClear
                onChange={setPromotionId}
                options={promos?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {option === agendaConst.packages && (
            <Form.Item
              label="Paquetes"
              name="packageId"
              rules={[
                { required: true, message: messages.required('Paquetes') },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.package}
                allowClear
                onChange={setPackageId}
                disabled={id}
                options={packs?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {[agendaConst.promotion, agendaConst.packages].includes(option) && (
            <Form.Item
              label="Tratamiento"
              name="treatmentId"
              rules={[
                { required: true, message: messages.required('Tratamiento') },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.treatment}
                options={treatmentPackage?.map(({ treatment, areas }) => ({
                  value: treatment?.id,
                  label: `${treatment?.name} - ${areas
                    ?.map((area) => area?.name)
                    .join(', ')}`,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {option === agendaConst.purchasedProducts && (
            <>
              <Form.Item
                label="Tratamientos"
                name="purchasedProductId"
                placeholder={placeHolders.purchasedProducts}
                rules={[
                  { required: true, message: messages.required('Tratamiento') },
                ]}
              >
                <CustomSelect
                  allowClear
                  onChange={onPurchasedProductChange}
                  disabled={id && true}
                  options={purchasedProducts
                    .filter(
                      ({ currentSession, sessions, id }) =>
                        currentSession < sessions ||
                        id === appointmentById?.purchasedProduct?.id
                    )
                    .map(({ id, treatment, currentSession, sessions }) => ({
                      value: id,
                      label: `${currentSession}/${sessions} - ${treatment?.name}`,
                      sessions,
                      currentSession,
                    }))}
                ></CustomSelect>
              </Form.Item>
              <Form.Item
                {...formConfig}
                name="sessions"
                label="Sesiones"
                rules={[
                  { required: true, message: messages.required('Sesiones') },
                ]}
              >
                <Select
                  allowClear
                  options={remainingSessions}
                  disabled={id && true}
                ></Select>
              </Form.Item>
            </>
          )}

          {option === agendaConst.newTreatments && (
            <TreatmentCategory
              form={form}
              update={id}
              appointment={appointmentById}
              treatmentTypeId={treatmentTypeId}
            />
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            disabled={disabledButton}
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/agenda" />
      </Card>
      <ModalView title={'Agregar paciente'} width={900}>
        <CustomerForm onModal={true} />
      </ModalView>
    </>
  );
};
