import { get } from './Action';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  GET_ALL_DAILY_CASH_CLOSING,
  GET_ALL_DAILY_CASH_CLOSING_BY_BRANCH,
} from '../types';
const basePath = '/daily-cash-closing';

export const getDailyCashClosing = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getDailyCashClosingDS, true)
  );
};

export const getDailyCashClosingByBranch =
  (branchId, { params }) =>
  async (dispatch) => {
    const queryParams = queryPathBuilder(params);
    return await dispatch(
      get(
        `${basePath}/${branchId}${queryParams}`,
        getDailyCashClosingByBranchDS
      )
    );
  };

const getDailyCashClosingDS = (dailyCashClosing) => ({
  type: GET_ALL_DAILY_CASH_CLOSING,
  payload: dailyCashClosing.items,
});

const getDailyCashClosingByBranchDS = (dailyCashClosing) => ({
  type: GET_ALL_DAILY_CASH_CLOSING_BY_BRANCH,
  payload: dailyCashClosing.items,
});
