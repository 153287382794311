import { useEffect, useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralCustomer } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { getAge, phoneMask, stringDate } from '../../../../util';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { generalCustomerReportConst } from '../../../../constants';

export const GeneralCustomerReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { generalCustomer } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(generalCustomerReportConst.title),
    sheet: generalCustomerReportConst.title,
  });
  const header = generalCustomerReportConst.header;
  let counter = 0;
  let age = 0;

  useEffect(() => {
    dispatch(getGeneralCustomer({ dates: filtersData.dates }));
  }, [dispatch, filtersData.dates]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          display: 'block',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={generalCustomerReportConst.title}
          col1={32}
          col2={0}
          col3={36}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
                colSpan={2}
              >
                {item}
              </th>
            ))}
          </tr>
          {generalCustomer?.map((row, key) => {
            counter += Number(1);
            age = getAge(row.birthday);
            return (
              <tr key={key}>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {counter}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.customerId}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row?.registryBranch || '-'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {stringDate(row.created_at)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {stringDate(row.birthday)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {age}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row.name}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row.lastName}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row.motherLastName || '-'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.points}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {phoneMask(row.phone)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {phoneMask(row.mobilePhone)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {row.email || '-'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.gender || '-'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.maritalStatus || '-'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.originCountry || '-'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.blackList ? 'SI' : 'NO'}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.status ? 'SI' : 'NO'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
