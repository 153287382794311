import { Descriptions } from 'antd';
import { changesDictionary } from '../../../../constants/Diary.const';

export const ChangesModalBody = ({ data }) => {
  const changes = data ? JSON.parse(data) : {};
  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label={'Cambios'} style={{ width: '30%' }}>
          Antes
        </Descriptions.Item>
        <Descriptions.Item>Despues</Descriptions.Item>
      </Descriptions>

      { Object.entries(changes).map(([key, value]) => (
        <>
          <Descriptions key={key} bordered>
            <Descriptions.Item
              label={changesDictionary[key]}
              style={{ width: '30%' }}
            >
              {value?.before
                ? changesDictionary[value?.before]
                  ? changesDictionary[value?.before]
                  : value?.before
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item>
              {value?.after
                ? changesDictionary[value?.after]
                  ? changesDictionary[value?.after]
                  : value?.after
                : '-'}
            </Descriptions.Item>
          </Descriptions>
        </>
      ))}
    </>
  );
};
