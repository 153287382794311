import { Card } from 'antd';
import { LoyaltyTable } from '../../components/Loyalty/LoyaltyTable';

import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Loyalty = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <LoyaltyTable />
    </Card>
  );
};
