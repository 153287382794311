import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Space, Switch, TimePicker } from 'antd';
import moment from 'moment';
import {
  daysDictionary,
  messages,
  midnight,
  timeFormat,
  weekdaysConst,
} from '../../../constants';
import { branchConst } from '../../../constants/Branch.const';

export const Schedules = ({
  open,
  setOpen,
  onOpenChange,
  timeString,
  setTimeString,
  branchSchedules,
  isBranch = false,
}) => {
  return (
    <>
      {weekdaysConst.map((day) => {
        const [key] = Object.keys(day);
        return (
          <Form.Item key={key} label={day[key]}>
            <Space size={'large'}>
              <Switch
                checked={open[key]}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(value) => {
                  setOpen({
                    ...open,
                    ...{
                      [key]: value,
                    },
                  });
                  onOpenChange(key);
                }}
              />
              <Form.Item
                name={key}
                rules={[
                  {
                    required: open[key],
                    message: messages.required(day[key]),
                  },
                  () => ({
                    validator(_) {
                      if (isBranch) return Promise.resolve();

                      if (!branchSchedules || branchSchedules.length < 1) {
                        return Promise.reject(
                          new Error(branchConst.ERROR_BRANCH_WITHOUT_HOURS)
                        );
                      }

                      const currentSchedule = branchSchedules.find(
                        (schedule) => schedule.day === daysDictionary[key]
                      );

                      const [opening, closing] = timeString[key];

                      if (opening === midnight && closing === midnight)
                        return Promise.resolve();

                      if (
                        moment(opening, timeFormat).isBefore(
                          moment(currentSchedule.opening, timeFormat)
                        ) ||
                        moment(closing, timeFormat).isAfter(
                          moment(currentSchedule.closing, timeFormat)
                        )
                      ) {
                        return Promise.reject(
                          new Error(
                            `${branchConst.ERROR_OUTSIDE_BRANCH_OURS} ${currentSchedule.opening} - ${currentSchedule.closing}`
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TimePicker.RangePicker
                  format={timeFormat}
                  minuteStep={30}
                  disabled={!open[key]}
                  onChange={(_, timeStringArray) =>
                    setTimeString({
                      ...timeString,
                      ...{
                        [key]: timeStringArray,
                      },
                    })
                  }
                />
              </Form.Item>
            </Space>
          </Form.Item>
        );
      })}
    </>
  );
};
