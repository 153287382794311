import { Card } from 'antd';
import { TreatmentForm } from '../../components/Treatment/TreatmentForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Treatment = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <TreatmentForm />
    </Card>
  );
};
