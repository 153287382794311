import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getDailySales } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatPrice } from '../../../../util/formatPrice';
import { stringDate } from '../../../../util';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { formatFileName } from '../../../../util/strings';
import { getDaysInMonth } from '../../../../util/getDaysInMonth';
import { dailySalesReportConst, monthDictionay } from '../../../../constants';

export const DailySalesReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { dailySales } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(dailySalesReportConst.title),
    sheet: dailySalesReportConst.title,
  });
  const header = dailySalesReportConst.header;
  let fullMxnTx = 0;
  let fullDlsTx = 0;
  let fullDlsToMxTx = 0;
  let fullCashTotalMx = 0;
  let fullOnline = 0;
  let fullApp = 0;
  let fullTdc = 0;
  let fullTdd = 0;
  let fullTotalDccTx = 0;
  let fullMxnProduct = 0;
  let fullDlsProduct = 0;
  let fullDlsToMxnProduct = 0;
  let fullTotalDcccProduct = 0;
  let fullDdcTotal = 0;

  useEffect(() => {
    if (filtersData.branchId && filtersData.month)
      dispatch(
        getDailySales({
          month: filtersData.month,
          branchId: filtersData.branchId,
        })
      );
  }, [dispatch, filtersData.month, filtersData.branchId]);

  useEffect(() => {
    if (filtersData.month) {
      const [year, month] = filtersData.month.split('-');
      const daysInMonth = getDaysInMonth(year, month);
      setPeriod(`01/${month}/${year} - ${daysInMonth}/${month}/${year}`);
    }
  }, [filtersData.month]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          display: 'block',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={dailySalesReportConst.title}
          period={period}
          col1={15}
          col2={0}
          col3={19}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {dailySales?.map((row, key) => {
            fullMxnTx += Number(row.mxnTx);
            fullDlsTx += Number(row.dlsTx);
            fullDlsToMxTx += Number(row.dlsToMxTx);
            fullCashTotalMx += Number(row.cashTotalMx);
            fullOnline += Number(row.online);
            fullApp += Number(row.app);
            fullTdc += Number(row.tdc);
            fullTdd += Number(row.tdd);
            fullTotalDccTx += Number(row.totalDccTx);
            fullMxnProduct += Number(row.mxnProduct);
            fullDlsProduct += Number(row.dlsProduct);
            fullDlsToMxnProduct += Number(row.dlsToMxnProduct);
            fullTotalDcccProduct += Number(row.totalDcccProduct);
            fullDdcTotal += Number(row.ddcTotal);

            return (
              <tr key={key}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {monthDictionay[row.month]}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  {stringDate(row.date)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >
                  {row.user}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.mxnTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                    backgroundColor: '#e6e9eb',
                  }}
                >
                  {formatPrice(row.dlsTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.dlsToMxTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.cashTotalMx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.online)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.app)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.tdc)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.tdd)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalDccTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.mxnProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                    backgroundColor: '#e6e9eb',
                  }}
                >
                  {formatPrice(row.dlsProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.dlsToMxnProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalDcccProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.ddcTotal)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '15px',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.exchageRate)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={4}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#FD0C01',
                color: '#FFFFFF',
                padding: '15px',
              }}
            >
              TOTALES
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullMxnTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullDlsTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullDlsToMxTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullCashTotalMx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullOnline)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullApp)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullTdc)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullTdd)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullTotalDccTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullMxnProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullDlsProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullDlsToMxnProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullTotalDcccProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullDdcTotal)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              N/A
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
