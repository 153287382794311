import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { stringDate } from '../../../../util/formatTime';
import { deleteTransfer, getTransfers } from '../../../../appRedux/actions';
import { TransferStatus } from '../TransferStatus';
import { TransferModalBody } from '../TransferModalBody';

import {
  filters,
  searchFilters,
  searchInputInitialValues,
  transferConst,
} from '../../../../constants/Transfer.const';
import { Button } from 'antd';
import { TransferTableOptions } from '../TransferTableMenuOptions';

export const TransferTable = ({ privileges, loggedBranchId, role }) => {
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const { transfers } = useSelector((state) => state.transfer);

  const onDelete = (id) => {
    dispatch(deleteTransfer(id));
  };

  const getModalData = useCallback(() => {
    const [modalData] = transfers?.filter(
      (cabainType) => cabainType.id === catalogId
    );
    return modalData;
  }, [transfers, catalogId]);

  useEffect(() => {
    setModalTitle('Transferencias');
    setModalData(getModalData());
  }, [getModalData]);

  const addRowClass = (order) => {
    if (!order?.acceptedAt) return;

    return order?.transferProduct?.map((product) => {
      if (product?.receivedQuantity !== product?.stockedQuantity)
        return 'row-volcano';
      return null;
    });
  };

  const columns = [
    {
      title: 'Número de transferencia',
      sorter: true,
      dataIndex: 'transferNumber',
      key: 'transferNumber',
      render: (transfer, { id }) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {transferConst.prefix + transfer}
        </Button>
      ),
    },
    {
      title: 'Sucursal de origen',
      sorter: true,
      dataIndex: 'originBranch',
      key: 'originBranch',
      render: (branch) => branch?.name,
      path: 'originBranch.name',
    },
    {
      title: 'Fecha de creación',
      sorter: true,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
    },
    {
      title: 'Sucursal de destino',
      sorter: true,
      dataIndex: 'destinyBranch',
      key: 'destinyBranch',
      render: (branch) => branch?.name,
      path: 'destinyBranch.name',
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <TransferStatus status={status} />,
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (
        _,
        { id, approvedAt, rejectedAt, acceptedAt, originBranch, deleted_at }
      ) => (
        <TransferTableOptions
          id={id}
          path="transferencias"
          deleted={deleted_at}
          approved={approvedAt}
          rejected={rejectedAt}
          accepted={acceptedAt}
          privileges={privileges}
          isOriginBranch={originBranch?.id === loggedBranchId}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];
  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        role={role}
        loggedBranchId={loggedBranchId}
      />
      <ListTable
        columns={columns}
        standOut={true}
        rowClass={addRowClass}
        name={'Transferencias'}
        getDataFunction={getTransfers}
      />
      <ModalView title={modalTitle} width={900}>
        <TransferModalBody data={modalData} />
      </ModalView>
    </>
  );
};
