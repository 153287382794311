import { Col, Row } from 'antd';
import { stringDate } from '../../../../../util/formatTime';

export const ContractsData = ({ data }) => {
  const firstContract = data?.kardex?.signatures?.find(
    (sign) => sign.number === 1
  );
  const secondContract = data?.kardex?.signatures?.find(
    (sign) => sign.number === 2
  );
  const thirdContract = data?.kardex?.signatures?.find(
    (sign) => sign.number === 3
  );
  const lastContract = data?.kardex?.signatures?.find(
    (sign) => sign.number === 4
  );
  return (
    <>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Contratos Firmados</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Contrato 1</h4>
        </Col>
        <Col span={16}>{stringDate(firstContract?.date)}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Contrato 2</h4>
        </Col>
        <Col span={16}>{stringDate(secondContract?.date)}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Contrato 3</h4>
        </Col>
        <Col span={16}>{stringDate(thirdContract?.date)}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Contrato indefinido</h4>
        </Col>
        <Col span={16}>{stringDate(lastContract?.date)}</Col>
      </Row>
    </>
  );
};
