import { useSelector } from 'react-redux';
import { getCashSales } from '../../../../appRedux/actions';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/CashSales';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { stringDate } from '../../../../util';
import { Button, Tag } from 'antd';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { useRef, useState } from 'react';
import { CashSalesModalBody } from '../CashSalesModalBody';
import { salesConst } from '../../../../constants/Sales';
import { commissionConst } from '../../../../constants/Commissions.const';
import { CashSaleTableOptions } from '../CashSaleTableOptions';
import { SellerUpdate } from '../UpdateSeller';

export const CashSalesTable = () => {
  const {
    privileges: { branch: privileges = [] },
  } = useSelector((state) => state?.userAuth);
  const { cashSaleById } = useSelector((state) => state?.cashSales);
  const [title, setTitle] = useState({});
  const [children, setChildren] = useState(null);
  const { modal } = useCustomModal();
  const tableFilter = useRef(null);

  const getModal = (order, action) => {
    modal();

    switch (action) {
      case 'updateSeller':
        setTitle(commissionConst.COMMISSION_AGENT_UPDATE);
        setChildren(<SellerUpdate order={order} />);
        break;
      default:
        setTitle(salesConst.DETAIL);
        setChildren(<CashSalesModalBody id={order?.id} />);
    }
  };

  const columns = [
    {
      title: 'Orden',
      sorter: true,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (orderNumber, order) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            getModal(order);
          }}
        >
          {orderNumber}
        </Button>
      ),
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name || '-',
      path: 'branch.name',
    },
    {
      title: 'Vendedor',
      sorter: true,
      dataIndex: 'seller',
      key: 'seller',
      render: (seller) => (
        <>
          {seller?.name} {seller?.lastName} {seller?.motherLastName}
        </>
      ),
      path: ['seller.name', 'seller.lastName', 'seller.motherLastName'],
    },
    {
      title: 'Cliente',
      sorter: true,
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => (
        <>
          {customer?.name} {customer?.lastName} {customer?.motherLastName}
        </>
      ),
      path: ['customer.name', 'customer.lastName', 'customer.motherLastName'],
    },
    {
      title: 'Total',
      sorter: true,
      dataIndex: 'total',
      key: 'total',
      render: (total) => (total ? `$${total}` : '-'),
      path: 'total',
    },
    {
      title: 'Fecha',
      sorter: true,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
      path: 'created_at',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'refundedAt',
      key: 'refundedAt',
      render: (refundedAt) => (
        <Tag color={refundedAt ? 'red' : 'blue'}>
          {refundedAt ? 'Devolución' : 'Venta'}
        </Tag>
      ),
      path: 'refundedAt',
      formatter: (refundedAt) => (refundedAt ? 'Devolución' : 'Venta'),
    },
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      render: (id, order) => (
        <CashSaleTableOptions
          id={id}
          action={{
            updateSeller: {
              getModal,
              params: [order, 'updateSeller'],
            },
          }}
          isRefund={order.refundedAt !== null}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        state={cashSaleById}
        ref={tableFilter}
      />
      <ListTable
        columns={columns}
        name={'Ventas en Efectivo'}
        getDataFunction={getCashSales}
      />
      <ModalView width={900} title={title}>
        {children}
      </ModalView>
    </>
  );
};
