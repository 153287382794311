import {
  CREATE_GOAL,
  DELETE_GOAL,
  GET_GOAL,
  GET_GOAL_BY_ID,
  UPDATE_GOAL,
} from '../types';

const initialState = {
  goals: [],
  goalById: {},
  goalDelete: null,
};

export default function Goal(state = initialState, action) {
  switch (action.type) {
    case CREATE_GOAL:
      return {
        ...state,
        goals: [...state.goals, action.payload],
      };
    case GET_GOAL:
      return {
        ...state,
        goals: action.payload,
      };
    case GET_GOAL_BY_ID:
      return {
        ...state,
        goalById: action.payload,
      };
    case UPDATE_GOAL:
      return {
        ...state,
        goals: action.payload,
      };
    case DELETE_GOAL:
      return {
        ...state,
        goals: state.goals.filter((goal) => goal.id !== state.goalDelete),
      };
    default:
      return state;
  }
}
