import {
  CREATE_MAX_MIN,
  STOCK_ADJUSTMENT,
  GET_MAX_MIN_BY_BRANCH,
  GET_STOCK,
  UPDATE_MAX_MIN,
  GET_STOCK_BY_BRANCH_ID,
  GET_STOCK_DETAILS,
  GET_STOCK_BY_BRANCH_AND_TREATMENT,
  CLEAR_STOCK_BY_BRANCH,
} from '../types';

const initialState = {
  stock: [],
  maxMinByBranch: [],
  stockByBranch: [],
  stockDetails: [],
  maxMin: {},
  stockAdjustment: {},
  stockForTreatment: [],
};

export default function Stock(state = initialState, action) {
  switch (action.type) {
    case GET_STOCK:
      return {
        ...state,
        stock: action.payload,
      };

    case GET_STOCK_DETAILS:
      return {
        ...state,
        stockDetails: action.payload,
      };

    case STOCK_ADJUSTMENT:
      return {
        ...state,
        stockAdjustment: state.stock.map((stock) =>
          stock.id === action.payload.id ? (stock = action.payload) : stock
        ),
      };

    case GET_MAX_MIN_BY_BRANCH:
      return {
        ...state,
        maxMinByBranch: action.payload,
      };

    case CREATE_MAX_MIN:
      return {
        ...state,
        maxMin: state.stock.map((stock) =>
          stock.branchId === action.payload.branch.id &&
          stock.productId === action.payload.product.id
            ? {
                ...stock,
                mmId: action.payload.mmId,
                max: action.payload.max,
                min: action.payload.min,
              }
            : stock
        ),
      };

    case UPDATE_MAX_MIN:
      return {
        ...state,
        maxMin: state.stock.map((stock) =>
          stock.branchId === action.payload.branch.id &&
          stock.productId === action.payload.product.id
            ? {
                ...stock,
                mmId: action.payload.mmId,
                max: action.payload.max,
                min: action.payload.min,
              }
            : stock
        ),
      };

    case GET_STOCK_BY_BRANCH_ID:
      return {
        ...state,
        stockByBranch: action.payload,
      };

    case GET_STOCK_BY_BRANCH_AND_TREATMENT:
      return {
        ...state,
        stockForTreatment: action.payload,
      };

    case CLEAR_STOCK_BY_BRANCH:
      return {
        ...state,
        stockByBranch: [],
      };

    default:
      return state;
  }
}
