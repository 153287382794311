import { GET_CONFIGS, UPDATE_CONFIGS } from '../types';

import { get, put } from './Action';

const basePath = '/setting';

// Get All Roles
export const getConfigs = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getConfigsDS));
};
//update exchange
export const updateConfigs = (id, values) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, values, updateConfigsDS)
  );
};

const getConfigsDS = (configs) => ({
  type: GET_CONFIGS,
  payload: configs,
});

const updateConfigsDS = (configs) => ({
  type: UPDATE_CONFIGS,
  payload: configs,
});
