import { Button, Card, Form } from 'antd';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  placeHolders,
} from '../../../../constants';
import { useEffect, useState } from 'react';
import {
  getCashSales,
  getCosmetologists,
  updateSeller,
} from '../../../../appRedux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';

export const SellerUpdate = ({ order }) => {
  const { cosmetologists } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [response, setResponse] = useState();
  const { seller: user } = order;
  const [form] = Form.useForm();
  const { closeModal } = useCustomModal();
  const { filtersData } = useSelector((state) => state?.generalStates);

  useEffect(() => {
    dispatch(getCosmetologists());
  }, [dispatch]);

  useEffect(() => {
    if ([200].includes(response)) {
      dispatch(getCashSales(filtersData));
      closeModal();
    }
  }, [response, closeModal, filtersData, dispatch]);

  useEffect(() => {
    const cosmetologist = cosmetologists.find(
      (seller) => seller.id === user?.id
    );
    if (cosmetologist) {
      form.setFieldsValue({ userId: user?.id });
    }
  }, [user, form, cosmetologists]);

  const onSubmit = async (values) => {
    setResponse(await dispatch(updateSeller(order?.id, values)));
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form
        {...formConfig}
        id="sellerUpdate"
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item name="userId" label="Cosmetóloga">
          <CustomSelect
            placeholder={placeHolders.commissionAgent}
            options={cosmetologists?.map(({ id, name, lastName }) => ({
              value: id,
              label: `${name} ${lastName}`,
            }))}
          ></CustomSelect>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-form"
          form="sellerUpdate"
        >
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
