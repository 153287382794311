import { customerConst, placeHolders } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { stringDate } from '../../../../../util';
import { PurchasedProductsStatus } from '../../../../uiComponents/PurchasedProductStatus';
import { Button, Form, Modal, Table } from 'antd';
import { StatusPurchasedProduct } from './StatusModal';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getPurchasedProductsByCustomer } from '../../../../../appRedux/actions/PurchasedProduct';
export const CustomerPurchasedProducts = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customerPurchasedProducts } = useSelector((state) => state.customer);
  const { purchasedProduct } = useSelector((state) => state.purchasedProduct);
  const [children, setChildren] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getPurchasedProductsByCustomer(customerId));
  }, [dispatch, customerId, purchasedProduct]);

  const getModal = (purchasedProduct) => {
    setIsModalOpen(true);
    setChildren(
      <StatusPurchasedProduct
        purchasedProduct={purchasedProduct}
        setIsModalOpen={setIsModalOpen}
        customerId={customerId}
        form={form}
      />
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const columns = [
    {
      title: 'Tratamiento',
      dataIndex: 'treatment',
      key: 'treatment',
      render: (treatment) => treatment?.name,
    },
    {
      title: 'Sesiones',
      dataIndex: 'sessions',
      key: 'sessions',
    },
    {
      title: 'Sesiones aplicadas',
      dataIndex: 'currentSession',
      key: 'currentSession',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <PurchasedProductsStatus status={status} />,
    },
    {
      title: 'Fecha de compra',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => stringDate(createdAt),
      path: 'created_at',
      formatter: (createdAt) => stringDate(createdAt),
    },
    {
      title: 'Orden',
      dataIndex: 'order',
      key: 'order',
      render: (order) => order?.orderNumber ?? '-',
    },
    {
      title: 'Opciones',
      dataIndex: 'purchasedProduct',
      key: 'purchasedProduct',
      render: (_, purchasedProduct) => (
        <Button
          type="link"
          onClick={() => getModal(purchasedProduct, 'purchasedProduct')}
          disabled={purchasedProduct.status === 'finished'}
        >
          <PlusCircleOutlined />
        </Button>
      ),
    },
  ];
  return (
    <>
      <Table
        pagination={customerConst?.pagination}
        dataSource={customerPurchasedProducts}
        columns={columns}
        name={placeHolders.purchasedProducts}
      />
      <Modal
        title={placeHolders.purchasedProducts}
        visible={isModalOpen}
        onCancel={handleCancel}
        width={580}
      >
        {children}
      </Modal>
    </>
  );
};
