import {
  CREATE_SUBSIDIARY,
  GET_SUBSIDIARIES,
  GET_SUBSIDIARY_BY_ID,
  UPDATE_SUBSIDIARY,
  DELETE_SUBSIDIARY,
  SET_SUBSIDIARY,
  GET_ALL_SUBSIDIARIES,
  RESET_BRANCH,
  DATA_DELETED,
} from '../types';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import { post, get, put, remove } from './Action';

const basePath = '/branch';

// Create Subsidiary
export const createSubsidiary = (subsidiary) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/create`, subsidiary, createSubsidiaryDS)
  );
};

// Get Subsidiaries
export const getSubsidiaries = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getSubsidiariesDS));
};

// Get Subsidiaries
export const getPaginatedBranches = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}/paginated${queryParams}`, getSubsidiariesDS, true)
  );
};

// Get Subsidiaries
export const getAllSubsidiaries = () => async (dispatch) => {
  return await dispatch(get(`${basePath}/all`, getAllSubsidiariesDS));
};

// Get Subsidiary By Id
export const getSubsidiaryById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getSubsidiaryByIdDS));
};

// Edit Subsidiary
export const updateSubsidiary = (id, subsidiary) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, subsidiary, updateSubsidiaryDS)
  );
};

// Delete Subsidiary
export const deleteSubsidiary = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteSubsidiaryDS)
  );
  dispatch(subsidiaryDeleteDS(id));
  return response;
};

// Reset Branch
export const resetBranch = () => async (dispatch) => {
  dispatch(reserBranchDS());
};

// Set Subsidiary
export const setSubsidiary =
  (subsidiaryId = null) =>
  async (dispatch) => {
    return dispatch(setSubsidiaryDS(subsidiaryId));
  };

const createSubsidiaryDS = (subsidiary) => ({
  type: CREATE_SUBSIDIARY,
  payload: subsidiary,
});

const getAllSubsidiariesDS = (subsidiary) => ({
  type: GET_ALL_SUBSIDIARIES,
  payload: subsidiary,
});

const getSubsidiariesDS = (subsidiary) => ({
  type: GET_SUBSIDIARIES,
  payload: subsidiary?.items ? subsidiary.items : subsidiary,
});

const getSubsidiaryByIdDS = (subsidiary) => ({
  type: GET_SUBSIDIARY_BY_ID,
  payload: subsidiary,
});

const updateSubsidiaryDS = (subsidiary) => ({
  type: UPDATE_SUBSIDIARY,
  payload: subsidiary,
});

export const subsidiaryDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteSubsidiaryDS = () => ({
  type: DELETE_SUBSIDIARY,
});

const setSubsidiaryDS = (subsidiaryId) => ({
  type: SET_SUBSIDIARY,
  payload: subsidiaryId,
});

const reserBranchDS = () => ({
  type: RESET_BRANCH,
  payload: {},
});
