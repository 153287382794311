import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { getIndividualCommissionMedical } from '../../../../appRedux/actions/Reports';
import { stringDate, stringTime } from '../../../../util';
import { formatPrice } from '../../../../util/formatPrice';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { individualCommissionMedicalReportConst } from '../../../../constants';

export const ComissionMedicalIndividualReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { individualCommissionMedical } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(individualCommissionMedicalReportConst.title),
    sheet: individualCommissionMedicalReportConst.title,
  });
  const header = individualCommissionMedicalReportConst.header;
  let fullCommission = 0;
  let fullIrs = 0;
  const [medicalCommission] = individualCommissionMedical;
  const isr = medicalCommission?.isr ?? 0;
  let fullPay = 0;

  useEffect(() => {
    if (filtersData.dates && filtersData.userId)
      dispatch(
        getIndividualCommissionMedical({
          date: filtersData.dates,
          userId: filtersData.userId,
        })
      );
  }, [dispatch, filtersData.dates, filtersData.userId]);

  useEffect(() => {
    if (filtersData.dates) {
      const [date1, date2] = filtersData.dates;

      setPeriod(
        `${moment(date1).format('DD/MM/YYYY')} - ${moment(date2).format(
          'DD/MM/YYYY'
        )}`
      );
    }
  }, [filtersData.dates]);

  return (
    <div>
      <ExportButton onDownload={onDownload} />

      <table ref={tableRef}>
        <ReportTableHeader
          user={fullname}
          role={role}
          title={individualCommissionMedicalReportConst.title}
          period={period}
          col1={3}
          col2={0}
          col3={7}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {individualCommissionMedical?.map((row, key) => {
            fullCommission += Number(row.commission);
            fullIrs += Number(row.commission) * (isr / 100);
            fullPay +=
              Number(row.commission) - Number(row.commission) * (isr / 100);

            return (
              <tr key={key}>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {row.orderNumber}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {stringDate(row.date)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {stringTime(row.time)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.customer}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row?.treatment}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.commission)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={5}
              style={{
                width: '100%',
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL DE COMISION GENERADA</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullCommission)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>ISR MEDICO</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {isr}%
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>ISR CORRESPONDIENTE</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullIrs)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL A PAGAR</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(fullPay)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
