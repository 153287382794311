import { Col, Row, Table } from 'antd';
import { stringDate } from '../../../../util/formatTime';

export const TransferModalBody = ({ data = {} }) => {
  const createdBy = `${data?.createdBy?.name} ${data?.createdBy?.lastName}`;
  const approvedBy = `${data?.approvedBy?.name} ${data?.approvedBy?.lastName}`;
  const acceptedBy = `${data?.acceptedBy?.name} ${data?.acceptedBy?.lastName}`;
  const rejectedBy = `${data?.rejectedBy?.name} ${data?.rejectedBy?.lastName}`;

  const { transferProduct } = data;
  const columns = [
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product?.name,
    },
    {
      title: 'SKU',
      dataIndex: 'product',
      key: 'sku',
      render: (product) => product?.sku,
    },
    {
      title: 'Lote',
      dataIndex: 'productStock',
      key: 'allotment',
      render: (stock) => stock?.allotment || '-',
    },
    {
      title: 'Cantidad enviada',
      dataIndex: 'stockedQuantity',
      key: 'stockedQuantity',
    },
    {
      title: 'Cantidad recibida',
      dataIndex: 'receivedQuantity',
      key: 'receivedQuantity',
    },
  ];

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Sucursal de origen</h4>
        </Col>
        <Col span={16}>{data?.originBranch?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Sucursal de destino</h4>
        </Col>
        <Col span={16}>{data?.destinyBranch?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Creada por</h4>
        </Col>
        <Col span={16}>{createdBy || '-'} </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Fecha de creación</h4>
        </Col>
        <Col span={16}>{stringDate(data?.created_at) || '-'}</Col>
      </Row>

      {!data.rejectedAt && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Aprobado por</h4>
            </Col>
            <Col span={16}>
              {approvedBy === 'undefined undefined' ? 'Pendiente' : approvedBy}
            </Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de aprobación</h4>
            </Col>
            <Col span={16}>{stringDate(data?.approvedAt) || 'Pendiente'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Aceptado por</h4>
            </Col>
            <Col span={16}>
              {acceptedBy === 'undefined undefined' ? 'Pendiente' : acceptedBy}
            </Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de surtido</h4>
            </Col>
            <Col span={16}>{stringDate(data?.acceptedAt) || 'Pendiente'}</Col>
          </Row>
        </>
      )}

      {data.rejectedAt && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Rechazado por</h4>
            </Col>
            <Col span={16}>{rejectedBy || 'Pendiente'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de rechazo</h4>
            </Col>
            <Col span={16}>{stringDate(data?.rejectedAt) || 'Pendiente'}</Col>
          </Row>
        </>
      )}

      <br />

      <Table
        dataSource={transferProduct}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 40, 60, 80],
        }}
      />
    </>
  );
};
