import { Card } from 'antd';
import { DailyCashClosingCashTable } from '../../components/DailyCashClosing/DailyCashClosingCashTable';
import { BackButton } from '../../uiComponents/BackButton';

export const DailyCashClosingCash = () => {
  return (
    <Card
      title={
        <BackButton
          path="/dashboard/corte-sucursal"
          label="Corte de productos"
          bordered={true}
          className="gx-card"
        />
      }
      bordered={true}
      className="gx-card"
    >
      <DailyCashClosingCashTable />
    </Card>
  );
};
