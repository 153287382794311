import { Tag } from 'antd';
import { paymentStatusDictionary } from '../constants/Sales';

export const setPaymentStatus = (status) => {
  let color = 'green';

  if (status === 'pending_payment') color = 'orange';
  else if (status === 'pending_confirmation') color = 'cyan';
  else if (status === 'error') color = 'red';

  return <Tag color={color}>{paymentStatusDictionary[status]}</Tag>;
};
