import { CloudDownloadOutlined } from '@ant-design/icons';

export const saleOrderConst = {
  modalHeader: 'Orden de venta',
  prefix: 'SO',
};

export const searchFilters = [
  { value: 'order.order_number', label: 'Orden' },
  { value: 'order.phone', label: 'Teléfono' },
  { value: 'order.rfc', label: 'RFC' },
  { value: 'order.name', label: 'Razón social' },
  { value: 'order.note', label: 'Número de nota' },
];

export const filters = {
  search: true,
};

export const searchInputInitialValues = {
  search: { filter: 'order.order_number' },
};

export const orderType = {
  cashOrder: 'Ordenes en efectivo',
  order: 'Orden',
};

export const saleTypeConst = {
  cashOrder: 'compra en efectivo',
  order: 'compras',
};

export const saleOrderTableMenuOpt = [
  {
    label: 'PDF',
    key: 'pdf',
    privilege: 'show',
    icon: <CloudDownloadOutlined />,
  },
];
