import {
  CREATE_SALE_ORDER,
  GET_SALE_ORDERS,
  GET_SALE_ORDER_BY_ID,
  UPDATE_SALE_ORDER,
  GET_SALE_ORDER_DELETE,
  DELETE_SALE_ORDER,
} from '../types';

const initialState = {
  saleOrders: [],
  saleOrderById: {},
  saleOrderDelete: null,
};

export default function SaleOrder(state = initialState, action) {
  switch (action.type) {
    case CREATE_SALE_ORDER:
      return {
        ...state,
        saleOrders: [...state.saleOrders, action.payload],
      };
    case GET_SALE_ORDERS:
      return {
        ...state,
        saleOrders: action.payload,
      };
    case GET_SALE_ORDER_BY_ID:
      return {
        ...state,
        saleOrderById: action.payload,
      };
    case UPDATE_SALE_ORDER:
      return {
        ...state,
        saleOrders: state.saleOrders.map((order) =>
          order.id === action.payload.id ? (order = action.payload) : order
        ),
      };
    case GET_SALE_ORDER_DELETE:
      return {
        ...state,
        saleOrderDelete: action.payload,
      };

    case DELETE_SALE_ORDER:
      return {
        ...state,
        saleOrders: state.saleOrders.filter(
          (order) => order.id !== state.saleOrderDelete
        ),
      };

    default:
      return state;
  }
}
