import {
  ACCEPT_TRANSFER,
  CREATE_TRANSFER,
  DATA_DELETED,
  GET_TRANSFERS,
  GET_TRANSFER_BY_ID,
  TRANSFER_DELETE,
} from '../types';
import { queryPathBuilder } from '../../util/queryPathBuilder';

import { post, get, put, patch, remove } from './Action';

const basePath = '/transfer';

// Create Transfer
export const createTransfer = (transfer) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, transfer, createTransferDS));
};

// Get Transfers
export const getTransfers = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getTransfersDs, true));
};

// Get Transfer By Id
export const getTransferById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getTransferByIdDS));
};

// Approve Transfer
export const approveTransfer = (id, transfer) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/approve`, transfer, approveTransferDS)
  );
};

// Accept Transfer
export const acceptTransfer = (id, transfer) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/confirm`, transfer, acceptTransferDS)
  );
};

// Reject Transfer
export const rejectTransfer = (id) => async (dispatch) => {
  return await dispatch(patch(`${basePath}/${id}/reject`, rejectTransferDS));
};

//delete transfer
export const deleteTransfer = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteTransferDS)
  );
  dispatch(getDeleteTransferDS(id));
  return response;
};

const createTransferDS = (transfer) => ({
  type: CREATE_TRANSFER,
  payload: transfer,
});

const getTransfersDs = (transfers) => ({
  type: GET_TRANSFERS,
  payload: transfers.items,
});

const getTransferByIdDS = (transfer) => ({
  type: GET_TRANSFER_BY_ID,
  payload: transfer,
});

const approveTransferDS = (transfer) => ({
  type: approveTransferDS,
  payload: transfer,
});

const acceptTransferDS = (transfer) => ({
  type: ACCEPT_TRANSFER,
  payload: transfer,
});

const rejectTransferDS = () => ({});

export const getDeleteTransferDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteTransferDS = (transfer) => ({
  type: TRANSFER_DELETE,
  payload: transfer,
});
