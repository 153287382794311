import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMERS_ORDERS,
  GET_CUSTOMERS_TOTAL_PURCHASE,
  GET_TUTORS,
  DATA_DELETED,
  RESET_CUSTOMER,
} from '../types';
import { post, get, put, remove } from './Action';

const basePath = '/customer';

// Create Customer
export const createCustomer = (customer) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, customer, createCustomerDS));
};

// Get Customers
export const getCustomers = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder({
    withDeleted: 'false',
    status: 1,
    ...params,
  });
  return await dispatch(get(`${basePath}${queryParams}`, getCustomersDS));
};

// Get All Customers
export const getAllCustomers = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}/all${queryParams}`, getAllCustomersDS)
  );
};

// Get Single All Customers
export const getSingleCustomers = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}/single${queryParams}`, getSingleCustomersDS, true)
  );
};

// Get Customers
export const getTutors = () => async (dispatch) => {
  const params = { isTutor: 1, blackList: '0' };
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getTutorsDS));
};

// Get Customer By Id
export const getCustomerById = (id, purchasedProduct) => async (dispatch) => {
  const queryParams = purchasedProduct
    ? `?purchasedProducts=${purchasedProduct}`
    : '';

  return await dispatch(
    get(`${basePath}/${id}${queryParams}`, getCustomerByIdDS)
  );
};

// Update Customer
export const updateCustomer = (id, customer) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, customer, updateCustomerDS)
  );
};

// Delete Customer
export const deleteCustomer = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteCustomerDS)
  );
  dispatch(customerDeleteDS(id));
  return response;
};

// Reset customer
export const resetCustomer = () => async (dispatch) => {
  await dispatch(resetCustomerDS());
};

// Get Customer Orders
export const getCustomerOrders = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}/orders`, getCustomerOrdersDS));
};

export const getCustomersTotalspurchase = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(
      `${basePath}/totals-purchases${queryParams}`,
      getCustomersTotalspurchaseDS,
      true
    )
  );
};

const createCustomerDS = (customer) => ({
  type: CREATE_CUSTOMER,
  payload: customer,
});

const getCustomersDS = (customers) => ({
  type: GET_CUSTOMERS,
  payload: customers.items,
});

const getAllCustomersDS = (customers) => ({
  type: GET_CUSTOMERS,
  payload: customers,
});

const getSingleCustomersDS = (customers) => ({
  type: GET_CUSTOMERS,
  payload: customers.items,
});

const getCustomerByIdDS = (customer) => ({
  type: GET_CUSTOMER_BY_ID,
  payload: customer,
});

const updateCustomerDS = (customer) => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
});

export const customerDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteCustomerDS = () => ({
  type: DELETE_CUSTOMER,
});

const getCustomerOrdersDS = (customerOrders) => ({
  type: GET_CUSTOMERS_ORDERS,
  payload: customerOrders,
});

const getCustomersTotalspurchaseDS = (customersTotalspurchase) => ({
  type: GET_CUSTOMERS_TOTAL_PURCHASE,
  payload: customersTotalspurchase.items,
});

const getTutorsDS = (tutors) => ({
  type: GET_TUTORS,
  payload: tutors.items,
});

const resetCustomerDS = () => ({
  type: RESET_CUSTOMER,
  payload: {},
});
