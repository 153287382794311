import {
  CREATE_LOCK,
  GET_LOCKS,
  GET_LOCK_BY_ID,
  UPDATE_LOCK,
  GET_LOCK_DELETE,
  DELETE_LOCK,
} from '../types';

const initialState = {
  locks: [],
  lockById: {},
  lockDelete: null,
};

export default function Lock(state = initialState, action) {
  switch (action.type) {
    case CREATE_LOCK:
      return {
        ...state,
        locks: [...state.locks, action.payload],
      };

    case GET_LOCKS:
      return {
        ...state,
        locks: action.payload,
      };

    case GET_LOCK_BY_ID:
      return {
        ...state,
        lockById: action.payload,
      };
    case UPDATE_LOCK:
      return {
        ...state,
        locks: state.locks.map((lock) =>
          lock.id === action.payload.id ? (lock = action.payload) : lock
        ),
      };
    case GET_LOCK_DELETE:
      return {
        ...state,
        lockDelete: action.payload,
      };
    case DELETE_LOCK:
      return {
        ...state,
        locks: state.locks.filter((lock) => lock.id !== state.lockDelete),
      };

    default:
      return state;
  }
}
