import { COLLAPSED_SIDER, DISABLED_ELEMENT, MODAL_VIEW } from "../types";

const initialState = {
    collapsed: false,
    modalView: false,
    disabled: false
};


export default function UI(state = initialState, action) {
    switch (action.type) {
        case COLLAPSED_SIDER:
            return {
                ...state,
                collapsed: action.payload
            }
        case MODAL_VIEW:
            return {
                ...state,
                modalView: action.payload
            }
        case DISABLED_ELEMENT:
            return {
                ...state,
                disabled: action.payload
            }
        default:
            return state;
    }
}

