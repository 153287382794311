import {
  CREATE_SUBSIDIARY,
  GET_SUBSIDIARIES,
  GET_SUBSIDIARY_BY_ID,
  UPDATE_SUBSIDIARY,
  GET_SUBSIDIARY_DELETE,
  DELETE_SUBSIDIARY,
  SET_SUBSIDIARY,
  GET_ALL_SUBSIDIARIES,
  RESET_BRANCH,
} from '../types';

const initialState = {
  subsidiaries: [],
  subsidiaryById: {},
  subsidiaryDelete: null,
  subsidiaryId: null,
  allSubsidiaries: [],
};

export default function Subsidiary(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUBSIDIARY:
      return {
        ...state,
        subsidiaries: [...state.subsidiaries, action.payload],
      };
    case GET_SUBSIDIARIES:
      return {
        ...state,
        subsidiaries: action.payload,
      };
    case GET_ALL_SUBSIDIARIES:
      return {
        ...state,
        allSubsidiaries: action.payload,
      };
    case GET_SUBSIDIARY_BY_ID:
      return {
        ...state,
        subsidiaryById: action.payload,
      };
    case UPDATE_SUBSIDIARY:
      return {
        ...state,
        subsidiaries: state.subsidiaries.map((subsidiary) =>
          subsidiary.id === action.payload.id
            ? (subsidiary = action.payload)
            : subsidiary
        ),
      };
    case GET_SUBSIDIARY_DELETE:
      return {
        ...state,
        subsidiaryDelete: action.payload,
      };

    case DELETE_SUBSIDIARY:
      return {
        ...state,
        subsidiaries: state.subsidiaries.filter(
          (subsidiary) => subsidiary.id !== state.subsidiaryDelete
        ),
      };

    case SET_SUBSIDIARY:
      return {
        ...state,
        subsidiaryId: action.payload,
      };

    case RESET_BRANCH:
      return {
        ...state,
        subsidiaryById: action.payload,
      };

    default:
      return state;
  }
}
