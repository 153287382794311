import { Dropdown, Menu } from 'antd';
import { cashSalesTableMenuOpt } from '../../../../constants/CashSales';
import { salesTableMenuOpt } from '../../../../constants/Sales';

export const SaleTableOptions = ({
  id,
  action = {},
  isRefund,
  paymentStatus,
  saleType,
}) => {
  const { refundOrder } = !!action && action;
  // Descomentar cuando se inserten priviligios de comisiones
  // let options = appointmentsTableMenuOpt().filter((opt) =>
  //   privileges.includes(opt.privilege)
  // );

  const options =
    !isRefund && paymentStatus === 'paid'
      ? salesTableMenuOpt
      : salesTableMenuOpt.filter((opt) => opt.key !== 'refund');

  const renderMenu = (id) => {
    return (
      <Menu
        onClick={({ key }) => handleMenuClick(key, id)}
        items={
          saleType !== 'online' && saleType !== 'App'
            ? options
            : options.filter((opt) => opt.key !== 'invoice')
        }
      />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'refund':
        refundOrder.getModal(...refundOrder.params);
        break;
      case 'invoice':
        isRefund
          ? window.open(
              `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/order/${id}/ticket/refund`,
              '_blank',
              'noopener,noreferrer'
            )
          : window.open(
              `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/order/${id}/ticket`,
              '_blank',
              'noopener,noreferrer'
            );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown.Button
        disabled={!(cashSalesTableMenuOpt.length > 0)}
        overlay={renderMenu(id)}
        trigger={['click']}
        placement="bottom"
      />
    </>
  );
};
