import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useNavigate } from 'react-router-dom';
import {
  deleteItemModal,
  modalButtons,
  purchaseTableMenuOpt,
} from '../../../../constants';

export const PurchaseOrderTableOptions = ({
  id = null,
  path = null,
  action = {},
  payed = false,
  privileges = [],
  deletedAt = false,
}) => {
  const navigate = useNavigate();
  let options = purchaseTableMenuOpt(payed).filter((opt) =>
    privileges.includes(opt.privilege)
  );

  const { onDelete } = !!action && action;

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };


  if (payed || deletedAt) {
    options = options.filter((option) => option.key !== 'delete');
  }
  
  if (deletedAt){
    options = options.filter((option) => option.key !== 'delete');
    options = options.filter((option) => option.key !== 'edit');
  }
  

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'edit':
        navigate(`/dashboard/${path}/${id}`);
        break;
      case 'delete':
        showDeleteConfirm();
        break;
      case 'pdf':
        window.open(
          `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/purchase-order/${id}/download`,
          '_blank',
          'noopener,noreferrer'
        );
        break;
      default:
        break;
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(onDelete.action(...onDelete.params)), 1000);
        });
      },
      onCancel() {},
    });
  };

  return (
    <Dropdown.Button
      disabled={!(options.length > 0)}
      overlay={renderMenu(id)}
      trigger={['click']}
      placement="bottom"
    />
  );
};
