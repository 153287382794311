import { Col, Row } from 'antd';
import { phoneMask } from '../../../../../util';


export const CustomerData = ({ data }) => {
  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Género</h4>
        </Col>
        <Col span={16}>{data?.gender || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{phoneMask(data?.phone) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Estado civil</h4>
        </Col>
        <Col span={16}>{data?.maritalStatus || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>País de nacimiento</h4>
        </Col>
        <Col span={16}>{data?.originCountry || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Referencia</h4>
        </Col>
        <Col span={16}>{data?.reference || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h5 className="strong"> Tutor</h5>
        </Col>
        <Col span={16}>{`${data?.tutor?.name || '-'} ${
          data?.tutor?.lastName || ''
        } ${data?.tutor?.motherLastName || ''}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Parentesco</h4>
        </Col>
        <Col span={16}>{data?.relationship || '-'}</Col>
      </Row>
    </>
  );
};
