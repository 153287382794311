import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { modalViewAction } from '../../../appRedux/actions/Ui';
import './styles.css';

export const ModalView = ({ title, width = 500, children }) => {
  const { modalView } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        title={<h2>{title}</h2>}
        visible={modalView}
        destroyOnClose={true}
        onCancel={() => dispatch(modalViewAction(!modalView))}
        width={width}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        {children}
      </Modal>
    </>
  );
};
