import React from 'react';
import { Card } from 'antd';
import { SubsidiaryForm } from '../../components/Subsidiary/SubsidiaryForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Subsidiary = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <SubsidiaryForm />
    </Card>
  );
};
