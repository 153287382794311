import { Card } from 'antd';
import { SalesTable } from '../../components/Sales/SalesTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const AppSales = () => {  
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <SalesTable saleType={'App'}/>
    </Card>
  );
};
