import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CabainTypesTable } from '../../components/CabainTypes/CabainTypeTable';
import { BackButton } from '../../uiComponents/BackButton';

export const CabainTypes = () => {
  const navigate = useNavigate();

  return (
    <Card
      title={<BackButton path="/dashboard/cabinas" label="tipo de cabinas" />}
      bordered={true}
      className="gx-card"
    >
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          navigate('/dashboard/tipos-cabina/crear');
        }}
      >
        <PlusOutlined />
        Nuevo
      </Button>

      <CabainTypesTable />
    </Card>
  );
};
