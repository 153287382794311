import { useSelector } from 'react-redux';
import { getDailyCashClosing } from '../../../../appRedux/actions';
import {
  dailyCashClosingConst,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/DailyCashClosing';
import { formatPrice } from '../../../../util/formatPrice';
import { stringDate } from '../../../../util/formatTime';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useState } from 'react';
import { DailyCashClosingModal } from '../DailyCashClosingModal';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { Button } from 'antd';

export const DailyCashClosingTable = () => {
  const {
    privileges: { branch: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const [modalData, setModalData] = useState();
  const { modal } = useCustomModal();

  const columns = [
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      path: 'branch.name',
      render: (branch, row) => (
        <Button
          type="link"
          onClick={() => {
            modal();
            setModalData({ branchId: row?.branchId, date: row?.date });
          }}
        >
          {branch}
        </Button>
      ),
    },
    {
      title: 'Tarjeta de Crédito',
      sorter: true,
      dataIndex: 'creditCardTotal',
      key: 'creditCardTotal',
      render: (creditCardTotal) => `${formatPrice(creditCardTotal || '0')}`,
    },
    {
      title: 'Tarjeta de Débito',
      sorter: true,
      dataIndex: 'debitCardTotal',
      key: 'debitCardTotal',
      render: (debitCardTotal) => `${formatPrice(debitCardTotal || '0')}`,
    },
    {
      title: 'Efectivo',
      sorter: true,
      dataIndex: 'cashTotal',
      key: 'cashTotal',
      render: (cashTotal) => `${formatPrice(cashTotal || '0')}`,
    },
    {
      title: 'Puntos',
      sorter: true,
      dataIndex: 'pointsTotal',
      key: 'pointsTotal',
      render: (pointsTotal) => `${formatPrice(pointsTotal || '0')}`,
    },
    {
      title: 'Dolares',
      sorter: true,
      dataIndex: 'cashTotalDollars',
      key: 'cashTotalDollars',
      render: (cashTotalDollars) => `${formatPrice(cashTotalDollars || '0')}`,
    },
    {
      title: 'Devoluciones',
      sorter: true,
      dataIndex: 'refundCash',
      key: 'refundCash',
      render: (refundCash, dailyCashClosing) =>
        `${formatPrice(
          refundCash ||
            0 + dailyCashClosing?.refundDebitCardTotal ||
            0 + dailyCashClosing?.refundCreditCardTotal ||
            0
        )}`,
    },
    {
      title: 'Total',
      sorter: true,
      dataIndex: 'total',
      key: 'total',
      render: (total) => `${formatPrice(total || '0')}`,
    },
    {
      title: 'Fecha',
      sorter: true,
      dataIndex: 'date',
      key: 'date',
      render: (date) => stringDate(date),
      path: 'date',
      formatter: (date) => stringDate(date),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'corteSucursal'}
        getDataFunction={getDailyCashClosing}
      />
      <ModalView title={dailyCashClosingConst.MODAL_TITLE} width={1200}>
        <DailyCashClosingModal
          branchId={modalData?.branchId}
          date={modalData?.date}
        />
      </ModalView>
    </>
  );
};
