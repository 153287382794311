import { Alert, Button, Card, Form, Switch } from 'antd';
import {
  appointmentConst,
  buttonLabel,
  cancellationReasonConst,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../../constants';
import { CustomSelect } from '../../../uiComponents';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelAppointment,
  clearMissedAppointments,
  getMissedAppointments,
} from '../../../../appRedux/actions';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const CancelAppointment = ({
  id,
  setIsModalOpen,
  purchasedProductId,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { missedAppointments } = useSelector(
    (state) => state.missedAppointment
  );
  const [reason, setReason] = useState(false);
  const [response, setResponse] = useState();
  const [customerCancellation, setCustomerCancellation] = useState(true);
  const [missedsAlert, setMissedAlert] = useState([]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      setIsModalOpen(false);
      dispatch(clearMissedAppointments());
    }
  }, [dispatch, response, setIsModalOpen]);

  useEffect(() => {
    if (purchasedProductId) dispatch(getMissedAppointments(purchasedProductId));
  }, [dispatch, purchasedProductId]);

  useEffect(() => {
    if (purchasedProductId) setMissedAlert(missedAppointments);
  }, [purchasedProductId, missedAppointments]);

  const onSubmit = async (values) => {
    values.customerCancellation = customerCancellation;
    setResponse(await dispatch(cancelAppointment(id, values)));
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      {missedsAlert?.length >= 2 && (
        <Alert
          message={appointmentConst.CANCEL_ALERT.TITLE}
          description={appointmentConst.CANCEL_ALERT.DESCRIPTION}
          type="error"
          showIcon
          closable
        />
      )}

      <Form
        {...formConfig}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          label="Motivo"
          name="cancellationReason"
          rules={[
            {
              required: true,
              message: messages.required('Motivo de cancelación'),
            },
          ]}
        >
          <CustomSelect
            placeholder={placeHolders.cancellationReason}
            options={cancellationReasonConst}
            onChange={setReason}
          ></CustomSelect>
        </Form.Item>

        <Form.Item
          label="Comentario"
          name="comment"
          rules={[
            {
              required: reason === 'Other' ? true : false,
              message: messages.required('Comentario'),
            },
          ]}
        >
          <TextArea className="input-adt" />
        </Form.Item>

        <Form.Item name="customerCancellation" label="¿Cliente cancela?">
          <Switch
            checked={customerCancellation}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={setCustomerCancellation}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" className="btn-form">
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
