export const phoneMask = (phone) => {
  if (phone) {
    return phone
      ?.replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{3})(\d)/, '$1) $2')
      .replace(/(\d{3})(\d{1,5})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }

  return '-';
};

export const quantityMask = (quantity) => {
  if (quantity) {
    return quantity.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '-';
};
