import { formatPrice } from '../../../../../util/formatPrice';
import { customerConst } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCustomerOrders } from '../../../../../appRedux/actions';
import { stringDate } from '../../../../../util';
import { Button, Table, Tag } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { paymentStatusDictionary } from '../../../../../constants/Sales';
import { setPaymentStatus } from '../../../../../util/orders';

export const CustomerCashOrders = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customerOrders } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomerOrders(customerId));
  }, [dispatch, customerId]);

  const columns = [
    {
      title: 'Orden',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total) => formatPrice(total),
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
      path: 'created_at',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Status',
      dataIndex: 'refundedAt',
      key: 'refundedAt',
      render: (refundedAt) => (
        <Tag color={refundedAt ? 'red' : 'blue'}>
          {refundedAt ? 'Devolución' : 'Venta'}
        </Tag>
      ),
      path: 'refundedAt',
      formatter: (refundedAt) => (refundedAt ? 'Devolución' : 'Venta'),
    },
    {
      title: 'Estado de pago',
      dataIndex: 'paymentStatus',
      render: (status) => setPaymentStatus('paid'),
      path: 'paymentStatus',
      fformatter: (paymentStatus) => paymentStatusDictionary['paid'],
    },
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      render: (id, order) => (
        <Button
          icon={<FileTextOutlined />}
          type="link"
          onClick={() => {
            order.refundedAt
              ? window.open(
                  `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/cash-order/${id}/ticket/refund`,
                  '_blank',
                  'noopener,noreferrer'
                )
              : window.open(
                  `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/cash-order/${id}/ticket`,
                  '_blank',
                  'noopener,noreferrer'
                );
          }}
        />
      ),
    },
  ];
  return (
    <>
      <Table
        pagination={customerConst?.pagination}
        dataSource={customerOrders?.cashOrders}
        columns={columns}
        name={'Ordenes Clientes Efectivo'}
      />
    </>
  );
};
