import { Table, Tabs } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerOrders } from "../../../../../appRedux/actions";
import { customerConst } from "../../../../../constants";
import { orderType } from "../../../../../constants/SaleOrder.const";
import { stringDate } from "../../../../../util";


export const LoyaltyModal = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customerOrders } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomerOrders(customerId));
  }, [dispatch, customerId]);

  const columns = [
    {
      title: 'orden',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => stringDate(created_at),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={orderType?.order} key="1">
          <Table
            pagination={customerConst?.pagination}
            dataSource={customerOrders?.orders}
            columns={columns}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={orderType?.cashOrder} key="2">
          <Table
            pagination={customerConst?.pagination}
            dataSource={customerOrders?.cashOrders}
            columns={columns}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
