import { GET_PRODUCT_TYPES } from "../types";

const initialState = {
  productTypes: [],
};

export default function ProductTypes(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: action.payload,
      };
    default:
      return state;
  }
}
