import { Form, Button, Card } from 'antd';
import {
  placeHolders,
  buttonLabel,
  formConfig,
  cardConfig,
} from '../../../../constants';
import { CustomSelect } from '../../../uiComponents';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommissionAgent } from '../../../../appRedux/actions';
import { useState } from 'react';

export const UpdateCommissionMedical = ({
  commissionId,
  onUpdateComplete,
}) => {
  const { doctors } = useSelector((state) => state?.user);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [response, setResponse] = useState();

  const onSubmit = async (values) => {
    try {
      setResponse(await dispatch(updateCommissionAgent(commissionId, values)));
      if ([200].includes(response)) {
        onUpdateComplete();
      }
    } catch (error) {}
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form
        {...formConfig}
        id="commissionsUpdate"
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item name="userId" label="Médico">
          <CustomSelect
            placeholder={placeHolders.commissionAgent}
            options={doctors?.map(({ id, name, lastName }) => ({
              value: id,
              label: `${name} ${lastName}`,
            }))}
          ></CustomSelect>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-form"
          form="commissionsUpdate"
        >
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
