import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_GOAL,
  DATA_DELETED,
  DELETE_GOAL,
  GET_GOAL,
  GET_GOAL_BY_ID,
  UPDATE_GOAL,
} from '../types';
import { post, get, put, remove } from './Action';

const basePath = '/goal';

export const createGoal = (goal) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, goal, createGoalDS));
};

export const getAllGoals = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}/${queryParams}`, getAllGoalsDS, true));
};

export const updateGoal = (id, goal) => async (dispatch) => {
  return await dispatch(put(`${basePath}/${id}/update`, goal, updateGoalDS));
};

export const getGoalById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getGoalByIdDS));
};

export const deleteGoal = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteGoalDS)
  );
  dispatch(getGoalDeleteDS(id));
  return response;
};

const createGoalDS = (goal) => ({
  type: CREATE_GOAL,
  payload: goal,
});

const getAllGoalsDS = (goals) => ({
  type: GET_GOAL,
  payload: goals.items,
});

const updateGoalDS = (goal) => ({
  type: UPDATE_GOAL,
  payload: goal,
});

const getGoalByIdDS = (goal) => ({
  type: GET_GOAL_BY_ID,
  payload: goal,
});

const getGoalDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteGoalDS = () => ({
  type: DELETE_GOAL,
});
