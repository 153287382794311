import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMedicalCommissionsByUser } from '../../../../appRedux/actions';
import { stringDate, stringTime } from '../../../../util/formatTime';
import { Button, Table } from 'antd';
import { UpdateCommissionMedical } from '../UpdateCommissionMedical';

export const MedicalCommissionModalBody = ({ id, privileges }) => {
  const dispatch = useDispatch();

  const { medicalCommissionsByUser } = useSelector((state) => state.commission);
  const [isUpdateComission, setIsUpdateComission] = useState(false);
  const [comissionId, setComissionId] = useState();
  const { filtersData } = useSelector((state) => state.generalStates);

  const updateCommission = (data) => {
    setComissionId(data.id);
    setIsUpdateComission(true);
  };

  const handleUpdateComplete = () => {
    setIsUpdateComission(false);
  };

  useEffect(() => {
    dispatch(getMedicalCommissionsByUser(id, { params: filtersData }));
  }, [dispatch, id, filtersData]);

  const columns = [
    {
      title: 'Número de cita',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (appointment) => appointment?.appointmentNumber,
    },
    {
      title: 'Médico',
      dataIndex: 'user',
      key: 'user',
      render: (user, data) =>
        privileges ? (
          <Button
            type="link"
            htmlType="button"
            onClick={() => updateCommission(data)}
          >
            {user?.name} {user?.lastName} {user?.motherLastName || ''}
          </Button>
        ) : (
          `${user?.name} ${user?.lastName} ${user?.motherLastName || ''}`
        ),
    },
    {
      title: 'Sucursal',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (appointment) => appointment?.branch?.name,
    },
    {
      title: 'Fecha',
      dataIndex: 'appointment',
      key: 'date',
      render: (appointment) => stringDate(appointment?.date),
    },
    {
      title: 'Hora',
      dataIndex: 'appointment',
      key: 'startTime',
      render: (appointment) => stringTime(appointment?.startTime),
    },
    {
      title: 'Cliente',
      dataIndex: 'appointment',
      key: 'customer',
      render: (appointment) => (
        <>
          {appointment?.customer?.name} {appointment?.customer?.lastName}{' '}
          {appointment?.customer?.motherLastName}
        </>
      ),
    },
    {
      title: 'Tratamiento',
      dataIndex: 'appointment',
      key: 'name',
      render: (appointment) => appointment?.treatment?.name,
    },
    {
      title: 'Comisión',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => `$${commission}`,
    },
  ];

  return (
    <>
      {isUpdateComission && (
        <UpdateCommissionMedical
          commissionId={comissionId}
          onUpdateComplete={handleUpdateComplete}
        />
      )}
      {!isUpdateComission && (
        <Table
          dataSource={medicalCommissionsByUser}
          columns={columns}
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [5, 10, 15],
          }}
        />
      )}
    </>
  );
};
