import { LeftOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import './styles.css';

export const BackButton = ({ path = '#', label = null }) => {
  const navigate = useNavigate();
  return (
    <Space
      className="back-button"
      type="link"
      onClick={() => {
        navigate(path);
      }}
    >
      <LeftOutlined />
      {label ? label : null}
    </Space>
  );
};
