import { Col, Row } from 'antd';

export const AddressData = ({ data }) => {
  return (
    <>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Domicilio Actual</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Dirección</h4>
        </Col>
        <Col span={16}>{data?.kardex?.address?.address || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Ciudad</h4>
        </Col>
        <Col span={16}>{data?.kardex?.address?.city || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Estado</h4>
        </Col>
        <Col span={16}>{data?.kardex?.address?.state || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Domicilio INE</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Dirección</h4>
        </Col>
        <Col span={16}>{data?.kardex?.address?.addressINE || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Ciudad</h4>
        </Col>
        <Col span={16}>{data?.kardex?.address?.cityINE || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Estado</h4>
        </Col>
        <Col span={16}>{data?.kardex?.address?.stateINE || '-'}</Col>
      </Row>
    </>
  );
};
