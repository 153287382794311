import React from 'react';
import { Provider } from 'react-redux';
import store, { Persistor } from './appRedux/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import 'main.css';
import { AlertManager } from './app/components/AlertManager';
import Router from './app/router/Router';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persistor}>
        <Router />
        <AlertManager />
      </PersistGate>
    </Provider>
  );
};
export default App;
