import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  deleteItemModal,
  modalButtons,
  stocktableOptConst,
} from '../../../../constants';

export const StockTableOpts = ({
  id = null,
  path = null,
  action = {},
  privileges = [],
}) => {
  const navigate = useNavigate();
  let options = stocktableOptConst.filter((opt) =>
    privileges.includes(opt.privileges)
  );
  const { onDelete, onEdit, setMaxMin } = !!action && action;

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'adjustment':
        onEdit
          ? onEdit.getModal(...onEdit.params)
          : navigate(`/dashboard/${path}/${id}`);
        break;
      case 'delete':
        showDeleteConfirm();
        break;
      case 'max/min':
        setMaxMin.getModal(...setMaxMin.params);
        break;
      default:
        break;
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(onDelete.action(...onDelete.params)), 1000);
        });
      },
      onCancel() {},
    });
  };

  return (
    <Dropdown.Button
      disabled={!(options.length > 0)}
      overlay={renderMenu(id)}
      trigger={['click']}
      placement="bottom"
    />
  );
};
