import {
  CREATE_BRANCH_APPLIANCE,
  GET_BRANCH_APPLIANCES,
  GET_BRANCH_APPLIANCE_BY_ID,
  UPDATE_BRANCH_APPLIANCE,
  GET_BRANCH_APLIANCE_DELETE,
  DELETE_BRANCH_APPLIANCE,
} from '../types';

const initialState = {
  appliances: [],
  applianceById: {},
  applianceDelete: null,
};

export default function BranchAppliances(state = initialState, action) {
  switch (action.type) {
    case CREATE_BRANCH_APPLIANCE:
      return {
        ...state,
        appliances: [...state.appliances, action.payload],
      };
    case GET_BRANCH_APPLIANCES:
      return {
        ...state,
        appliances: action.payload,
      };
    case GET_BRANCH_APPLIANCE_BY_ID:
      return {
        ...state,
        applianceById: action.payload,
      };
    case UPDATE_BRANCH_APPLIANCE:
      return {
        ...state,
        appliances: state.appliances.map((apl) =>
          apl.id === action.payload.id ? (apl = action.payload) : apl
        ),
      };
    case GET_BRANCH_APLIANCE_DELETE:
      return {
        ...state,
        applianceDelete: action.payload,
      };

    case DELETE_BRANCH_APPLIANCE:
      return {
        ...state,
        appliances: state.appliances.filter(
          (apl) => apl.id !== state.applianceDelete
        ),
      };

    default:
      return state;
  }
}
