import { EditOutlined, FileTextOutlined } from '@ant-design/icons';

export const searchInputInitialValues = {
  search: { filter: 'order.order_number' },
};

export const searchFilters = [
  { value: 'order.order_number', label: 'Venta' },
  { value: 'seller.name', label: 'Vendedor' },
  { value: 'customer.name', label: 'Cliente' },
];

export const filters = {
  search: true,
  date: true,
  branches: true,
  dates: true,
};

export const cashSalesTableMenuOpt = [
  {
    label: 'Recibo',
    key: 'invoice',
    icon: <FileTextOutlined />,
    privilege: 'view',
  },
  {
    label: 'Cosmetóloga',
    key: 'edit',
    icon: <EditOutlined />,
    privilege: 'edit',
  },
];
