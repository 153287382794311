export const categoryTypeConst = [
  { value: 'treatment', label: 'Tratamiento' },
  { value: 'product', label: 'Producto' },
];

export const categoryTypeDiccionary = {
  treatment: 'Tratamiento',
  product: 'Producto',
};

export const searchFilters = [{ value: 'category.name', label: 'Nombre' }];

export const searchInputInitialValues = {
  search: { filter: 'category.name' },
};

export const filters = {
  search: true,
  categoryType: true,
};
