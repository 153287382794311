import { Tag } from 'antd';

export const UserStatus = ({ status }) => {

  const setStatus = () => {
    if (status === 'activo') {
      return <Tag color='green'>{status}</Tag>;
    } else if (status === 'inactivo') {
      return <Tag color='red'>{status}</Tag>;
    } 
  };

  return setStatus();
};
