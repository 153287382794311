import { GET_MISSED_APPOINTMENTS, CLEAR_MISSED_APPOINTMENTS } from '../types';

const initialState = {
  missedAppointments: [],
};

export default function MissedAppointment(state = initialState, action) {
  switch (action.type) {
    case GET_MISSED_APPOINTMENTS:
      return {
        ...state,
        missedAppointments: action.payload,
      };
    case CLEAR_MISSED_APPOINTMENTS:
      return {
        ...state,
        missedAppointments: [],
      };
    default:
      return state;
  }
}
