import {
  GET_STOCK,
  STOCK_ADJUSTMENT,
  CREATE_MAX_MIN,
  GET_MAX_MIN_BY_BRANCH,
  UPDATE_MAX_MIN,
  GET_STOCK_BY_BRANCH_ID,
  GET_STOCK_DETAILS,
  GET_STOCK_BY_BRANCH_AND_TREATMENT,
  CLEAR_STOCK_BY_BRANCH,
} from '../types';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import { get, patch, post, put } from './Action';

const basePath = '/stock';

// Get Stock
export const getStock = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getStockDS, true));
};

// Get Stock Details
export const getStockDetails = (branchId, productId) => async (dispatch) => {
  return await dispatch(
    get(`${basePath}/${branchId}/${productId}/details`, getStockDetailsDS)
  );
};

// Drop Stock
export const stockAdjustment = (stock) => async (dispatch) => {
  return await dispatch(
    patch(`${basePath}/adjustment`, stock, stockAdjustmentDS)
  );
};

// Create Max/Min
export const createMaxMin = (data) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/create-max-min`, data, createMaxMinDS)
  );
};

// Get Max Min By Branch
export const getMaxMinByBranch = (branchId) => async (dispatch) => {
  return await dispatch(
    get(`${basePath}/${branchId}/get-max-min-by-branch`, getMaxMinByBranchDS)
  );
};

// Max Min Update
export const updateMaxMin = (id, data) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update-max-min`, data, updateMaxMinDS)
  );
};

// Get Stock By Branch Id
export const getStockByBranchId =
  (branchId = null) =>
  async (dispatch) => {
    let query = '';

    if (branchId) {
      query = `?branchId=${branchId}`;
    }

    return await dispatch(
      get(`${basePath}/get-stock-by-branch-id${query}`, getStockByBranchIdDS)
    );
  };

// Get Stock By Branch Id and Order Type
export const getStockByBranchIdAndOrderType =
  (orderType, action, branchId = null, aggregate = null) =>
  async (dispatch) => {
    let query = '';

    if (branchId && !aggregate) {
      query = `&branchId=${branchId}`;
    } else if (!branchId && aggregate) {
      query = `&aggregate=${aggregate}`;
    } else if (branchId && aggregate) {
      query = `&branchId=${branchId}&aggregate=${aggregate}`;
    }

    return await dispatch(
      get(
        `${basePath}/get-stock-by-branch-id?orderType=${orderType}&action=${action}${query}`,
        getStockByBranchIdDS
      )
    );
  };

// Get Stock By Branch Id
export const getStockByBranchAndTreatment =
  (branchId, treatmentId) => async (dispatch) => {
    const query = `?branchId=${branchId}&treatmentId=${treatmentId}`;
    return await dispatch(
      get(
        `${basePath}/get-stock-by-branch-and-treatment${query}`,
        getStockByBranchAndTreatmentDS
      )
    );
  };

// Clear stock by branch
export const clearStockByBranch = () => {
  return (dispatch) => {
    dispatch(clearStockByBranchDS());
  };
};

const getStockDS = (stock) => ({
  type: GET_STOCK,
  payload: stock.items,
});

const getStockDetailsDS = (stock) => ({
  type: GET_STOCK_DETAILS,
  payload: stock,
});

const stockAdjustmentDS = (stock) => ({
  type: STOCK_ADJUSTMENT,
  payload: stock,
});

const createMaxMinDS = (stock) => ({
  type: CREATE_MAX_MIN,
  payload: stock,
});

const getMaxMinByBranchDS = (maxMin) => ({
  type: GET_MAX_MIN_BY_BRANCH,
  payload: maxMin,
});

const updateMaxMinDS = (stock) => ({
  type: UPDATE_MAX_MIN,
  payload: stock,
});

const getStockByBranchIdDS = (stock) => ({
  type: GET_STOCK_BY_BRANCH_ID,
  payload: stock,
});

const getStockByBranchAndTreatmentDS = (stock) => ({
  type: GET_STOCK_BY_BRANCH_AND_TREATMENT,
  payload: stock,
});

const clearStockByBranchDS = () => ({
  type: CLEAR_STOCK_BY_BRANCH,
});
