import {
  SET_APPOINTMENT_DATA,
  SET_FILTERS_DATA,
  CLEAR_FILTERS_DATA,
} from '../types';

// Appoitment data
export function setAppointmentData(data) {
  return (dispatch) => {
    dispatch(setAppointmentDataDS(data));
  };
}

// Filters data
export const setFiltersData = (data) => {
  return (dispatch) => {
    dispatch(setFiltersDataDS(data));
  };
};

// Filters data
export const clearFiltersData = () => {
  return (dispatch) => {
    dispatch(clearFiltersDataDS());
  };
};

// Appoitment data
const setAppointmentDataDS = (data) => ({
  type: SET_APPOINTMENT_DATA,
  payload: data,
});

const setFiltersDataDS = (data) => ({
  type: SET_FILTERS_DATA,
  payload: data,
});

const clearFiltersDataDS = () => ({
  type: CLEAR_FILTERS_DATA,
});
