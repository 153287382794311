import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_COUPON,
  GET_COUPONS,
  GET_COUPON_BY_ID,
  UPDATE_COUPON,
  DELETE_COUPON,
  DATA_DELETED,
} from '../types';

import { post, get, put, remove } from './Action';

// Create Coupon
const basePath = '/coupon';
export const createCoupon = (coupon) => async (dispatch) => {
  const response = await dispatch(
    post(`${basePath}/create`, coupon, createCouponDS, true)
  );

  return response?.status;
};

// Get Treatmenst
export const getCoupons = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getCouponsDS, true));
};

// Get Coupon By Id
export const getCouponById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getCouponByIdDS));
};

// Update Coupon
export const updateCoupon = (id, coupon) => async (dispatch) => {
  const response = await dispatch(
    put(`${basePath}/${id}/update`, coupon, updateCouponDS, true)
  );
  return response?.status;
};

// Delete Coupon
export const deleteCoupon = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteCouponDS)
  );
  dispatch(getCouponDeleteDS(id));
  return response;
};

const createCouponDS = (coupon) => ({
  type: CREATE_COUPON,
  payload: coupon,
});

const getCouponsDS = (treatments) => ({
  type: GET_COUPONS,
  payload: treatments.items,
});

const getCouponByIdDS = (coupon) => ({
  type: GET_COUPON_BY_ID,
  payload: coupon,
});

const updateCouponDS = (coupon) => ({
  type: UPDATE_COUPON,
  payload: coupon,
});

export const getCouponDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteCouponDS = () => ({
  type: DELETE_COUPON,
});
