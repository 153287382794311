import { Card } from 'antd';
import { GoalForm } from '../../components/Goal/GoalForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Goal = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <GoalForm />
    </Card>
  );
};
