import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Tag } from 'antd';
import { stringDate, stringTime } from '../../../../util/formatTime';
import {
  appointmentStatus,
  missedAppointmentConst,
} from '../../../../constants';
import { getAppointmentById } from '../../../../appRedux/actions';

export const AppointmentModalBody = ({ appointmentId }) => {
  const dispatch = useDispatch();
  const { appointmentById } = useSelector((state) => state.agenda);
  const { cancellations } = appointmentById;
  const [cancellation] = cancellations?.length > 0 ? cancellations : [];

  useEffect(() => {
    dispatch(getAppointmentById(appointmentId));
  }, [dispatch, appointmentId]);

  return (
    <>
      <Card bordered={false} style={{ marginBottom: '0px' }}></Card>
      <Row className="row">
        <Col span={8}>
          <h4>Cita</h4>
        </Col>
        <Col span={16}>{appointmentById?.appointmentNumber || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Dia de la cita</h4>
        </Col>
        <Col span={16}>
          <Tag color="green">{stringDate(appointmentById?.date) || '-'}</Tag>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Hora de inicio</h4>
        </Col>
        <Col span={16}>
          <Tag color="blue">
            {stringTime(appointmentById?.startTime) || '-'}
          </Tag>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Hora de finalización</h4>
        </Col>
        <Col span={16}>
          <Tag color="red">{stringTime(appointmentById?.endTime) || '-'}</Tag>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Metodo de cita</h4>
        </Col>
        <Col span={16}>{appointmentById?.appointmentMethod}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Paciente</h4>
        </Col>
        <Col span={16}>{`${appointmentById?.customer?.name} ${
          appointmentById?.customer?.lastName
        } ${appointmentById?.customer?.motherLastName || ''}`}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={16}>{appointmentById?.branch?.name}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Cabina</h4>
        </Col>
        <Col span={16}>{appointmentById?.cabain?.name}</Col>
      </Row>

      {appointmentById?.promotion && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Promocion</h4>
            </Col>
            <Col span={16}>{appointmentById?.promotion?.name}</Col>
          </Row>
        </>
      )}

      {appointmentById?.package && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Paquete</h4>
            </Col>
            <Col span={16}>{appointmentById?.package?.name}</Col>
          </Row>
        </>
      )}

      <Row className="row">
        <Col span={8}>
          <h4>Tratamiento</h4>
        </Col>
        <Col span={16}>{appointmentById?.treatment?.name}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Observaciones</h4>
        </Col>
        <Col>{appointmentById?.observations || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Status</h4>
        </Col>
        <Col span={16}>
          <Tag color={`#${appointmentStatus[appointmentById?.status]?.color}`}>
            {appointmentStatus[appointmentById?.status]?.value}
          </Tag>
        </Col>
      </Row>

      {cancellations?.length > 0 &&
        cancellation?.type === missedAppointmentConst.TYPES.CANCELLATION && (
          <>
            <Row className="row">
              <Col span={8}>
                <h4>Motivo de cancelación</h4>
              </Col>
              <Col span={16}>
                {
                  missedAppointmentConst.REASON_DICTIONARY[
                    cancellation?.cancellationReason
                  ]
                }
              </Col>
            </Row>

            <Row className="row">
              <Col span={8}>
                <h4>Comentarios</h4>
              </Col>
              <Col span={16}>{cancellation?.comment || '-'}</Col>
            </Row>
          </>
        )}
    </>
  );
};
