import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../../appRedux/actions/Product';
import { medicalSaleProduct } from '../../../../constants';
import {
  messages,
  placeHolders,
  twoColumnsFormConfig,
  oneColumnFormConfig,
  dateFormatMx,
} from '../../../../constants/Forms';
import { CustomSelect, NumericInput } from '../../../uiComponents';

export const PurchaseOrderProducts = ({
  form = [],
  providerId = null,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const formProducts = form.getFieldValue('products');
  const { products } = useSelector((state) => state.product);
  const [selectedItems, setSelectedItems] = useState(
    formProducts?.map((item) => item?.productId)
  );
  const [productType, setProductType] = useState([]);
  let filteredOptions = [];
  if (providerId) {
    filteredOptions = products.filter(
      (product) =>
        !selectedItems?.map((item) => item?.value).includes(product.id)
    );
  }

  useEffect(() => {
    if (providerId)
      dispatch(
        getProducts({
          providerId,
          status: '1',
          isApproved: '1',
          withDeleted: 'false',
        })
      );
  }, [dispatch, providerId]);

  const onProductChange = (product = null, data) => {
    setSelectedItems(
      form.getFieldValue('products')?.map((item) => item?.productId)
    );

    const selectedProduct = products?.find(
      (item) => item?.id === product?.value
    );

    if (data) {
      setProductType((items) =>
        items.toSpliced(data?.index, 1, selectedProduct?.productType?.name)
      );
    }
  };

  const onRemoveProduct = (index) => {
    setProductType((items) => items.toSpliced(index, 1));
  };

  return (
    <>
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <div className="products-container">
            {fields.map((field) => (
              <>
                <hr className="divider" />
                <Divider style={{ marginTop: 30 }} />
                <Row wrap>
                  <Col span={fields.length > 1 ? 23 : 24}>
                    <Form.Item
                      {...oneColumnFormConfig}
                      label="Producto"
                      name={[field.name, 'productId']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Producto'),
                        },
                      ]}
                    >
                      <CustomSelect
                        placeholder={placeHolders.product}
                        onChange={onProductChange}
                        labelInValue={true}
                        disabled={disabled}
                        options={filteredOptions.map(({ id, name, sku }) => ({
                          value: id,
                          label: `${sku} - ${name}`,
                          index: field.name,
                        }))}
                      ></CustomSelect>
                    </Form.Item>
                  </Col>

                  {fields.length > 1 && !disabled && (
                    <Col span={1}>
                      <MinusCircleOutlined
                        className="danger-button-product"
                        onClick={() => {
                          remove(field.name);
                          onProductChange();
                          onRemoveProduct(field.name);
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <Row wrap>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Cantidad"
                      name={[field.name, 'quantity']}
                      dependencies={[field.name, 'stock']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Cantidad'),
                        },
                      ]}
                    >
                      <NumericInput
                        className={'input-w100'}
                        min={0}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Precio"
                      name={[field.name, 'price']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Precio'),
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        stringMode={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row wrap>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Lote"
                      name={[field.name, 'allotment']}
                      normalize={(value, prevVal, prevVals) =>
                        value.replace(/\s/g, '')
                      }
                      rules={[
                        {
                          required:
                            productType[field.name] === medicalSaleProduct,
                          message: messages.required('Lote'),
                        },
                      ]}
                    >
                      <Input className={'input-w100'} disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Expiración"
                      name={[field.name, 'expiresAt']}
                      rules={[
                        {
                          required:
                            productType[field.name] === medicalSaleProduct,
                          message: messages.required('Expiración'),
                        },
                      ]}
                    >
                      <DatePicker
                        className={'input-w100'}
                        format={dateFormatMx}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ))}
            <Button
              className="add-btn"
              disabled={disabled}
              onClick={() => {
                add();
                onProductChange();
              }}
              icon={<PlusOutlined />}
            >
              Agregar producto
            </Button>
          </div>
        )}
      </Form.List>
    </>
  );
};
