import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { GoalTable } from '../../components/Goal/GoalTable';

export const Goals = () => {
  const navigate = useNavigate();
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          navigate('/dashboard/metas/crear');
        }}
      >
        <PlusOutlined />
        Nuevo
      </Button>

      <GoalTable />
    </Card>
  );
};
