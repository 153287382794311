import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { getAllLogs } from '../../../../appRedux/actions/Log';
import { stringDateTime } from '../../../../util/formatTime';
import {
  actionDictionary,
  changesDictionary,
  filters,
  prefixesDictionary,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Logs.const';
import { Button, Tag } from 'antd';
import { ChangesModalBody } from '../ModalHystory';

export const HistoryTable = () => {
  const {
    privileges: { history: privileges = [] },
    role,
  } = useSelector((state) => state.userAuth);
  const { logs } = useSelector((state) => state?.logs);
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();
  const { catalogId } = useCustomModal();

  const getModalData = useCallback(() => {
    const [modalData] = logs?.filter((log) => log?.id === catalogId);
    return modalData;
  }, [logs, catalogId]);

  useEffect(() => {
    setModalData(getModalData());
  }, [getModalData]);

  const getDateTimeString = (date) => {
    return `${stringDateTime(date)}`;
  };

  const onModalClick = (changes, product) => {
    modal();
    setModalData({ changes, product });
  };
  const columns = [
    {
      title: 'Módulo',
      sorter: true,
      dataIndex: 'module',
      key: 'module',
      render: (module, log) =>
        log?.changes ? (
          <Button
            type="link"
            htmlType="button"
            href="#/"
            onClick={() => onModalClick(log?.changes, log?.product)}
          >
            {module?.name}
          </Button>
        ) : (
          <Button type="link" htmlType="button" style={{ color: '#000000' }}>
            {module?.name}
          </Button>
        ),
      path: 'module.name',
    },
    {
      title: 'Acción',
      sorter: true,
      dataIndex: 'action',
      key: 'action',
      render: (action) => actionDictionary[action],
    },
    {
      title: 'Número de orden',
      sorter: true,
      dataIndex: 'order',
      key: 'order',
      render: (order, log) =>
        prefixesDictionary[log?.module?.alias] + order || '-',
    },
    {
      title: 'Cambios',
      sorter: true,
      dataIndex: 'changes',
      key: 'changes',
      render: (changes, log, quantity) =>
        changes &&
        Object.keys(JSON.parse(changes))?.map((field, key) => (
          <Tag color="blue" key={key}>
            {' '}
            {changesDictionary[field] || field}
          </Tag>
        )),
      path: 'no',
    },
    {
      title: 'Stock',
      sorter: true,
      dataIndex: 'stock',
      key: 'stock',
      render: (stock) => stock ?? 0,
    },
    {
      title: 'Producto',
      sorter: true,
      dataIndex: 'product',
      key: 'product',
      render: (product) => product || '-',
    },
    {
      title: 'Sucursal Origen',
      sorter: true,
      dataIndex: 'originBranch',
      key: 'originBranch',
      render: (originBranch) => originBranch?.name || '-',
      path: 'no',
    },
    {
      title: 'Sucursal Destino',
      sorter: true,
      dataIndex: 'destinationBranch',
      key: 'destinationBranch',
      render: (destinationBranch) => destinationBranch?.name || '-',
      path: 'no',
    },
    {
      title: 'Usuario',
      sorter: true,
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => (
        <>
          {record.user?.name} {record.user?.lastName}{' '}
          {record.user?.motherLastName}
        </>
      ),
      path: ['user.name', 'user.lastName', 'user.motherLastName'],
    },
    {
      title: 'Fecha',
      sorter: true,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => getDateTimeString(date),
      path: 'created_at',
      formatter: (date) => getDateTimeString(date),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        role={role}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ModalView title={'Cambios'} width={700}>
        <ChangesModalBody data={modalData} />
      </ModalView>
      <ListTable
        columns={columns}
        name={'Historial'}
        getDataFunction={getAllLogs}
      />
    </>
  );
};
