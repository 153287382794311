import {
  CREATE_ASSORTMENT_ORDER,
  DELETE_ASSORTMENT_ORDER,
  GET_ASSORTMENT_ORDERS,
  GET_ASSORTMENT_ORDER_BY_ID,
  UPDATE_ASSORTMENT_ORDER,
  APPROVE_ASSORTMENT_ORDER,
  DATA_DELETED,
} from '../types';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import { post, get, put, remove, patch } from './Action';

const basePath = '/assortment-order';

// Create Assortment Order
export const createAssortmentOrder = (order) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, order, createAssortmentDS));
};

// Get Assortment Orders
export const getAssortmentOrders = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getAssortmentOrdersDS, true)
  );
};

// Get Assortment Order By Id
export const getAssortmentOrderById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getAssortmentOrderByIdDS));
};

// Edit Assortment Order
export const confirmAssortmentOrder = (id, order) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/confirm`, order, confirmAssortmentOrderDS)
  );
};

// Approve Assortment Order
export const approveAssortmentOrder = (id, order) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/approve`, order, approveAssortmentOrderDS)
  );
};

// Reject Assortment Order
export const rejectAssortmentOrder = (id) => async (dispatch) => {
  return await dispatch(
    patch(`${basePath}/${id}/reject`, deleteAssortmentOrderDS)
  );
};

// Delete Assortment Order
export const deleteAssortmentOrder = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteAssortmentOrderDS)
  );
  dispatch(assortmentDeleteDS(id));
  return response;
};

const createAssortmentDS = (order) => ({
  type: CREATE_ASSORTMENT_ORDER,
  payload: order,
});

const getAssortmentOrdersDS = (orders) => ({
  type: GET_ASSORTMENT_ORDERS,
  payload: orders.items,
});

const getAssortmentOrderByIdDS = (order) => ({
  type: GET_ASSORTMENT_ORDER_BY_ID,
  payload: order,
});

const confirmAssortmentOrderDS = (order) => ({
  type: UPDATE_ASSORTMENT_ORDER,
  payload: order,
});

const approveAssortmentOrderDS = (order) => ({
  type: APPROVE_ASSORTMENT_ORDER,
  payload: order,
});

export const assortmentDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteAssortmentOrderDS = (order) => ({
  type: DELETE_ASSORTMENT_ORDER,
  payload: order,
});
