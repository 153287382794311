import { Card } from 'antd';
import { CustomerForm } from '../../components/Customer/CustomerForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Customer = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <CustomerForm />
    </Card>
  );
};
