import { Col, Row } from 'antd';
export const ProductTypeModal = ({ data = {} }) => {
  const { name } = data;

  return (
    <>
      <Row className='row'>
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{`${name}`}</Col>
      </Row>
    </>
  );
};
