import { useEffect, useState } from 'react';
import { Form, Card, Input, Select, Button, Switch, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCategories,
  createProduct,
  editProduct,
  getProductTypes,
  getProductById,
  rejectProduct,
  getProviders,
} from '../../../../appRedux/actions';
import {
  NumericInput,
  CustomSelect,
  UploadPhotos,
  CancelButton,
} from '../../../uiComponents';

import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
  masks,
  rejectProductModal,
  modalButtons,
} from '../../../../constants';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import { unmaskPercent } from '../../../../util/MaskedValues';
import './styles.css';
import { types } from '../../../../constants/Product.const';
import { formatPrice } from '../../../../util/formatPrice';
import { validatePhotos } from '../../../../util';
import { RESET_PRODUCT_BY_ID } from '../../../../appRedux/types';

export const ProductForm = ({ privileges }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const { id } = useParams();
  const [form] = Form.useForm();
  const editMode = id;
  const { categories } = useSelector((state) => state.category);
  const { productById } = useSelector((state) => state.product);
  const { productTypes } = useSelector((state) => state.productTypes);
  const { providers } = useSelector((state) => state.provider);
  const [res, setRes] = useState();
  const [active, setActive] = useState(true);
  const productType = productById
    ? types[productById?.productType?.name]
    : null;
  const [fileList, setFileList] = useState([]);
  const [photosIds, setPhotosIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editMode && productById.id === id) {
      const photos = productById?.photos
        ? productById?.photos?.map((photo) => ({
            uid: photo.id,
            name: photo.name,
            status: 'done',
            url: `${process.env.REACT_APP_API}/v1/product/photo/${photo.name}`,
          }))
        : [];

      setFileList(photos);

      const ids = productById?.photos
        ? productById?.photos?.map((photo) => photo?.id || photo?.uid)
        : [];

      setPhotosIds(ids);
    }
  }, [id, productById]);

  useEffect(() => {
    dispatch(getCategories({ type: 'product', withDeleted: 'false' }));
    dispatch(getProductTypes());
    dispatch(getProviders({ withDeleted: 'false' }));
    if (editMode) dispatch(getProductById(id));
  }, [dispatch, editMode, id]);

  useEffect(() => {
    if (editMode && productById.id === id) {
      productById.providersIds = productById?.providers?.map(
        (provider) => provider.id
      );
      productById.categoriesIds = productById?.categories?.map(
        (category) => category.id
      );
      productById.productTypeId = productById?.productType?.id;

      productById.realCost =
        Number(productById.cost) + (productById.cost * productById.tax) / 100;

      productById.franchiseRealCost = Number(
        productById?.franchisePrice
      ).toFixed(2);

      productById.realCost = `$ ${Number(productById.realCost).toFixed(2)}`;
      productById.tax = `${productById.tax}`;
      productById.franchiseTax = `${productById.franchiseTax || '0'}`;

      productById.additionalCostFranchise = productById?.additionalCostFranchise
        ? productById?.additionalCostFranchise.toString() + '%'
        : null;

      productById.profitPorcentage = productById?.profitPorcentage
        ? productById?.profitPorcentage.toString()
        : null;

      setActive(productById.status);
      form.setFieldsValue(productById);
    }
  }, [editMode, form, id, productById, productType]);

  useEffect(() => {
    if (res && (res === 201 || res === 200)) {
      dispatch({ type: RESET_PRODUCT_BY_ID });
      navigate('/dashboard/productos');
    }
  }, [navigate, res]);

  const onChangePhotos = (photos) => {};

  const onActiveChange = (status) => {
    setActive(status);
  };

  const onCalculateCost = () => {
    const values = form.getFieldsValue();

    const franchiseCost =
      !isNaN(unmaskPercent(values.additionalCostFranchise)) &&
      values.additionalCostFranchise
        ? `${unmaskPercent(values.additionalCostFranchise)}`
        : 0;

    const taxes = unmaskPercent(values.tax)
      ? `${unmaskPercent(values.tax)}`
      : 0;
    const franchTax = values.franchiseTax
      ? `${unmaskPercent(values.franchiseTax)}`
      : 0;
    const costReal = Number(values.cost) * (Number(taxes) / 100 + 1);

    const expenses = values.indirectExpenses
      ? Number(values.indirectExpenses)
      : 0;

    values.franchiseRealCost =
      Number(costReal) *
        (1 + Number(franchTax) / 100) *
        (1 + Number(franchiseCost) / 100) +
      Number(expenses);

    const final = formatPrice(values.franchiseRealCost.toFixed(2));

    form.setFieldsValue({ franchiseRealCost: final });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (values?.tax) values.tax = parseInt(values.tax);
    if (values?.franchiseTax)
      values.franchiseTax = parseInt(values.franchiseTax);
    if (values?.additionalCostFranchise)
      values.additionalCostFranchise = unmaskPercent(
        values.additionalCostFranchise
      );
    if (values?.points) values.points = Number(values.points);
    if (editMode) {
      const currentPhotos = values.photos.map((photo) => photo.uid || photo.id);
      const photosToDelete = photosIds.filter(
        (x) => !currentPhotos.includes(x)
      );
      values.photos = values?.photos?.filter((photo) =>
        photo?.uid?.includes('rc-upload')
      );

      values.productType = productById?.productType?.name;
      values.productTypeId = productById?.productType?.id;

      if (!values.indirectExpenses)
        values.indirectExpenses = productById.indirectExpenses;
      if (!values.salePrice) values.salePrice = productById.salePrice;
      if (!values.points) values.points = productById.points;

      if (values.profitPorcentage)
        values.profitPorcentage = unmaskPercent(values.profitPorcentage, true);

      values.status = active;

      setRes(await dispatch(editProduct(id, values, photosToDelete)));
    } else {
      setRes(await dispatch(createProduct(values)));
    }
    setIsLoading(false);
  };

  const onReject = () => {
    confirm({
      title: rejectProductModal.title,
      icon: <ExclamationCircleOutlined />,
      content: rejectProductModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise(async (resolve) => {
          setRes(await dispatch(rejectProduct(productById.id)));
          resolve();
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          autoComplete="off"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          id="onSubmit"
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label="Fotos"
            rules={[
              { required: true, message: messages.required('Foto') },
              () => ({
                validator(_, value) {
                  return validatePhotos(_, value);
                },
              }),
            ]}
            name="photos"
          >
            <UploadPhotos
              onChange={onChangePhotos}
              limit={3}
              files={fileList}
            />
          </Form.Item>
          <Form.Item
            label="Tipo de producto"
            name="productTypeId"
            rules={[
              {
                required: true,
                message: messages.required('Tipo de Producto'),
              },
            ]}
          >
            <Select
              placeholder={placeHolders.productType}
              options={productTypes?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              disabled={id}
            ></Select>
          </Form.Item>

          <Form.Item
            label="SKU"
            name="sku"
            rules={[
              { required: true, message: messages.required('SKU') },
              {
                min: 4,
                message: messages.validation.sku,
              },
              {
                max: 12,
                message: messages.validation.sku,
              },
            ]}
          >
            <Input disabled={id} />
          </Form.Item>

          <Form.Item
            label="Nombre"
            name="name"
            rules={[{ required: true, message: messages.required('Nombre') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Marca"
            name="brand"
            rules={[{ required: true, message: messages.required('Marca') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Codigo de barras"
            name="barCode"
            rules={[
              {
                required: true,
                message: messages.required('Codigo de barras'),
              },
            ]}
          >
            <NumericInput />
          </Form.Item>

          <Form.Item
            label="Descripcion"
            name="description"
            rules={[
              {
                required: true,
                message: messages.required('Descripción'),
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Tipo contenido"
            name="contentType"
            rules={[
              {
                required: true,
                message: messages.required('Tipo contenido'),
              },
            ]}
          >
            <Select placeholder={placeHolders.contentType}>
              <Select.Option value="Bolsa">Bolsa</Select.Option>
              <Select.Option value="Paquete">Paquete</Select.Option>
              <Select.Option value="Caja">Caja</Select.Option>
              <Select.Option value="Pieza">Pieza</Select.Option>
              <Select.Option value="Vial">Vial</Select.Option>
              <Select.Option value="Jeringuilla">Jeringuilla</Select.Option>
              <Select.Option value="Ampolleta">Ampolleta</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Contenido"
            name="content"
            rules={[
              {
                required: true,
                message: messages.required('Contenido'),
              },
            ]}
          >
            <NumericInput />
          </Form.Item>

          <Form.Item
            label="Unidad de medida"
            name="measureUnit"
            rules={[
              {
                required: true,
                message: messages.required('Unidad de medida'),
              },
            ]}
          >
            <Select placeholder={placeHolders.measureUnit}>
              <Select.Option value="ml">Mililitro(s)</Select.Option>
              <Select.Option value="l">Litro(s)</Select.Option>
              <Select.Option value="g">Gramo(s)</Select.Option>
              <Select.Option value="kg">Kilogramo(s)</Select.Option>
              <Select.Option value="pza">Pieza(s)</Select.Option>
              <Select.Option value="ud">Unidad(es)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Costo sin IVA"
            name="cost"
            onChange={onCalculateCost}
            rules={[
              { required: true, message: messages.required('Costo sin IVA') },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              stringMode={true}
            />
          </Form.Item>

          <Form.Item
            label="IVA"
            name="tax"
            rules={[{ required: true, message: messages.required('IVA') }]}
          >
            <Select placeholder={placeHolders.iva} onChange={onCalculateCost}>
              <Select.Option value="0">0%</Select.Option>
              <Select.Option value="8">8%</Select.Option>
              <Select.Option value="16">16%</Select.Option>
            </Select>
          </Form.Item>

          {editMode && productById && !productById?.rejected_at ? (
            <>
              <Form.Item label="Costo a moldearte" name="realCost">
                <Input disabled />
              </Form.Item>
              {['cosmetólogo', 'venta', 'médico'].includes(productType) &&
                privileges.includes('approve') && (
                  <Form.Item
                    label="Gastos indirectos"
                    name="indirectExpenses"
                    rules={[
                      {
                        required: true,
                        message: messages.required('Gastos indirectos'),
                      },
                    ]}
                  >
                    <NumericInput onChange={onCalculateCost} />
                  </Form.Item>
                )}
              {privileges.includes('approve') && (
                <>
                  <Form.Item
                    label="IVA a franquicia"
                    name="franchiseTax"
                    rules={[
                      {
                        required: true,
                        message: messages.required('IVA a franquicia'),
                      },
                    ]}
                  >
                    <Select
                      placeholder={placeHolders.franchiseTax}
                      onChange={onCalculateCost}
                    >
                      <Select.Option value="0">0%</Select.Option>
                      <Select.Option value="8">8%</Select.Option>
                      <Select.Option value="16">16%</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Porcentaje adicional franquicia"
                    name="additionalCostFranchise"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    {productById.id === id && (
                      <MaskedInput
                        mask={masks.percent}
                        onChange={onCalculateCost}
                        defaultValue={
                          editMode &&
                          typeof productById?.additionalCostFranchise?.toString() !==
                            'undefined'
                            ? `${productById?.additionalCostFranchise
                                ?.toString()
                                .replace('%', '')}%`
                            : null
                        }
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    label="Precio a Franquicia"
                    name="franchiseRealCost"
                    rules={[
                      {
                        required: true,
                        message: messages.required('Costo a moldearte'),
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {editMode &&
          productById &&
          !productById?.rejected_at &&
          productType === 'cosmetólogo' &&
          privileges.includes('approve') ? (
            <>
              <Form.Item
                label="Porcentaje de utilidad"
                name="profitPorcentage"
                rules={[
                  {
                    required: true,
                    message: messages.required('Porcentaje de utilidad'),
                  },
                ]}
              >
                {productById.id === id && (
                  <MaskedInput
                    mask={masks.percent}
                    defaultValue={
                      editMode &&
                      typeof productById?.profitPorcentage?.toString() !==
                        'undefined'
                        ? `${productById?.profitPorcentage
                            ?.toString()
                            .replace('%', '')}%`
                        : null
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                label="Precio de venta"
                name="salePrice"
                rules={[
                  {
                    required: true,
                    message: messages.required('Precio de venta'),
                  },
                ]}
              >
                <NumericInput />
              </Form.Item>

              <Form.Item
                label="Puntos"
                name="points"
                rules={[
                  {
                    required: true,
                    message: messages.required('Puntos'),
                  },
                ]}
              >
                <NumericInput />
              </Form.Item>
            </>
          ) : (
            <></>
          )}

          <Form.Item
            label="Proveedores"
            name="providersIds"
            rules={[
              { required: true, message: messages.required('Proveedores') },
            ]}
          >
            <CustomSelect
              mode="multiple"
              placeholder={placeHolders.providers}
              options={providers?.map((provider) => ({
                label: provider.name,
                value: provider.id,
              }))}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            label="Categorías"
            name="categoriesIds"
            rules={[
              { required: true, message: messages.required('Categorías') },
            ]}
          >
            <CustomSelect
              mode="multiple"
              placeholder={placeHolders.categories}
              options={categories?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            ></CustomSelect>
          </Form.Item>

          {editMode && productById && !productById?.rejected_at ? (
            <Form.Item label="Activar" name="status">
              <Switch checked={active} onChange={onActiveChange} />
            </Form.Item>
          ) : (
            <></>
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            form="onSubmit"
            loading={isLoading}
          >
            {editMode &&
            privileges.includes('approve') &&
            !productById?.approved_at &&
            !productById?.rejected_at
              ? buttonLabel.approve
              : buttonLabel.save}
          </Button>
          {editMode &&
            !productById?.approved_at &&
            !productById?.rejected_at &&
            privileges.includes('approve') && (
              <Button className="btn-form" onClick={onReject} danger>
                {buttonLabel.reject}
              </Button>
            )}
        </Form>
        <CancelButton path="/dashboard/productos" />
      </Card>
    </>
  );
};
