import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CodepenOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import LogoLogin from '../../../assets/images/LOGO-AZUL-300x97.png';
import {
  resetPassword,
  changePasswordCode,
} from '../../../appRedux/actions/UserAuth';
import {
  buttonLabel,
  lengthValidation,
  messages,
  minLengthValidation,
  placeHolders,
} from '../../../constants/Forms';
import './styles.css';
import { PasswordValidatorForm } from '../PasswordValidatorForm';

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const { code } = useParams();
  const [isTokenMode, setIsTokenMode] = useState(code ? true : false);
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (values?.email) {
      dispatch(resetPassword(values));
      setIsTokenMode(true);
    } else {
      setResponse(await dispatch(changePasswordCode(values)));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if ([200, 201].includes(response)) {
      navigate('/login');
    }
  }, [navigate, response]);

  return (
    <Row>
      <Col className="col-forgot" span={24}>
        <div className="forgot-container">
          <div className="logo-forgot">
            <img alt="Moldearte Logo" src={LogoLogin} />
          </div>
          <div className="forgot-body">
            <Form
              name="normal_forgot"
              className="forgot-form"
              initialValues={{ remember: true }}
              onFinish={onSubmit}
            >
              {!isTokenMode && (
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: messages.required('Usuario'),
                    },
                    {
                      type: 'email',
                      message: messages.required('Email'),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={placeHolders.email}
                  />
                </Form.Item>
              )}
              {isTokenMode && (
                <>
                  <Form.Item
                    name="code"
                    initialValue={code}
                    rules={[
                      {
                        required: true,
                        message: messages.required('Código'),
                      },
                      {
                        min: 6,
                        message: minLengthValidation('Código', 6),
                      },
                      {
                        max: 6,
                        message: lengthValidation('Código', 6),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      prefix={
                        <CodepenOutlined className="site-form-item-icon" />
                      }
                      placeholder={placeHolders.code}
                    />
                  </Form.Item>
                  <PasswordValidatorForm />
                </>
              )}
              <Row style={{ paddingBottom: '10px' }}>
                <Col span={8}>
                  {isTokenMode && (
                    <Link to="#" onClick={() => setIsTokenMode(false)}>
                      {buttonLabel.resendCode}
                    </Link>
                  )}
                </Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <Link to="/">{buttonLabel.login}</Link>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="forgot-form-button"
                  loading={isLoading}
                >
                  {buttonLabel.resetPassword}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};
