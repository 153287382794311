import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { deleteProvider, getProviders } from '../../../../appRedux/actions';
import {
  ListTable,
  TableMenuOpt,
  ModalView,
  TableFilters,
} from '../../../uiComponents';
import { ProviderModal } from '../ProviderModal';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Provider.const';

export const ProvidersTable = () => {
  const {
    privileges: { providers: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const { providers } = useSelector((state) => state.provider);
  const { catalogId, setModal } = useCustomModal();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});

  const getModalData = useCallback(() => {
    const [modalData] = providers?.filter(
      (provider) => provider.id === catalogId
    );
    return modalData;
  }, [providers, catalogId]);

  useEffect(() => {
    setModalTitle(getModalData()?.name);
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteProvider(id));
  };

  const columns = [
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (provider, { id }) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {provider}
        </Button>
      ),
    },
    {
      title: 'Razón Social',
      sorter: true,
      dataIndex: 'tradename',
      key: 'tradename',
    },
    {
      title: 'Contacto',
      sorter: true,
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'Condiciones de pago',
      sorter: true,
      dataIndex: 'payConditions',
      key: 'payConditions',
      render: (text) => `${text} días`,
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          privileges={privileges}
          path="proveedores"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Proveedores'}
        getDataFunction={getProviders}
        params={{ withDeleted: 'false' }}
      />
      <ModalView title={modalTitle}>
        <ProviderModal data={modalData} />
      </ModalView>
    </>
  );
};
