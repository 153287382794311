import { Button, Card, Form, InputNumber } from 'antd';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateConfigs } from '../../../../appRedux/actions';
import { buttonLabel, cardConfig, formConfig } from '../../../../constants';
import { CancelButton } from '../../../uiComponents';

export const SettingForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { configs } = useSelector((state) => state?.configs);
  const [response, setResponse] = useState();
  const [configId, setConfigId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setConfigId(configs?.id);
  }, [configs?.id]);

  useEffect(() => {
    if (configId) {
      form.setFieldsValue(configs);
    }
  }, [configs, form, configId]);

  useEffect(() => {
    if (response && [200, 201].includes(response))
      navigate('/dashboard/configuraciones');
  }, [navigate, response]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (configId) {
      setResponse(await dispatch(updateConfigs(configId, values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form onFinish={onSubmit} form={form} {...formConfig}>
          <Form.Item name="exchangeRate" label="Tipo de cambio">
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              stringMode={true}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/configuraciones" />
      </Card>
    </>
  );
};
