import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getOccupancyPercentageByBranch } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { getDaysInMonth } from '../../../../util/getDaysInMonth';
import { formatFileName } from '../../../../util/strings';
import { occupancyPercentageReportByBranchConst } from '../../../../constants';

export const OccupancyPercentageReportByBranch = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { occupancyPercentageByBranch } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(occupancyPercentageReportByBranchConst.title),
    sheet: occupancyPercentageReportByBranchConst.title,
  });
  const header = occupancyPercentageReportByBranchConst.header;
  let total = 0;
  let fullYes = 0;
  let fullNo = 0;
  let fullVoicemail = 0;
  let fullConfirmed = 0;
  let fullWhatsapp = 0;
  let fullTotal = 0;
  let percentage = 0;
  let color = '';

  useEffect(() => {
    if (filtersData.dates)
      dispatch(getOccupancyPercentageByBranch({ dates: filtersData.dates }));
  }, [dispatch, filtersData.dates]);

  return (
    <div>
      <ExportButton onDownload={onDownload} />

      <table ref={tableRef} style={{ margin: '0 auto' }}>
        <ReportTableHeader
          user={fullname}
          role={role}
          title={occupancyPercentageReportByBranchConst.title}
          period={period}
          col1={10}
          col2={0}
          col3={14}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                colSpan={2}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {occupancyPercentageByBranch?.map((row, key) => {
            fullYes += Number(row.yes);
            fullNo += Number(row.no);
            fullVoicemail += Number(row.voicemail);
            fullWhatsapp += Number(row.whatsapp);
            fullConfirmed += Number(row.confirmed);

            total =
              Number(row.yes) +
              Number(row.no) +
              Number(row.voicemail) +
              Number(row.whatsapp);

            percentage = Number(row.confirmed);

            if (percentage < 50) {
              color = '#FF7B7C';
            } else if (percentage >= 50 && percentage < 70) {
              color = '#FEEF7A';
            } else {
              color = '#9FE47D';
            }

            fullTotal += total;
            return (
              <tr key={key}>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.yes}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.no}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.voicemail}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.whatsapp}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {total}
                </td>

                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: color,
                  }}
                >
                  <b>{percentage}%</b>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL DE CITAS</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullYes}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullNo}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullVoicemail}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullWhatsapp}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              {fullTotal}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
              }}
            >
              <b>{fullConfirmed}%</b>
            </td>
          </tr>

          <tr>
            <td colSpan={14}></td>
          </tr>
          <tr>
            <td
              colSpan={14}
              style={{
                backgroundColor: '#9FE47D',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <b>Del 70 al 100%</b>
            </td>
          </tr>
          <tr>
            <td
              colSpan={14}
              style={{
                backgroundColor: '#FEEF7A',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <b>Del 50 al 69%</b>
            </td>
          </tr>
          <tr>
            <td
              colSpan={14}
              style={{
                backgroundColor: '#FF7B7C',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <b>Del 0 al 49%</b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
