import { Avatar, Card } from 'antd';
import DefaultImage from '../../../../assets/images/ICONO-AZUL-100x100.png';
import { hexToRGB } from '../../../../util/colors';
import './styles.css';

export const HeaderItem = ({
  description = '',
  cabain = 'No Definida',
  color = '#000000',
}) => {
  return (
    <>
      <Card
        className="header-item"
        bordered={true}
        cover={
          <Avatar
            className="avatar"
            alt="..."
            src={
              false /* Aqui recibira la variable con la ruta */
                ? DefaultImage /* Usara la variable */
                : DefaultImage
            }
          />
        }
        style={{
          marginBottom: 0,
          borderTop: `5px solid #${color}`,
          backgroundColor: `rgba(${hexToRGB(color)}, 0.1)`,
        }}
      >
        <h5 className="cabain-type" style={{ color: `#${color}` }}>
          {cabain.toUpperCase()}
        </h5>
        <p className="description">{description}</p>
      </Card>
    </>
  );
};
