import {
  CREATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_DELETE,
  UPDATE_PURCHASE_ORDER,
} from '../types';
import { queryPathBuilder } from '../../util/queryPathBuilder';
import { post, get, put, remove } from './Action';

const basePath = '/purchase-order';

// Create Purchase Order
export const createPurchaseOrder = (order) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, order, createPurchaseDS));
};

// Get Purchase Orders
export const getPurchaseOrders = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getPurchaseOrdersDS, true)
  );
};

// Get Purchase Order By Id
export const getPurchaseOrderById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getPurchaseOrderByIdDS));
};

// Edit Purchase Order
export const editPurchaseOrder = (id, order) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, order, editPurchaseOrderDS)
  );
};

// Delete Purchase Order
export const deletePurchaseOrder = (id) => async (dispatch) => {
  dispatch(getPurchaseOrderDeleteDS(id));
  return await dispatch(
    remove(`${basePath}/${id}/delete`, deletePurchaseOrderDS)
  );
};

const createPurchaseDS = (order) => ({
  type: CREATE_PURCHASE_ORDER,
  payload: order,
});

const getPurchaseOrdersDS = (orders) => ({
  type: GET_PURCHASE_ORDERS,
  payload: orders.items,
});

const getPurchaseOrderByIdDS = (order) => ({
  type: GET_PURCHASE_ORDER_BY_ID,
  payload: order,
});

const editPurchaseOrderDS = (order) => ({
  type: UPDATE_PURCHASE_ORDER,
  payload: order,
});

export const getPurchaseOrderDeleteDS = (id) => ({
  type: GET_PURCHASE_ORDER_DELETE,
  payload: id,
});

const deletePurchaseOrderDS = () => ({
  type: DELETE_PURCHASE_ORDER,
});
