import { Dropdown, Menu } from 'antd';
import { saleOrderTableMenuOpt } from '../../../../constants/SaleOrder.const';

export const SaleOrderTableOptions = ({ id = null, privileges = [] }) => {
  let options = saleOrderTableMenuOpt.filter((opt) =>
    privileges.includes(opt.privilege)
  );

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'pdf':
        window.open(
          `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/sale-order/${id}/download`,
          '_blank',
          'noopener,noreferrer'
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown.Button
        disabled={!(options.length > 0)}
        overlay={renderMenu(id)}
        trigger={['click']}
        placement="bottom"
      />
    </>
  );
};
