import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { AssortmentOrderForm } from '../../components/AssortmentOrder/AssortmentOrderForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const AssortmentOrder = () => {
  const {
    fullname,
    role,
    branchId,
    privileges: { assortmentOrder = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <AssortmentOrderForm
        loggedUserName={fullname}
        role={role}
        mainBranchId={branchId}
        privileges={assortmentOrder}
      />
    </Card>
  );
};
