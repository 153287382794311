import {
  UserOutlined,
  LockOutlined,
  ShopOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import LogoLogin from '../../../assets/images/LOGO-AZUL-300x97.png';
import { signIn } from '../../../appRedux/actions/UserAuth';
import { buttonLabel, messages, placeHolders } from '../../../constants/Forms';
import './styles.css';
import { useEffect, useState } from 'react';
import { getSubsidiaries } from '../../../appRedux/actions';
import { CustomSelect } from '../../uiComponents';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const [branchId, setBranchId] = useState();
  const [form] = Form.useForm();
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    dispatch(getSubsidiaries({ status: '1' }));

    const savedLoginData = JSON.parse(localStorage.getItem('loginData'));
    if (savedLoginData) {
      form.setFieldsValue(savedLoginData);
      setRemember(savedLoginData.remember || false);
    }
  }, [dispatch, form]);

  const onSubmit = async (values) => {
    if (values.remember) {
      localStorage.setItem('loginData', JSON.stringify(values));
    } else {
      localStorage.removeItem('loginData');
    }
    dispatch(signIn(values));
  };

  const handleRememberChange = (e) => {
    setRemember(e.target.checked);
  };

  return (
    <Row>
      <Col className="col-login" span={24}>
        <div className="login-container">
          <div className="logo-login">
            <img alt="Moldearte Logo" src={LogoLogin} />
          </div>
          <div className="login-body">
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: false }}
              onFinish={onSubmit}
              form={form}
            >
              <div style={{ position: 'relative' }}>
                <div className="prefix-icon-wrapper">
                  <ShopOutlined className="site-form-item-icon" />
                </div>
                <Form.Item
                  name="branchId"
                  rules={[
                    {
                      required: true,
                      message: messages.required('Sucursal'),
                    },
                  ]}
                >
                  <CustomSelect
                    name="branchId"
                    className={'custom-select'}
                    size="large"
                    onChange={setBranchId}
                    placeholder={placeHolders.branch}
                    options={subsidiaries?.map((subsidiary) => ({
                      label: subsidiary.name,
                      value: subsidiary.id,
                    }))}
                  ></CustomSelect>
                </Form.Item>
              </div>

              <Form.Item name="code" rules={[]}>
                <Input
                  size="large"
                  prefix={<NumberOutlined className="site-form-item-icon" />}
                  placeholder={placeHolders.branchCode}
                />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: messages.required('Usuario'),
                  },
                  {
                    type: 'email',
                    message: messages.required('Email'),
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={placeHolders.email}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: messages.required('Contraseña'),
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={placeHolders.password}
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox checked={remember} onChange={handleRememberChange}>
                    {buttonLabel.remember}
                  </Checkbox>
                </Form.Item>
                <Link className="login-form-forgot" to="/forgot-password">
                  {buttonLabel.forgetPassword}
                </Link>
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {buttonLabel.login}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};
