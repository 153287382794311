import { Col, Row } from 'antd';

export const CabainTypeModal = ({ data = {} }) => {
  const { color, order, treatmentType } = data;

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Tipo</h4>
        </Col>
        <Col span={16}>{treatmentType?.appointmentName || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Orden</h4>
        </Col>
        <Col span={16}>{order}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Color</h4>
        </Col>
        <Col span={16}>
          <div
            style={{
              width: '28px',
              height: '28px',
              borderRadius: '15px',
              backgroundColor: '#' + color?.color,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
