export const hexToRGB = (hex) => {
  if (hex.length !== 6) {
    return [0, 0, 0];
  }

  var aRgbHex = hex.match(/.{1,2}/g);
  var aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ];
  return aRgb.join(', ');
};
