import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { createLock, getCabains } from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  dateFormat,
  dateFormatMx,
  formConfig,
  messages,
  placeHolders,
  timeFormat,
  timeFormatComplete,
} from '../../../../constants';
import {
  hoursConst,
  lockConst,
  lockReasonsConst,
  lockTypeConst,
  daysConst,
} from '../../../../constants/Lock.const';
import { CustomSelect } from '../../../uiComponents';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { arraySort } from '../../../../util/arraySort';

export const LockModalBody = ({ setOpenLocks }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { cabains } = useSelector((state) => state.cabain);
  const { appointmentData } = useSelector((state) => state.generalStates);
  const [response, setResponse] = useState();
  const [reason, setReason] = useState();
  const [lockType, setLockType] = useState();
  const [range, setRange] = useState();
  const [branchId, setBranchId] = useState();
  const [recurrent, setRecurrent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (branchId) dispatch(getCabains({ branchId, withDeleted: false }));
  }, [dispatch, branchId]);

  useEffect(() => {
    if ([200, 201].includes(response)) setOpenLocks(false);
  }, [response, dispatch, setOpenLocks]);

  useEffect(() => {
    setBranchId(appointmentData.branchId);
  }, [lockType, appointmentData]);

  const onRange = (dates) => {
    const formatedDates = dates.map((day) => day.format(dateFormat));
    setRange(formatedDates);
  };

  const onLockTypeChange = (type) => {
    setLockType(type);
    form.setFieldsValue({ time: [] });
  };

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    formValues.branchId = branchId ? branchId : null;
    formValues.time = range && range;
    formValues.date =
      lockType !== lockConst.daysRange ? appointmentData.date : null;

    if (lockType === lockConst.hoursRange) {
      formValues.time = range.map((hour) =>
        moment(hour, [timeFormatComplete]).format(timeFormatComplete)
      );
    }

    setResponse(
      await dispatch(
        createLock({
          ...formValues,
        })
      )
    );
    setIsLoading(false);
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} {...formConfig}>
        <Form.Item
          name="reason"
          label="Motivo"
          rules={[
            {
              required: true,
              message: messages.required('Motivo'),
            },
          ]}
        >
          <Select
            placeholder={placeHolders.reason}
            allowClear
            onChange={setReason}
            options={lockReasonsConst}
          ></Select>
        </Form.Item>

        {reason === lockConst.other && (
          <Form.Item
            name="reasonDescription"
            label="Descripción"
            rules={[
              {
                required: true,
                message: messages.required('Descripción'),
              },
            ]}
          >
            <TextArea />
          </Form.Item>
        )}

        <Form.Item
          name="type"
          label="Tipo de bloqueo"
          rules={[
            {
              required: true,
              message: messages.required('Tipo de bloqueo'),
            },
          ]}
        >
          <Select
            placeholder={placeHolders.lockType}
            allowClear
            onChange={onLockTypeChange}
            options={lockTypeConst}
          ></Select>
        </Form.Item>

        {lockType === lockConst.hour && (
          <Form.Item
            name="time"
            label="Tiempo"
            rules={[
              {
                required: true,
                message: messages.required('Tiempo'),
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={placeHolders.lockType}
              allowClear
              onChange={setRange}
              options={hoursConst}
            ></Select>
          </Form.Item>
        )}

        {lockType === lockConst.hoursRange && (
          <Form.Item
            name="time"
            label="Tiempo"
            rules={[
              {
                required: true,
                message: messages.required('Tiempo'),
              },
            ]}
          >
            <TimePicker.RangePicker
              format={timeFormat}
              minuteStep={30}
              onChange={(_, timeString) => setRange(timeString)}
            />
          </Form.Item>
        )}

        {lockType === lockConst.daysRange && (
          <Form.Item
            name="time"
            label="Tiempo"
            rules={[
              {
                required: true,
                message: messages.required('Tiempo'),
              },
            ]}
          >
            <DatePicker.RangePicker
              format={dateFormatMx}
              onChange={(date) => onRange(date)}
            />
          </Form.Item>
        )}

        {lockType !== lockConst.day && (
          <Form.Item
            name="cabainId"
            label="Cabina"
            rules={[
              {
                required: true,
                message: messages.required('Cabina'),
              },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.cabain}
              allowClear
              options={arraySort(cabains, 'name')?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            ></CustomSelect>
          </Form.Item>
        )}

        {[lockConst.hour, lockConst.cabain].includes(lockType) && (
          <Form.Item {...formConfig} name="recurrent" label="Recurrente">
            <Switch
              checked={recurrent}
              onChange={setRecurrent}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            ></Switch>
          </Form.Item>
        )}

        {[lockConst.hoursRange].includes(lockType) && (
          <Form.Item
            label="Días"
            name="days"
            rules={[
              {
                required: true,
                message: messages.required('Días'),
              },
            ]}
          >
            <CustomSelect
              mode="multiple"
              placeholder={placeHolders.days}
              allowClear
              options={daysConst}
            ></CustomSelect>
          </Form.Item>
        )}

        <Button
          type="primary"
          htmlType="submit"
          className="btn-form"
          loading={isLoading}
        >
          {buttonLabel.lock}
        </Button>
      </Form>
    </Card>
  );
};
