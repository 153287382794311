import { Col, Row } from 'antd';
import { stringDate } from '../../../util/formatTime';

export const OrderProducts = ({ orderProducts, orderType, span = 8 }) => {
  const quantity = ['purchase', 'sale', 'treatment'];
  const orderedQuantity = ['assortment'];
  const stockedQuantity = ['assortment', 'transfer'];
  const receivedQuantity = ['assortment', 'transfer'];
  const price = ['sale', 'purchase'];
  const allotment = ['purchase'];
  const expires = ['purchase'];

  const listProducts = () => {
    const products = [];
    orderProducts?.forEach((order) => {
      products.push(
        <Row key={order.id} className="row order-products">
          <Col span={span}>{order?.product?.name}</Col>

          {order?.quantity && <Col span={span}>{order?.quantity}</Col>}

          {order?.orderedQuantity && (
            <Col span={span}>{order?.orderedQuantity}</Col>
          )}

          {order?.stockedQuantity && (
            <Col span={span}>{order?.stockedQuantity}</Col>
          )}

          {order?.receivedQuantity && (
            <Col span={span}>{order?.receivedQuantity}</Col>
          )}

          {order?.allotment && <Col span={span}>{order?.allotment}</Col>}

          {order?.price && <Col span={span}>${order?.price}</Col>}

          {order?.expiresAt && (
            <Col span={span === 4 ? 8 : span}>
              {stringDate(order?.expiresAt)}
            </Col>
          )}
        </Row>
      );
    });

    return products;
  };

  return (
    <>
      <div className="products-details">
        <h2 className="title">Productos</h2>
        <Row className="order-products-header">
          <Col span={span}>Producto</Col>
          {quantity.includes(orderType) && <Col span={span}>Cantidad</Col>}
          {orderedQuantity.includes(orderType) && (
            <Col span={span}>Cantidad ordenada</Col>
          )}
          {stockedQuantity.includes(orderType) && (
            <Col span={span}>Cantidad enviada</Col>
          )}
          {receivedQuantity.includes(orderType) && (
            <Col span={span}>Cantidad recibida</Col>
          )}
          {allotment.includes(orderType) && <Col span={span}>Lote</Col>}
          {price.includes(orderType) && <Col span={span}>Precio</Col>}
          {expires.includes(orderType) && (
            <Col span={span === 4 ? 8 : span}>Expiración</Col>
          )}
        </Row>
        {listProducts()}
      </div>
    </>
  );
};
