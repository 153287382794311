import {
  SET_APPOINTMENT_DATA,
  SET_FILTERS_DATA,
  CLEAR_FILTERS_DATA,
} from '../types';

const initialState = {
  appointmentData: {},
  filtersData: {},
  dataDeleted: null,
};

export default function GeneralStates(state = initialState, action) {
  switch (action.type) {
    case SET_APPOINTMENT_DATA:
      return {
        ...state,
        appointmentData: { ...state.appointmentData, ...action.payload },
      };
    case SET_FILTERS_DATA:
      return {
        ...state,
        filtersData: { ...state.filtersData, ...action.payload },
      };
    case CLEAR_FILTERS_DATA:
      return {
        ...state,
        filtersData: {},
      };
    default:
      return state;
  }
}
