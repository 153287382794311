import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SalesTable } from '../../components/Sales/SalesTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const BranchSales = () => {
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <Button
        type="ghost"
        className="btn-primary-outline"
        onClick={() => {
          navigate('/dashboard/ventas-sucursal-producto');
        }}
      >
        Ventas de Producto
      </Button>
      <SalesTable saleType={'POS'} />
    </Card>
  );
};
  