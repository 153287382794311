import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, DatePicker, InputNumber } from 'antd';
import {
  getSubsidiaries,
  getAllDepartments,
  getAllJobPositions,
} from '../../../../../appRedux/actions';
import {
  placeHolders,
  messages,
  lengthValidation,
  dateFormat,
  masks,
  disableDaysAfterToday,
  WorkShift,
} from '../../../../../constants';
import { NumericInput, CustomSelect } from '../../../../uiComponents';
import { MaskedInput } from 'antd-mask-input';

export const InitStepForm = ({ form, editMode }) => {
  const dispatch = useDispatch();
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { departments } = useSelector((state) => state.departments);
  const { jobPositions } = useSelector((state) => state.jobPositions);
  const [isMedic, setIsMedic] = useState(false);

  useEffect(() => {
    dispatch(getSubsidiaries({ status: '1' }));
    dispatch(getAllDepartments());
    dispatch(getAllJobPositions());
  }, [dispatch]);

  const handleSelectJobPosition = (value) => {
    const jobPosition = jobPositions.find((pos) => pos.id === value);
    setIsMedic(jobPosition.name.startsWith('Médico'));
    const departmentId = jobPosition?.department?.id;
    form.setFieldsValue({
      departmentId: departmentId,
    });
  };

  return (
    <>
      <Form.Item
        label="Número de empleado"
        name="employeeNumber"
        rules={[
          { required: true, message: messages.required('Número de Empleado') },
          { max: 6, message: lengthValidation('Número de Empleado', 6) },
        ]}
      >
        <NumericInput />
      </Form.Item>
      <Form.Item
        label="Sucursal"
        name="branchId"
        rules={[{ required: true, message: messages.required('Sucursal') }]}
      >
        <CustomSelect
          placeholder={placeHolders.branch}
          options={subsidiaries?.map((subsidiary) => ({
            label: subsidiary.name,
            value: subsidiary.id,
          }))}
        ></CustomSelect>
      </Form.Item>
      <Form.Item
        label="Turno"
        name="workShift"
        rules={[{ required: true, message: messages.required('Turno') }]}
      >
        <CustomSelect
          placeholder={placeHolders.workShift}
          options={WorkShift}
        ></CustomSelect>
      </Form.Item>
      <Form.Item
        label="Puesto"
        name="jobPositionId"
        rules={[{ required: true, message: messages.required('Puesto') }]}
      >
        <CustomSelect
          placeholder={placeHolders.jobPosition}
          onChange={handleSelectJobPosition}
          options={jobPositions?.map((jobPosition) => ({
            label: jobPosition.name,
            value: jobPosition.id,
          }))}
        ></CustomSelect>
      </Form.Item>
      <Form.Item
        label="Departamento"
        name="departmentId"
        rules={[{ required: true, message: messages.required('Departamento') }]}
      >
        <CustomSelect
          disabled
          placeholder={placeHolders.department}
          options={departments?.map((department) => ({
            label: department.name,
            value: department.id,
          }))}
        ></CustomSelect>
      </Form.Item>
      <Form.Item
        name="birthdate"
        label="Fecha de nacimiento"
        rules={[
          { required: true, message: messages.required('Fecha de nacimiento') },
        ]}
      >
        <DatePicker
          className="gx-w-100"
          placeholder={placeHolders.date}
          format={dateFormat}
          disabledDate={disableDaysAfterToday}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Celular"
        rules={[{ required: true, message: messages.required('Celular') }]}
      >
        <MaskedInput mask={masks.phone} />
      </Form.Item>
      <Form.Item
        label="Salario Fiscal"
        name="salary"
        rules={[
          { required: true, message: messages.required('Salario Fiscal') },
        ]}
      >
        <InputNumber
          formatter={(value) =>
            `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
          min={1}
        />
      </Form.Item>
      <Form.Item
        name="bankAccount"
        label="Cuenta bancaria"
        rules={[
          { required: true, message: messages.required('Cuenta bancaria') },
          { max: 18, message: lengthValidation('Cuenta bancaria', 18) },
        ]}
      >
        <NumericInput />
      </Form.Item>
      <Form.Item
        name="isr"
        label="ISR"
        rules={[{ required: isMedic, message: messages.required('ISR') }]}
      >
        <MaskedInput mask={masks.numPercent} />
      </Form.Item>
      <Form.Item
        name="admissionDate"
        label="Fecha de ingreso"
        rules={[
          { required: true, message: messages.required('Fecha de ingreso') },
        ]}
      >
        <DatePicker
          className="gx-w-100"
          placeholder={placeHolders.date}
          format={dateFormat}
          disabledDate={disableDaysAfterToday}
        />
      </Form.Item>
      {editMode && (
        <>
          <Form.Item name="departureDate" label="Fecha de egreso">
            <DatePicker
              className="gx-w-100"
              placeholder={placeHolders.date}
              format={dateFormat}
              disabledDate={disableDaysAfterToday}
            />
          </Form.Item>
          <Form.Item name="reentryDate" label="Fecha de reingreso">
            <DatePicker
              className="gx-w-100"
              placeholder={placeHolders.date}
              format={dateFormat}
              disabledDate={disableDaysAfterToday}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};
