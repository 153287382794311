import {
  CREATE_TREATMENT,
  GET_TREATMENTS,
  GET_TREATMENT_BY_ID,
  UPDATE_TREATMENT,
  GET_TREATMENT_DELETE,
  DELETE_TREATMENT,
} from '../types';

const initialState = {
  treatments: [],
  treatmentById: {},
  treatmentDelete: null,
};

export default function Treatment(state = initialState, action) {
  switch (action.type) {
    case CREATE_TREATMENT:
      return {
        ...state,
        treatments: [...state.treatments, action.payload],
      };
    case GET_TREATMENTS:
      return {
        ...state,
        treatments: action.payload,
      };
    case GET_TREATMENT_BY_ID:
      return {
        ...state,
        treatmentById: action.payload,
      };
    case UPDATE_TREATMENT:
      return {
        ...state,
        treatments: state.treatments.map((treatment) =>
          treatment.id === action.payload.id
            ? (treatment = action.payload)
            : treatment
        ),
      };
    case GET_TREATMENT_DELETE:
      return {
        ...state,
        treatmentDelete: action.payload,
      };
    case DELETE_TREATMENT:
      return {
        ...state,
        treatments: state.treatments.filter(
          (treatment) => treatment.id !== state.treatmentDelete
        ),
      };
    default:
      return state;
  }
}
