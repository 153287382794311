import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PackageTable } from '../../components/Package/PackageTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Promotions = () => {
  const {
    privileges: { promotions: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      {privileges.includes('create') && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            navigate('/dashboard/promociones/crear');
          }}
        >
          <PlusOutlined />
          Nuevo
        </Button>
      )}
      <PackageTable title="Promociones" privileges={privileges} />
    </Card>
  );
};
