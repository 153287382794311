import { Card } from 'antd';
import { ProductTypeTable } from '../../components/ProductTypes/ProductTypeTable';
import { BackButton } from '../../uiComponents/BackButton';

export const ProductType = () => {
  return (
    <Card
      title={
        <BackButton path="/dashboard/productos" label="TIPOS DE PRODUCTO" />
      }
      bordered={true}
      className="gx-card"
    >
      <ProductTypeTable />
    </Card>
  );
};
