import { useState } from 'react';

import { customerConst } from '../../../../constants/Customer.const';
import { phoneMask } from '../../../../util';
import moment from 'moment';
import { dateFormat } from '../../../../constants';
import { useSelector } from 'react-redux';
import { getSingleCustomers } from '../../../../appRedux/actions';
import {
  searchFilters,
  filters,
  searchInputInitialValues,
} from '../../../../constants/MembersConst';
import { ListTable, TableFilters } from '../../../uiComponents';

export const WeMissYouTable = () => {
  const {
    privileges: { miss_you: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const [dates, setDates] = useState({});

  useState(() => {
    const startDate = moment().subtract(30, 'days').format(dateFormat);
    const endDate = moment().format(dateFormat);
    setDates({ startDate, endDate });
  });

  const columns = [
    {
      title: 'ID de cliente',
      sorter: true,
      dataIndex: 'customerId',
      key: 'customerId',
      render: (id, customer) => id,
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => name?.toUpperCase(),
    },
    {
      title: 'Apellido Paterno',
      sorter: true,
      dataIndex: 'lastName',
      key: 'lastName',
      render: (lastName) => lastName?.toUpperCase(),
    },
    {
      title: 'Apellido Materno',
      sorter: true,
      dataIndex: 'motherLastName',
      key: 'motherLastName',
      render: (motherLastName) =>
        motherLastName?.toUpperCase() ? motherLastName?.toUpperCase() : '-',
    },
    {
      title: 'Género',
      sorter: true,
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Correo',
      sorter: true,
      dataIndex: 'email',
      key: 'email',
      render: (email) => (email ? email : '-'),
    },
    {
      title: 'Celular',
      sorter: true,
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (mobilePhone) => phoneMask(mobilePhone),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        params={{ dates }}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        blackListParam={true}
      />
      <ListTable
        columns={columns}
        name={customerConst.weMissYou}
        getDataFunction={getSingleCustomers}
        size="large"
      />
    </>
  );
};
