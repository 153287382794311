import { useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import {
  messages,
  placeHolders,
  productStockColumnsFormConfig,
  stockColumnFormConfig,
  twoColumnsFormConfig,
} from '../../../../constants/Forms';
import { CustomSelect, NumericInput } from '../../../uiComponents';

export const TransferProducts = ({
  privileges = {},
  form = [],
  isCreateMode = false,
  isApproveMode = false,
  isConfirmMode = false,
  stockByBranch = [],
  deleted,
}) => {
  const formProducts = form.getFieldValue('transferProducts');
  const [selectedItems, setSelectedItems] = useState(
    formProducts?.map((item) => item?.stockId)
  );
  const filteredOptions = form.getFieldValue('originBranchId')
    ? stockByBranch.filter(
        (product) =>
          !selectedItems?.map((item) => item?.value).includes(product.stockId)
      )
    : [];

  const onProductChange = (product = null, productData) => {
    setSelectedItems(
      form.getFieldValue('transferProducts')?.map((item) => item?.productS)
    );

    const formItems = form.getFieldValue('transferProducts');
    const selectedProducts = formItems?.map((item) => item?.productS);
    const index = selectedProducts.findIndex(
      (item) => item?.key === product?.key
    );

    if (formItems[index] && index >= 0) {
      formItems[index].stock = productData?.stock ?? 0;
      formItems[index].productId = productData?.productId ?? 0;
      form.setFieldsValue({
        products: formItems,
      });
    }
  };

  return (
    <>
      <Form.List disabled name="transferProducts">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <>
                <hr className="divider" />
                <Row wrap>
                  <Col span={20}>
                    {filteredOptions && (
                      <Form.Item
                        {...productStockColumnsFormConfig}
                        label="Producto"
                        name={[field.name, 'productS']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Producto'),
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder={placeHolders.product}
                          onChange={onProductChange}
                          labelInValue={true}
                          disabled={!isCreateMode || deleted}
                          options={filteredOptions.map((product) => ({
                            value: product?.stockId,
                            label: `SKU: ${product?.sku} - Lote: ${product?.allotment} - ${product?.name}`,
                            productId: product?.productId,
                            stock: product?.stock,
                          }))}
                        ></CustomSelect>
                      </Form.Item>
                    )}
                  </Col>
                  {isCreateMode && privileges.includes('approve') && (
                    <Col span={fields.length > 1 ? 3 : 4}>
                      <Form.Item
                        {...stockColumnFormConfig}
                        label="Stock"
                        initialValue={0}
                        name={[field.name, 'stock']}
                      >
                        <NumericInput disabled={true} min={1} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={1}>
                    {fields.length > 1 && !isConfirmMode && (
                      <MinusCircleOutlined
                        className="danger-button-product"
                        onClick={() => {
                          remove(field.name);
                          onProductChange();
                        }}
                      />
                    )}
                  </Col>
                </Row>

                <Row wrap>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Cantidad a enviar"
                      name={[field.name, 'stockedQuantity']}
                      dependencies={[field.name, 'stock']}
                      rules={[
                        {
                          required: isApproveMode ? true : false,
                          message: messages.required('Cantidad enviada'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!isCreateMode) return Promise.resolve();

                            if (
                              value <=
                              getFieldValue('transferProducts')[field.name]
                                ?.stock
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('Inventario insuficiente')
                            );
                          },
                        }),
                      ]}
                    >
                      <NumericInput min={0} disabled={!isCreateMode} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Cantidad recibida"
                      name={[field.name, 'receivedQuantity']}
                      rules={[
                        {
                          required: isConfirmMode ? true : false,
                          message: messages.required('Cantidad recibida'),
                        },
                      ]}
                    >
                      <NumericInput
                        min={0}
                        disabled={!isConfirmMode || deleted}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ))}

            {!isConfirmMode && (
              <Button
                className="add-btn"
                disabled={!isCreateMode}
                onClick={() => {
                  add();
                  onProductChange();
                }}
                icon={<PlusOutlined />}
              >
                Agregar producto
              </Button>
            )}
          </div>
        )}
      </Form.List>
    </>
  );
};
