import { Col, Row } from 'antd';
import { phoneMask } from '../../../../util/phoneMask';

export const ProviderModal = ({ data = {} }) => {
  const {
    name,
    tradename,
    rfc,
    regime,
    address,
    city,
    state,
    country,
    zipCode,
    email,
    phone,
    contactName,
    mobilePhone,
    payConditions,
    clabeAccount,
    bankAccount,
    deliveryTime,
    bank,
  } = data;

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{name || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Razón Social</h4>
        </Col>
        <Col span={16}>{tradename || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>RFC</h4>
        </Col>
        <Col span={16}>{rfc || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Régimen</h4>
        </Col>
        <Col span={16}>{regime ? `Pesona ${regime}` : '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Dirección</h4>
        </Col>
        <Col span={16}>
          {`${address ? address : '-'} ${city ? city : ''} ${
            state ? state + ', ' : ''
          } ${country ? country : ''} ${zipCode ? 'C.P' + zipCode : ' '}`}
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Correo electrónico</h4>
        </Col>
        <Col span={16}>{`${email ? email : '-'}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{`${phone ? phoneMask(phone) : '-'}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Contacto</h4>
        </Col>
        <Col span={16}>{`${contactName}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Celular</h4>
        </Col>
        <Col span={16}>{`${phoneMask(mobilePhone)}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Condiciones de pago</h4>
        </Col>
        <Col span={16}>{`${payConditions} días`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Tiempo de entrega</h4>
        </Col>
        <Col span={16}>{`${deliveryTime ? deliveryTime : '-'}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Banco</h4>
        </Col>
        <Col span={16}>{`${bank ? bank : '-'}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>CLABE</h4>
        </Col>
        <Col span={16}>{`${clabeAccount ? clabeAccount : '-'}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Cuenta bancaria</h4>
        </Col>
        <Col span={16}>{`${bankAccount ? bankAccount : '-'}`}</Col>
      </Row>
    </>
  );
};
