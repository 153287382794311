import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AssortmentOrderTable } from '../../components/AssortmentOrder/AssortmentOrderTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const AssortmentOrders = () => {
  const navigate = useNavigate();
  const {
    privileges: { assortmentOrder = [] },
    role = null,
  } = useSelector((state) => state.userAuth);

  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      {assortmentOrder.includes('create') && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            navigate('/dashboard/ordenes-de-surtido/crear');
          }}
        >
          <PlusOutlined />
          Nuevo
        </Button>
      )}

      <AssortmentOrderTable privileges={assortmentOrder} role={role} />
    </Card>
  );
};
