import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
  key: 'main-root',
  storage,
  whitelist: ['userAuth', 'user', 'agenda', 'generalStates'],
};

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const persistedReducer = persistReducer(persistConfig, createRootReducer);

const store = createStore(persistedReducer, bindMiddleware([thunk]));

const Persistor = persistStore(store);

export { Persistor };
// creando el STORE a travez de redux, pasando como middleware thunk(retrasa el envío de una acción hasta que se cumpla una línea de código asíncrona)
export default store;
