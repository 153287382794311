import { Input, Form, Typography, Space } from 'antd';
import {
  messages,
  lengthValidation,
  masks,
} from '../../../../../constants/Forms';
import { TwoColumnsFormItem } from '../../../../uiComponents/TwoColumnsFormItem';
import { MaskedInput } from 'antd-mask-input';

export const JobReferencesStepForm = () => {
  return (
    <>
      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'center' }}
      >
        <Typography.Title level={5}>Principal</Typography.Title>
      </Space>
      <Form.Item
        label="Empresa"
        name="mainJobReferenceName"
        rules={[
          {
            required: true,
            message: messages.required('Empresa'),
          },
          {
            max: 80,
            message: lengthValidation('Empresa', 80),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Puesto desempeñado"
        name="mainJobReferencePosition"
        rules={[
          {
            required: true,
            message: messages.required('Puesto desempeñado'),
          },
          {
            max: 80,
            message: lengthValidation('Puesto desempeñado', 80),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <TwoColumnsFormItem>
        <Form.Item
          label="Jefe Directo"
          name="mainJobReferenceBoss"
          rules={[
            {
              required: true,
              message: messages.required('Jefe Directo'),
            },
            {
              max: 80,
              message: lengthValidation('Jefe Directo', 80),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mainJobReferencePhone"
          label="Teléfono de contacto"
          rules={[
            {
              required: true,
              message: messages.required('Teléfono de contacto'),
            },
          ]}
        >
          <MaskedInput mask={masks.phone} />
        </Form.Item>
      </TwoColumnsFormItem>

      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'center' }}
      >
        <Typography.Title level={5}>Secundaria</Typography.Title>
      </Space>
      <Form.Item
        label="Empresa"
        name="secondaryJobReferenceName"
        rules={[
          {
            max: 80,
            message: lengthValidation('Empresa', 80),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Puesto desempeñado"
        name="secondaryJobReferencePosition"
        rules={[
          {
            max: 80,
            message: lengthValidation('Puesto desempeñado', 80),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <TwoColumnsFormItem>
        <Form.Item
          label="Jefe Directo"
          name="secondaryJobReferenceBoss"
          rules={[
            {
              max: 80,
              message: lengthValidation('Jefe Directo', 80),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="secondaryJobReferencePhone"
          label="Teléfono de contacto"
        >
          <MaskedInput mask={masks.phone} />
        </Form.Item>
      </TwoColumnsFormItem>
    </>
  );
};
