import { Card } from 'antd';
import { StockTable } from '../../components/Stock/StockTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Stock = () => {
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <StockTable />
    </Card>
  );
};
