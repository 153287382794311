import { Tag } from 'antd';
import { useCallback, useEffect, useState } from 'react';

export const RenderStatus = ({ data }) => {
  const {
    status,
    activeColor = 'green',
    activeText = 'Activo',
    inactiveColor = 'red',
    inactiveText = 'Inactivo',
  } = data;

  const [color, setColor] = useState('green');
  const [text, setText] = useState('Activo');

  const tagColor = useCallback(() => {
    const color = status ? activeColor : inactiveColor;
    setColor(color);
  }, [activeColor, inactiveColor, status]);

  const tagText = useCallback(() => {
    const text = status ? activeText : inactiveText;
    setText(text);
  }, [status, activeText, inactiveText]);

  useEffect(() => {
    tagColor();
    tagText();
  }, [tagColor, tagText, status]);

  return (
    <>
      <Tag color={color}>{text}</Tag>
    </>
  );
};
