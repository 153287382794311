import { Col, Row, Table } from 'antd';
import { stringDate } from '../../../../util/formatTime';

export const AssormentOrderModalBody = ({ data = {} }) => {
  const { assortmentOrderProduct } = data;
  const columns = [
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product?.name,
    },
    {
      title: 'SKU',
      dataIndex: 'product',
      key: 'sku',
      render: (product) => product?.sku,
    },
    {
      title: 'Lote',
      dataIndex: 'productStock',
      key: 'productStock',
      render: (productStock) => productStock?.allotment,
    },
    {
      title: 'Cantidad ordenada',
      dataIndex: 'orderedQuantity',
      key: 'orderedQuantity',
    },
    {
      title: 'Cantidad enviada',
      dataIndex: 'stockedQuantity',
      key: 'stockedQuantity',
    },
    {
      title: 'Cantidad recibida',
      dataIndex: 'receivedQuantity',
      key: 'receivedQuantity',
    },
  ];

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={16}>{data?.branch?.name || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Tipo de orden</h4>
        </Col>
        <Col span={16}>{data?.orderType || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Ordenado por</h4>
        </Col>
        <Col span={16}>
          {`${data?.orderBy?.name || '-'} ${data?.orderBy?.lastName || ''}  ${
            data?.orderBy?.motherLastName || ''
          }`}
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Fecha de creación</h4>
        </Col>
        <Col span={16}>{stringDate(data?.created_at) || '-'}</Col>
      </Row>
      {!data.rejectedAt && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Aprobado por</h4>
            </Col>
            <Col span={16}>
              {`${data?.approvedBy?.name || '-'} ${
                data?.approvedBy?.lastName || ''
              }  ${data?.approvedBy?.motherLastName || ''}`}
            </Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de aprobación</h4>
            </Col>
            <Col span={16}>{stringDate(data?.approvedAt) || '-'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de envió</h4>
            </Col>
            <Col span={16}>{stringDate(data?.sentAt) || '-'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de finalización</h4>
            </Col>
            <Col span={16}>{stringDate(data?.finishedAt) || '-'}</Col>
          </Row>
        </>
      )}

      {data.rejectedAt && (
        <Row className="row">
          <Col span={8}>
            <h4>Fecha de rechazo</h4>
          </Col>
          <Col span={16}>{stringDate(data?.rejectedAt) || '-'}</Col>
        </Row>
      )}

      <br />

      <Table
        dataSource={assortmentOrderProduct}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 40, 60, 80],
        }}
      />
    </>
  );
};
