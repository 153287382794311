import {
  GET_ALL_DAILY_CASH_CLOSING_CASH,
  GET_ALL_DAILY_CASH_CLOSING_CASH_BY_BRANCH,
} from '../types';

const initialState = {
  dailyCashClosingCash: [],
  dailyCashClosingCashByBranch: [],
};

export default function DailyCashClosingCash(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DAILY_CASH_CLOSING_CASH:
      return {
        ...state,
        dailyCashClosingCash: action.payload,
      };
    case GET_ALL_DAILY_CASH_CLOSING_CASH_BY_BRANCH:
      return {
        ...state,
        dailyCashClosingCashByBranch: action.payload,
      };
    default:
      return state;
  }
}
