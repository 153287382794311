import {
  CREATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_DELETE,
  UPDATE_PURCHASE_ORDER,
} from '../types';

const initialState = {
  purchaseOrders: [],
  purchaseOrderById: {},
  purchaseOrderDelete: null,
};

export default function PurchaseOrder(state = initialState, action) {
  switch (action.type) {
    case CREATE_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: [...state.purchaseOrders, action.payload],
      };
    case GET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.payload,
      };
    case GET_PURCHASE_ORDER_BY_ID:
      return {
        ...state,
        purchaseOrderById: action.payload,
      };
    case UPDATE_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.map((order) =>
          order.id === action.payload.id ? (order = action.payload) : order
        ),
      };
    case GET_PURCHASE_ORDER_DELETE:
      return {
        ...state,
        purchaseOrderDelete: action.payload,
      };

    case DELETE_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.filter(
          (order) => order.id !== state.purchaseOrderDelete
        ),
      };

    default:
      return state;
  }
}
