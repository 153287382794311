import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ProductTable } from '../../components/Products/ProductTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Products = () => {
  const {
    privileges: { products = [] },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      {products.includes('create') && (
        <>
          <Button
            type='primary'
            htmlType='submit'
            onClick={() => {
              navigate('/dashboard/productos/crear');
            }}
          >
            <PlusOutlined />
            Nuevo
          </Button>
          <Button
            type='ghost'
            className='btn-primary-outline'
            onClick={() => {
              navigate('/dashboard/tipos-producto');
            }}
          >
            Tipo de producto
          </Button>
        </>
      )}
      <ProductTable privileges={products} />
    </Card>
  );
};
