import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const LabelMainMenu = ({
  icon,
  label,
  route,
  externalRoute,
}) => {
  const { token } = useSelector((state) => state.userAuth);

  return route || externalRoute ? (
    externalRoute ? (
      <a
        className="link"
        href={`${externalRoute}?token=${token}`}
        target="_blank"
        rel="noreferrer"
      >
        {icon && <i className={`icon ${icon}`} />}
        <span>{label}</span>
      </a>
    ) : (
      <Link
        to={route ? `${route}` : '#'}
        className="link"
        target={externalRoute ? '_blank' : ''}
      >
        {icon && <i className={`icon ${icon}`} />}
        <span>{label}</span>
      </Link>
    )
  ) : (
    <>
      <span>
        {icon && <i className={`icon ${icon}`} />}
        <span>{label}</span>
      </span>
    </>
  );
};
