import { Input, Form, Typography, Space } from 'antd';
import {
  messages,
  lengthValidation,
  masks,
} from '../../../../../constants/Forms';
import { MaskedInput } from 'antd-mask-input';

export const ContactsStepForm = ({ form, editMode }) => {
  return (
    <>
      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'center' }}
      >
        <Typography.Title level={5}>Principal</Typography.Title>
      </Space>
      <Form.Item
        label="Nombre"
        name="mainContactName"
        rules={[
          {
            required: true,
            message: messages.required('Nombre'),
          },
          {
            max: 100,
            message: lengthValidation('Nombre', 100),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Parentesco"
        name="mainContactRelationship"
        rules={[
          {
            required: true,
            message: messages.required('Parentesco'),
          },
          {
            max: 50,
            message: lengthValidation('Parentesco', 50),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mainContactPhone"
        label="Teléfono"
        rules={[
          {
            required: true,
            message: messages.required('Teléfono'),
          },
        ]}
      >
        <MaskedInput mask={masks.phone} />
      </Form.Item>

      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'center' }}
      >
        <Typography.Title level={5}>Secundario</Typography.Title>
      </Space>
      <Form.Item
        label="Nombre"
        name="secondaryContactName"
        rules={[
          {
            max: 100,
            message: lengthValidation('Nombre', 100),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Parentesco"
        name="secondaryContactRelationship"
        rules={[
          {
            max: 50,
            message: lengthValidation('Parentesco', 50),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="secondaryContactPhone" label="Teléfono">
        <MaskedInput mask={masks.phone} />
      </Form.Item>
    </>
  );
};
