import { GET_CONFIGS } from '../types';

const initialState = {
  configs: {},
};

export default function Configs(state = initialState, action) {
  switch (action.type) {
    case GET_CONFIGS:
      return {
        ...state,
        configs: action.payload,
      };
    default:
      return state;
  }
}