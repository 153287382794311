import React from 'react';
import { Card } from 'antd';
import { CabainTypesForm } from '../../components/CabainTypes/CabainTypeForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const CabainType = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <CabainTypesForm />
    </Card>
  );
};
