import { Col, Row } from 'antd';
import { phoneMask } from '../../../../../util/phoneMask';

export const ContactData = ({ data }) => {
  const mainContact = data?.kardex?.contacts?.find( contact => contact.main === 1);
  const secondaryContact = data?.kardex?.contacts?.find( contact => contact.main === 0);
  return (
    <>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Principal</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{mainContact?.name || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Parentesco</h4>
        </Col>
        <Col span={16}>{mainContact?.relationships || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{phoneMask(mainContact?.phone)}</Col>
      </Row>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Secundario</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{secondaryContact?.name || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Parentesco</h4>
        </Col>
        <Col span={16}>{secondaryContact?.relationships || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{phoneMask(secondaryContact?.phone)}</Col>
      </Row>
    </>
  );
};
