import { Button, Tag } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllDiaries } from '../../../../appRedux/actions/Diary';
import {
  actionDictionary,
  changesDictionary,
  filters,
  searchFilters,
  searchInputInitialValues,
  statusDictionary,
} from '../../../../constants/Diary.const';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import {
  stringDate,
  stringDateTime,
  stringTime,
} from '../../../../util/formatTime';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { ChangesModalBody } from '../ModalDiary';
import { isArray } from 'lodash';
import { mainBranch } from '../../../../constants/Branch.const';

export const DiariesTable = () => {
  const {
    privileges: { appointment: privileges = [] },
  } = useSelector((state) => state.userAuth);

  const { branchId: loggedBranchId, branch: branchLogged } = useSelector(
    (state) => state.userAuth
  );

  const { diaries } = useSelector((state) => state.diary);
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();
  const { catalogId } = useCustomModal();
  const { filtersData } = useSelector((state) => state.generalStates);

  const getModalData = useCallback(() => {
    const [modalData] = diaries?.filter((diary) => diary.id === catalogId);
    return modalData;
  }, [diaries, catalogId]);

  useEffect(() => {
    setModalData(getModalData());
  }, [getModalData]);

  const onModalClick = (changes) => {
    modal();
    setModalData(changes);
  };

  const getDateTimeString = (dateTime) => {
    return `${stringDateTime(dateTime)}`;
  };

  const getWhoScheduled = (user, row) => {
    if (!user) {
      return (
        <>{`${row?.customer?.name} ${row?.customer?.lastName} ${
          row?.customer?.motherLastNameastName ?? ''
        }`}</>
      );
    }

    return (
      <>
        {user?.name ? user?.name : '-'} {user?.lastName} {user?.motherLastName}
      </>
    );
  };

  const columns = [
    {
      title: 'Cita',
      sorter: true,
      dataIndex: 'appointmentNumber',
      key: 'appointmentNumber',
      render: (order, { changes }) =>
        changes ? (
          <Button
            type="link"
            htmlType="button"
            href="#/"
            onClick={() => onModalClick(changes)}
          >
            {`${'C'}${order}`}
          </Button>
        ) : (
          <Button type="link" htmlType="button" style={{ color: '#000000' }}>
            {'C' + order}
          </Button>
        ),
    },
    {
      title: 'Acción',
      sorter: true,
      dataIndex: 'action',
      key: 'action',
      render: (action) => actionDictionary[action],
      path: 'action',
      formatter: (action) => actionDictionary[action],
    },
    {
      title: 'Fecha de cita',
      sorter: true,
      dataIndex: 'date',
      key: 'date',

      render: (date) => stringDate(date),
      path: 'date',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Hora de cita',
      sorter: true,
      dataIndex: 'time',
      key: 'time',
      render: (time) => stringTime(time),
    },
    {
      title: 'Cliente',
      sorter: true,
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => (
        <>
          {customer?.name} {customer?.lastName} {customer?.motherLastName}
        </>
      ),
      path: ['customer.name', 'customer.lastName', 'customer.motherLastName'],
    },
    {
      title: 'Tratamiento',
      sorter: true,
      dataIndex: 'treatment',
      key: 'treatment',
      render: (treatment) => treatment?.name || '-',
      path: 'treatment.name',
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => statusDictionary[record.status],
      path: 'status',
      formatter: (status) => statusDictionary[status],
    },
    {
      title: 'Comentarios',
      sorter: true,
      dataIndex: 'comments',
      key: 'comments',
      render: (comments) => comments,
    },
    {
      title: 'Descripción',
      sorter: true,
      dataIndex: 'changes',
      key: 'changes',
      render: (changes) =>
        (changes &&
          Object.keys(JSON.parse(changes))?.map((field) => (
            <Tag color="blue">{changesDictionary[field]}</Tag>
          ))) ||
        '-',
      path: 'changes',
      formatter: (changes) => {
        const result =
          (changes &&
            Object.keys(JSON.parse(changes))?.map(
              (field) => changesDictionary[field]
            )) ||
          '-';
        if (isArray(result)) {
          return result.join(' / ');
        }
        return result;
      },
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name || '-',
      path: 'branch.name',
    },
    {
      title: 'Agendo',
      sorter: true,
      dataIndex: 'user',
      key: 'user',
      render: (user, row) => getWhoScheduled(user, row),
      path: ['user.name', 'user.lastName', 'user.motherLastName'],
    },
    {
      title: 'Fecha de Creación',
      sorter: true,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (dateTime) => getDateTimeString(dateTime),
      path: 'created_at',
      formatter: (dateTime) => getDateTimeString(dateTime),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        loggedBranchId={loggedBranchId}
        initialParams={
          branchLogged !== mainBranch ? { branchId: loggedBranchId } : undefined
        }
      />
      <ModalView title={'Bitácora'} width={700}>
        <ChangesModalBody data={modalData} />
      </ModalView>
      <ListTable
        columns={columns}
        name={'Bitácora de Citas'}
        getDataFunction={getAllDiaries}
        params={filtersData}
      />
    </>
  );
};
