import { Descriptions } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigs } from '../../../../appRedux/actions';

export const SettingsTable = ({ privileges }) => {
  const dispatch = useDispatch();
  const { configs } = useSelector((state) => state.configs);

  const settingsTranslations = {
    exchangeRate: 'Tipo de Cambio',
  };

  const excludes = ['id', 'created_at'];

  useEffect(() => {
    dispatch(getConfigs());
  }, [dispatch]);

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label={'Configuración'} style={{ width: '50%' }}>
          Valor
        </Descriptions.Item>
      </Descriptions>
      {Object.entries(configs || {})?.map(([key, value]) =>
        !excludes.includes(key) ? (
          <>
            <Descriptions key={key} bordered>
              <Descriptions.Item
                label={settingsTranslations[key] || key}
                style={{ width: '30%' }}
                key={`${key}-1`}
              >
                {`$ ${value}` || '-'}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : null
      )}
    </>
  );
};
