import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { TransferTable } from '../../components/Transfer/TransferTable';

export const Transfers = () => {
  const navigate = useNavigate();
  const {
    role,
    branchId,
    privileges: { transfers = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      {transfers.includes('create') && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            navigate('/dashboard/transferencias/crear');
          }}
        >
          <PlusOutlined />
          Nuevo
        </Button>
      )}

      <TransferTable
        privileges={transfers}
        loggedBranchId={branchId}
        role={role}
      />
    </Card>
  );
};
