import React from 'react';
import { Card } from 'antd';
import { HistoryTable } from '../../components/History/HistoryTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const History = () => {
  const {
    privileges: { history = [] },
  } = useSelector((state) => state.userAuth);
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      <HistoryTable privileges={history}/>
    </Card>
  );
};