import { DollarOutlined, FileTextOutlined } from '@ant-design/icons';

export const searchInputInitialValues = {
  search: { filter: 'order_number' },
};

export const searchFilters = [
  { value: 'order_number', label: 'Venta' },
  { value: 'seller.name', label: 'Vendedor' },
  { value: 'customer.name', label: 'Cliente' },
  { value: 'payReference', label: 'Referencia' },
];

export const filters = {
  search: true,
  date: true,
  branches: true,
  dates: true,
  paymentMethod: true,
};

export const salesConst = {
  DETAIL: 'Detalle',
  REFUND: 'Devolución',
};

export const paymentMethod = [
  { value: 'Efectivo', label: 'Efectivo' },
  { value: 'Paypal', label: 'Paypal' },
  { value: 'Puntos', label: 'Puntos' },
  { value: 'Tarjeta de crédito/débito', label: 'Tarjeta de crédito/débito' },
];

export const refundMethod = [
  { value: 'Efectivo', label: 'Efectivo' },
  { value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' },
  { value: 'Tarjeta de débito', label: 'Tarjeta de débito' },
];

export const paymentStatusDictionary = {
  paid: 'Pagado',
  error: 'Error',
  pending_payment: 'Pago pendiente',
  pending_confirmation: 'Por confirmar',
};

export const originSaleDictionary = {
  app: 'APP',
  pos: 'SUCURSAL',
  online: 'LINEA',
};

export const salesTableMenuOpt = [
  {
    label: 'Recibo',
    key: 'invoice',
    icon: <FileTextOutlined />,
    privilege: 'view',
  },
  {
    label: 'Devolución',
    key: 'refund',
    icon: <DollarOutlined />,
    privilege: 'refund',
  },
];
