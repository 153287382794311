import { Tag } from "antd";

export const searchFilters = [
    { value: 'customer.name', label: 'Nombre' },
    { value: 'customer.last_name', label: 'Apellido paterno' },
    { value: 'customer.customer_id', label: 'Id de cliente' },
    { value: 'customer.phone', label: 'Teléfono' },
    { value: 'customer.mobilePhone', label: 'Celular' },
    { value: 'customer.email', label: 'Correo electrónico' },
  ];
  export const filters = {
    search: true,
    status: true,
    gender: true,
    maritalStatus: true,
    blackList: true,
  };
  
  export const searchInputInitialValues = {
    search: { filter: 'customer.name' },
    status: '1',
    blackList: '0',
  };
  
  export const genders = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Femenino', label: 'Femenino' },
    { value: 'Otro', label: 'Otro' },
  ];

  export const maritalStatus = [
    { value: 'Soltero', label: 'Soltero' },
    { value: 'Casado', label: 'Casado' },
    { value: 'Viudo', label: 'Viudo' },
    { value: 'Divorciado', label: 'Divorciado' },
    { value: 'Otro', label: 'Otro' },
  ];

  export const dictionaryScaleConst = {
    REGULAR: <Tag>BRONCE</Tag>,
    ORO: <Tag color="#F1E2A0">ORO</Tag>,
    PLATA: <Tag color="#ced4da">PLATA</Tag>,
    PLATINO: <Tag color="gray">PLATINO</Tag>,
    DIAMANTE: <Tag color="blue">DIAMANTE</Tag>,
  };