import { Col, Row } from 'antd';

export const UserData = ({ data }) => {
  return (
    <>
      <Row className='row'>
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data?.name || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Apellido paterno</h4>
        </Col>
        <Col span={16}>{data?.lastName || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Apellido materno</h4>
        </Col>
        <Col span={16}>{data?.motherLastName || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Correo electrónico / Usuario</h4>
        </Col>
        <Col span={16}>{data?.username || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Rol</h4>
        </Col>
        <Col span={16}>{data?.role?.role || '-'}</Col>
      </Row>
    </>
  );
};
