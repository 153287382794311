import { useCallback, useState } from 'react';
import { ReportsFilters } from './ReportsFilter';
import { ProfitabilityByBranchReport } from './ProfitabilityByBranchReport';

export const ReportsMain = () => {
  const [showReport, setShowReport] = useState();

  const getSelected = useCallback((value, search) => {
    if (search === true) {
      setShowReport(value);
    }
  }, []);

  return (
    <>
      <ReportsFilters getSelected={getSelected} />
      {showReport ? showReport : <ProfitabilityByBranchReport />}
    </>
  );
};
