import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PurchaseOrderTable } from '../../components/PurchaseOrder/PurchaseOrderTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const PurchaseOrders = () => {
  const {
    privileges: { purchaseOrder = [] },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      {purchaseOrder.includes('create') && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            navigate('/dashboard/ordenes-de-compra/crear');
          }}
        >
          <PlusOutlined />
          Nuevo
        </Button>
      )}

      <PurchaseOrderTable privileges={purchaseOrder} />
    </Card>
  );
};
