import {
  CREATE_ASSORTMENT_ORDER,
  DELETE_ASSORTMENT_ORDER,
  GET_ASSORTMENT_ORDERS,
  GET_ASSORTMENT_ORDER_BY_ID,
  UPDATE_ASSORTMENT_ORDER,
  APPROVE_ASSORTMENT_ORDER,
} from '../types';

const initialState = {
  assortmentOrders: [],
  assortmentOrderById: {},
  assortmentOrderDelete: null,
};

export default function AssortmentOrder(state = initialState, action) {
  switch (action.type) {
    case CREATE_ASSORTMENT_ORDER:
      return {
        ...state,
        assortmentOrders: [...state.assortmentOrders, action.payload],
      };
    case GET_ASSORTMENT_ORDERS:
      return {
        ...state,
        assortmentOrders: action.payload,
      };
    case GET_ASSORTMENT_ORDER_BY_ID:
      return {
        ...state,
        assortmentOrderById: action.payload,
      };
    case UPDATE_ASSORTMENT_ORDER:
      return {
        ...state,
        assortmentOrders: state.assortmentOrders.map((order) =>
          order.id === action.payload.id ? (order = action.payload) : order
        ),
      };
    case APPROVE_ASSORTMENT_ORDER:
      return {
        ...state,
        assortmentOrders: state.assortmentOrders.map((order) =>
          order.id === action.payload.id ? (order = action.payload) : order
        ),
      };
    case DELETE_ASSORTMENT_ORDER:
      return {
        ...state,
        assortmentOrders: state.assortmentOrders.map((order) =>
          order.id === action.payload.id ? (order = action.payload) : order
        ),
      };

    default:
      return state;
  }
}
