import {
  DeleteOutlined,
  DownSquareOutlined,
  EditOutlined,
  FormOutlined,
  ToolOutlined,
} from '@ant-design/icons';

export const tableMenuOpt = (options) => {
  return [
    {
      label: options ? options.edit.label : 'Editar',
      key: 'edit',
      icon: options ? options.edit.icon : <EditOutlined />,
      privilege: 'edit',
    },
    {
      label: 'Eliminar',
      key: 'delete',
      icon: <DeleteOutlined />,
      privilege: 'delete',
    },
    {
      label: 'Baja',
      key: 'drop',
      icon: <DownSquareOutlined />,
    },
    {
      label: 'Max/Min',
      key: 'max/min',
      icon: <ToolOutlined />,
    },
    {
      label: 'Nota',
      key: 'note',
      icon: <FormOutlined />,
      privilege: 'create',
    },
  ];
};
