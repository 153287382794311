import { GET_TREATMENT_TYPES, GET_TREATMENT_TYPE_BY_ID } from '../types';

const initialState = {
  treatmentTypes: [],
  treatmentTypeById: {},
};

export default function TreatmentType(state = initialState, action) {
  switch (action.type) {
    case GET_TREATMENT_TYPES:
      return {
        ...state,
        treatmentTypes: action.payload,
      };
    case GET_TREATMENT_TYPE_BY_ID:
      return {
        ...state,
        treatmentById: action.payload,
      };
    default:
      return state;
  }
}
