import { Input, Form, Radio } from 'antd';
import {
  placeHolders,
  messages,
  lengthValidation,
  minLengthValidation,
  MaritalStatus,
  Gender,
  EducationalDegree,
  BloodType,
  regex,
} from '../../../../../constants';
import {
  NumericInput,
  TwoColumnsFormItem,
  CustomSelect,
} from '../../../../uiComponents';
import TextArea from 'antd/lib/input/TextArea';

export const PersonalStepForm = ({ form, editMode }) => {
  if (!editMode) {
    form.setFieldsValue({
      nationality: 'Mexicana',
    });
  }

  return (
    <>
      <TwoColumnsFormItem>
        <Form.Item
          label="Género"
          name="gender"
          rules={[{ required: true, message: messages.required('Género') }]}
        >
          <CustomSelect
            placeholder={placeHolders.gender}
            options={Gender}
          ></CustomSelect>
        </Form.Item>
        <Form.Item
          label="Estado civil"
          name="maritalStatus"
          rules={[
            { required: true, message: messages.required('Estado Civil') },
          ]}
        >
          <CustomSelect
            placeholder={placeHolders.maritalStatus}
            options={MaritalStatus}
          ></CustomSelect>
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="Nacionalidad"
          name="nationality"
          rules={[
            { required: true, message: messages.required('Nacionalidad') },
            { max: 50, message: lengthValidation('Nacionalidad', 50) },
          ]}
          z
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Estado de nacimiento"
          name="birthState"
          rules={[
            {
              required: true,
              message: messages.required('Estado de nacimiento'),
            },
            {
              max: 50,
              message: lengthValidation('Estado de nacimiento', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="Ciudad de nacimiento"
          name="birthCity"
          rules={[
            {
              required: true,
              message: messages.required('Ciudad de nacimiento'),
            },
            {
              max: 50,
              message: lengthValidation('Ciudad de nacimiento', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="NSS"
          name="nss"
          rules={[
            { required: true, message: messages.required('NSS') },
            { min: 11, message: minLengthValidation('NSS', 11) },
            { max: 11, message: lengthValidation('NSS', 11) },
          ]}
        >
          <NumericInput />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="CURP"
          name="curp"
          rules={[
            { required: true, message: messages.required('CURP') },
            { pattern: regex.curp, message: messages.validation.curp },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="rfc"
          label="RFC"
          rules={[
            { required: true, message: messages.required('RFC') },
            { pattern: regex.rfc, message: messages.validation.rfc },
          ]}
        >
          <Input />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="OCR INE"
          name="ocr"
          rules={[
            { required: true, message: messages.required('OCR INE') },
            { min: 12, message: minLengthValidation('OCR INE', 12) },
            { max: 13, message: lengthValidation('OCR INE', 13) },
          ]}
        >
          <NumericInput />
        </Form.Item>
        <Form.Item
          label="INFONAVIT"
          name="infonavit"
          rules={[{ required: true, message: messages.required('INFONAVIT') }]}
        >
          <Radio.Group>
            <Radio value="1"> Si </Radio>
            <Radio value="0"> No </Radio>
          </Radio.Group>
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="Nivel educativo"
          name="educationalDegree"
          rules={[
            { required: true, message: messages.required('Nivel educativo') },
          ]}
        >
          <CustomSelect
            placeholder={placeHolders.educationalDegree}
            options={EducationalDegree}
          ></CustomSelect>
        </Form.Item>
        <Form.Item
          label="Profesión"
          name="profession"
          rules={[
            {
              required: true,
              message: messages.required('Profesión'),
            },
            {
              max: 50,
              message: lengthValidation('Profesión', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="Cédula profesional"
          name="professionalLicense"
          rules={[
            { min: 7, message: minLengthValidation('Cédula profesional', 7) },
          ]}
        >
          <NumericInput />
        </Form.Item>
        <Form.Item
          label="Cédula provisional"
          name="provisionalProfesionalLicense"
          rules={[
            { min: 7, message: minLengthValidation('Cédula provisional', 7) },
          ]}
        >
          <NumericInput />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="No. Hijos"
          name="childrens"
          rules={[
            {
              required: true,
              message: messages.required('Hijos'),
            },
          ]}
        >
          <NumericInput />
        </Form.Item>
        <Form.Item
          label="Correo personal"
          name="personalEmail"
          rules={[
            {
              required: true,
              message: messages.required('Correo personal'),
            },
            {
              type: 'email',
              message: messages.required('Correo personal'),
            },
            { max: 100, message: lengthValidation('Correo personal', 100) },
          ]}
        >
          <Input placeholder={placeHolders.email} />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="Tipo de sangre"
          name="bloodType"
          rules={[
            { required: true, message: messages.required('Tipo de sangre') },
          ]}
        >
          <CustomSelect
            placeholder={placeHolders.bloodType}
            options={BloodType}
          ></CustomSelect>
        </Form.Item>
        <Form.Item
          label="Enfermedades crónicas"
          name="diseases"
          rules={[
            {
              required: true,
              message: messages.required('Enfermedades crónicas'),
            },
          ]}
        >
          <TextArea placeholder={placeHolders.diseases} />
        </Form.Item>
      </TwoColumnsFormItem>

      <TwoColumnsFormItem>
        <Form.Item
          label="Alergias"
          name="allergies"
          rules={[
            {
              required: true,
              message: messages.required('Alergias'),
            },
          ]}
        >
          <TextArea placeholder={placeHolders.allergies} />
        </Form.Item>
        <Form.Item
          label="Alergias a medicamento"
          name="drugAllergies"
          rules={[
            {
              required: true,
              message: messages.required('Alergias a medicamento'),
            },
          ]}
        >
          <TextArea placeholder={placeHolders.drugAllergies} />
        </Form.Item>
      </TwoColumnsFormItem>
    </>
  );
};
