export const FilterConst = {
  DATE_RANGE_SECTIONS: [
    '/dashboard/historial',
    '/dashboard/bitacora',
    '/dashboard/comisiones-medicas',
    '/dashboard/comisiones-cosmetologicas',
    '/dashboard/ventas-en-sucursal',
    '/dashboard/ventas-sucursal-producto',
    '/dashboard/ventas-en-linea',
    '/dashboard/ventas-en-app',
    '/dashboard/corte-sucursal',
    '/dashboard/corte-de-productos',
    '/dashboard/citas',
    '/dashboard/metas',
  ],
};
