import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Button,
  Form,
  Input,
  Switch,
  Select,
  PageHeader,
  Divider,
} from 'antd';
import {
  createSubsidiary,
  updateSubsidiary,
  getSubsidiaryById,
  resetBranch,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  daysDictionary,
  formConfig,
  initialOpenState,
  masks,
  messages,
  regex,
  weekdaysConst,
} from '../../../../constants';
import { NumericInput, CancelButton } from '../../../uiComponents';
import './styles.css';
import { setTimeFormat } from '../../../../util/formatTime';
import { Schedules } from '../../../uiComponents/Schedules';
import { MaskedInput } from 'antd-mask-input';
import { unmaskPhone } from '../../../../util/MaskedValues';
import { phoneMask } from '../../../../util';

export const SubsidiaryForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();
  const [franchise, setFranchise] = useState(false);
  const [active, setActive] = useState(true);
  const { subsidiaryById } = useSelector((state) => state.subsidiary);
  const [open, setOpen] = useState(initialOpenState);
  const [timeString, setTimeString] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) dispatch(getSubsidiaryById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      dispatch(resetBranch());
      navigate('/dashboard/sucursales');
    }
  }, [navigate, response, dispatch]);

  useEffect(() => {
    if (id === subsidiaryById.id && subsidiaryById?.schedules) {
      const days = {};
      let schedules = [];
      let isOpen = {};

      for (const schedule of subsidiaryById?.schedules) {
        const [objectKey] = Object.keys(weekdaysConst[schedule?.day]);
        const opening = schedule?.opening;
        const closing = schedule?.closing;

        if (opening && closing) {
          days[objectKey] = ![opening, closing].includes('00:00:00')
            ? [setTimeFormat(opening), setTimeFormat(closing)]
            : [];

          schedules = { ...schedules, ...{ [objectKey]: [opening, closing] } };
          isOpen = {
            ...isOpen,
            ...{ [objectKey]: days[objectKey].length > 0 ? true : false },
          };
        }

        schedules.length < 1 ? setOpen(initialOpenState) : setOpen(isOpen);
      }

      setTimeString(schedules);

      form.setFieldsValue({
        ...subsidiaryById,
        ...days,
      });
    }
  }, [form, id, subsidiaryById]);

  useEffect(() => {
    if (id) {
      setFranchise(subsidiaryById.franchise);
      setActive(subsidiaryById.active);
    }
  }, [subsidiaryById, id]);

  const onFranchiseChange = (value) => {
    setFranchise(value);
  };

  const onActiveChange = (value) => {
    setActive(value);
  };

  const onOpenChange = (day) => {
    form.setFieldsValue({
      [day]: [],
    });

    setTimeString({
      ...timeString,
      ...{
        [day]: [],
      },
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    values.schedule = Object.keys(timeString).map((key) => {
      if (timeString[key].length < 1)
        return {
          day: daysDictionary[key],
          opening: '00:00',
          closing: '00:00',
        };

      return {
        day: daysDictionary[key],
        opening: timeString[key][0],
        closing: timeString[key][1],
      };
    });

    values.phone = unmaskPhone(values.phone);

    if (id) {
      setResponse(await dispatch(updateSubsidiary(id, values)));
    } else {
      setResponse(await dispatch(createSubsidiary(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              {
                required: true,
                message: messages.required('Nombre'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Calle"
            name="street"
            rules={[
              {
                required: true,
                message: messages.required('Calle'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Número exterior"
            name="extNumber"
            rules={[
              {
                required: true,
                message: messages.required('Número exterior'),
              },
            ]}
          >
            <NumericInput />
          </Form.Item>

          <Form.Item label="Número interior" name="intNumber">
            <NumericInput />
          </Form.Item>

          <Form.Item
            label="Colonia"
            name="suburb"
            rules={[
              {
                required: true,
                message: messages.required('Colonia'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Ciudad"
            name="city"
            rules={[
              {
                required: true,
                message: messages.required('Ciudad'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Estado"
            name="state"
            rules={[
              {
                required: true,
                message: messages.required('Estado'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Código postal"
            name="zipCode"
            rules={[
              {
                required: true,
                message: messages.required('Código postal'),
              },
              {
                len: 5,
                message: messages.lenght,
              },
            ]}
          >
            <NumericInput />
          </Form.Item>

          <Form.Item
            label="Teléfono"
            name="phone"
            rules={[
              {
                required: true,
                message: messages.required('Teléfono'),
              },
            ]}
          >
            {(subsidiaryById.id === id || !id) && (
              <MaskedInput
                mask={masks.phone}
                defaultValue={
                  id
                    ? subsidiaryById?.phone
                      ? phoneMask(subsidiaryById.phone)
                      : null
                    : null
                }
              />
            )}
          </Form.Item>

          <Form.Item name="lat" label="Latitud">
            <Input />
          </Form.Item>

          <Form.Item name="lng" label="Longitud">
            <Input />
          </Form.Item>

          <Form.Item
            label="Código de Sucursal"
            name="code"
            rules={[
              {
                required: true,
                message: messages.required('Código de Sucursal'),
              },
              {
                len: 10,
                message: messages.lenghtValidation,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="zone" label="Zona horaria">
            <Select placeholder="Selecciona una zona horaria">
              <Select.Option value="UTC(CNM) – 5">
                Tiempo del Sureste
              </Select.Option>
              <Select.Option value="UTC(CNM) – N">
                Tiempo del Centro
              </Select.Option>
              <Select.Option value="UTC(CNM) – (N+1)">
                Tiempo del Pacífico
              </Select.Option>
              <Select.Option value="UTC(CNM) – (N+2)">
                Tiempo del Noroeste
              </Select.Option>
            </Select>
          </Form.Item>

          {!subsidiaryById.distributionCenter && (
            <>
              <Form.Item label="Activar" name="active">
                <Switch checked={active} onChange={onActiveChange} />
              </Form.Item>

              <Form.Item name="franchise" label="Franquicia">
                <Switch checked={franchise} onChange={onFranchiseChange} />
              </Form.Item>
            </>
          )}

          {franchise && (
            <>
              <Form.Item
                label="RFC"
                name="rfc"
                rules={[
                  { required: true, message: messages.required('RFC') },
                  { pattern: regex.rfc, message: 'RFC Invalido' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Razón social"
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: messages.required('Razón social'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}

          <PageHeader className="site-page-header" title="Horarios" />
          <Divider />

          <Schedules
            open={open}
            setOpen={setOpen}
            onOpenChange={onOpenChange}
            timeString={timeString}
            setTimeString={setTimeString}
            isBranch={true}
          />

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/sucursales" />
      </Card>
    </>
  );
};
