import { GET_ALL_CONSENTS } from '../types';

import { get } from './Action';

const basePath = '/consent';

// Get All Consents
export const getAllConsents = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getAllConsentsDS));
};

const getAllConsentsDS = (consent) => ({
  type: GET_ALL_CONSENTS,
  payload: consent,
});
