import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getCosmetologicFeesByComestologist } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { stringDate, stringTime } from '../../../../util';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import moment from 'moment';
import { cosmetologicFeesByCosmetologistReportConst } from '../../../../constants';

export const CosmetologicFeesByCosmetologistReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { cosmetologicFeesByCosmetologist } = useSelector(
    (state) => state.reports
  );
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(cosmetologicFeesByCosmetologistReportConst.title),
    sheet: cosmetologicFeesByCosmetologistReportConst.title,
  });
  const header = cosmetologicFeesByCosmetologistReportConst.header;
  let total = 0;
  let totalCommissions = 0;
  let totalPercentage = 0;

  useEffect(() => {
    if (filtersData.userId && filtersData.dates)
      dispatch(
        getCosmetologicFeesByComestologist({
          dates: filtersData.dates,
          userId: filtersData.userId,
        })
      );
  }, [dispatch, filtersData.userId, filtersData.dates]);

  useEffect(() => {
    if (filtersData.dates) {
      const [date1, date2] = filtersData.dates;

      setPeriod(
        `${moment(date1).format('DD/MM/YYYY')} - ${moment(date2).format(
          'DD/MM/YYYY'
        )}`
      );
    }
  }, [filtersData.dates]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          margin: '0 auto',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={cosmetologicFeesByCosmetologistReportConst.title}
          period={period}
          col1={4}
          col2={0}
          col3={8}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {cosmetologicFeesByCosmetologist?.map((row, key) => {
            total = total + Number(row.salePrice);
            totalPercentage = row.percent;
            totalCommissions = totalCommissions + row.commission;
            return (
              <tr key={key}>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {row.orderNumber}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {stringDate(row.date)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {stringTime(row.time)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.patient}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.product}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.commission)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(Number(row.salePrice))}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL DE VENTA</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(total)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>PORCENTAJE DE COMISIÓN CORRESPONDIENTE</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {totalPercentage}%
            </td>
          </tr>
          <tr>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL DE COMISIÓN</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(totalCommissions)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL A PAGAR</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(total - totalCommissions)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
