import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_CABAIN,
  GET_CABAINS,
  GET_CABAIN_BY_ID,
  UPDATE_CABAIN,
  DELETE_CABAIN,
  DATA_DELETED,
} from '../types';
import { post, get, put, remove } from './Action';

const basePath = '/cabain';

// Create Cabain
export const createCabain = (cabain) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, cabain, createCabainDS));
};

// Get Cabains
export const getCabains = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getCabainsDS, true));
};

// Get Cabain By Id
export const getCabainById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getCabainByIdDS));
};

// Update Cabain
export const updateCabain = (id, cabain) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, cabain, updateCabainDS)
  );
};

// Delete Cabain
export const deleteCabain = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteCabainDS)
  );
  dispatch(cabainDeleteDS(id));
  return response;
};

const createCabainDS = (cabain) => ({
  type: CREATE_CABAIN,
  payload: cabain,
});

const getCabainsDS = (cabain) => ({
  type: GET_CABAINS,
  payload: cabain.items,
});

const getCabainByIdDS = (cabain) => ({
  type: GET_CABAIN_BY_ID,
  payload: cabain,
});

const updateCabainDS = (cabain) => ({
  type: UPDATE_CABAIN,
  payload: cabain,
});

export const cabainDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteCabainDS = () => ({
  type: DELETE_CABAIN,
});
