import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { ReportsMain } from '../../components/Reports';

export const Reports = () => {
  const {
    privileges: { Rentability = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <ReportsMain />
    </Card>
  );
};
