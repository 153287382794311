import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Switch } from 'antd';
import {
  createCoupon,
  getCouponById,
  getProducts,
  getSubsidiaries,
  getTreatments,
  updateCoupon,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  couponTypeConst,
  couponsFormConfig,
  dateFormatMx,
  formConfig,
  lengthValidation,
  masks,
  messages,
  minLengthValidation,
  placeHolders,
} from '../../../../constants';
import {
  CancelButton,
  CustomSelect,
  NumericInput,
} from '../../../uiComponents';
import TextArea from 'antd/lib/input/TextArea';
import { MaskedInput } from 'antd-mask-input';
import { formatPrice } from '../../../../util/formatPrice';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const CouponForm = () => {
  const [res, setRes] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { couponById } = useSelector((state) => state.coupon);
  const { subsidiaries } = useSelector((state) => state.subsidiary);

  const { treatments } = useSelector((state) => state.treatment);
  const { products } = useSelector((state) => state.product);

  const [couponType, setCouponType] = useState(null);
  const [treatment, setTreatment] = useState(null);
  const [product, setProduct] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (id) dispatch(getCouponById(id));

    dispatch(getSubsidiaries({ status: '1' }));
    dispatch(getTreatments({ status: '1' }));
    dispatch(
      getProducts({
        status: 1,
        productStatus: 'approved',
        productType: 'Cosmetólogo/Venta',
        withDeleted: 'false',
      })
    );
  }, [id, dispatch]);

  useEffect(() => {
    if (!product && !treatment && couponById) {
      form.setFieldsValue({
        productId: couponById?.product?.id,
        treatmentId: couponById?.treatment?.id,
      });
    } else {
      form.setFieldsValue({
        regularPrice: formatPrice(0),
        discountPrice: formatPrice(0),
      });
    }
  }, [couponById, couponType, form, product, treatment]);

  useEffect(() => {
    if (!couponById) {
      setProduct(null);
      setTreatment(null);
    }
  }, [couponById, couponType]);

  useEffect(() => {
    if (product || treatment) {
      const regPrice = product
        ? products.find((prod) => {
            return prod.id === product;
          })?.salePrice || 0
        : treatments.find((treat) => {
            return treat.id === treatment;
          })?.price || 0;

      const discountPrice = regPrice - regPrice * (discount / 100);
      form.setFieldsValue({
        regularPrice: formatPrice(regPrice),
        discountPrice: formatPrice(discountPrice),
      });
    }
  }, [form, product, products, treatment, treatments, discount]);

  useEffect(() => {
    if (couponById && id) {
      setStatus(couponById?.status);
      setCouponType(couponById.type);
      setDiscount(couponById.discount);

      const regPrice = couponById?.product
        ? couponById?.product?.salePrice || 0
        : couponById?.treatment?.price || 0;

      const discountPrice = formatPrice(
        regPrice - regPrice * (couponById.discount / 100)
      );

      form.setFieldsValue({
        ...couponById,
        expireDate: moment(couponById?.expireDate),
        branchesIds: couponById?.branches?.map((branch) => branch.id),
        productId: couponById?.product?.id,
        treatmentId: couponById?.treatment?.id,
        regularPrice: formatPrice(regPrice),
        discountPrice,
      });
    }
  }, [couponById, form, id]);

  useEffect(() => {
    form.setFieldsValue({
      discount: discount,
    });
  }, [discount, form]);

  useEffect(() => {
    if ([200, 201].includes(res)) {
      navigate('/dashboard/cupones');
    }
  }, [navigate, res]);

  const onHandledSubmit = async (values) => {
    setIsLoading(true)
    values.discount = parseFloat(values.discount);
    if (id) {
      setRes(await dispatch(updateCoupon(id, values)));
    } else {
      setRes(await dispatch(createCoupon(values)));
    }
    setIsLoading(false)
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onHandledSubmit}
          form={form}
        >
          <Form.Item
            rules={[
              { required: true, message: messages.required('Código') },
              { min: 5, message: minLengthValidation('Código', 5) },
              { max: 15, message: lengthValidation('Código', 15) },
            ]}
            name="code"
            label="Código"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: messages.required('Nombre') },
              { max: 50, message: lengthValidation('Nombre', 50) },
            ]}
            name="name"
            label="Nombre"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: messages.required('Descripción') },
            ]}
            name="description"
            label="Descripción"
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: messages.required('Sucursales') },
            ]}
            name="branchesIds"
            label="Válido en"
          >
            <CustomSelect
              placeholder={placeHolders.branch}
              mode="multiple"
              options={subsidiaries?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Límite de usos"
            name="limit"
            rules={[
              {
                required: true,
                message: messages.required('Límite de usos'),
              },
            ]}
          >
            <NumericInput />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: messages.required('Tipo') }]}
            name="type"
            label="Tipo"
          >
            <CustomSelect
              onChange={setCouponType}
              placeholder={placeHolders.type}
              options={couponTypeConst}
              disabled={id ? true : false}
            ></CustomSelect>
          </Form.Item>

          {couponType === 'Treatment' && (
            <Form.Item
              rules={[
                { required: true, message: messages.required('Tratamiento') },
              ]}
              name="treatmentId"
              label="Tratamiento"
            >
              <CustomSelect
                onChange={setTreatment}
                placeholder={placeHolders.treatment}
                options={treatments?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                disabled={id ? true : false}
              />
            </Form.Item>
          )}

          {couponType === 'Product' && (
            <Form.Item
              rules={[
                { required: true, message: messages.required('Producto') },
              ]}
              name="productId"
              label="Producto"
            >
              <CustomSelect
                onChange={setProduct}
                placeholder={placeHolders.product}
                options={products?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                disabled={id ? true : false}
              />
            </Form.Item>
          )}
          <Form.Item
            {...couponsFormConfig}
            name="discount"
            label="Descuento"
            rules={[
              { required: true, message: messages.required('Descuento') },
            ]}
          >
            {!id && (
              <MaskedInput
                onChange={(value) => {
                  setDiscount(
                    value.maskedValue !== '_.%'
                      ? parseFloat(value.maskedValue)
                      : 0
                  );
                }}
                mask={masks.numPercent}
              />
            )}
            {id && discount && (
              <>
                <Input
                  disabled={id ? true : false}
                  defaultValue={`${couponById?.discount}%`}
                />
              </>
            )}
          </Form.Item>
          <Form.Item
            {...couponsFormConfig}
            label="Precio regular"
            name="regularPrice"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            {...couponsFormConfig}
            label="Precio c/descuento"
            name="discountPrice"
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="expireDate"
            label="Vigencia"
            rules={[{ required: true, message: messages.required('Vigencia') }]}
          >
            <DatePicker style={{ width: '100%' }} format={dateFormatMx} />
          </Form.Item>
          {id && (
            <Form.Item label="Status" name="status">
              <Switch
                checked={status}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setStatus}
              />
            </Form.Item>
          )}

          <Button type="primary" htmlType="submit" className="btn-form" loading={isLoading}>
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/cupones" />
      </Card>
    </>
  );
};
