import { Col, Row, Typography } from 'antd';
import { phoneMask, quantityMask } from '../../../../../util/phoneMask';
import { stringDate } from '../../../../../util/formatTime';

export const InitData = ({ data }) => {
  const { Text } = Typography;
  return (
    <>
      <Row className='row'>
        <Col span={8}>
          <h4>Número de empleado</h4>
        </Col>
        <Col span={16}>{data?.kardex?.employeeNumber || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={16}>{data?.kardex?.mainBranch?.name || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Turno</h4>
        </Col>
        <Col span={16}>{data?.kardex?.workShift || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Puesto</h4>
        </Col>
        <Col span={16}>
          {`${data?.kardex?.jobPosition?.name || '-'} / ${
            data?.kardex?.jobPosition?.department?.name || '-'
          }`}{' '}
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Fecha de nacimiento</h4>
        </Col>
        <Col span={16}>{stringDate(data?.kardex?.birthdate) || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Celular</h4>
        </Col>
        <Col span={16}>{phoneMask(data?.kardex?.phone[0]?.phone) || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Salario fiscal</h4>
        </Col>
        <Col span={16}>
          {`$ ${quantityMask(data?.kardex?.bankInformation?.salary)}`} - 
          <Text type='success'>{` ${
            data?.kardex?.bankInformation?.isr
              ? '(' + data?.kardex?.bankInformation?.isr + '% ISR)'
              : '-'
          }`}</Text>{' '}
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Cuenta Bancaria</h4>
        </Col>
        <Col span={16}>{data?.kardex?.bankInformation?.bankAccount || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Fecha de ingreso</h4>
        </Col>
        <Col span={16}>{stringDate(data?.kardex?.admissionDate) || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Fecha de egreso</h4>
        </Col>
        <Col span={16}>{stringDate(data?.kardex?.departureDate) || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Fecha de reingreso</h4>
        </Col>
        <Col span={16}>{stringDate(data?.kardex?.reentryDate) || '-'}</Col>
      </Row>
    </>
  );
};
