import { useDispatch } from 'react-redux';
import { logoutAway } from '../../../appRedux/actions/UserAuth';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const timeout = process.env.REACT_APP_TIME_OUT_AWAY * 1000;
const promptBeforeIdle = process.env.REACT_APP_TIME_OUT_AWAY_PROMPT * 1000;

export const IddleComponent = () => {
  // const [state, setState] = useState('Active');
  // const [remaining, setRemaining] = useState(timeout);
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onIdle = async () => {
    const currentPage = window.location.pathname;
    await dispatch(logoutAway());

    if (
      !['/quick-login', '/', '/login', '/forgot-password'].includes(currentPage)
    )
      navigate('/quick-login');
  };

  const onActive = () => {
    // setState('Active');
    // setOpen(false);
  };

  const onPrompt = () => {
    // setState('Prompted');
    // setOpen(true);
  };

  useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      // setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  // const handleStillHere = () => {
  //   activate();
  // };

  // const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  // const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';
  return (
    // <div
    //   className="modal"
    //   style={{
    //     display: open ? 'flex' : 'none',
    //   }}
    // >
    //   <h3>Are you still here?</h3>
    //   <p>Logging out in {remaining} seconds</p>
    //   <button onClick={handleStillHere}>Im still here</button>
    // </div>
    <></>
  );
};
