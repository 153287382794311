import { GET_ALL_MODULES } from '../types';

import { get } from './Action';

const basePath = '/module';

// Get All Modules
export const getModules = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getModulesDS));
};

const getModulesDS = (modules) => ({
  type: GET_ALL_MODULES,
  payload: modules,
});