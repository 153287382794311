import {
  CheckSquareOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

export const transferConst = {
  prefix: 'TR',
};

export const searchFilters = [
  { value: 'transfer.transfer_number', label: 'Transferencia' },
  { value: 'createdBy.name', label: 'Creó' },
  { value: 'approvedBy.name', label: 'Aprobó' },
  { value: 'acceptedBy.name', label: 'Aceptó' },
  { value: 'rejectedBy.name', label: 'Rechazó' },
];

export const filters = {
  search: true,
  orderStatus: true,
  transferBranches: true,
};

export const searchInputInitialValues = {
  search: { filter: 'transfer.transfer_number' },
};

export const transferTableMenuOpt = (approve, rejected) => {
  return [
    {
      label: !approve && !rejected ? 'Aprobar' : 'Editar',
      key: 'edit',
      icon: !approve && !rejected ? <CheckSquareOutlined /> : <EditOutlined />,
      privilege: !approve && !rejected ? 'approve' : 'edit',
    },
    {
      label: 'PDF',
      key: 'pdf',
      icon: <CloudDownloadOutlined />,
      privilege: 'reports',
    },
    {
      label: 'Eliminar',
      key: 'delete',
      icon: <DeleteOutlined />,
      privilege: 'delete',
    },
  ];
};
