import { Col, Row } from 'antd';
import { phoneMask } from '../../../../../util/phoneMask';

export const JobReferencesData = ({ data }) => {
  const mainJobReference = data?.kardex?.jobReferences?.find(
    (jobReference) => jobReference.main === 1
  );
  const secondaryjobReference = data?.kardex?.jobReferences?.find(
    (jobReference) => jobReference.main === 0
  );
  return (
    <>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Principal</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Empresa</h4>
        </Col>
        <Col span={16}>{mainJobReference?.company || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Puesto</h4>
        </Col>
        <Col span={16}>{mainJobReference?.position || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Jefe Directo</h4>
        </Col>
        <Col span={16}>{mainJobReference?.boss || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{phoneMask(mainJobReference?.phone)}</Col>
      </Row>
      <Row className='row'>
        <Col span={24}>
          <h4 className='subtitle'>Secundaria</h4>
        </Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Empresa</h4>
        </Col>
        <Col span={16}>{secondaryjobReference?.company || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Puesto</h4>
        </Col>
        <Col span={16}>{secondaryjobReference?.position || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Jefe Directo</h4>
        </Col>
        <Col span={16}>{secondaryjobReference?.boss || '-'}</Col>
      </Row>
      <Row className='row'>
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{phoneMask(secondaryjobReference?.phone)}</Col>
      </Row>
    </>
  );
};
