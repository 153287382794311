import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tag } from 'antd';
import {
  deleteCustomer,
  getSingleCustomers,
} from '../../../../appRedux/actions';
import {
  customerConst,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Customer.const';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { CustomerTableOptions } from '../CustomerTableOptions';
import { NoteModalBody } from '../../Note/NoteCreateModalBody';
import { phoneMask } from '../../../../util';
import { CustomerModal } from '../CustomerModalBody';

export const CustomerTable = () => {
  const {
    privileges: { clients: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const { modal } = useCustomModal();
  const [modalTitle, setModalTitle] = useState(null);
  const [children, setChildren] = useState(null);

  const onDelete = (id) => {
    dispatch(deleteCustomer(id));
  };

  const getModal = (customer, action = null) => {
    modal();
    switch (action) {
      case 'note':
        setModalTitle('Crear Nota');
        setChildren(<NoteModalBody customer={customer.id} />);
        break;
      case 'customer':
        setModalTitle(
          `${customer?.name} ${customer?.lastName} ${
            customer?.motherLastName || ''
          }`
        );
        setChildren(<CustomerModal data={customer} />);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: 'ID de cliente',
      sorter: true,
      dataIndex: 'customerId',
      key: 'customerId',
      render: (id, customer) => (
        <Button
          type="link"
          htmlType="button"
          onClick={() => getModal(customer, 'customer')}
          key={`buttonViewCustomer-${id}`}
        >
          {id}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => name?.toUpperCase(),
    },
    {
      title: 'Apellido Paterno',
      sorter: true,
      dataIndex: 'lastName',
      key: 'lastName',
      render: (lastName) => lastName?.toUpperCase(),
    },
    {
      title: 'Apellido Materno',
      sorter: true,
      dataIndex: 'motherLastName',
      key: 'motherLastName',
      render: (motherLastName) => motherLastName?.toUpperCase(),
    },
    {
      title: 'Puntos',
      sorter: true,
      dataIndex: 'points',
      key: 'points',
      render: (points) => <Tag>{points}</Tag>,
    },
    {
      title: 'Género',
      sorter: true,
      dataIndex: 'gender',
      key: 'gender',
      render: (gender) => gender?.toUpperCase(),
    },
    {
      title: 'Celular',
      sorter: true,
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (mobilePhone) => phoneMask(mobilePhone),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (customer, { id }) => (
        <CustomerTableOptions
          id={id}
          privileges={privileges}
          path="clientes"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
            onAddNote: {
              getModal,
              params: [customer, 'note'],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        blackListParam={true}
      />
      <ListTable
        columns={columns}
        name={customerConst.customers}
        getDataFunction={getSingleCustomers}
      />
      <ModalView title={modalTitle} width={1500}>
        {children}
      </ModalView>
    </>
  );
};
