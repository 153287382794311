import { Card } from 'antd';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { CommissionMedical } from '../../components/Commissions/CommissionTableMedical';

export const MedicalCommission = () => {
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <CommissionMedical />
    </Card>
  );
};
