import axios from 'axios';
import messages from '../../constants/feedbackMessages/es.json';

const baseURL = `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}`;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  async (err) => {
    err.status = 500;
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    const code = res?.data?.code;
    res.statusText =
      (code ? messages[res.status][code] : messages[res.status]['0']) ||
      messages[res.status]['0'];
    return res;
  },
  async (err) => {
    if (!err['response']) {
      err['response'] = { status: err.status };
    }

    let errorCode = 0;

    if (Array.isArray(err?.response?.data?.message)) {
      err?.response?.data?.message?.forEach((element) => {
        try {
          const errorParsed = JSON.parse(element);
          errorCode = errorParsed.error;
        } catch (error) {
          console.log('Invalid JSON string');
        }
      });
    } else {
      if (Number(err?.response?.data?.error)) {
        errorCode = err?.response?.data?.error;
      }
    }

    err.response.statusText =
      messages[err?.response?.status ? err.response.status : err.status][
        errorCode
      ];
    return Promise.reject(err.response);
  }
);
export default axiosInstance;
