import { GET_TREATMENT_TYPES, GET_TREATMENT_TYPE_BY_ID } from '../types';
import { get } from './Action';

// Create New treatment
const basePath = '/treatment-type';

// Get Treatment Types
export const getTreatmentTypes = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getTreatmentTypesDS));
};

// Get Treatment Type By Id
export const getTreatmentTypeById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getTreatmentTypeByIdDS));
};

const getTreatmentTypesDS = (treatments) => ({
  type: GET_TREATMENT_TYPES,
  payload: treatments,
});

const getTreatmentTypeByIdDS = (treatment) => ({
  type: GET_TREATMENT_TYPE_BY_ID,
  payload: treatment,
});
