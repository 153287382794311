export const queryPathBuilder = (params) => {
  if (!params) return '';
  let queryParam = '';
  let i = 0;

  for (const [column, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      queryParam += i > 0 ? '&' : '?';
      if (column === 'search' || column === 'extraParams') {
        queryParam += `${column}=${JSON.stringify(value)}`;
      } else {
        queryParam += `${column}=${value}`;
      }
      i++;
    }
  }

  return queryParam;
};
