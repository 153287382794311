import { Col, Row, Tabs, Card } from 'antd';
import { NoteHistoryBody } from '../../Note/NoteHistoryBody';
import { CustomerAppointmentsHistory } from '../CustomerAppointmentsHistory';
import { CustomerData } from './CustomerData';
import { CustomerMainData } from './CustomerMainData';
import { CustomerCashOrders } from './CustomerCashOrders';
import { CustomerOrders } from './CustomerOrders';
import { CustomerPurchasedProducts } from './CustomerPurchasedProducts';

export const CustomerModal = ({ data = {} }) => {
  const tabs = [
    {
      name: 'Información',
      key: 'customer',
      component: <CustomerData data={data} />,
    },
    {
      name: 'Notas',
      key: 'note',
      component: <NoteHistoryBody customerId={data?.id} />,
    },
    {
      name: 'Citas',
      key: 'appointmentHistory',
      component: <CustomerAppointmentsHistory appointments={data?.id} />,
    },
    {
      name: 'Órdenes',
      key: 'customerHistory',
      component: <CustomerOrders customerId={data?.id} />,
    },
    {
      name: 'Órdenes en efectivo',
      key: 'cashOrderHistory',
      component: <CustomerCashOrders customerId={data?.id} />,
    },
    {
      name: 'Productos adquiridos',
      key: 'purchasedTreatments',
      component: <CustomerPurchasedProducts customerId={data?.id} />,
    },
  ];
  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col span={7}>
            <CustomerMainData data={data} />
          </Col>
          <Col span={17}>
            <Card bordered={true}>
              <Tabs type="line">
                {tabs.map(({ name, key, component: Component }) => {
                  return (
                    <Tabs.TabPane tab={name} key={key}>
                      {Component}
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
