import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTreatments, deleteTreatment } from '../../../../appRedux/actions';
import {
  TableMenuOpt,
  RenderStatus,
  ModalView,
  ListTable,
  TableFilters,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { TreatmentModal } from '../TreatmentModal';
import { Button } from 'antd';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Treatments.cons';
import { label } from '../../../../constants';

export const TreatmentTable = () => {
  const {
    privileges: { treatments: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const treatments = useSelector((state) => state.treatment.treatments);

  const getModalData = useCallback(() => {
    const [modalData] = treatments?.filter((type) => type.id === catalogId);
    return modalData;
  }, [treatments, catalogId]);

  useEffect(() => {
    setModalTitle(getModalData()?.name);
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteTreatment(id));
  };

  const columns = [
    {
      title: 'SKU',
      sorter: true,
      dataIndex: 'sku',
      key: 'sku',
      render: (name, { id }) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descripcion',
      sorter: true,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Tipo de Tratamiento',
      sorter: true,
      dataIndex: 'treatmentTypeId',
      key: 'treatmentTypeId',
      render: (_, { treatmentType }) => <div>{treatmentType?.name}</div>,
      path: 'treatmentType.name',
    },
    {
      title: 'Estado',
      sorter: true,
      dataIndex: 'active',
      key: 'active',
      render: (status) => <RenderStatus data={{ status }} />,
      path: 'active',
      formatter: (value) => (value ? label.activeText : label.inactiveText),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          privileges={privileges}
          path={'tratamientos'}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Tratamientos'}
        getDataFunction={getTreatments}
      />
      <ModalView title={modalTitle} width={700}>
        <TreatmentModal data={modalData} />
      </ModalView>
    </>
  );
};
