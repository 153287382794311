import {
  CREATE_COUPON,
  GET_COUPONS,
  GET_COUPON_BY_ID,
  UPDATE_COUPON,
  GET_COUPON_DELETE,
  DELETE_COUPON,
} from '../types';

const initialState = {
  coupons: [],
  couponById: {},
  couponDelete: null,
};

export default function Coupon(state = initialState, action) {
  switch (action.type) {
    case CREATE_COUPON:
      return {
        ...state,
        coupons: [...state.coupons, action.payload],
      };
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload,
      };
    case GET_COUPON_BY_ID:
      return {
        ...state,
        couponById: action.payload,
      };
    case UPDATE_COUPON:
      return {
        ...state,
        coupons: state.coupons.map((coupon) =>
          coupon.id === action.payload.id ? (coupon = action.payload) : coupon
        ),
      };
    case GET_COUPON_DELETE:
      return {
        ...state,
        couponDelete: action.payload,
      };
    case DELETE_COUPON:
      return {
        ...state,
        coupons: state.coupons.filter(
          (coupon) => coupon.id !== state.couponDelete
        ),
      };
    default:
      return state;
  }
}
