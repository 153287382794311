import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CategoryTable } from '../../components/Categories/CategoryTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Categories = () => {
  const {
    privileges: { categories = [] },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      {categories.includes('create') && (
        <>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              navigate('/dashboard/categorias/crear');
            }}
          >
            <PlusOutlined />
            Nuevo
          </Button>
        </>
      )}

      <CategoryTable />
    </Card>
  );
};
