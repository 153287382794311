import _ from 'lodash';
import 'moment/locale/es-mx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  placeHolders,
  roleConst,
  timeFormatComplete,
} from '../../../constants';
import { lockConst } from '../../../constants/Lock.const';
import {
  dateFormat,
  timeFormat12,
  fullDate,
} from '../../../constants/TimeConst';
import { addTime } from '../../../util/addTime';
import { arraySort } from '../../../util/arraySort';
import { CustomSelect } from '../../uiComponents';
import { Loading } from '../Loading';
import { LockModalBody } from '../Lock/LockModalBody';
import { MyLocksModalBody } from '../Lock/MyLocksModalBody';
import { AgendaAppointment } from './AgendaAppointment';
import { AppointmentExtension } from './AppointmentExtension';
import { HeaderItem } from './HeaderItem';
import { SiderAgenda } from './SiderAgenda';
import './styles.css';
import { Button, Layout, Modal, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es-mx';
import {
  clearAppointmentById,
  clearMissedAppointments,
  getAllSubsidiaries,
  getSubsidiaries,
  hideLoading,
  setSelectedBranchId,
  showLoading,
} from '../../../appRedux/actions';
import { setAppointmentData } from '../../../appRedux/actions/General';
import {
  ClockCircleOutlined,
  LockOutlined,
  PlusOutlined,
} from '@ant-design/icons';

export const CalendarAgenda = ({ dairyPrivileges, blockersPrivileges }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Content } = Layout;
  const { appointmentData } = useSelector((state) => state.generalStates);
  const [currentDate, setCurrentDate] = useState(() =>
    appointmentData?.date ? moment(appointmentData?.date) : moment()
  );
  const [child, setChild] = useState(null);
  const { allSubsidiaries, subsidiaries } = useSelector(
    (state) => state.subsidiary
  );
  const { locks } = useSelector((state) => state.lock);
  const { appointments, selectedBranchId } = useSelector(
    (state) => state.agenda
  );
  const [branches, setBranches] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [fixedData, setFixedData] = useState([]);
  const [cabains, setCabains] = useState([]);
  const [fixedColumns, setFixedColumns] = useState([]);
  const [selectedCabainId, setSelectedCabainId] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const timeIterval = '00:30:00';
  const { role, branchId: loggedBranchId } = useSelector(
    (state) => state.userAuth
  );
  const [openLocks, setOpenLocks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllSubsidiaries());
    dispatch(clearMissedAppointments());
    dispatch(clearAppointmentById());
  }, [dispatch]);

  useEffect(() => {
    if (!openLocks) {
      setIsLoading(true);
      dispatch(
        getSubsidiaries({
          status: '1',
          agenda: '1',
          date: currentDate,
          withDeleted: false,
          branchId: selectedBranchId,
          excludeCabainLocks: 1,
        })
      ).then((response) => {
        setIsLoading(false);
      });
    }
  }, [dispatch, locks, appointments, currentDate, selectedBranchId, openLocks]);

  useEffect(() => {
    setBranches(
      subsidiaries.length > 0
        ? subsidiaries.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})
        : []
    );

    if ([roleConst.branchManager, roleConst.franchisee].includes(role)) {
      dispatch(setSelectedBranchId(loggedBranchId));
    } else if (subsidiaries.length > 0 && !selectedBranchId)
      dispatch(setSelectedBranchId(subsidiaries[0].id));
  }, [subsidiaries, role, loggedBranchId, selectedBranchId, dispatch]);

  useEffect(() => {
    if (selectedBranchId) {
      dispatch(
        setAppointmentData({
          branchId: selectedBranchId,
          cabainId: selectedCabainId,
          time: selectedTime,
        })
      );
    }
  }, [selectedBranchId, dispatch, selectedCabainId, selectedTime]);

  useEffect(() => {
    if (branches && selectedBranchId) {
      dispatch(showLoading());
      const branch = branches[selectedBranchId];
      setCabains(branch?.cabain || []);
      let cabains = _.cloneDeep(branch?.cabain);
      const selectedDate = moment(currentDate).format(dateFormat);
      let lockByDay = false;

      cabains = cabains?.map((cabain) => {
        cabain.appointments = cabain.appointments.reduce(
          (obj, item) => ({ ...obj, [`${item.startTime}-${item.date}`]: item }),
          {}
        );

        lockByDay = branch?.locks?.find(
          (lock) => lock?.type === lockConst.day && lock?.date === selectedDate
        );

        const locks = {
          [lockConst.hour]: [],
          [lockConst.cabain]: [],
          [lockConst.hoursRange]: [],
          [lockConst.daysRange]: [],
        };
        const timeLocksArray = [];
        const returnArray = [];

        const [cabainLocks] = branch?.locks
          .filter((item) => item?.cabain?.id === cabain.id)
          .map((item) => {
            const { type, time, days } = item;
            const date = item?.recurrent
              ? selectedDate
              : moment(item?.date).format(dateFormat);
            switch (type) {
              case lockConst.hour:
                timeLocksArray.push(...time);
                [locks[lockConst.hour]].push(
                  timeLocksArray.map((t) => {
                    returnArray.push(`${t}-${date}`);
                    return returnArray;
                  })
                );
                return locks;
              case lockConst.cabain:
                locks[lockConst.cabain].push(
                  item?.recurrent ? selectedDate : item?.date
                );
                return locks;
              case lockConst.hoursRange:
                locks[lockConst.hoursRange] = [
                  ...locks[lockConst.hoursRange],
                  time.map((t) => {
                    const selectedDay = moment(selectedDate).day().toString();
                    if (days?.includes(selectedDay)) {
                      return `${t}-${date}`;
                    }
                    return null;
                  }),
                ];
                return locks;
              case lockConst.daysRange:
                locks[lockConst.daysRange].push(time.map((t) => t));
                return locks;
              default:
                return [];
            }
          });
        cabain.locks = cabainLocks;
        return cabain;
      });

      /* Getting datOfWeek from the selected date and get schedule */
      const branchSchedule = branch?.schedules.find(
        (schedule) => schedule.day === currentDate.isoWeekday() - 1
      );
      let fixedData = [];

      if (branchSchedule) {
        const branchOpening = parseInt(branchSchedule.opening) || 0; // 0
        const branchClosing = parseInt(branchSchedule.closing) || 0; // 0
        const branchIsClosed = !branchOpening && !branchClosing; // 0
        let startTime = branchIsClosed ? 0 : branchOpening; // 0
        const closeTime = branchIsClosed ? 24 : branchClosing; // 20
        let currentTime = branchSchedule.opening; // '07:00:00'
        const currentAppointment = [];

        for (startTime; startTime <= closeTime - 0.5; startTime += 0.5) {
          const startTimeObj = {
            time: moment(
              `${startTime} ${
                startTime - Math.floor(startTime) !== 0 ? ':30:00' : ':00:00'
              } `,
              [timeFormatComplete]
            ).format(timeFormat12),
            key: Math.random(),
          };

          if (!cabains) break;
          const cabainsSorted = arraySort(cabains, 'name');
          for (const cabain of cabainsSorted) {
            const { appointments, locks } = cabain;
            const indexTime = `${moment(currentTime, [
              timeFormatComplete,
            ]).format(timeFormatComplete)}-${selectedDate}`;
            let component = <></>;
            const cabainSchedule = cabain?.schedules?.find(
              (schedule) => schedule.day === currentDate.isoWeekday() - 1
            );

            const cabainOpening = parseInt(cabainSchedule?.opening) || 0;
            const cabainClosing = parseInt(cabainSchedule?.closing) || 0;
            const [currentAppointmentTime] = indexTime.split('-');
            const daysOff = [
              branchOpening,
              branchClosing,
              cabainOpening,
              cabainClosing,
            ];

            // Render add appointment button
            component = dairyPrivileges.includes('create') ? (
              <Button
                type="link"
                onClick={() => {
                  setSelectedCabainId(cabain?.id);
                  setSelectedTime(currentAppointmentTime);
                  setTimeout(() => navigate(`/dashboard/agenda/crear/`), 500);
                }}
              >
                <PlusOutlined className="add" />
              </Button>
            ) : (
              <></>
            );

            if (locks?.hour && locks?.hour?.includes(indexTime)) {
              // Render locks by hour
              component = <LockOutlined className="locked" />;
            }
            if (locks?.cabain && locks?.cabain?.includes(selectedDate)) {
              // Render locks by cabain
              component = <LockOutlined className="locked" />;
            }
            if (lockByDay) {
              // Render locks by day
              component = <LockOutlined className="locked" />;
            }
            if (
              locks?.hours_range &&
              locks.hours_range.some(
                (lock) =>
                  moment(lock[0], timeFormatComplete) <=
                    moment(indexTime, timeFormatComplete) &&
                  moment(lock[1], timeFormatComplete) >
                    moment(indexTime, timeFormatComplete)
              )
            ) {
              // Render locks by hours range
              component = <LockOutlined className="locked" />;
            }
            if (locks?.days_range) {
              const indexDate = moment(
                `${selectedDate}T${indexTime}`,
                fullDate
              );
              if (
                locks.days_range.some(
                  (lock) =>
                    moment(`${lock[0]}T00:00:00`, fullDate) <= indexDate &&
                    indexDate <= moment(`${lock[1]}T23:59:59`, fullDate)
                )
              ) {
                component = <LockOutlined className="locked" />;
              }
              // Render locks by days range
            }
            if (daysOff.includes(0)) {
              // Render locks by non-business days
              component = <LockOutlined className="locked" />;
            }
            if (
              cabainOpening >= startTime ||
              cabainClosing <= startTime ||
              currentTime > startTime ||
              moment(`${selectedDate} ${currentTime}`).isBefore(
                moment().subtract(30, 'minutes')
              )
            ) {
              // Render locks by schedules and past dates
              component = <LockOutlined className="locked" />;
            }
            if (appointments[indexTime]) {
              // Render date component
              currentAppointment.push({
                ...appointments[indexTime],
                cabain,
                indexTime,
              });
              component = (
                <AgendaAppointment
                  appointment={appointments[indexTime]}
                  privileges={dairyPrivileges}
                />
              );
            }

            for (let i = 0; i < currentAppointment.length; i++) {
              const currentTime = moment(
                indexTime.split('-')[0],
                timeFormatComplete
              );
              if (
                moment(currentAppointment[i]?.startTime, timeFormatComplete) <=
                  currentTime &&
                moment(currentAppointment[i]?.endTime, timeFormatComplete) >
                  currentTime &&
                cabain?.id === currentAppointment[i]?.cabain?.id &&
                currentAppointment[i]?.indexTime !== indexTime
              ) {
                component = (
                  <AppointmentExtension appointment={currentAppointment[i]} />
                );
              }
            }

            startTimeObj[cabain?.name] = component;
          }
          fixedData.push(startTimeObj);
          currentTime = addTime(currentTime, timeIterval);
        }
        setFixedData(fixedData);
      }
      dispatch(hideLoading());
    }
  }, [selectedBranchId, branches, currentDate, navigate, dispatch]);

  useEffect(() => {
    if (fixedData && cabains) {
      const sortedCabains = cabains
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .sort(
          (firstItem, secondItem) =>
            firstItem.type?.order - secondItem.type?.order
        );

      const columns = [
        {
          title: <ClockCircleOutlined />,
          dataIndex: 'time',
          fixed: true,
          width: 100,
        },
        ...sortedCabains.map(({ description, name, type }, index) => {
          return {
            key: index,
            title: (
              <HeaderItem
                description={description}
                cabain={name}
                color={type?.color?.color}
              />
            ),
            dataIndex: name,
          };
        }),
      ];
      setFixedColumns(columns);
    }
  }, [cabains, fixedData]);

  const getModal = (action = null) => {
    setOpenLocks(true);

    switch (action) {
      case 'locks':
        setModalTitle(lockConst.lockLabel);
        setChild(<LockModalBody setOpenLocks={setOpenLocks} />);
        break;
      case 'myLocks':
        setModalTitle(lockConst.locksLabel);
        setChild(<MyLocksModalBody privileges={blockersPrivileges} />);
        break;
      default:
        break;
    }
  };

  const sizeTable = (fixedColumns.length - 1) * 190 + 100;
  return (
    <>
      {isLoading && <Loading isLoading={isLoading} />}
      <Layout className="layout-agenda">
        <Layout>
          <Content>
            <div className="container">
              <Space
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  justifyContent: 'left',
                }}
                align="start"
              >
                <Button
                  type="ghost"
                  className="btn-primary-outline"
                  onClick={() => {
                    navigate('/dashboard/citas');
                  }}
                >
                  Citas
                </Button>
                {![
                  roleConst.branchManager,
                  roleConst.franchisee,
                  roleConst.medic,
                ].includes(role) && (
                  <CustomSelect
                    placeholder={placeHolders.branch}
                    style={{ minWidth: '200px' }}
                    onChange={(id) => {
                      dispatch(setSelectedBranchId(id));
                    }}
                    options={allSubsidiaries?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={selectedBranchId}
                  ></CustomSelect>
                )}
                <Button onClick={() => getModal('myLocks')}>
                  <LockOutlined className="locked" />
                  {lockConst.locksLabel}
                </Button>
                {blockersPrivileges.includes('create') && (
                  <Button type="primary" onClick={() => getModal('locks')}>
                    <LockOutlined className="locked" />
                    {lockConst.lockLabel}
                  </Button>
                )}
              </Space>
              <Table
                className="agenda"
                style={{ width: `${sizeTable}px` }}
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                width={sizeTable}
                scroll={{ x: sizeTable, y: 800 }}
                loading={isLoading}
              />
            </div>
          </Content>
          <SiderAgenda value={currentDate} onSelect={setCurrentDate} />
        </Layout>
        <Modal
          title={modalTitle}
          width={'80%'}
          destroyOnClose={true}
          visible={openLocks}
          onCancel={() => setOpenLocks(!openLocks)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          {child}
        </Modal>
      </Layout>
    </>
  );
};
