import { GET_ALL_ROLES } from '../types';

import { get } from './Action';

const basePath = '/role';

// Get All Roles
export const getAllRoles = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getRolesDS));
};

const getRolesDS = (roles) => ({
  type: GET_ALL_ROLES,
  payload: roles,
});