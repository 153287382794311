import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_TREATMENT,
  GET_TREATMENTS,
  GET_TREATMENT_BY_ID,
  UPDATE_TREATMENT,
  DELETE_TREATMENT,
  SAVE_TREATMENT_IMAGE_ID,
  DATA_DELETED,
} from '../types';

import { post, get, put, remove } from './Action';

// Create Treatment
const basePath = '/treatment';
export const createTreatment = (treatment) => async (dispatch) => {
  const [{ originFileObj: photo }] = !!treatment.photos && treatment.photos;

  const response = await dispatch(
    post(`${basePath}/create`, treatment, createTreatmentDS, true)
  );

  if (response && photo) await dispatch(createPhoto(photo, response?.data?.id));

  return response?.status;
};

// Get Treatmenst
export const getTreatments = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getTreatmentsDS, true)
  );
};

// Get Treatment By Id
export const getTreatmentById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getTreatmentByIdDS));
};

// Update Treatment
export const updateTreatment = (id, treatment) => async (dispatch) => {
  const photo =
    treatment?.photos?.length > 0 ? treatment.photos[0].originFileObj : null;

  const response = await dispatch(
    put(`${basePath}/${id}/update`, treatment, updateTreatmentDS, true)
  );

  if (response && photo) await dispatch(createPhoto(photo, response?.data?.id));

  return response?.status;
};

// Delete Treatment
export const deleteTreatment = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteTreatmentDS)
  );
  dispatch(getTreatmentDeleteDS(id));
  return response;
};

export const createPhoto = (file, treatmentId) => async (dispatch) => {
  let photo = new FormData();
  photo.append('image', file);
  return await dispatch(
    post(`${basePath}/${treatmentId}/photo`, photo, createPhotosDS)
  );
};

const createTreatmentDS = (treatment) => ({
  type: CREATE_TREATMENT,
  payload: treatment,
});

const getTreatmentsDS = (treatments) => ({
  type: GET_TREATMENTS,
  payload: treatments.items,
});

const getTreatmentByIdDS = (treatment) => ({
  type: GET_TREATMENT_BY_ID,
  payload: treatment,
});

const updateTreatmentDS = (treatment) => ({
  type: UPDATE_TREATMENT,
  payload: treatment,
});

export const getTreatmentDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteTreatmentDS = () => ({
  type: DELETE_TREATMENT,
});

// SAVE ID IMAGE
const createPhotosDS = (id) => ({
  type: SAVE_TREATMENT_IMAGE_ID,
  payload: id,
});
