import { Input } from 'antd';

export const NumericInput = (props) => {
  const { value, onChange, placeholder, negatives = false } = props;

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if (
      (negatives || inputValue.charAt(inputValue.length - 1) !== '-') &&
      (reg.test(inputValue) || inputValue === '' || inputValue === '-')
    ) {
      onChange(inputValue);
    } else {
      onChange(value ? value : '');
    }
  };

  const handleBlur = () => {
    let valueTemp = value;

    if (valueTemp !== undefined) {
      if (
        value?.toString().charAt(value?.length - 1) === '.' ||
        value === '-'
      ) {
        valueTemp = value?.slice(0, -1);
      }
      onChange(valueTemp?.toString().replace(/(\d+)/, '$1'));
    }
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={props?.onBlur || handleBlur}
      placeholder={placeholder}
      maxLength={25}
    />
  );
};
