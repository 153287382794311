import { Card } from 'antd';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { CouponForm } from '../../components/Coupon/CouponForm';

export const Coupon = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <CouponForm />
    </Card>
  );
};
