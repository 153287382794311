import { GET_ALL_MODULES } from '../types';

const initialState = {
  modules: [],
};

export default function Module(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MODULES:
      return {
        ...state,
        modules: action.payload,
      };
    default:
      return state;
  }
}
