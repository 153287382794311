import { SET_ERROR, DELETE_ERROR, SET_SUCCESS, DELETE_SUCCESS } from "../types";

const initialState = {
  error: null,
  succes: null,
};

export default function error(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case DELETE_ERROR:
      return { ...state, error: null };
    case SET_SUCCESS:
      return {
        ...state,
        success: action.success,
      };
    case DELETE_SUCCESS:
      return { ...state, success: null };
    default:
      return { ...state, error: null, success: null };
  }
}
