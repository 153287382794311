import { Col, Row, Table, Tag } from 'antd';
import { setPaymentStatus, stringDate } from '../../../../util';
import { formatPrice } from '../../../../util/formatPrice';

export const SalesModalBody = ({ data = {} }) => {
  const columns = [
    {
      title: 'Articulo',
      dataIndex: 'package',
      key: 'package',
      render: (pkg, item) => (pkg?.name ? pkg?.name : item?.treatment?.name),
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Precio',
      dataIndex: 'salePrice',
      key: 'salePrice',
      render: (price, item) => formatPrice(price * item?.quantity),
    },
    {
      title: 'Total',
      dataIndex: 'price',
      key: 'price',
      render: (price, item) => formatPrice(price * item?.quantity),
    },
  ];
  return (
    <>
      <Row className="row">
        <Col span={4}>
          <h4>No. Orden</h4>
        </Col>
        <Col span={8}>{data?.orderNumber || '-'}</Col>
        <Col span={4}>
          <h4 className="strong"> Vendedor</h4>
        </Col>
        <Col span={8}>{`${data?.seller?.name || '-'} ${
          data?.seller?.lastName || ''
        } ${data?.seller?.motherLastName || ''}`}</Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Fecha</h4>
        </Col>
        <Col span={8}>{stringDate(data?.created_at) || '-'}</Col>
        <Col span={4}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={8}>{data?.branch?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4 className="strong"> Cliente</h4>
        </Col>
        <Col span={8}>{`${data?.customer?.name || '-'} ${
          data?.customer?.lastName || ''
        } ${data?.customer?.motherLastName || ''}`}</Col>
        <Col span={4}>
          <h4>Descuento</h4>
        </Col>
        <Col span={8}>
          {data?.discountTotal ? formatPrice(data?.discountTotal) : '-'}
          {data?.coupon && (
            <Tag style={{ marginLeft: 5 }}>{data.coupon?.name}</Tag>
          )}
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Puntos</h4>
        </Col>
        <Col span={8}>
          <Tag>{data?.points}</Tag>
        </Col>
        <Col span={4}>
          <h4>Total</h4>
        </Col>
        <Col span={8}>{data?.total ? formatPrice(data?.total) : '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Método de pago</h4>
        </Col>
        <Col span={8}>{data?.paymentMethod || '-'}</Col>
        <Col span={4}>
          <h4>Status</h4>
        </Col>
        <Col span={8}>
          <Tag color={data?.refundedAt ? 'red' : 'blue'}>
            {data?.refundedAt ? 'Devolución' : 'Venta'}
          </Tag>
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Estado de pago</h4>
        </Col>
        <Col span={8}>{setPaymentStatus(data?.paymentStatus)}</Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Referencia débito</h4>
        </Col>
        <Col span={8}>
          {data?.payReferenceDebit > 0 ? data?.payReferenceDebit : '-'}
        </Col>
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Referencia crédito</h4>
        </Col>
        <Col span={8}>
          {data?.payReferenceCredit > 0 ? data?.payReferenceCredit : '-'}
        </Col>

        {data?.refundMethod && (
          <>
            <Col span={4}>
              <h4>Método de devolución</h4>
            </Col>
            <Col span={8}>{data?.refundMethod || '-'}</Col>
          </>
        )}
      </Row>

      <Row className="row">
        <Col span={4}>
          <h4>Referencia conekta</h4>
        </Col>
        <Col span={8}>{data?.payReferenceConekta || '-'}</Col>
        {data?.refundReason && (
          <>
            <Col span={4}>
              <h4>Razón de devolución</h4>
            </Col>
            <Col span={8}>{data?.refundReason || '-'}</Col>
          </>
        )}
      </Row>

      <Row className="row">
        <Col span={24}>
          <Table
            pagination={{
              defaultPageSize: 3,
            }}
            dataSource={data.orderDetails}
            columns={columns}
          />
        </Col>
      </Row>
    </>
  );
};
