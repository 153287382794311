import { Card } from 'antd';
import { CategoryForm } from '../../components/Categories/CategoryForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Category = () => {
    return (
       <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
        <CategoryForm />
       </Card>
    )
}
