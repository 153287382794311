import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCabainTypes,
  deleteCabainType,
} from '../../../../appRedux/actions/CabainType';
import { ListTable, TableMenuOpt, ModalView } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { CabainTypeModal } from '../CabainTypeModal';
import './styles.css';

export const CabainTypesTable = () => {
  const {
    privileges: { cabain_types: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const { cabainTypes } = useSelector((state) => state.cabainType);

  const getModalData = useCallback(() => {
    const [modalData] = cabainTypes?.filter(
      (cabainType) => cabainType.id === catalogId
    );
    return modalData;
  }, [cabainTypes, catalogId]);

  useEffect(() => {
    setModalTitle(getModalData()?.name);
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteCabainType(id));
  };

  const columns = [
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (color) => {
        return (
          <div
            style={{
              width: '28px',
              height: '28px',
              borderRadius: '15px',
              backgroundColor: '#' + color?.color,
            }}
          />
        );
      },
      path: 'color.color',
      formatter: (color) => `#${color}`,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (name, { id }) => (
        <a
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {name}
        </a>
      ),
      path: 'name',
    },
    {
      title: 'HEX',
      dataIndex: 'color',
      key: 'color',
      render: (color) => <span>#{color?.color?.toUpperCase()}</span>,
      path: 'color.color',
    },
    {
      title: 'Ordenamiento',
      dataIndex: 'order',
      key: 'order',
      render: (orden) => <span>{orden}</span>,
      path: 'order',
    },
    {
      title: 'Tipo',
      dataIndex: 'treatmentType',
      key: 'treatmentType',
      render: (type) => type?.appointmentName || '-',
      path: 'treatmentType.appointmentName',
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          path="tipos-cabina"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          privileges={privileges}
        />
      ),
    },
  ];
  return (
    <>
      <ModalView title={modalTitle} data={modalData} catalog="cabainType">
        <CabainTypeModal data={modalData} />
      </ModalView>
      <ListTable
        columns={columns}
        data={cabainTypes}
        name={'Tipos de Cabina'}
        getDataFunction={getCabainTypes}
        legacy={false}
      />
    </>
  );
};
