import { messages } from '../constants';

export const validatePhotos = (_, value) => {
  if (!value) return Promise.resolve();

  let sizeError = false;
  for (const image of value) {
    const fileSizeInMB = image.size / 1024 / 1024;

    if (fileSizeInMB >= 1) {
      sizeError = true;
    }
  }
  return sizeError
    ? Promise.reject(new Error(messages.imageSize))
    : Promise.resolve();
};
