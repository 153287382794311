import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_NOTE,
  GET_NOTES,
  GET_NOTE_BY_ID,
  UPDATE_NOTE,
  GET_NOTE_DELETE,
  DELETE_NOTE,
  SAVE_NOTE_IMAGE_ID,
} from '../types';
import { post, put, get, remove } from './Action';

const basePath = '/note';

// CREATE note
export const createNote = (note) => async (dispatch) => {
  const photo = note?.photos?.length > 0 ? note.photos[0].originFileObj : null;
  const response = await dispatch(
    post(`${basePath}/create`, note, createNoteDS, true)
  );
  if (response && photo) await dispatch(createPhoto(photo, response?.data?.id));

  return response?.status;
};

//GET Notes
export const getNotes = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(get(`${basePath}${queryParams}`, getNotesDS));
};

// Get Note By Id
export const getNoteById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getNoteByIdDS));
};

// Update Note
export const updateNote = (id, note) => async (dispatch) => {
  return await dispatch(put(`${basePath}/${id}/update`, note, updateNoteDS));
};

// Delete Note
export const deleteNote = (id) => async (dispatch) => {
  dispatch(getNoteDeleteDS(id));
  return await dispatch(remove(`${basePath}/${id}/delete`, deleteNoteDS));
};

// Create Note Photo
export const createPhoto = (file, noteId) => async (dispatch) => {
  let photo = new FormData();
  photo.append('image', file);
  return await dispatch(
    post(`${basePath}/${noteId}/photo`, photo, createPhotosDS)
  );
};

const createNoteDS = (note) => ({
  type: CREATE_NOTE,
  payload: note,
});

const getNotesDS = (notes) => ({
  type: GET_NOTES,
  payload: notes.items,
});

const getNoteByIdDS = (note) => ({
  type: GET_NOTE_BY_ID,
  payload: note,
});

const updateNoteDS = (note) => ({
  type: UPDATE_NOTE,
  payload: note,
});

const getNoteDeleteDS = (id) => ({
  type: GET_NOTE_DELETE,
  payload: id,
});

const deleteNoteDS = () => ({
  type: DELETE_NOTE,
});

// SAVE ID IMAGE
const createPhotosDS = (id) => ({
  type: SAVE_NOTE_IMAGE_ID,
  payload: id,
});
