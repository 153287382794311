import { GET_ALL_CONSENTS } from "../types";

const initialState = {
  consents: [],
};

export default function Consent(state= initialState, action){
  switch (action.type){
    case GET_ALL_CONSENTS:
      return{
        ...state,
        consents: action.payload
      };
      default:
        return state;
  }
}