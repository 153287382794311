import { Button, Card, DatePicker, Form, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createGoal,
  getGoalById,
  updateGoal,
} from '../../../../appRedux/actions/Goal';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  monthName,
  monthTwoDigit,
  placeHolders,
  yearFourDigit,
} from '../../../../constants';
import { CancelButton, CustomSelect } from '../../../uiComponents';
import moment from 'moment';
import { getSubsidiaries } from '../../../../appRedux/actions';
import './styles.css';

export const GoalForm = () => {
  const [res, setRes] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { goalById } = useSelector((state) => state.goal);

  const [isLoading, setIsLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getGoalById(id));
    }

    dispatch(getSubsidiaries({ status: '1' }));
  }, [id, dispatch]);

  useEffect(() => {
    if (Object.keys(goalById).length) {
      form.setFieldsValue({
        goal: String(goalById.goal),
        year: moment(goalById.year, yearFourDigit),
        month: moment(goalById.month, monthTwoDigit),
        branchId: goalById.branch.id,
      });
      setMonth(goalById.month);
      setYear(goalById.year);
    }
  }, [form, goalById]);

  useEffect(() => {
    if ([200, 201].includes(res)) {
      navigate('/dashboard/metas');
    }
  }, [navigate, res]);

  const handleMonthChange = (date) => {
    const month = moment(date).month() + 1;
    const formattedMonth = month.toString().padStart(2, '0');

    setMonth(formattedMonth);
  };

  const handleYearChange = (date) => {
    const years = moment(date).year();
    setYear(years);
  };

  const formatPrice = (value) => {
    if (value) {
      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  const formatParse = (value) => {
    return value?.replace(/\$\s?|(,*)/g, '');
  };

  const onHandledSubmit = async (values) => {
    setIsLoading(true);

    values.year = String(year);
    values.month = String(month);

    if (id) {
      setRes(await dispatch(updateGoal(id, values)));
    } else {
      setRes(await dispatch(createGoal(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          autoComplete="off"
          onFinish={onHandledSubmit}
          form={form}
        >
          <Form.Item
            rules={[{ required: true, message: messages.required('Meta') }]}
            name="goal"
            label="Meta"
          >
            <InputNumber
              formatter={formatPrice}
              parser={formatParse}
              stringMode={true}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: messages.required('Sucursal') }]}
            name="branchId"
            label="Sucursal"
          >
            <CustomSelect
              placeholder={placeHolders.branch}
              mode="single"
              options={subsidiaries?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="month"
            label="Mes"
            rules={[
              {
                required: true,
                message: messages.required('Mes'),
              },
            ]}
          >
            <DatePicker
              picker="month"
              className="ant-picker"
              format={monthName}
              onChange={handleMonthChange}
            />
          </Form.Item>
          <Form.Item
            name="year"
            label="Año"
            rules={[
              {
                required: true,
                message: messages.required('Año'),
              },
            ]}
          >
            <DatePicker
              picker="year"
              format={yearFourDigit}
              onChange={handleYearChange}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/metas" />
      </Card>
    </>
  );
};
