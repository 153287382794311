import {
  CREATE_CABAIN_TYPE,
  DELETE_CABAIN_TYPE,
  GET_CABAIN_TYPE_DELETE,
  GET_CABAIN_TYPES,
  GET_CABAIN_TYPE_BY_ID,
  UPDATE_CABAIN_TYPE,
} from '../types';

const initialState = {
  cabainTypes: [],
  cabainTypeById: {},
  cabainTypeDelete: null,
};

export default function CabainType(state = initialState, action) {
  switch (action.type) {
    case CREATE_CABAIN_TYPE:
      return {
        ...state,
        cabainTypes: [...state.cabainTypes, action.payload],
      };
    case GET_CABAIN_TYPES:
      return {
        ...state,
        cabainTypes: action.payload,
      };
    case GET_CABAIN_TYPE_BY_ID:
      return {
        ...state,
        cabainTypeById: action.payload,
      };
    case UPDATE_CABAIN_TYPE:
      return {
        ...state,
        cabainTypes: state.cabainTypes.map((ct) =>
          ct.id === action.payload.id ? (ct = action.payload) : ct
        ),
      };
    case GET_CABAIN_TYPE_DELETE:
      return {
        ...state,
        cabainTypeDelete: action.payload,
      };
    case DELETE_CABAIN_TYPE:
      return {
        ...state,
        cabainTypes: state.cabainTypes.filter(
          (ct) => ct.id !== state.cabainTypeDelete
        ),
      };
    default:
      return state;
  }
}
