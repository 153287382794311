import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { TransferForm } from '../../components/Transfer/TransferForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Transfer = () => {
  const {
    fullname,
    role,
    branchId,
    privileges: { transfers = [] },
  } = useSelector((state) => state.userAuth);

  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className="gx-card">
      <TransferForm
        logedUserName={fullname}
        role={role}
        loggedBranchId={branchId}
        privileges={transfers}
      />
    </Card>
  );
};
