export const searchFilters = [
  { value: 'log.order', label: 'Número de orden' },
  { value: 'product', label: 'Producto' },
];

export const searchInputInitialValues = {
  search: { filter: 'product' },
};

export const filters = {
  search: true,
  actions: true,
  modules: true,
  transferBranches: true,
  dates: true,
};

export const actionsConst = [
  { value: 'update', label: 'Actualizar' },
  { value: 'delete', label: 'Eliminar' },
  { value: 'approve', label: 'Aprobar' },
  { value: 'create', label: 'Crear' },
  { value: 'reject', label: 'Rechazar' },
  { value: 'confirm', label: 'Confimar' },
  { value: 'drop', label: 'Baja' },
];

export const modulesDictionary = {
  products: 'Productos',
  purchaseOrder: 'Orden de compra',
  saleOrder: 'Orden de venta',
  transfers: 'Transferencias',
  assortmentOrder: 'Resurtido',
  stock: 'Inventario',
  agenda: 'Agenda',
};
export const actionDictionary = {
  update: 'Actualizar',
  delete: 'Eliminar',
  create: 'Crear',
  approve: 'Aprobar',
  reject: 'Rechazar',
  confirm: 'Confimar',
  drop: 'Baja',
  registration: 'Registro',
  sale: 'Venta',
};
export const changesDictionary = {
  name: 'Nombre',
  before: 'Antes',
  after: 'Despues',
  desciption: 'Descripción',
  moldearteCost: 'Costo moldearte',
  indirectExpenses: 'Costos Indirectos',
  sku: 'SKU',
  brand: 'Marca',
  description: 'Descricion',
  content: 'Contenido',
  measureUnit: 'Unidad de medida',
  cost: 'Costo',
  salePrice: 'Costo de venta',
  points: 'Puntos',
  franchiseTax: 'Impuesto a franquicia',
  additionalCostFranchise: 'Costo adicional Franquicia',
  franchisePrice: 'Precio Franquicia',
  barCode: 'Código de barras',
  status: 'Estado',
  orderNumber: 'Número de orden',
  orderType: 'Tipo de orden',
  approvedAt: 'Fecha de Aprobación',
  rejectedAt: 'Fecha de rechazo',
  sentAt: 'Fecha de envio',
  created_at: 'Fecha de creación',
  approved_at: 'Fecha de Aprobación',
  rejected_at: 'Fecha de rechazo',
  updated_at: 'Fecha de actualización',
  payStatus: 'Estado de pago',
  payedAt: 'Fecha de pago',
  contentType: 'Tipo de contenido',
  quantity: 'Cantidad',
  justification: 'Justificación',
  tax: 'Impuesto',
  finishedAt: 'Fecha de finalización',
};
export const prefixesDictionary = {
  purchaseOrder: 'OC',
  saleOrder: 'SO',
  transfers: 'TR',
  assortmentOrder: 'OR',
  dairy: 'CITA-',
  branch_sales: 'POS',
};
