import { GET_ALL_DEPARTMENTS } from '../types';

import { get } from './Action';

const basePath = '/department';

// Get All Roles
export const getAllDepartments = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getDeparmentsDS));
};

const getDeparmentsDS = (jobPositions) => ({
  type: GET_ALL_DEPARTMENTS,
  payload: jobPositions,
});