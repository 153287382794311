import { Card } from 'antd';
import { ApplianceForm } from '../../components/Appliances/ApplianceForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Appliance = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <ApplianceForm />
    </Card>
  );
};
