import { formatPrice } from '../../../../../util/formatPrice';
import { customerConst } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCustomerOrders } from '../../../../../appRedux/actions';
import { stringDate } from '../../../../../util';
import { Button, Table, Tag } from 'antd';
import {
  originSaleDictionary,
  paymentStatusDictionary,
} from '../../../../../constants/Sales';
import { FileTextOutlined } from '@ant-design/icons';
import { setPaymentStatus } from '../../../../../util/orders';

export const CustomerOrders = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customerOrders } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomerOrders(customerId));
  }, [dispatch, customerId]);

  const columns = [
    {
      title: 'Orden',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Método de pago',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (paymentMethod) => paymentMethod || '-',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total) => formatPrice(total),
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
      path: 'created_at',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Status',
      dataIndex: 'refundedAt',
      key: 'refundedAt',
      render: (refundedAt) => (
        <Tag color={refundedAt ? 'red' : 'blue'}>
          {refundedAt ? 'Devolución' : 'Venta'}
        </Tag>
      ),
      path: 'refundedAt',
      formatter: (refundedAt) => (refundedAt ? 'Devolución' : 'Venta'),
    },
    {
      title: 'Estado de pago',
      dataIndex: 'paymentStatus',
      render: (status) => setPaymentStatus(status),
      path: 'paymentStatus',
      formatter: (paymentStatus) => paymentStatusDictionary[paymentStatus],
    },
    {
      title: 'Origen',
      dataIndex: 'origin',
      render: (origin) => (
        <Tag>{originSaleDictionary[origin.toLowerCase()]}</Tag>
      ),
      path: 'origin',
      formatter: (origin) => originSaleDictionary[origin.toLowerCase()],
    },
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      render: (id, order) =>
        order.origin === 'App' || order.origin === 'ONLINE' ? (
          <></>
        ) : (
          <Button
            icon={<FileTextOutlined />}
            type="link"
            onClick={() => {
              order.refundedAt
                ? window.open(
                    `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/order/${id}/ticket/refund`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                : window.open(
                    `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/order/${id}/ticket`,
                    '_blank',
                    'noopener,noreferrer'
                  );
            }}
          />
        ),
    },
  ];
  return (
    <>
      <Table
        pagination={customerConst.pagination}
        dataSource={customerOrders?.orders}
        columns={columns}
        name={'Ordenes Clientes'}
      />
    </>
  );
};
