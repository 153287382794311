import { Input, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  messages,
  lengthValidation,
} from '../../../../../constants/Forms';
import { TwoColumnsFormItem } from '../../../../uiComponents/TwoColumnsFormItem';

export const AddressStepForm = ({ form, editMode }) => {
  return (
    <>
      <Form.Item
        label='Dirección'
        name='address'
        rules={[
          {
            required: true,
            message: messages.required('Dirección'),
          },
          {
            max: 255,
            message: lengthValidation('Dirección', 255),
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <TwoColumnsFormItem>
        <Form.Item
          label='Ciudad'
          name='city'
          rules={[
            {
              required: true,
              message: messages.required('Ciudad'),
            },
            {
              max: 50,
              message: lengthValidation('Ciudad', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Estado'
          name='state'
          rules={[
            {
              required: true,
              message: messages.required('Estado'),
            },
            {
              max: 50,
              message: lengthValidation('Estado', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </TwoColumnsFormItem>

      <Form.Item
        label='Dirección INE'
        name='addressINE'
        rules={[
          {
            required: true,
            message: messages.required('Dirección INE'),
          },
          {
            max: 255,
            message: lengthValidation('Dirección INE', 255),
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <TwoColumnsFormItem>
        <Form.Item
          label='Ciudad INE'
          name='cityINE'
          rules={[
            {
              required: true,
              message: messages.required('Ciudad INE'),
            },
            {
              max: 50,
              message: lengthValidation('Ciudad INE', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Estado INE'
          name='stateINE'
          rules={[
            {
              required: true,
              message: messages.required('Estado INE'),
            },
            {
              max: 50,
              message: lengthValidation('Estado INE', 50),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </TwoColumnsFormItem>
    </>
  );
};
