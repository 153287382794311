import { Col, Row } from 'antd';
import { RenderStatus } from '../../../uiComponents/RenderStatus';

export const ApplianceModalBody = ({ data = {} }) => {
  return (
    <>
      <Row className="row">
        <Col span={11}>
          <h4>Tipo de aparato</h4>
        </Col>
        <Col span={13}>{data?.type || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Marca</h4>
        </Col>
        <Col span={13}>{data?.brand || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Modelo</h4>
        </Col>
        <Col span={13}>{data?.model || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Descripción</h4>
        </Col>
        <Col span={13}>{data?.description || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Status</h4>
        </Col>
        <Col span={13}>
          <RenderStatus data={data} />
        </Col>
      </Row>
    </>
  );
};
