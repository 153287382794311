import { Col, Collapse, Row, Tag } from 'antd';
import { weekDaysOrder } from '../../../../constants';
import { branchConst } from '../../../../constants/Branch.const';
import { arraySort } from '../../../../util/arraySort';
import { RenderStatus } from '../../../uiComponents/RenderStatus';
const { Panel } = Collapse;

export const CabainModalBody = ({ data }) => {
  const sortedSchedules = arraySort(data.schedules, branchConst.day);

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data?.name}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={16}>{data?.branch?.name || 'Sin asignar'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Tipo</h4>
        </Col>
        <Col span={16}>{data?.type?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Estado</h4>
        </Col>
        <Col span={16}>
          <RenderStatus data={data} />
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Usuario</h4>
        </Col>
        <Col span={16}>
          <Col>{data?.description || '-'}</Col>
        </Col>
      </Row>

      <Collapse ghost style={{ fontSize: 16 }}>
        <Panel header="Horario" key="1">
          <Row className="row">
            <Col span={24}>
              {sortedSchedules?.map((schedule) => {
                const [openingHour, openingMinute] =
                  schedule.opening.split(':');
                const [closingHour, closingMinute] =
                  schedule.closing.split(':');
                return (
                  <Row key={`schedule-${schedule.day}`}>
                    <Col span={8}>
                      <h5>
                        {`${weekDaysOrder[schedule.day]}
                      `}
                        <br />
                      </h5>
                    </Col>

                    <Tag color="blue">{`${openingHour}:${openingMinute}`}</Tag>
                    <Tag color="blue">{`${closingHour}:${closingMinute}`}</Tag>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};
