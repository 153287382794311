import { Card } from 'antd';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';
import { SettingForm } from '../../components/Settings/SettingsForm';

export const Setting = () => {
  const {
    privileges: { setting },
  } = useSelector((state) => state.userAuth);
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <SettingForm privileges={setting} />
    </Card>
  );
};
