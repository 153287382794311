import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoupons, deleteCoupon } from '../../../../appRedux/actions';
import {
  TableMenuOpt,
  RenderStatus,
  ModalView,
  ListTable,
  TableFilters,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { Button, Typography } from 'antd';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Coupon.const';
import { couponTypeDictionary, label } from '../../../../constants';
import { stringDate } from '../../../../util';
import { formatPrice } from '../../../../util/formatPrice';
import { CouponModal } from '../CouponModal';

export const CouponTable = () => {
  const {
    privileges: { treatments: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const { Text } = Typography;
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const { coupons } = useSelector((state) => state.coupon);

  const getModalData = useCallback(() => {
    const [modalData] = coupons?.filter((type) => type.id === catalogId);
    return modalData;
  }, [coupons, catalogId]);

  useEffect(() => {
    setModalTitle(getModalData()?.name);
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteCoupon(id));
  };
  const columns = [
    {
      title: 'Código',
      sorter: true,
      dataIndex: 'code',
      key: 'code',
      render: (name, { id }) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Límite',
      sorter: true,
      dataIndex: 'limit',
      key: 'limit',
    },
    {
      title: 'Usados',
      sorter: true,
      dataIndex: 'used',
      key: 'used',
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Vigencia',
      sorter: true,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (date) => stringDate(date),
      path: 'date',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (type) => couponTypeDictionary[type],
      path: 'type',
      formatter: (type) => couponTypeDictionary[type],
    },
    {
      title: 'Producto',
      dataIndex: 'type',
      key: 'type',
      render: (_, { type, treatment, product }) => {
        return couponTypeDictionary[type] === 'Tratamiento'
          ? treatment.name
          : product.name;
      },
      path: 'default',
      formatter: ({ type, treatment, product }) => {
        return couponTypeDictionary[type] === 'Tratamiento'
          ? treatment.name
          : product.name;
      },
    },
    {
      title: 'Precio',
      sorter: true,
      dataIndex: 'price',
      key: 'price',
      render: (_, { type, treatment, product }) => {
        return formatPrice(
          couponTypeDictionary[type] === 'Tratamiento'
            ? treatment.price
            : product.salePrice
        );
      },
      path: 'default',
      formatter: ({ type, treatment, product }) => {
        return formatPrice(
          couponTypeDictionary[type] === 'Tratamiento'
            ? treatment.price
            : product.salePrice
        );
      },
    },
    {
      title: 'Precio c/descuento',
      sorter: true,
      dataIndex: 'priceDiscount',
      key: 'priceDiscount',
      render: (_, { type, treatment, product, discount }) => {
        const price =
          couponTypeDictionary[type] === 'Tratamiento'
            ? treatment.price
            : product.salePrice;

        const finalPrice = price - price * (discount / 100);
        return (
          <>
            {formatPrice(finalPrice)}
            <Text type="success">{` (${discount}%)`}</Text>
          </>
        );
      },
      path: 'default',
      formatter: ({ type, treatment, product, discount }) => {
        const price =
          couponTypeDictionary[type] === 'Tratamiento'
            ? treatment.price
            : product.salePrice;

        const finalPrice = price - price * (discount / 100);
        return `${formatPrice(finalPrice)} (${discount}%)`;
      },
    },
    {
      title: 'Estado',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <RenderStatus data={{ status }} />,
      path: 'status',
      formatter: (value) => (value ? label.activeText : label.inactiveText),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          privileges={privileges}
          path={'cupones'}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Cupones'}
        getDataFunction={getCoupons}
      />
      <ModalView title={modalTitle} width={850}>
        <CouponModal data={modalData} />
      </ModalView>
    </>
  );
};
