export const searchInputInitialValues = {};

export const searchFilters = [];

export const filters = {
  search: false,
  date: true,
  branches: true,
  dates: true,
};

export const dailyCashClosingConst = {
  MODAL_TITLE: 'Cortes por sucursal',
};
