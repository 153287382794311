import { Avatar, Card, Col, Row, Space, Tag } from 'antd';
import { OrderProducts } from '../../../uiComponents/OrderProducts';
import DefaultImage from '../../../../assets/images/ICONO-AZUL-100x100.png';
import './styles.css';
import { treatmentConst } from '../../../../constants/Treatments.cons';
import { formatPrice } from '../../../../util/formatPrice';

export const TreatmentModal = ({ data = {} }) => {
  const treatmentTypeMedical = data.treatmentType.name;

  const showTerm = (term) => {
    const [hr, minutes] = term.split(':');
    const hrs = hr > 0 ? `${hr} : ${minutes} hora(s)` : ` ${minutes} minutos`;

    return hrs;
  };

  return (
    <>
      <Card
        bordered={false}
        cover={
          <Avatar
            className="avatar"
            alt="..."
            src={
              data?.photo
                ? `${process.env.REACT_APP_API}/v1/photo/thumbnail/${data?.photo?.name}`
                : DefaultImage
            }
          />
        }
        style={{ marginBottom: '0px' }}
      ></Card>
      <Row className="row">
        <Col span={8}>
          <h4>Tipo de tratamiento</h4>
        </Col>
        <Col span={16}>{data?.treatmentType?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>SKU</h4>
        </Col>
        <Col span={16}>{data?.sku || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Descripción</h4>
        </Col>
        <Col span={16}>{data?.description || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Categoría</h4>
        </Col>
        <Col span={16}>
          {' '}
          <Space wrap>
            {data?.categories?.map((category) => (
              <Tag key={category.id}>{category.name}</Tag>
            ))}
          </Space>
        </Col>
      </Row>

      {data?.areas?.length > 0 && (
        <Row className="row">
          <Col span={8}>
            <h4>Areas</h4>
          </Col>
          <Col span={16}>
            {' '}
            <Space wrap>
              {data?.areas?.map((area) => (
                <Tag key={area.id}>{area.name}</Tag>
              ))}
            </Space>
          </Col>
        </Row>
      )}

      {data?.appliance?.length > 0 && (
        <Row className="row">
          <Col span={8}>
            <h4>Aparatos</h4>
          </Col>
          <Col span={16}>
            <Space wrap>
              {data?.appliance?.map((app) => (
                <Tag key={app.id}>{app.name}</Tag>
              ))}
            </Space>
          </Col>
        </Row>
      )}

      <Row className="row">
        <Col span={8}>
          <h4>Requiere evaluación</h4>
        </Col>
        <Col span={16}>{data?.assessment ? 'Si' : 'No'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Requiere consentimiento</h4>
        </Col>
        <Col span={16}>{data?.consent ? 'Si' : 'No'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Precio</h4>
        </Col>
        <Col span={16}>{formatPrice(data?.price) || '-'}</Col>
      </Row>
      {treatmentTypeMedical === treatmentConst.medical && (
        <Row className="row">
          <Col span={8}>
            <h4>Comisión médica</h4>
          </Col>
          <Col span={16}>{`${formatPrice(data?.medicalFee)}`}</Col>
        </Row>
      )}

      <Row className="row">
        <Col span={8}>
          <h4>Puntos</h4>
        </Col>
        <Col span={16}>{data?.points || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Duración</h4>
        </Col>
        <Col span={16}>{showTerm(data?.term) || '-'}</Col>
      </Row>

      {data?.treatmentProduct?.length > 0 && (
        <OrderProducts
          orderProducts={data?.treatmentProduct}
          orderType="treatment"
          span={12}
        />
      )}
    </>
  );
};
