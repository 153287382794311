import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AppliancesTable } from '../../components/Appliances/ApplianceTable';
import { BackButton } from '../../uiComponents/BackButton';
import { useSelector } from 'react-redux';

export const Appliances = () => {
  const {
    privileges: { appliances: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  return (
    <Card
      title={
        <BackButton
          path="/dashboard/aparatos-sucursal"
          label="Aparatos"
          bordered={true}
          className="gx-card"
        />
      }
      bordered={true}
      className="gx-card"
    >
      {privileges.includes('create') && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            navigate('/dashboard/aparatos/crear');
          }}
        >
          <PlusOutlined />
          Nuevo
        </Button>
      )}
      <AppliancesTable />
    </Card>
  );
};
