export const searchFilters = [
  { value: 'branch.name', label: 'Nombre' },
  { value: 'branch.phone', label: 'Teléfono' },
];

export const filters = {
  search: true,
  status: true,
  franchise: true,
};

export const searchInputInitialValues = {
  search: { filter: 'branch.name' },
  status: '1',
};
export const branchConst = {
  day: 'day',
  ERROR_BRANCH_WITHOUT_HOURS: 'Sucursal sin horarios',
  ERROR_OUTSIDE_BRANCH_OURS: 'El horario de la sucursal es de',
};

export const mainBranch = 'Centro Operativo';
