export const searchFilters = [{ value: 'cabain.name', label: 'Nombre' }];

export const searchInputInitialValues = {
  search: { filter: 'cabain.name' },
  status: '1',
};

export const filters = {
  search: true,
  status: true,
  cabainTypes: true,
  branches: true,
};
export const cabainConst = {
  day: 'day',
};
