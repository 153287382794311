import {
  GET_EXPENSES_BY_BRANCH,
  GET_MEDICAL_COMMISSIONS_BY_BRANCH,
  GET_GENERAL_CUSTOMER,
  GET_GENERAL_APPOINTMENTS,
  GET_GENERAL_EXPENSES,
  GET_GENERAL_INVENTORY,
  GET_OCCUPANCY_PERCENTAGE_BY_BRANCH,
  GET_PROFITABILITY_BY_BRANCH,
  GET_STOCK_BY_BRANCH,
  GET_GENERAL_COSMETOLOGIC_FEES,
  GET_COSMETOLOGIC_FEE_BY_COSMETOLOGIST,
  GET_SCHEDULED_APPOINTMENTS,
  GET_INDIVIDUAL_COMMISSION_MEDICAL,
  GET_DAILY_SALES,
  GET_MONTHLY_SALES,
  GET_DAILY_CASH_CLOSING,
} from '../types';

const initialState = {
  stockByBranch: [],
  expensesByBranch: [],
  medicalComissionByBranch: [],
  generalInventory: [],
  generalExpenses: [],
  profitabilityByBranch: [],
  occupancyPercentageByBranch: [],
  generalCustomer: [],
  generalAppointment: [],
  generalCosmetologicFees: [],
  cosmetologicFeesByCosmetologist: [],
  generalScheduledAppointments: [],
  individualCommissionMedical: [],
  dailySales: [],
  monthlySales: [],
  dailyCashClosing: [],
};

export default function Reports(state = initialState, action) {
  switch (action.type) {
    case GET_STOCK_BY_BRANCH:
      return {
        ...state,
        stockByBranch: action.payload,
      };
    case GET_EXPENSES_BY_BRANCH:
      return {
        ...state,
        expensesByBranch: action.payload,
      };
    case GET_MEDICAL_COMMISSIONS_BY_BRANCH:
      return {
        ...state,
        medicalComissionByBranch: action.payload,
      };
    case GET_GENERAL_INVENTORY:
      return {
        ...state,
        generalInventory: action.payload,
      };
    case GET_GENERAL_EXPENSES:
      return {
        ...state,
        generalExpenses: action.payload,
      };
    case GET_PROFITABILITY_BY_BRANCH:
      return {
        ...state,
        profitabilityByBranch: action.payload,
      };
    case GET_OCCUPANCY_PERCENTAGE_BY_BRANCH:
      return {
        ...state,
        occupancyPercentageByBranch: action.payload,
      };
    case GET_GENERAL_CUSTOMER:
      return {
        ...state,
        generalCustomer: action.payload,
      };
    case GET_GENERAL_APPOINTMENTS:
      return {
        ...state,
        generalAppointment: action.payload,
      };

    case GET_GENERAL_COSMETOLOGIC_FEES:
      return {
        ...state,
        generalCosmetologicFees: action.payload,
      };
    case GET_INDIVIDUAL_COMMISSION_MEDICAL:
      return {
        ...state,
        individualCommissionMedical: action.payload,
      };

    case GET_SCHEDULED_APPOINTMENTS:
      return {
        ...state,
        generalScheduledAppointments: action.payload,
      };

    case GET_COSMETOLOGIC_FEE_BY_COSMETOLOGIST:
      return {
        ...state,
        cosmetologicFeesByCosmetologist: action.payload,
      };

    case GET_MONTHLY_SALES:
      return {
        ...state,
        monthlySales: action.payload,
      };
    case GET_DAILY_CASH_CLOSING:
      return {
        ...state,
        dailyCashClosing: action.payload,
      };

    case GET_DAILY_SALES:
      return {
        ...state,
        dailySales: action.payload,
      };
    default:
      return state;
  }
}
