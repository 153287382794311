import { Button, Card, Form } from 'antd';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  placeHolders,
} from '../../../../constants';
import { useEffect, useState } from 'react';
import {
  getAppointments,
  getDoctors,
  updateCommissionAgent,
} from '../../../../appRedux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';

export const UpdateCommissionAgent = ({ appointment }) => {
  const { doctors } = useSelector((state) => state?.user);
  const { filtersData } = useSelector((state) => state?.generalStates);
  const dispatch = useDispatch();
  const [response, setResponse] = useState();
  const { closeModal } = useCustomModal();
  const { attendedBy: user } = appointment;
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getDoctors());
  }, [dispatch]);

  useEffect(() => {
    if ([200].includes(response)) {
      const params = filtersData.hasOwnProperty('status')
        ? filtersData
        : { status: 1 };
      dispatch(getAppointments(params));
      closeModal()
    }
  }, [response, dispatch]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ userId: user?.id });
    }
  }, [user]);

  const onSubmit = async (values) => {
    setResponse(await dispatch(updateCommissionAgent(appointment?.id, values)));
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form
        {...formConfig}
        id="commissionsUpdate"
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item name="userId" label="Médico">
          <CustomSelect
            placeholder={placeHolders.commissionAgent}
            options={doctors?.map(({ id, name, lastName }) => ({
              value: id,
              label: `${name} ${lastName}`,
            }))}
          ></CustomSelect>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-form"
          form="commissionsUpdate"
        >
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
