
import { ListTable } from '../../../uiComponents/Table/ListTable';
import { Space, Popconfirm, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import { data } from './orderList';

export const OrderTable = () => {
   const navigate = useNavigate();

    const onDelete = (id) => {
        // dispatch(deleteProduct(id));
    };
    
    const columns = [
        {
          title: "Numero",
          sorter: true,
          dataIndex: "numero",
          key: "numero",
          align: "center"
        },
        {
          title: "Proveedor",
          sorter: true,
          dataIndex: "proveedor",
          key: "proveedor",
          align: "center",
        },
        {
          title: "Fecha",
          sorter: true,
          dataIndex: "fecha",
          key: "fecha",
          align: "center",
        },
        {
          title: "Sucursal",
          sorter: true,
          dataIndex: "sucursal",
          key: "sucursal",
          align: "center",
        },
        {
          title: "Estatus",
          sorter: true,
          dataIndex: "estatus",
          key: "estatus",
          align: "center",
          render: (text, record) => (
            <Tag 
                color={ text  === 'en proceso' ? 'cyan' 
                  : text === 'surtido' ? 'lime' 
                  :  'red'}> 
                {text}
            </Tag>
          )
        },       
        {
          title: "Opciones",
          key: "id",
          align: "center",
          render: (text, record) => (
            <Space size="middle">
              <EditOutlined 
                onClick={()=> { navigate(`/dashboard/resurtidos/${record.id}`)}} 
                style={{ fontSize: '25px',}}
              />
              <Popconfirm
                title="Esta seguro？"
                okText="Si"
                cancelText="No"
                onConfirm={() => {
                  onDelete(record.id);
                }}
              >
                <DeleteOutlined style={{ fontSize: '25px', color: 'red'}}/>
              </Popconfirm>
            </Space>
          )
        },
      ];
  return (
    <>
        <ListTable columns={columns}  data={data} size="small" />
    </>
  )
}
