import { useState } from 'react';
import { ListTable, TableMenuOpt, ModalView } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { getProductTypes } from '../../../../appRedux/actions/ProductTypes';
import { ProductTypeModal } from '../ProductTypeModal';

export const ProductTypeTable = () => {
  const { modal } = useCustomModal();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});

  const showModal = (data) => {
    modal();
    setModalTitle(data?.name);
    setModalData(data);
  };

  const columns = [
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (productType, row) => (
        <a
          href="#/"
          onClick={() => {
            showModal(row);
          }}
        >
          {productType}
        </a>
      ),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: () => <TableMenuOpt disabled="true" />,
    },
  ];

  return (
    <>
      <ModalView title={modalTitle}>
        <ProductTypeModal data={modalData} />
      </ModalView>
      <ListTable
        columns={columns}
        name={'Tipos de producto'}
        getDataFunction={getProductTypes}
      />
    </>
  );
};
