import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./registerServiceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import App from "./App";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";

ReactDOM.render(
  <ConfigProvider locale={es_ES}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
