import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { buttonLabel } from '../../../constants';
import './styles.css';
export const CancelButton = ({ path }) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        type="danger"
        className="danger-button"
        onClick={() => {
          navigate(path);
        }}
      >
        {buttonLabel.cancel}
      </Button>
    </>
  );
};
