import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { getMonthlySales } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { monthDictionay, monthlySalesConst } from '../../../../constants';

export const MonthlySales = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { filtersData } = useSelector((state) => state.generalStates);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { monthlySales } = useSelector((state) => state.reports);
  const header = monthlySalesConst.header;
  const [branch, setBranch] = useState();
  const [period, setPeriod] = useState();
  let cashTotalTx = 0;
  let onlineTotalTx = 0;
  let appTotalTx = 0;
  let tdcTotalTx = 0;
  let tddTotalTx = 0;
  let dccTotalTx = 0;
  let cashTotalProduct = 0;
  let dlsTotalProduct = 0;
  let dccTotalProduct = 0;
  let dccTotal = 0;
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(monthlySalesConst.title),
    sheet: monthlySalesConst.title,
  });

  useEffect(() => {
    if (filtersData?.year && filtersData.branchId)
      dispatch(
        getMonthlySales({
          year: filtersData.year,
          branchId: filtersData.branchId,
        })
      );
  }, [filtersData.year, filtersData.branchId, dispatch]);

  useEffect(() => {
    if (monthlySales) {
      setBranch(monthlySales[0]?.branch);
      setPeriod(`01/01/${filtersData.year} - 31/12/${filtersData.year}`);
    }
  }, [monthlySales, filtersData.year]);

  const getTotals = (row) => {
    cashTotalTx += Number(row.cashTotalTx);
    onlineTotalTx += Number(row.online);
    appTotalTx += Number(row.app);
    tdcTotalTx += Number(row.tdc);
    tddTotalTx += Number(row.tdd);
    dccTotalTx += Number(row.totalDccTx);
    cashTotalProduct += Number(row.mxnProduct);
    dlsTotalProduct += Number(row.dlsProduct);
    dccTotalProduct += Number(row.totalDcccProduct);
    dccTotal += Number(row.ddcTotal);
  };

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          display: 'block',
        }}
      >
        <ReportTableHeader
          branch={branch}
          user={fullname}
          period={period}
          role={role}
          title={monthlySalesConst.title}
          col1={10}
          col2={0}
          col3={14}
        />
        <tbody>
          <tr>
            {header.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '5px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {monthlySales?.map((row, key) => {
            getTotals(row);

            return (
              <tr key={key}>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {monthDictionay[row.month]}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.mxnTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.dlsTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.cashTotalTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.online)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.app)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.tdc)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.tdd)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.totalDccTx)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.mxnProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.dlsProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.totalDcccProduct)}
                </td>
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.ddcTotal)}
                </td>
              </tr>
            );
          })}
          <tr>
            <td
              colSpan={4}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#FD0C01',
                color: '#FFFFFF',
              }}
            >
              TOTALES
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(cashTotalTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(onlineTotalTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(appTotalTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(tdcTotalTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(tddTotalTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(dccTotalTx)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(cashTotalProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(dlsTotalProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(dccTotalProduct)}
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#DDDDDD',
              }}
            >
              {formatPrice(dccTotal)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
