export const deleteItemModal = {
  title: '¿Deseas eliminar este elemento?',
  body: 'Al dar click en confirmar se eliminará y esta acción no se podrá deshacer',
};

export const orderConfirmModal = {
  title: '¿Deseas confirmar esta orden?',
  body: 'Al dar click en aceptar se estará generando la orden',
};

export const modalButtons = {
  ok: 'Aceptar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
};

export const rejectProductModal = {
  title: '¿Deseas rechazar este producto?',
  body: 'Al dar click en rechazar, el producto se rechazará y necesitara guardarse de nuevo para pedir aprobación',
};
