import React from 'react';
import { Breadcrumb, Space } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { BackButton } from '../BackButton';

export const TitleBreadcrumbs = () => {
  const pathname = useLocation()?.pathname?.split('/');

  const items = pathname.filter(
    (part) => part?.trim() !== '' && part !== 'dashboard'
  );

  const lastItem = items.length - 1;
  const path = '/dashboard/' + [items.slice(0, -1)];

  const regUUID =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return (
    <Space>
      {pathname.length > 3 && <BackButton path={path} />}
      <Breadcrumb>
        {items.map((item, index) => {
          return (
            <Breadcrumb.Item key={item}>
              {lastItem !== index ? (
                <Link to={`../${item}`}>{item.replace(/-/g, ' ')}</Link>
              ) : regUUID.test(item) ? (
                'Editar'
              ) : (
                item.replace(/-/g, ' ')
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </Space>
  );
};
