import {
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  GET_DOCTORS,
  GET_USER_BY_ID,
  GET_USER_DELETE,
  UPDATE_USER,
  GET_ALL_USERS,
  GET_COSMETOLOGISTS,
} from '../types';

const initialState = {
  users: [],
  doctors: [],
  cosmetologists: [],
  userById: {},
  userDelete: null,
  userImage: {},
  userImageId: {},
};

export default function Product(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_DOCTORS:
      return {
        ...state,
        doctors: action.payload || [],
      };
    case GET_COSMETOLOGISTS:
      return {
        ...state,
        cosmetologists: action.payload || [],
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        userById: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? (user = action.payload) : user
        ),
      };
    case GET_USER_DELETE:
      return {
        ...state,
        userDelete: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== state.userDelete),
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
}
