import { Col, Row } from 'antd';

export const PersonalData = ({ data }) => {
  return (
    <>
      <Row className="row">
        <Col span={5}>
          <h4>Género</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.gender || '-'}</Col>
        <Col span={5}>
          <h4>Estado civíl</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.maritalStatus || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>Nacionalidad</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.nationality || '-'}</Col>
        <Col span={5}>
          <h4>Nacimiento</h4>
        </Col>
        <Col span={7}>
          {data?.kardex?.personalData?.birthCity || '-'} /{' '}
          {data?.kardex?.personalData?.birthState || '-'}
        </Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>NSS</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.nss || '-'}</Col>
        <Col span={5}>
          <h4>CURP</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.curp || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>RFC</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.rfc || '-'}</Col>
        <Col span={5}>
          <h4>OCR INE</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.ocr || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>Infonavit</h4>
        </Col>
        <Col span={7}>
          {data?.kardex?.personalData?.infonavit ? 'Si' : 'No'}
        </Col>
        <Col span={5}>
          <h4>Educación</h4>
        </Col>
        <Col span={7}>
          {data?.kardex?.personalData?.educationalDegree || '-'} /{' '}
          {data?.kardex?.personalData?.profession || '-'}{' '}
        </Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>Cédula Profesional</h4>
        </Col>
        <Col span={7}>
          {data?.kardex?.personalData?.professionalLicense || '-'}
        </Col>
        <Col span={5}>
          <h4>Cédula Provisional</h4>
        </Col>
        <Col span={7}>
          {data?.kardex?.personalData?.provisionalProfesionalLicense || '-'}
        </Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>Hijos</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.childrens}</Col>
        <Col span={5}>
          <h4>Correo personal</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.email || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>Tipo de sangre</h4>
        </Col>
        <Col span={7}>
          {data?.kardex?.personalData?.bloodType?.toUpperCase() || '-'}
        </Col>
        <Col span={5}>
          <h4>Enfermedades crónicas</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.diseases || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={5}>
          <h4>Alergias</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.allergies || '-'}</Col>
        <Col span={5}>
          <h4>Alergias medicamento</h4>
        </Col>
        <Col span={7}>{data?.kardex?.personalData?.drugAllergies || '-'}</Col>
      </Row>
    </>
  );
};
