import { Col, Row, Table } from 'antd';
import { phoneMask } from '../../../../util/phoneMask';
import { formatPrice } from '../../../../util/formatPrice';

export const SaleOrderModal = ({ data = {} }) => {
  const { saleOrderProduct } = data;
  const columns = [
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product?.name,
    },
    {
      title: 'SKU',
      dataIndex: 'product',
      key: 'sku',
      render: (product) => product?.sku,
    },
    {
      title: 'Lote',
      dataIndex: 'productStock',
      key: 'allotment',
      render: (stock) => stock?.allotment || '-',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (price) => formatPrice(price),
    },
    {
      title: 'Subtotal',
      dataIndex: 'quantity',
      key: 'subttotal',
      render: (quantity, { price }) =>
        formatPrice(Number(quantity) * Number(price)),
    },
  ];

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={16}>{data?.branch?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Número de nota</h4>
        </Col>
        <Col span={16}>{data?.note || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{phoneMask(data?.phone)}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>RFC</h4>
        </Col>
        <Col span={16}>{data?.rfc || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Total</h4>
        </Col>
        <Col span={16}>${data?.totalAmount}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Descripción</h4>
        </Col>
        <Col span={16}>{data?.comments || '-'}</Col>
      </Row>

      <br />

      <Table
        dataSource={saleOrderProduct}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 40, 60, 80],
        }}
      />
    </>
  );
};
