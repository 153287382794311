import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssortmentOrders,
  deleteAssortmentOrder,
} from '../../../../appRedux/actions';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { AssortmentOrderStatus } from '../AssortmentOrderStatus';
import { stringDate } from '../../../../util';
import {
  assortmentOrderConst,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/AssortmentOrder.const';
import { AssormentOrderModalBody } from '../AssormentOrderModalBody';
import { Button } from 'antd';
import { AssortmentOrderTableOptions } from '../AssortmentOrderTableOptions';

export const AssortmentOrderTable = ({ privileges, role }) => {
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const { assortmentOrders } = useSelector((state) => state?.assortmentOrder);

  const getModalData = useCallback(() => {
    const [modalData] = assortmentOrders?.filter(
      (cabainType) => cabainType.id === catalogId
    );
    return modalData;
  }, [assortmentOrders, catalogId]);

  useEffect(() => {
    setModalTitle(
      `${assortmentOrderConst.header}: ${
        assortmentOrderConst.prefix + getModalData()?.orderNumber
      }`
    );
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteAssortmentOrder(id));
  };

  const addRowClass = (order) => {
    if (!order?.finishedAt) return;

    return order?.assortmentOrderProduct?.map((product) => {
      if (product?.receivedQuantity !== product?.stockedQuantity)
        return 'row-volcano';
      return null;
    });
  };

  const columns = [
    {
      title: 'Número de orden',
      sorter: true,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (number, { id }) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {assortmentOrderConst.prefix + number}
        </Button>
      ),
    },
    {
      title: 'Tipo de orden',
      sorter: true,
      dataIndex: 'orderType',
      key: 'orderType',
    },
    {
      title: 'Fecha de creación',
      sorter: true,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name,
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <AssortmentOrderStatus status={status} />,
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (
        _,
        { id, approvedAt, sentAt, deleted_at, finishedAt, status }
      ) => (
        <AssortmentOrderTableOptions
          id={id}
          path="ordenes-de-surtido"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          deleted={!!deleted_at}
          approved={approvedAt}
          privileges={privileges}
          sent={!!sentAt}
          status={status}
          finished={finishedAt}
          role={role}
        />
      ),
    },
  ];
  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        standOut={true}
        rowClass={addRowClass}
        name={'Orden de Resurtido'}
        getDataFunction={getAssortmentOrders}
      />
      <ModalView title={modalTitle} width={900}>
        <AssormentOrderModalBody data={modalData} />
      </ModalView>
    </>
  );
};
