import { Tag } from 'antd';

export const TransferStatus = ({ status }) => {
  const setStatus = () => {
    if (status === 'En proceso') {
      return <Tag color='blue'>{status}</Tag>;
    } else if (status === 'En transito') {
      return <Tag color='yellow'>{status}</Tag>;
    } else if (status === 'Terminada') {
      return <Tag color='green'>{status}</Tag>;
    } else if(status === 'Rechazada'){
      return <Tag color='orange'>{status}</Tag>;
    }else if(status === 'Eliminada'){
      return <Tag color='red'>{status}</Tag>;
    }
  };

  return setStatus();
};
