import {
  CREATE_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY_BY_ID,
  UPDATE_CATEGORY,
  GET_CATEGORY_DELETE,
  DELETE_CATEGORY,
} from '../types';

const initialState = {
  categories: [],
  categoryById: {},
  categoryDelete: null,
};

export default function Category(state = initialState, action) {
  switch (action.type) {
    case CREATE_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_CATEGORY_BY_ID:
      return {
        ...state,
        categoryById: action.payload,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === action.payload.id
            ? (category = action.payload)
            : category
        ),
      };
    case GET_CATEGORY_DELETE:
      return {
        ...state,
        categoryDelete: action.payload,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== state.categoryDelete
        ),
      };
    default:
      return state;
  }
}
