import { Col, Collapse, Row, Tag } from 'antd';
import { weekDaysOrder } from '../../../../constants';
import { branchConst } from '../../../../constants/Branch.const';
import { timeZones } from '../../../../constants/TimeConst';
import { arraySort } from '../../../../util/arraySort';
import { phoneMask } from '../../../../util/phoneMask';
const { Panel } = Collapse;

export const SubsidiaryModal = ({ data = {} }) => {
  const {
    name,
    street,
    extNumber,
    intNumber,
    suburb,
    city,
    state,
    zipCode,
    phone,
    lat,
    lng,
    zone,
    schedules,
    franchise,
    rfc,
    businessName,
    active,
    code,
  } = data;

  const sortedSchedules = arraySort(schedules, branchConst.day);

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{name ? name : '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Código de Sucursal</h4>
        </Col>
        <Col span={16}>{code ? code : '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Dirección</h4>
        </Col>
        <Col span={16}>
          {`${street} ${extNumber} ${intNumber ? '-' + intNumber : ''} ${
            suburb ? suburb + ', ' : ''
          } ${city ? city : ''} ${state ? state + ', ' : ''} C.P.${zipCode}`}
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Teléfono</h4>
        </Col>
        <Col span={16}>{`${phone ? phoneMask(phone) : '-'}`}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Zona horaria</h4>
        </Col>
        <Col span={16}>{zone ? timeZones[zone] : '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Latitud</h4>
        </Col>
        <Col span={16}>{lat ? lat : '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Longitud</h4>
        </Col>
        <Col span={16}>{lng ? lng : '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>¿Es franquicia?</h4>
        </Col>
        <Col span={16}>{franchise ? 'Si' : 'No'}</Col>
      </Row>

      {franchise && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>RFC</h4>
            </Col>
            <Col span={16}>{rfc ? rfc : '-'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Razón social</h4>
            </Col>
            <Col span={16}>{businessName ? businessName : '-'}</Col>
          </Row>
        </>
      )}

      <Row className="row">
        <Col span={8}>
          <h4>Status</h4>
        </Col>
        <Col span={16}>
          <Tag color={active ? 'green' : 'red'}>
            {active ? 'Activa' : 'Inactiva'}
          </Tag>
        </Col>
      </Row>

      <Collapse ghost style={{ fontSize: 16 }}>
        <Panel header="Horario" key="1">
          <Row className="row">
            <Col span={24}>
              {sortedSchedules?.map((schedule) => {
                const [openingHour, openingMinute] =
                  schedule.opening.split(':');
                const [closingHour, closingMinute] =
                  schedule.closing.split(':');
                return (
                  <Row key={`schedule-${schedule.day}`}>
                    <Col span={8}>
                      <h5>
                        {`${weekDaysOrder[schedule.day]}
                      `}
                        <br />
                      </h5>
                    </Col>

                    <Tag color="blue">{`${openingHour}:${openingMinute}`}</Tag>
                    <Tag color="blue">{`${closingHour}:${closingMinute}`}</Tag>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};
