import { Col, Row } from 'antd';
import { stringDate } from '../../../../util';
import { RenderStatus } from '../../../uiComponents/RenderStatus';
import { applianceStatus } from '../ApplianceStatus';

export const BranchApplianceModalBody = ({ data = {} }) => {
  return (
    <>
      <Row className="row">
        <Col span={11}>
          <h4>SKU</h4>
        </Col>
        <Col span={13}>{data?.sku || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Tipo de aparato</h4>
        </Col>
        <Col span={13}>{data?.appliance?.type || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Marca</h4>
        </Col>
        <Col span={13}>{data?.appliance?.brand || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Modelo</h4>
        </Col>
        <Col span={13}>{data?.appliance?.model || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Descripción</h4>
        </Col>
        <Col span={13}>{data?.appliance?.description || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Status</h4>
        </Col>
        <Col span={13}>
          <RenderStatus data={data} />
        </Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Descripción de estado</h4>
        </Col>
        <Col span={13}>{applianceStatus(data?.statusDescription) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Último mantenimiento</h4>
        </Col>
        <Col span={13}>{stringDate(data?.lastMaintenance) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Sucursal</h4>
        </Col>
        <Col span={13}>{data?.branch?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={11}>
          <h4>Comentarios</h4>
        </Col>
        <Col span={13}>{data?.comments || '-'}</Col>
      </Row>
    </>
  );
};
