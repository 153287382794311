import {
  CheckSquareOutlined,
  CloudDownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';

export const purchaseOrderConst = {
  header: 'Orden de compra',
  prefix: 'OC',
};

export const payStatus = [
  { label: 'Pendiente', value: 'Pendiente' },
  { label: 'Completado', value: 'Completado' },
];

export const searchFilters = [
  { value: 'order.order_number', label: 'Orden' },
  { value: 'order.invoice_number', label: 'Factura' },
];

export const filters = {
  search: true,
  providers: true,
  payMethods: true,
  payStatus: true,
  payConditions: true,
};

export const searchInputInitialValues = {
  search: { filter: 'order.order_number' },
};

export const payMethods = [
  { value: 'Efectivo', label: 'Efectivo' },
  { value: 'Transferencia electrónica', label: 'Transferencia electrónica' },
  { value: 'Depósito bancario', label: 'Depósito bancario' },
  { value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' },
  { value: 'Tarjeta de débito', label: 'Tarjeta de débito' },
];

export const PaymentMethod = [
  { value: 'Efectivo', label: 'Efectivo' },
  { value: 'Paypal', label: 'Paypal' },
  { value: 'Puntos', label: 'Puntos' },
  { value: 'Tarjeta de crédito/débito', label: 'Tarjeta de crédito/débito' },
];

export const purchaseTableMenuOpt = (payed) => {
  return [
    {
      label: !payed ? 'Aprobar' : 'Editar',
      key: 'edit',
      icon: !payed ? <CheckSquareOutlined /> : <EditOutlined />,
      privilege: !payed ? 'approve' : 'edit',
    },
    // {
    //   label: 'Eliminar',
    //   key: 'delete',
    //   icon: <DeleteOutlined />,
    //   privilege: 'delete',
    // },
    {
      label: 'PDF',
      key: 'pdf',
      icon: <CloudDownloadOutlined />,
      privilege: 'reports',
    },
  ];
};

export const purchaseOrderStatusConst = {
  Pendiente: <Tag color="yellow">Pendiente</Tag>,
  Completado: <Tag color="green">Completado</Tag>,
  deleted_at: <Tag color="red">Eliminado</Tag>,
};
