import { GET_ALL_DIARIES } from "../types";

const initialState = {
  diaries: [],
};

export default function Diary(state = initialState, action){
  switch (action.type) {
    case GET_ALL_DIARIES:
      return{
        ...state,
        diaries: action.payload,
      };
      default:
        return state;
  }
}