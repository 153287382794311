import { useState, useEffect } from 'react';
import { Steps, Form, Card, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useStepsForm } from 'sunflower-antd';
import { UserStepForm } from './UserStepForm';
import { InitStepForm } from './InitStepForm';
import { PersonalStepForm } from './PersonalStepFrom';
import { AddressStepForm } from './AddressStepForm';
import { ContactsStepForm } from './ContactsStepForm';
import { JobReferencesStepForm } from './JobReferencesStepForm';
import { ContractsStepForm } from './ContractsStepForm';
import {
  cardConfig,
  formConfig,
  buttonLabel,
} from '../../../../constants/Forms';
import {
  createUser,
  editUser,
  getUserById,
} from '../../../../appRedux/actions';
import './styles.css';
import { CancelButton } from '../../../uiComponents/CancelButton';

export const UserForm = ({ privileges = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const editMode = id;
  const [res, setRes] = useState();
  const { userById } = useSelector((state) => state.user);

  const steps = [
    { title: 'Usuario' },
    { title: 'Iniciales' },
    { title: 'Personales' },
    { title: 'Domicilio' },
    { title: 'Contactos' },
    { title: 'Referencias' },
    { title: 'Contratos' },
  ];

  const totalSteps = steps.length;
  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    total: steps.length,
    isBackValidate: false,
    submit: async (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (values) => {
    if (editMode) {
      values.status =
        values.status || values.status === 'activo' ? 'activo' : 'inactivo';
      setRes(await dispatch(editUser(id, values, userById)));
    } else {
      setRes(await dispatch(createUser(values)));
    }
  };

  useEffect(() => {
    if (editMode) dispatch(getUserById(id));
  }, [dispatch, editMode, id]);

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue(userById);
    }
  }, [editMode, form, userById]);

  useEffect(() => {
    if (res && [200, 201].includes(res)) navigate('/dashboard/personal');
  }, [navigate, res]);

  const formList = [
    <UserStepForm form={form} editMode={editMode} userById={userById} />,
    <InitStepForm form={form} editMode={editMode} userById={userById} />,
    <PersonalStepForm form={form} editMode={editMode} userById={userById} />,
    <AddressStepForm form={form} editMode={editMode} />,
    <ContactsStepForm form={form} editMode={editMode} />,
    <JobReferencesStepForm form={form} editMode={editMode} />,
    <ContractsStepForm form={form} editMode={editMode} />,
  ];

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <div>
          <Steps {...stepsProps} items={steps} labelPlacement="vertical" />

          <div style={{ marginTop: 40 }}>
            <Form {...formConfig} {...formProps}>
              {formList[current]}
              <Form.Item>
                {current < totalSteps - 1 && (
                  <Button
                    className="btn-form"
                    onClick={() => gotoStep(current + 1)}
                  >
                    {buttonLabel.next}
                  </Button>
                )}
                {current === totalSteps - 1 && (
                  <Button
                    className="btn-form"
                    type="primary"
                    loading={formLoading}
                    onClick={() => {
                      submit();
                    }}
                    
                  >
                    {buttonLabel.save}
                  </Button>
                )}
                {current > 0 && (
                  <Button
                    className="btn-form"
                    onClick={() => gotoStep(current - 1)}
                  >
                    {buttonLabel.previous}
                  </Button>
                )}
                <CancelButton path="/dashboard/personal" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
