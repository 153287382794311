export const medicalSaleProduct = 'Médico/Venta';

export const productTypeConst = {
  Input: 'Insumo',
};

export const types = {
  'Médico/Venta': 'médico',
  'Cosmetólogo/Venta': 'cosmetólogo',
  'Uso Cabina': 'cabina',
  Insumo: 'insumo',
};

export const productTypes = [
  { value: 'Médico/Venta', label: 'Médico' },
  { value: 'Cosmetólogo/Venta', label: 'Cosmetólogo' },
  { value: 'Uso Cabina', label: 'Cabina' },
  { value: 'Insumo', label: 'Insumo' },
];

export const apiTypes = {
  'Uso Cabina': 'cabain',
  'Médico/Venta': 'medical',
  'Cosmetólogo/Venta': 'cosmetic',
  Insumo: 'input',
};

export const productStatus = [
  { value: 'approved', label: 'Aprobado' },
  { value: 'pending', label: 'Pendiente' },
  { value: 'rejected', label: 'Rechazado' },
];

export const searchFilters = [
  { value: 'product.name', label: 'Nombre' },
  { value: 'product.sku', label: 'SKU' },
  { value: 'product.brand', label: 'Marca' },
  { value: 'product.barcode', label: 'Código de barras' },
];

export const searchInputInitialValues = {
  search: { filter: 'product.name' },
  status: '1',
};

export const filters = {
  search: true,
  status: true,
  productTypes: true,
  providers: true,
  categories: true,
  productStatus: true,
  productsCategories: true,
};
