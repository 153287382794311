import { Col, Row, Avatar, Card, Tag } from 'antd';
import DefaultImage from '../../../../../assets/images/ICONO-AZUL-100x100.png';
import { phoneMask } from '../../../../../util/phoneMask';
import { stringDate } from '../../../../../util/formatTime';
import { RenderStatus } from '../../../../uiComponents';

export const CustomerMainData = ({ data }) => {
  return (
    <Card
      bordered={true}
      cover={
        <Avatar
          className="avatar"
          alt="..."
          src={
            data?.photo?.name
              ? `${process.env.REACT_APP_API}/v1/customer/avatar/${data?.photo?.name}`
              : DefaultImage
          }
        />
      }
    >
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Id de Cliente</h5>
        </Col>
        <Col span={16}>{`${data?.customerId}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Nombre</h5>
        </Col>
        <Col
          span={16}
        >{`${data?.name?.toUpperCase()} ${data?.lastName?.toUpperCase()} ${
          data?.motherLastName?.toUpperCase() || ' '
        }`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Sucursal de registro</h5>
        </Col>
        <Col span={16}>{`${data?.registryBranch || '-'}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Teléfono</h5>
        </Col>
        <Col span={16}>{phoneMask(data?.mobilePhone) || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Fecha de nacimiento</h5>
        </Col>
        <Col span={16}>{stringDate(data?.birthday) || '-'}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Correo Electrónico</h5>
        </Col>
        <Col span={16}>{data?.email || '-'}</Col>
      </Row>
      {data?.isTutor === true && (
        <Row className="row">
          <Col span={8}>
            <h5 className="strong">Tutor</h5>
          </Col>
          <Col span={16}>
            <Tag color="green"> Si </Tag>
          </Col>
        </Row>
      )}
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Status</h5>
        </Col>
        <Col span={16}>
          <RenderStatus data={data} />
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Lista Negra</h5>
        </Col>
        <Col span={16}>
          {' '}
          <Tag color={data?.blackList ? 'red' : 'green'}>
            {data?.blackList ? 'Si' : 'No'}
          </Tag>
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h5 className="strong">Puntos</h5>
        </Col>
        <Col span={16}>
          {' '}
          <Tag>{data?.points}</Tag>
        </Col>
      </Row>
    </Card>
  );
};
