import { Button } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { deletePackage, getPackages } from '../../../../appRedux/actions';
import { label } from '../../../../constants';
import {
  filters,
  searchFilters,
  searchInputInitialValues
} from '../../../../constants/Package.const';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import {
  ListTable,
  ModalView,
  RenderStatus,
  TableFilters,
  TableMenuOpt
} from '../../../uiComponents';
import { PackageModalBody } from '../PackageModalBody';

export const PackageTable = ({ title = 'Paquetes', privileges = [] }) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();
  const { pathname } = useLocation();
  const type = pathname.includes('paquetes') ? 'Package' : 'Promotion';
  const [, , path] = pathname.split('/');

  const onModalClick = (pack) => {
    modal();
    setModalData(pack);
  };

  const onDelete = (id) => {
    dispatch(deletePackage(id));
  };

  const columns = [
    {
      title: 'SKU',
      sorter: true,
      dataIndex: 'sku',
      key: 'sku',
      render: (sku, pack) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => onModalClick(pack)}
        >
          {sku}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tiempo en cabina',
      sorter: true,
      dataIndex: 'cabainTime',
      key: 'cabainTime',
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (_, data) => <RenderStatus data={data} />,
      path: 'status',
      formatter: (value) => (value ? label.activeText : label.inactiveText),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          privileges={privileges}
          path={path}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        privileges={privileges}
      />
      <ListTable columns={columns} name={title} getDataFunction={getPackages} params={{ type }} />
      <ModalView title={title} width={700}>
        <PackageModalBody data={modalData} />
      </ModalView>
    </>
  );
};
