import { useState, useRef, useEffect } from 'react';
import { Button, Card, Popover, Tooltip } from 'antd';
import { appointmentStatus } from '../../../../constants';
import { phoneMask } from '../../../../util';
import { hexToRGB } from '../../../../util/colors';
import { PopoverBody } from '../popoverBody';
import { PopoverHeader } from '../popoverHeader';
import './styles.css';
import { MoreOutlined } from '@ant-design/icons';

export const AgendaAppointment = ({ appointment, privileges }) => {
  const { customer, appointmentType, treatment } = appointment;
  const { name, lastName, motherLastName, mobilePhone } =
    !!customer && customer;
  let phone = mobilePhone;

  if (customer?.tutor) {
    phone = customer?.tutor?.mobilePhone;
  }

  const [popoverVisible, setPopoverVisible] = useState(false);
  const popoverRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      event.target.id !== 'selectConfirmAppointment' &&
      event.target.className !== 'ant-select-selection-item'
    ) {
      setPopoverVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="agenda-appointment">
        <Card
          bordered={true}
          className="appointment-item"
          style={{
            margin: '2px 0 0 0',
            backgroundColor: `rgba(${hexToRGB(
              appointmentStatus[appointment?.status].color
            )}, 0.15)`,
            color: `#${appointmentStatus[appointment?.status].color}`,
            borderRadius: '0',
            height: '120px',
          }}
          extra={
            <div ref={popoverRef}>
              <Popover
                title={
                  <PopoverHeader
                    appointment={appointment}
                    privileges={privileges}
                  />
                }
                content={
                  <PopoverBody
                    appointment={appointment}
                    privileges={privileges}
                  />
                }
                placement="right"
                visible={popoverVisible}
                onVisibleChange={(visible) => setPopoverVisible(visible)}
              >
                <Button
                  type="link"
                  style={{ marginRight: '-20px', marginTop: '0' }}
                  onClick={() => setPopoverVisible(!popoverVisible)}
                >
                  <MoreOutlined />
                </Button>
              </Popover>
            </div>
          }
        >
          <span className="appoitment-content customer">
            {`${name || ''} ${lastName || ''} ${motherLastName || ''}`}
          </span>

          <span className="appoitment-content">{appointmentType}</span>
          <span className="appoitment-content">{phoneMask(phone)}</span>
          <Tooltip title={treatment?.name}>
            <span className="appoitment-content text-truncate">
              {treatment?.name}
            </span>
          </Tooltip>
        </Card>
      </div>
    </>
  );
};
