export const payConditionsConst = [
  { value: 0, label: 'N/A' },
  { value: 15, label: '15 Días' },
  { value: 30, label: '30 Días' },
  { value: 45, label: '45 Días' },
  { value: 60, label: '60 Días' },
  { value: 90, label: '90 Días' },
];

export const searchFilters = [
  { value: 'provider.name', label: 'Nombre' },
  { value: 'provider.phone', label: 'Teléfono' },
  { value: 'provider.email', label: 'Correo eléctronico' },
  { value: 'provider.rfc', label: 'RFC' },
  { value: 'provider.tradename', label: 'Razón social' },
];

export const filters = {
  search: true,
  payConditions: true
};

export const searchInputInitialValues = {
  search: { filter: 'provider.name' },
};
