import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetPin } from '../../../appRedux/actions/UserAuth';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  lengthValidation,
  messages,
  minLengthValidation,
  placeHolders,
} from '../../../constants';
import { NumericInput } from '../../uiComponents';

export const ChangePinModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();

  useEffect(() => {
    if ([200, 201].includes(response)) {
      closeModal();
    }
  }, [response, dispatch, closeModal]);

  const onSubmit = async (values) => {
    setResponse(await dispatch(resetPin(values)));
  };
  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form onFinish={onSubmit} form={form} {...formConfig}>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: messages.required('Contraseña actual'),
              },
              { message: messages.validation.password },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={placeHolders.currentPassword}
            />
          </Form.Item>
          <Form.Item
            name="newPin"
            rules={[
              {
                required: true,
                message: messages.required('PIN'),
              },
              {
                min: 4,
                message: minLengthValidation('PIN', 4),
              },
              {
                max: 4,
                message: lengthValidation('PIN', 4),
              },
            ]}
          >
            <NumericInput type="password" placeholder={placeHolders.pin} />
          </Form.Item>
          <Form.Item
            name="pin"
            rules={[
              {
                required: true,
                message: messages.required('PIN'),
              },
              {
                min: 4,
                message: minLengthValidation('PIN', 4),
              },
              {
                max: 4,
                message: lengthValidation('PIN', 4),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPin') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Los pines no coinciden!'));
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPin') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Los pines no coinciden!'));
                },
              }),
            ]}
          >
            <NumericInput
              type="password"
              placeholder={placeHolders.confirmPin}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="btn-form">
            {buttonLabel.changePin}
          </Button>
        </Form>
      </Card>
    </>
  );
};
