import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDailyCashClosingCashByBranch } from '../../../../appRedux/actions';
import { stringDate } from '../../../../util/formatTime';
import { formatPrice } from '../../../../util/formatPrice';
import { Table } from 'antd';

export const DailyCashClosingCashModal = ({ branchId, date }) => {
  const dispatch = useDispatch();
  const { dailyCashClosingCashByBranch } = useSelector(
    (state) => state.dailyCashClosingCash
  );

  const { filtersData } = useSelector((state) => state.generalStates);

  useEffect(() => {
    dispatch(
      getDailyCashClosingCashByBranch(branchId, {
        params: { ...filtersData, dates: `${date},${date}` },
      })
    );
  }, [dispatch, branchId, filtersData, date]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'dailyCashClosignCashNumber',
      key: 'dailyCashClosignCashNumber',
    },
    {
      title: 'Sucursal',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name || '-',
      path: 'branch.name',
    },
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <>
          {user?.name} {user?.lastName} {user?.motherLastName}
        </>
      ),
      path: ['user.name', 'user.lastName', 'user.motherLastName'],
    },
    {
      title: 'Efectivo',
      dataIndex: 'cashTotal',
      key: 'cashTotal',
      render: (cashTotal) => `${formatPrice(cashTotal || '0')}`,
    },
    {
      title: 'Dolares',
      dataIndex: 'cashTotalDollars',
      key: 'cashTotalDollars',
      render: (cashTotalDollars) => `${formatPrice(cashTotalDollars || '0')}`,
    },
    {
      title: 'Devoluciones',
      dataIndex: 'refundCash',
      key: 'refundCash',
      render: (refundCash) => `${formatPrice(refundCash || 0)}`,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total) => `${formatPrice(total) || '0'}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => stringDate(date),
      path: 'created_at',
      formatter: (date) => stringDate(date),
    },
  ];

  return (
    <Table
      dataSource={dailyCashClosingCashByBranch}
      columns={columns}
      pagination={{
        defaultPageSize: 5,
        pageSizeOptions: [5, 10, 15],
      }}
    />
  );
};
