import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Form, Row } from 'antd';
import { createMaxMin, updateMaxMin } from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
} from '../../../../constants';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { NumericInput } from '../../../uiComponents';

export const MaxMinModalBody = ({ data }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setShowModal } = useCustomModal();
  const { productId, product, branchId, max, min, mmId } = data;

  useEffect(() => {
    if (mmId) {
      form.setFieldsValue({
        max,
        min,
      });
    }
  }, [form, max, min, mmId]);

  const onSubmit = async (values) => {
    if (mmId) {
      await dispatch(updateMaxMin(mmId, values));
    } else {
      values.productId = productId;
      values.branchId = branchId;
      await dispatch(createMaxMin(values));
    }
    setShowModal(false);
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <h3>{product}</h3>
      <br />
      <Form {...formConfig} autoComplete="off" onFinish={onSubmit} form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Máximo"
              name="max"
              rules={[
                {
                  required: true,
                  message: messages.required('Cantidad'),
                },
              ]}
            >
              <NumericInput />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Mínimo"
              name="min"
              dependencies={['max']}
              rules={[
                {
                  required: true,
                  message: messages.required('Cantidad'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (Number(getFieldValue('max')) >= Number(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'La cantidad mínima no puede ser mayor a la cantidad máxima'
                      )
                    );
                  },
                }),
              ]}
            >
              <NumericInput />
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" className="btn-form">
          {buttonLabel.confirm}
        </Button>
      </Form>
    </Card>
  );
};
