import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { getCategories, getTreatments } from '../../../../appRedux/actions';
import { formConfig, messages, placeHolders } from '../../../../constants';
import './style.css';
import { CustomSelect } from '../../../uiComponents';

export const TreatmentCategory = ({
  form,
  update,
  appointment,
  treatmentTypeId,
}) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);
  const [categoriesIds, setCategoriesIds] = useState();
  const { treatments } = useSelector((state) => state.treatment);
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setFilteredTreatments((items) => {
      if (categoriesIds?.length > 0) {
        items[index] = treatments;
        return items.filter((item) => item?.length > 0);
      } else {
        items[index] = [];
        return items;
      }
    });
  }, [treatments, index, categoriesIds]);

  useEffect(() => {
    if (update && appointment) {
      setCategoriesIds(
        appointment?.categories?.map((category) => category?.id)
      );
      form.setFieldsValue({
        treatmentCategory: [
          {
            categoryId: appointment?.categories?.map(
              (category) => category?.id
            ),
            treatmentId: appointment?.treatment?.id,
          },
        ],
      });
    }
  }, [update, appointment, form]);

  useEffect(() => {
    dispatch(getCategories({ treatmentTypeId, withDeleted: 'false' }));
    if (categoriesIds?.length > 0) {
      dispatch(
        getTreatments({ categoriesIds, treatmentTypeId, withDeleted: 'false' })
      );
    }
  }, [dispatch, categoriesIds, treatmentTypeId]);

  const onCategoryChange = (values, key) => {
    setCategoriesIds(values);
    setIndex(key);

    if (values?.length < 1) {
      const formItems = form.getFieldValue('treatmentCategory');
      formItems[key].treatmentId = null;
      form.setFieldsValue({ treatmentCategory: formItems });
    }
  };

  const removeTreatments = (key) => {
    setFilteredTreatments((items) => {
      items.splice(key, 1);
      return items;
    });
  };

  return (
    <>
      <Form.List name="treatmentCategory">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <div key={field.key}>
                <hr className="divider" />
                <Row wrap>
                  <Col span={fields.length > 1 ? 23 : 24}>
                    <Form.Item
                      {...formConfig}
                      label="Categoría"
                      name={[field.name, 'categoryId']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Categoría'),
                        },
                      ]}
                    >
                      <CustomSelect
                        placeholder={placeHolders.category}
                        mode="multiple"
                        allowClear
                        onChange={(values) =>
                          onCategoryChange(values, field?.name)
                        }
                        options={categories?.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                      ></CustomSelect>
                    </Form.Item>
                  </Col>

                  <Col span={1}>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        className="danger-button-product"
                        onClick={() => {
                          remove(field.name);
                          removeTreatments(field.name);
                        }}
                      />
                    )}
                  </Col>

                  <Col span={fields.length > 1 ? 23 : 24}>
                    <Form.Item
                      {...formConfig}
                      label="Tratamiento"
                      name={[field.name, 'treatmentId']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Tratamiento'),
                        },
                      ]}
                    >
                      <CustomSelect
                        placeholder={placeHolders.treatment}
                        allowClear
                        options={filteredTreatments[field.name]?.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )}
                      ></CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}

            {!update && (
              <Button
                className="add-btn"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Agregar
              </Button>
            )}
          </>
        )}
      </Form.List>
    </>
  );
};
