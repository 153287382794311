import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DailyCashClosingTable } from '../../components/DailyCashClosing/DailyCashClosingTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const DailyCashClosing = () => {
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <Button
        type="ghost"
        className="btn-primary-outline"
        onClick={() => {
          navigate('/dashboard/corte-de-productos');
        }}
      >
        Corte de productos
      </Button>
      <DailyCashClosingTable />
    </Card>
  );
};
