import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { stockAdjustment } from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  dateFormat,
  dateFormatMx,
  formConfig,
  messages,
} from '../../../../constants';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { stockAdjustmentTypeConst } from '../../../../constants';
import { RenderStatus } from '../../../uiComponents';
import { useState } from 'react';
import moment from 'moment';

export const StockAdjustmentModalBody = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setShowModal } = useCustomModal();
  const [loading, setLoading] = useState(false);
  const { productId, branchId, product, sku, branch, status, quantity } = data;

  const handleQuantityChange = (value) => {
    if (isNaN(value) || value < 1) {
      return 1;
    }
    return value;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    values.productId = productId;
    values.branchId = branchId;
    values.quantity = Number(values.quantity);
    await dispatch(stockAdjustment(values)).then((response) => {
      if (response) {
        setShowModal(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Row>
        <Col span={10}>
          <PageHeader
            className="site-page-header"
            title={product}
            style={{
              whiteSpace: 'normal',
              height: 'auto',
              marginBottom: '10px',
            }}
          >
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="SKU">{sku}</Descriptions.Item>
              <Descriptions.Item label="Cantidad">{quantity}</Descriptions.Item>
              <Descriptions.Item label="Status">
                <RenderStatus data={{ status }} />
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
        </Col>

        <Col span={14}>
          <Form
            {...formConfig}
            autoComplete="off"
            onFinish={onSubmit}
            form={form}
          >
            <Form.Item label="Sucursal" name="branch" initialValue={branch}>
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              {...formConfig}
              name="type"
              label="Ajuste"
              rules={[
                {
                  required: true,
                  message: messages.required('Ajuste'),
                },
              ]}
            >
              <Select
                placeholder="Selecciona un tipo de ajuste"
                options={stockAdjustmentTypeConst}
              ></Select>
            </Form.Item>

            <Form.Item
              label="Cantidad"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: messages.required('Cantidad'),
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                stringMode={true}
                min={1}
                onChange={handleQuantityChange}
              />
            </Form.Item>

            <Form.Item
              label="Lote"
              name="allotment"
              rules={[
                {
                  required: true,
                  message: messages.required('Lote'),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Justificación"
              name="justification"
              rules={[
                {
                  required: true,
                  message: messages.required('Justificación'),
                },
              ]}
            >
              <TextArea />
            </Form.Item>

            <Form.Item {...formConfig} name="expiresIn" label="Expiración">
              <DatePicker
                style={{ width: '100%' }}
                format={dateFormatMx}
                value={moment('2020-08-01', dateFormat)}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="btn-form"
              loading={loading}
            >
              {buttonLabel.confirm}
            </Button>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
