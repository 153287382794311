import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, Select, Card, Switch, DatePicker } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  getSubsidiaries,
  getAppliances,
  getBranchApplianceById,
  editBranchAppliance,
  createBranchAppliance,
} from '../../../../appRedux/actions';
import {
  cardConfig,
  formConfig,
  messages,
  buttonLabel,
  placeHolders,
  statusDescriptions,
  dateFormat,
  dateFormatMx,
} from '../../../../constants';
import { CancelButton, CustomSelect } from '../../../uiComponents';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import './styles.css';

export const BranchApplianceForm = () => {
  const { id } = useParams();
  const { applianceById } = useSelector((state) => state.branchAppliance);
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { appliances } = useSelector((state) => state.appliances);
  const [form] = Form.useForm();
  const [lastMaintenance, setLastMaintenance] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [status, setStatus] = useState(true);
  const [branchId, setBranchId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {
    branchId: loggedBranchId,
    privileges: { appliances: privileges = [] },
  } = useSelector((state) => state.userAuth);

  useEffect(() => {
    if (!id) setBranchId(loggedBranchId);
  }, [id, loggedBranchId]);

  useEffect(() => {
    if (id) dispatch(getBranchApplianceById(id));
    dispatch(getSubsidiaries({ status: '1' }));
    dispatch(getAppliances({ status: '1' }));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      navigate('/dashboard/aparatos-sucursal');
    }
  }, [navigate, response]);

  useEffect(() => {
    if (id && applianceById) {
      setLastMaintenance(applianceById.lastMaintenance);
      setStatus(applianceById.status);
      setBranchId(applianceById?.branch?.id);

      applianceById.applianceId = applianceById?.appliance?.id;
      applianceById.branchId = applianceById?.branch?.id;
      applianceById.lastMaintenance =
        applianceById.lastMaintenance && moment(applianceById.lastMaintenance);
      form.setFieldsValue(applianceById);
    }
  }, [form, applianceById, id]);

  const onLastMaintenance = (value, dateString) => {
    const day = moment(value).format(dateFormat);
    setLastMaintenance(day);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    values.lastMaintenance = lastMaintenance;
    values.branchId = branchId;

    if (id) {
      setResponse(await dispatch(editBranchAppliance(id, values)));
    } else {
      setResponse(await dispatch(createBranchAppliance(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            name="applianceId"
            label="Aparato"
            rules={[
              {
                required: true,
                message: messages.required('Aparato'),
              },
            ]}
          >
            <CustomSelect
              disabled={!privileges.includes('approve')}
              placeholder={placeHolders.appliance}
              allowClear
              options={appliances?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            ></CustomSelect>
          </Form.Item>

          {privileges.includes('create') && (
            <Form.Item
              name="branchId"
              label="Sucursal"
              rules={[
                {
                  required: true,
                  message: messages.required('Sucursal'),
                },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.branch}
                allowClear
                onChange={setBranchId}
                options={subsidiaries?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          <Form.Item
            name="sku"
            label="SKU"
            rules={[
              { required: true, message: messages.required('SKU') },
              {
                min: 4,
                message: messages.validation.sku,
              },
              {
                max: 12,
                message: messages.validation.sku,
              },
            ]}
          >
            <Input disabled={id ? true : false} />
          </Form.Item>

          {id && (
            <>
              <Form.Item
                name="statusDescription"
                label="Estado"
                rules={[
                  {
                    require: true,
                    message: messages.required('Estado'),
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona una descripción"
                  options={statusDescriptions}
                ></Select>
              </Form.Item>

              <Form.Item name="lastMaintenance" label="Último mantenimiento">
                <DatePicker
                  style={{ width: '100%' }}
                  format={dateFormatMx}
                  onChange={(object, dateString) =>
                    onLastMaintenance(object, dateString)
                  }
                />
              </Form.Item>

              {privileges.includes('approve') && (
                <Form.Item {...formConfig} name="status" label="Status">
                  <Switch
                    checked={status}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={setStatus}
                  />
                </Form.Item>
              )}
            </>
          )}

          <Form.Item name="comments" label="Comentarios">
            <TextArea />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/aparatos-sucursal" />
      </Card>
    </>
  );
};
