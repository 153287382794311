import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMedicalCommissions } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatPrice } from '../../../../util/formatPrice';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { medicalComissionsReportConst } from '../../../../constants/MedicalCommissionsByBranch';

export const MedicalCommissionsByBranchReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { medicalComissionByBranch } = useSelector((state) => state.reports);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(medicalComissionsReportConst.title),
    sheet: medicalComissionsReportConst.title,
  });
  const header = medicalComissionsReportConst.header;
  let subTotal = 0;
  let isrTotal = 0;
  let totalNeto = 0;

  useEffect(() => {
    if (filtersData.dates)
      dispatch(
        getMedicalCommissions({
          dates: filtersData.dates,
          branchId: filtersData.branchId,
        })
      );
  }, [dispatch, filtersData.dates, filtersData.branchId]);

  useEffect(() => {
    if (filtersData.dates) {
      const [date1, date2] = filtersData.dates;

      setPeriod(
        `${moment(date1).format('DD/MM/YYYY')} - ${moment(date2).format(
          'DD/MM/YYYY'
        )}`
      );
    }
  }, [filtersData.dates]);

  return (
    <div>
      <ExportButton onDownload={onDownload} />

      <table style={{ margin: '0 auto' }} ref={tableRef}>
        <ReportTableHeader
          user={fullname}
          role={role}
          title={medicalComissionsReportConst.title}
          period={period}
          col1={6}
          col2={0}
          col3={10}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                colSpan={2}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>

          {medicalComissionByBranch?.map((row, key) => {
            subTotal += Number(row.subtotal);
            isrTotal += Number(row.isr);
            totalNeto += Number(row.commission);

            return (
              <tr key={key}>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {`${row?.name} ${row?.lastName} ${row?.motherLastName}`}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.subtotal)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.isr)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {formatPrice(row.commission)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={4}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>SUBTOTAL GENERAL</h4>
            </td>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(subTotal)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={4}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL DE ISR</h4>
            </td>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(isrTotal)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={4}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTAL NETO</h4>
            </td>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(totalNeto)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
