import { GET_ALL_ROLES } from '../types';

const initialState = {
  roles: [],
};

export default function Role(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
}