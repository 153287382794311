import { Button, Tag } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAppointments } from '../../../../appRedux/actions';
import { appointmentStatus } from '../../../../constants';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Appointments.const';
import { salesConst } from '../../../../constants/Sales';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { stringDate, stringTime } from '../../../../util';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { AppointmentModalBody } from '../AppoinmentModalBody';

export const AppointmentTable = () => {
  const {
    privileges: { branch: privileges = [] },
  } = useSelector((state) => state?.userAuth);

  const [title, setTitle] = useState({});
  const [children, setChildren] = useState(null);
  const { modal } = useCustomModal();

  const getModal = (appointment, action) => {
    modal();

    switch (action) {
      default:
        setTitle(salesConst.DETAIL);
        setChildren(<AppointmentModalBody appointmentId={appointment?.id} />);
    }
  };

  const getWhoScheduled = (user, row) => {
    if (!user) {
      return (
        <>{`${row?.customer?.name} ${row?.customer?.lastName} ${
          row?.customer?.motherLastNameastName ?? ''
        }`}</>
      );
    }

    return (
      <>
        {user?.name ? user?.name : '-'} {user?.lastName} {user?.motherLastName}
      </>
    );
  };

  const columns = [
    {
      title: 'Cita',
      sorter: true,
      dataIndex: 'appointmentNumber',
      key: 'appointmentNumber',
      render: (appointmentNumber, appointment) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            getModal(appointment);
          }}
        >
          {appointmentNumber}
        </Button>
      ),
    },
    {
      title: 'Dia de la cita',
      sorter: true,
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Tag color="green">{stringDate(date)} </Tag>,
    },
    {
      title: 'Hora de inicio',
      sorter: true,
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime) => <Tag color="blue"> {stringTime(startTime)} </Tag>,
    },
    {
      title: 'Agendo',
      sorter: true,
      dataIndex: 'user',
      key: 'user',
      render: (user, row) => getWhoScheduled(user, row),
      path: [
        'attendedBy.name',
        'attendedBy.lastName',
        'attendedBy.motherLastName',
      ],
    },
    {
      title: 'Atendió',
      sorter: true,
      dataIndex: 'attendedBy',
      key: 'attendedBy',
      render: (attendedBy) => (
        <>
          {attendedBy?.name ? attendedBy?.name : '-'} {attendedBy?.lastName}{' '}
          {attendedBy?.motherLastName}
        </>
      ),
      path: [
        'attendedBy.name',
        'attendedBy.lastName',
        'attendedBy.motherLastName',
      ],
    },
    {
      title: 'Metodo de cita',
      sorter: true,
      dataIndex: 'appointmentMethod',
      key: 'appointmentMethod',
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name || '-',
      path: 'branch.name',
    },
    {
      title: 'Tipo de cabina',
      sorter: true,
      dataIndex: 'cabain',
      key: 'cabain',
      render: (cabain) => cabain?.name || '-',
      path: 'cabain.name',
    },
    {
      title: 'Cliente',
      sorter: true,
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => (
        <>
          {customer?.name} {customer?.lastName} {customer?.motherLastName}
        </>
      ),
      path: ['customer.name', 'customer.lastName', 'customer.motherLastName'],
    },
    {
      title: 'Tratamiento',
      sorter: true,
      dataIndex: 'treatment',
      key: 'treatment',
      render: (treatment) => treatment?.name || '-',
      path: 'treatment.name',
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={`#${appointmentStatus[status]?.color}`}>
          {appointmentStatus[status]?.value}
        </Tag>
      ),
      path: 'status',
      formatter: (status) => appointmentStatus[status]?.value,
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Citas'}
        getDataFunction={getAppointments}
      />
      <ModalView width={800} title={title}>
        {children}
      </ModalView>
    </>
  );
};
