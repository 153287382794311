import { Card } from 'antd';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

import { CommissionComestology } from '../../components/Commissions/CommissionTableComestology';

export const CosmetologyCommission = () => {
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <CommissionComestology />
    </Card>
  );
};
