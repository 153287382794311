import { queryPathBuilder } from '../../util/queryPathBuilder';
import { GET_ALL_LOGS } from '../types';

import { get } from './Action';

const basePath = '/log';

// Get All Roles
export const getAllLogs = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getLogsDS, true));
};

const getLogsDS = (logs) => ({
  type: GET_ALL_LOGS,
  payload: logs?.items,
});
