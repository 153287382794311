import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Card, Button, DatePicker, InputNumber } from 'antd';
import {
  editPurchaseOrder,
  getPurchaseOrderById,
  createPurchaseOrder,
  getProviders,
} from '../../../../appRedux/actions';

import {
  buttonLabel,
  cardConfig,
  formConfig,
  lengthValidation,
  messages,
  placeHolders,
  modalButtons,
  orderConfirmModal,
  dateFormat,
  payStatus,
  dateFormatMx,
} from '../../../../constants';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CustomSelect, CancelButton } from '../../../uiComponents';
import { addDaysToDate, stringDate } from '../../../../util/formatTime';
import { useCallback } from 'react';
import { PurchaseOrderProducts } from '../PurchaseOrderProducts';
import './styles.css';
import { payMethods } from '../../../../constants/PurchaseOrder.conts';

export const PurchaseOrderForm = () => {
  const { id } = useParams();
  const editMode = id;
  const { purchaseOrderById } = useSelector((state) => state.purchaseOrder);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const { providers } = useSelector((state) => state.provider);
  const [providerId, setProviderId] = useState();
  const [payConditions, setPayConditions] = useState(0);
  const [arrivalDate, setArrivalDate] = useState();
  const [creditExpiration, setCreditExpiration] = useState();
  const [formDisabled, setFormDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editMode) dispatch(getPurchaseOrderById(id));
    dispatch(getProviders({ withDeleted: 'false' }));
  }, [dispatch, editMode, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response))
      navigate('/dashboard/ordenes-de-compra');
  }, [navigate, response]);

  useEffect(() => {
    if (editMode && purchaseOrderById) {
      const status = purchaseOrderById.payStatus === 'Completado' && true;
      setFormDisabled(status);
    }
  }, [purchaseOrderById, editMode]);

  useEffect(() => {
    if (purchaseOrderById && editMode) {
      purchaseOrderById.providerId = purchaseOrderById?.provider?.id;
      purchaseOrderById.arrivedAt = moment(purchaseOrderById.arrivedAt);
      purchaseOrderById.payedAt =
        purchaseOrderById.payedAt && moment(purchaseOrderById.payedAt);
      form.setFieldsValue(purchaseOrderById);
      setListItems(purchaseOrderById);
      form.setFieldsValue({ products: purchaseOrderById.purchaseOrderProduct });
    }
  }, [form, editMode, purchaseOrderById]);

  const onProviderChange = useCallback(
    (providerId) => {
      setProviderId(providerId);

      const [provider] = providers.filter(({ id }) => id === providerId);
      setPayConditions(provider?.payConditions);

      form.setFieldsValue({ products: [''] });
    },
    [providers, form]
  );

  const onArrivalDateChange = useCallback((value, dateString) => {
    const day = moment(value).format(dateFormat);
    setArrivalDate(day);
  }, []);

  const getExpirationDate = useCallback(() => {
    const date = arrivalDate ? arrivalDate : null;
    const creditExpiration = addDaysToDate(date, payConditions);
    form.setFieldsValue({ creditExpiration: stringDate(creditExpiration) });
    setCreditExpiration(creditExpiration);
  }, [arrivalDate, payConditions, form]);

  useEffect(() => {
    getExpirationDate();
  }, [onProviderChange, onArrivalDateChange, getExpirationDate]);

  const setListItems = ({ purchaseOrderProduct }) => {
    purchaseOrderProduct?.forEach((product) => {
      if (product?.expiresAt) product.expiresAt = moment(product?.expiresAt);
      product.productId = {
        value: product?.product?.id,
        label: product?.product?.name,
      };
    });
  };

  const showOrderConfirm = async (values) => {
    confirm({
      title: orderConfirmModal.title,
      icon: <ExclamationCircleOutlined />,
      content: orderConfirmModal.body,
      okText: modalButtons.ok,
      cancelText: modalButtons.cancel,
      okButtonProps: {
        loading: isLoading,
      },
      onOk() {
        setIsLoading(true);
        onSubmit(values);
      },
      onCancel() {},
    });
  };

  const onSubmit = async (values) => {
    const products = values?.products?.map((product) => ({
      ...product,
      productId: product.productId.value || product.productId,
    }));

    const newValue = {
      ...values,
      purchaseOrderProducts: products,
      creditExpiration: creditExpiration,
    };

    if (editMode) {
      setResponse(await dispatch(editPurchaseOrder(id, newValue)));
    } else {
      setResponse(await dispatch(createPurchaseOrder(newValue)));
    }
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          id="onSubmit"
          initialValues={{ products: [''] }}
          autoComplete="off"
          onFinish={showOrderConfirm}
          form={form}
          disabled={formDisabled}
        >
          <Form.Item
            {...formConfig}
            name="invoiceNumber"
            label="Número de factura"
            rules={[
              {
                required: true,
                message: messages.required('Número de factura'),
              },
              { max: 20, message: lengthValidation('Número de factura', 20) },
            ]}
          >
            <Input disabled={editMode ? true : false} />
          </Form.Item>

          {providers && (
            <Form.Item
              {...formConfig}
              name="providerId"
              label="Proveedor"
              rules={[
                { required: true, message: messages.required('Proveedor') },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.provider}
                onChange={onProviderChange}
                options={providers.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                disabled={editMode ? true : false}
              ></CustomSelect>
            </Form.Item>
          )}

          <Form.Item
            {...formConfig}
            name="payMethod"
            label="Forma de pago"
            rules={[
              { required: true, message: messages.required('Forma de pago') },
            ]}
          >
            <CustomSelect
              disabled={editMode ? true : false}
              placeholder={placeHolders.payMethod}
              options={payMethods.map(({ value, label }) => ({
                value,
                label,
              }))}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="arrivedAt"
            label="Fecha de llegada"
            rules={[
              {
                required: true,
                message: messages.required('Fecha de llegada'),
              },
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={dateFormatMx}
              value={moment('2020-08-01', dateFormat)}
              onChange={(value, value2) => onArrivalDateChange(value, value2)}
              disabled={editMode ? true : false}
            />
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="creditExpiration"
            label="Expiración del crédito"
            rules={[
              {
                required: true,
                message: messages.required('Expiración del crédito'),
              },
            ]}
          >
            <Input disabled={true} />
          </Form.Item>

          {editMode && (
            <>
              <Form.Item
                {...formConfig}
                name="payStatus"
                label="Estado de pago"
                rules={[
                  {
                    required: true,
                    message: messages.required('Estado de pago'),
                  },
                ]}
              >
                <CustomSelect
                  placeholder={placeHolders.payState}
                  options={payStatus}
                ></CustomSelect>
              </Form.Item>

              <Form.Item
                {...formConfig}
                name="payedAt"
                label="Pagado en"
                rules={[
                  { required: true, message: messages.required('Pagado en') },
                ]}
              >
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
            </>
          )}

          <Form.Item
            {...formConfig}
            name="tax"
            label="IVA"
            rules={[{ required: true, message: messages.required('IVA') }]}
          >
            <CustomSelect
              placeholder="IVA"
              options={[
                { label: '0%', value: 0 },
                { label: '8%', value: 8 },
                { label: '16%', value: 16 },
              ]}
              disabled={editMode ? true : false}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="amount"
            label="Total"
            rules={[{ required: true, message: messages.required('Total') }]}
          >
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              stringMode={true}
            />
          </Form.Item>

          <Form.Item {...formConfig} name="comments" label="Comentarios">
            <TextArea disabled={editMode} />
          </Form.Item>

          <PurchaseOrderProducts
            form={form}
            providerId={providerId}
            disabled={editMode ? true : false}
          />

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            form="onSubmit"
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/ordenes-de-compra" />
      </Card>
    </>
  );
};
