import {
  CREATE_LOCK,
  GET_LOCKS,
  GET_LOCK_BY_ID,
  UPDATE_LOCK,
  GET_LOCK_DELETE,
  DELETE_LOCK,
} from '../types';
import { post, put, get, remove } from './Action';

const basePath = '/lock';

// Create Lock
export const createLock = (lock) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, lock, createLockDS));
};

// Get Locks
export const getLocks = (branchId) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${branchId}`, getLocksDS));
};

// Get Lock By Id
export const getLockById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getLockByIdDS));
};

// Update Lock
export const updateLock = (id, lock) => async (dispatch) => {
  return await dispatch(put(`${basePath}/${id}/update`, lock, updateLockDS));
};

// Delete Lock
export const deleteLock = (id) => async (dispatch) => {
  dispatch(lockDeleteDS(id));
  return await dispatch(remove(`${basePath}/${id}/delete`, deleteLockDS));
};

const createLockDS = (lock) => ({
  type: CREATE_LOCK,
  payload: lock,
});

const getLocksDS = (locks) => ({
  type: GET_LOCKS,
  payload: locks,
});

const getLockByIdDS = (lock) => ({
  type: GET_LOCK_BY_ID,
  payload: lock,
});

const updateLockDS = (lock) => ({
  type: UPDATE_LOCK,
  payload: lock,
});

export const lockDeleteDS = (id) => ({
  type: GET_LOCK_DELETE,
  payload: id,
});

const deleteLockDS = () => ({
  type: DELETE_LOCK,
});
