import { Card } from 'antd';
import { ProviderForm } from '../../components/Providers/ProviderForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Provider = () => {
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <ProviderForm />
    </Card>
  );
};
