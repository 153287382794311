import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../../appRedux/actions/UserAuth';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../constants';
import { PasswordValidatorForm } from '../PasswordValidatorForm';

export const ChangePasswordModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();

  useEffect(() => {
    if ([200, 201].includes(response)) {
      closeModal();
    }
  }, [response, dispatch, closeModal]);

  const onSubmit = async (values) => {
    setResponse(await dispatch(changePassword(values)));
  };
  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form onFinish={onSubmit} form={form} {...formConfig}>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: messages.required('Contraseña actual'),
              },
              { message: messages.validation.password },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={placeHolders.currentPassword}
            />
          </Form.Item>

          <PasswordValidatorForm />

          <Button type="primary" htmlType="submit" className="btn-form">
            {buttonLabel.resetPassword}
          </Button>
        </Form>
      </Card>
    </>
  );
};
