import { GET_COLORS } from '../types';

import { get } from './Action';

const basePath = '/color';

// Get Colors
export const getColors = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getColorsDS));
};
const getColorsDS = (colors) => ({
  type: GET_COLORS,
  payload: colors,
});
