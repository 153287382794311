import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tag } from 'antd';
import { getSingleCustomers } from '../../../../appRedux/actions';
import { customerConst } from '../../../../constants/Customer.const';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { phoneMask } from '../../../../util';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { LoyaltyModal } from './LoyaltyModal';
import {
  searchFilters,
  filters,
  searchInputInitialValues,
} from '../../../../constants/MembersConst';
export const LoyaltyTable = () => {
  const {
    privileges: { loyalty_system: privileges = [] },
  } = useSelector((state) => state.userAuth);

  const { modal } = useCustomModal();
  const [customerId, setCustomerId] = useState();

  const showModal = (customerId) => {
    setCustomerId(customerId);
    modal();
  };

  const columns = [
    {
      title: 'ID Miembro',
      sorter: true,
      dataIndex: 'customerId',
      key: 'customerId',
      render: (id, customer) => (
        <Button
          type="link"
          htmlType="button"
          onClick={() => showModal(customer.id)}
        >
          {id}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => name?.toUpperCase(),
    },
    {
      title: 'Apellido Paterno',
      sorter: true,
      dataIndex: 'lastName',
      key: 'lastName',
      render: (lastName) => lastName?.toUpperCase(),
    },
    {
      title: 'Apellido Materno',
      sorter: true,
      dataIndex: 'motherLastName',
      key: 'motherLastName',
      render: (motherLastName) =>
        motherLastName?.toUpperCase() ? motherLastName?.toUpperCase() : '-',
    },
    {
      title: 'Puntos',
      sorter: true,
      dataIndex: 'points',
      key: 'points',
      render: (points) => <Tag>{points}</Tag>,
    },
    {
      title: 'Correo',
      sorter: true,
      dataIndex: 'email',
      key: 'email',
      render: (email) => (email ? email : '-'),
    },
    {
      title: 'Género',
      sorter: true,
      dataIndex: 'gender',
      key: 'gender',
      render: (gender) => gender?.toUpperCase(),
    },
    {
      title: 'Celular',
      sorter: true,
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (mobilePhone) => phoneMask(mobilePhone),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        blackListParam={true}
      />
      <ListTable
        columns={columns}
        name={customerConst.loyalty}
        getDataFunction={getSingleCustomers}
      />
      <ModalView title={customerConst.memberTittle} width={700}>
        <LoyaltyModal customerId={customerId} />
      </ModalView>
    </>
  );
};
