import {
  CREATE_PACKAGE,
  GET_PACKAGES,
  GET_PACKAGE_BY_ID,
  UPDATE_PACKAGE,
  GET_PACKAGE_DELETE,
  DELETE_PACKAGE,
} from '../types';

const initialState = {
  packages: [],
  packageById: {},
  packageDelete: null,
};

export default function Package(state = initialState, action) {
  switch (action.type) {
    case CREATE_PACKAGE:
      return {
        ...state,
        packages: [...state.packages, action.payload],
      };
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case GET_PACKAGE_BY_ID:
      return {
        ...state,
        packageById: action.payload,
      };
    case UPDATE_PACKAGE:
      return {
        ...state,
        packages: state.packages.map((pack) =>
          pack.id === action.payload.id ? (pack = action.payload) : pack
        ),
      };
    case GET_PACKAGE_DELETE:
      return {
        ...state,
        packageDelete: action.payload,
      };
    case DELETE_PACKAGE:
      return {
        ...state,
        packages: state.packages.filter(
          (pack) => pack.id !== state.packageDelete
        ),
      };
    default:
      return state;
  }
}
