import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import getOptions from './config';
import { THEME_TYPE_LITE } from '../../../../constants/ThemeSetting';
import { useState } from 'react';

export const MainMenu = () => {
  // Opciones que deberian de venir en las propiedades:
  /**
   *  {
   *    "items" <-- elementos del menu ( pueden tener submenus utilizando la propiedad "children")
   *    "mode" <-- para definir como se va a desplegar
   *    "style" <-- para definir que clase de css usara.
   *  }
   */
  const { themeType } = useSelector(({ settings }) => settings);
  const { privileges } = useSelector((state) => state.userAuth);
  const options = getOptions(privileges);
  const [current, setCurrent] = useState('dashboard');

  const onClick = (e) => {
    setCurrent(e.key);
  }
  
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      className={options.style}
      mode={options.mode}
      items={options.items}
      theme={themeType === THEME_TYPE_LITE ? 'light' : 'dark'}
      style={{ backgroundColor: '#009bdf', color: '#fff' }}
    />
  );
};
