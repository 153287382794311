import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getProfitabilityByBranch } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import {
  monthsArray,
  profitabilityByBranchReportConst,
  profitabilityConcepts,
  profitabilityTypes,
} from '../../../../constants';

export const ProfitabilityByBranchReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { profitabilityByBranch } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const header = profitabilityByBranchReportConst.header;
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(profitabilityByBranchReportConst.title),
    sheet: profitabilityByBranchReportConst.title,
  });

  let total = 0;
  let monthsTotal = 0;
  let conceptTotal = 0;
  let expensesTotal = 0;
  let incomeTotal = 0;

  useEffect(() => {
    if (filtersData.year && filtersData.branchId && filtersData.type)
      dispatch(
        getProfitabilityByBranch({
          year: filtersData.year,
          branchId: filtersData.branchId,
          type: filtersData.type,
        })
      );
  }, [dispatch, filtersData.year, filtersData.branchId, filtersData.type]);

  useEffect(() => {
    if (filtersData.year)
      setPeriod(`01/01/${filtersData.year} - 31/12/${filtersData.year}`);
  }, [filtersData.year]);

  const getTotalByMonth = (month) => {
    for (const row of profitabilityByBranch) {
      for (const key of Object.keys(row)) {
        if (key === month && row.type === 'expenses') {
          expensesTotal += Number(row[month]);
        } else if (key === month && row.type === 'income') {
          incomeTotal += Number(row[month]);
        }
        monthsTotal = incomeTotal - expensesTotal;
      }
    }
    total += monthsTotal;
  };

  return (
    <div>
      <ExportButton onDownload={onDownload} />

      <table style={{ display: 'block', overflow: 'scroll' }} ref={tableRef}>
        <ReportTableHeader
          user={fullname}
          role={role}
          title={profitabilityByBranchReportConst.title}
          period={period}
          branch={profitabilityByBranch[0]?.branch}
          col1={12}
          col2={0}
          col3={16}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {profitabilityByBranch?.map((row, key) => {
            conceptTotal = 0;

            return (
              <tr key={key}>
                {row.type === 'expenses' && (
                  <>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {profitabilityConcepts[row.concept]}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                        backgroundColor: '#D0312D',
                        color: '#FFFFFF',
                      }}
                    >
                      {profitabilityTypes[row.type]}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.branch}
                    </td>
                    {monthsArray?.map((month, key) => {
                      conceptTotal += Number(row[month]);

                      return (
                        <td
                          key={key}
                          style={{
                            border: '1px solid black',
                            textAlign: 'center',
                            padding: '15px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatPrice(row[month])}
                        </td>
                      );
                    })}

                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(conceptTotal)}
                    </td>
                  </>
                )}
                {row.type === 'income' && (
                  <>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {profitabilityConcepts[row.concept]}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                        backgroundColor: '#4CAF4F',
                        color: '#FFFFFF',
                      }}
                    >
                      {profitabilityTypes[row.type]}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.branch}
                    </td>
                    {monthsArray?.map((month, key) => {
                      conceptTotal += Number(row[month]);

                      return (
                        <td
                          key={key}
                          style={{
                            border: '1px solid black',
                            textAlign: 'center',
                            padding: '15px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatPrice(row[month])}
                        </td>
                      );
                    })}

                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(conceptTotal)}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={3}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#D0312D',
                color: '#FFFFFF',
                padding: '15px',
              }}
            >
              TOTAL MENSUAL
            </td>
            {monthsArray?.map((month, key) => {
              monthsTotal = 0;
              expensesTotal = 0;
              incomeTotal = 0;
              getTotalByMonth(month);

              return (
                <td
                  key={key}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    backgroundColor: '#E6E9EB',
                  }}
                >
                  {formatPrice(
                    monthsTotal < 0 ? monthsTotal * -1 : monthsTotal
                  )}
                </td>
              );
            })}
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#E6E9EB',
              }}
            >
              {formatPrice(total < 0 ? total * -1 : total)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
