import { AUTH_QUICK_LOGIN } from '../types';
import { post } from './Action';

const basePath = '/auth';

export function quickLogin(quickLogin) {
  return async (dispatch) => {
    return await dispatch(
      post(`${basePath}/quick-login`, quickLogin, quickLoginDS)
    );
  };
}

// Data save DB
const quickLoginDS = (data, refresh) => ({
  type: AUTH_QUICK_LOGIN,
  payload: {
    user_id: data.userId,
    token: data.payload.token,
    refresh_token: refresh,
    refresh_token_exp: null,
    token_exp: null,
    quick_login: true,
  },
});
