import { EditOutlined } from '@ant-design/icons';

export const appointmentStatus = {
  absence: {
    value: 'Inasistencia',
    alias: 'absence',
    color: '8D8D8D',
  },
  finished: {
    value: 'Terminada',
    alias: 'finished',
    color: '319799',
  },
  inProcess: {
    value: 'En Proceso',
    alias: 'inProcess',
    color: 'EE734E',
  },
  scheduled: {
    value: 'Agendada',
    alias: 'scheduled',
    color: '7557D4',
  },
  cancelled: {
    value: 'Cancelada',
    alias: 'cancelled',
    color: 'FF0000',
  },
};

export const appointmentStatusCalendar = {
  absence: {
    value: 'Inasistencia',
    alias: 'absence',
    color: '8D8D8D',
  },
  finished: {
    value: 'Terminada',
    alias: 'finished',
    color: '319799',
  },
  inProcess: {
    value: 'En Proceso',
    alias: 'inProcess',
    color: 'EE734E',
  },
  scheduled: {
    value: 'Agendada',
    alias: 'scheduled',
    color: '7557D4',
  },
};

export const appointmentStatusConst = [
  { value: appointmentStatus.absence.alias, label: 'Inasistencia' },
  { value: appointmentStatus.finished.alias, label: 'Terminada' },
  { value: appointmentStatus.inProcess.alias, label: 'En proceso' },
  { value: appointmentStatus.scheduled.alias, label: 'Agendada' },
];

export const appointmentConst = {
  DOCTOR: 'Médico',
  COSMETOLOGIST: 'Cosmetóloga',
  VALUATION: 'Valoración',
  ALERT: {
    TITLE: 'ATENCIÓN',
    DESCRIPTION:
      'Este cliente tiene 3 citas canceladas consecutivamente, agendarle solamente tratamientos pagados',
  },
  MISSED_APPOINTMENTS_ALERT: {
    TITLE: 'ATENCIÓN',
    DESCRIPTION:
      'Este tratamiento ha sido reagendado 2 veces y ya no puede puede modificarse',
    OK_BUTTON: 'Entendido',
  },
  CANCEL_ALERT: {
    TITLE: 'ATENCIÓN',
    DESCRIPTION:
      'Esta sera la tercera cancelacion o cambio para este tratamiento, al continuar se dara por terminado',
  },
  PURCHASED_PRODUCT_ALERT: {
    TITLE: 'ATENCIÓN',
    DESCRIPTION: 'Tratamiento no pagado',
  },
};

export const confirmMethodsConst = [
  { value: 'si', label: 'Si' },
  { value: 'no', label: 'No' },
  { value: 'whatsapp', label: 'Whatsapp' },
  { value: 'buzón', label: 'Buzón' },
];

export const cancellationReasonConst = [
  { value: 'Traffic', label: 'Tráfico' },
  { value: 'Desease', label: 'Enfermedad' },
  { value: 'Work', label: 'Trabajo' },
  { value: 'Unspecified', label: 'Sin especificar' },
  { value: 'Other', label: 'Otro' },
];

export const searchInputInitialValues = {
  search: { filter: 'appointment_number' },
};

export const searchFilters = [
  { value: 'appointment_number', label: 'Cita' },
  { value: 'attendedBy.name', label: 'Atendió' },
  { value: 'customer.name', label: 'Cliente' },
  { value: 'treatment.name', label: 'Tratamiento' },
  { value: 'user.name', label: 'Agendo' },
];

export const filters = {
  appointmentStatus: true,
  search: true,
  date: true,
  branches: true,
  dates: true,
  cabains: true,
};

export const missedAppointmentConst = {
  TYPES: {
    CANCELLATION: 'Cancellation',
    RESCHEDULE: 'Reschedule',
  },
  REASON_DICTIONARY: {
    Traffic: 'Tráfico',
    Desease: 'Enfermedad',
    Work: 'Trabajo',
    Unspecified: 'Sin especificar',
    Other: 'Otro',
  },
};

export const appointmentsTableMenuOpt = [
  {
    label: 'Médico',
    key: 'edit',
    icon: <EditOutlined />,
    privilege: 'edit',
  },
];
