import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import {
  deleteError,
  deleteSuccess,
} from '../../../appRedux/actions/AlertManager';

export const AlertManager = () => {
  const { error, success } = useSelector((state) => state.alertManager);
  const dispatch = useDispatch();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message,
      description: '',
      maxCount: 1,
    });
  };

  if (error) {
    openNotificationWithIcon(
      'error',
      error?.statusText ? error?.statusText : 'Error en el Servidor'
    );
    dispatch(deleteError());
  }
  if (success) {
    openNotificationWithIcon(
      'success',
      success || 'Operación exitosa'
    );
    dispatch(deleteSuccess());
  }

  return <></>;
};
