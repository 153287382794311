export const packageConst = {
  promotion: 'Promotion',
  package: 'Package',
};

export const searchFilters = [
  { value: 'package.sku', label: 'SKU' },
  { value: 'package.name', label: 'Nombre' },
];

export const searchInputInitialValues = {
  search: { filter: 'package.sku' },
  status: '1',
};

export const filters = {
  search: true,
  status: true,
  branches: true,
  treatmentType: true,
  categories: true,
  payMethods: true,
  // dates: true,
  treatmentCategory: true,
};

export const treatmentType = {
  valoration: 'Valoración',
};
