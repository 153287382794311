import { GET_MISSED_APPOINTMENTS, CLEAR_MISSED_APPOINTMENTS } from '../types';
import { get } from './Action';

const basePath = '/missed-appointment';

// Get missed appointments
export const getMissedAppointments = (id) => async (dispatch) => {
  return await dispatch(
    get(`${basePath}/${id}/purchased-product`, getMissedAppointmentsDS)
  );
};

// Clear missed appointments
export const clearMissedAppointments = () => (dispatch) => {
  return dispatch(clearMissedAppointmentsDS());
};

const getMissedAppointmentsDS = (appoinments) => ({
  type: GET_MISSED_APPOINTMENTS,
  payload: appoinments,
});

const clearMissedAppointmentsDS = () => ({
  type: CLEAR_MISSED_APPOINTMENTS,
});
