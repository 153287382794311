import { Button, Card, Form, Input } from 'antd';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../../constants';
import TextArea from 'antd/lib/input/TextArea';
import { CustomSelect } from '../../../uiComponents';
import { refundMethod } from '../../../../constants/Sales';
import { LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getSales, refundSale } from '../../../../appRedux/actions';
import { useEffect, useState } from 'react';
import { useCustomModal } from '../../../../hooks/useCustomModal';

export const SalesRefundModalBody = ({ data = {} }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();
  const { closeModal } = useCustomModal();
  const { filtersData } = useSelector((state) => state?.generalStates);

  useEffect(() => {
    if ([200, 201].includes(response)) {
      dispatch(getSales(filtersData));
      closeModal();
    }
  }, [closeModal, response]);

  const onSubmit = async (values) => {
    values.branchId = data?.branch?.id;
    setResponse(await dispatch(refundSale(data?.orderNumber, values)));
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form {...formConfig} autoComplete="off" onFinish={onSubmit} form={form}>
        <Form.Item
          label="Razón de devolución"
          name="refundReason"
          rules={[
            {
              required: true,
              message: messages.required('Razón de devolución'),
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Ingresa la razón por la cual se realiza la devolución"
          />
        </Form.Item>
        <Form.Item
          label="Método de devolución"
          name="refundMethod"
          rules={[
            {
              required: true,
              message: messages.required('Método de devolución'),
            },
          ]}
        >
          <CustomSelect
            placeholder={placeHolders.refundMethod}
            options={refundMethod}
          ></CustomSelect>
        </Form.Item>

        <Form.Item
          label="Contraseña actual"
          name="password"
          rules={[
            {
              required: true,
              message: messages.required('Contraseña actual'),
            },
            { message: messages.validation.password },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={placeHolders.currentPassword}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" className="btn-form">
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
