import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteSubsidiary,
  getPaginatedBranches,
} from '../../../../appRedux/actions';
import {
  ListTable,
  ModalView,
  TableMenuOpt,
  RenderStatus,
  TableFilters,
} from '../../../uiComponents';
import { phoneMask } from '../../../../util';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { SubsidiaryModal } from '../SubsidiaryModal';
import { Button } from 'antd';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Branch.const';
import { label } from '../../../../constants';

export const SubsidiaryTable = ({ privileges }) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();

  const onModalClick = (branch) => {
    modal();
    setModalData(branch);
  };

  const onDelete = (id) => {
    dispatch(deleteSubsidiary(id));
  };

  const columns = [
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name, branch) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            onModalClick(branch);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Teléfono',
      sorter: true,
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => phoneMask(phone),
    },
    {
      title: 'Código de sucursal',
      sorter: true,
      dataIndex: 'code',
      key: 'code',
      render: (code) => code,
    },
    {
      title: '¿Es franquicia?',
      sorter: true,
      dataIndex: 'franchise',
      key: 'franchise',
      render: (franchise) => (franchise ? 'Si' : 'No'),
      path: 'franchise',
      formatter: (franchise) => (franchise ? 'Si' : 'No'),
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'active',
      key: 'active',
      render: (status) => <RenderStatus data={{ status }} />,
      path: 'active',
      formatter: (value) => (value ? label.activeText : label.inactiveText),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: ({ distributionCenter }, { id }) => (
        <TableMenuOpt
          id={id}
          path="sucursales"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          privileges={privileges}
          distributionCenter={distributionCenter}
        />
      ),
    },
  ];
  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ModalView title={'Sucursales'}>
        <SubsidiaryModal data={modalData} />
      </ModalView>
      <ListTable
        columns={columns}
        name={'Sucursales'}
        getDataFunction={getPaginatedBranches}
      />
    </>
  );
};
