import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tag } from 'antd';
import {
  deletePurchaseOrder,
  getPurchaseOrders,
} from '../../../../appRedux/actions';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { PurchaseOrderModalBody } from '../PurchaseOrderModalBody';
import {
  filters,
  purchaseOrderConst,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/PurchaseOrder.conts';
import { PurchaseOrderTableOptions } from '../PurchaseOrderTableMenuOptions';
import { formatPrice } from '../../../../util/formatPrice';
import { stringDate } from '../../../../util/formatTime';

export const PurchaseOrderTable = ({ privileges }) => {
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();
  const { purchaseOrders } = useSelector((state) => state.purchaseOrder);

  const getModalData = useCallback(() => {
    const [modalData] = purchaseOrders?.filter(
      (cabainType) => cabainType.id === catalogId
    );
    return modalData;
  }, [purchaseOrders, catalogId]);

  useEffect(() => {
    setModalTitle(
      `${purchaseOrderConst.header}: ${purchaseOrderConst.prefix}${
        getModalData()?.orderNumber
      }`
    );
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deletePurchaseOrder(id));
  };

  const columns = [
    {
      title: 'Número de orden',
      sorter: true,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (number, { id }) => (
        <Button
          type="link"
          htmlType="button"
          onClick={() => {
            setModal(id);
          }}
        >
          {purchaseOrderConst.prefix + number}
        </Button>
      ),
    },
    {
      title: 'Número de factura',
      sorter: true,
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      render: (name) => name,
    },
    {
      title: 'Proveedor',
      sorter: true,
      dataIndex: 'provider',
      key: 'provider',
      render: (provider) => provider?.name,
      path: 'provider.name',
    },
    {
      title: 'Método de pago',
      sorter: true,
      dataIndex: 'payMethod',
      key: 'payMethod',
    },
    {
      title: 'Total',
      sorter: true,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => formatPrice(amount),
    },
    {
      title: 'Estado de pago',
      sorter: true,
      dataIndex: 'payStatus',
      key: 'payStatus',
      render: (payStatus, items) =>
        !items.deleted_at ? (
          <Tag color={payStatus === 'Pendiente' ? 'yellow' : 'green'}>
            {payStatus}
          </Tag>
        ) : (
          <Tag color="red">Eliminado</Tag>
        ),
      path: 'payStatus',
    },
    {
      title: 'Fecha de pago',
      sorter: true,
      dataIndex: 'payedAt',
      key: 'payedAt',
      render: (paid) => (paid ? stringDate(paid) : '-'),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id, payedAt, deleted_at }) => (
        <PurchaseOrderTableOptions
          id={id}
          path="ordenes-de-compra"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          privileges={privileges}
          payed={payedAt}
          deletedAt={deleted_at}
        />
      ),
    },
  ];
  return (
    <>
      <TableFilters
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Ordenes de compra'}
        getDataFunction={getPurchaseOrders}
      />
      <ModalView title={modalTitle} width={900}>
        <PurchaseOrderModalBody data={modalData} />
      </ModalView>
    </>
  );
};
