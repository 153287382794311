
import { Card } from "antd";
import { MembersTable } from "../../components/Members/MembersTable";
import { TitleBreadcrumbs } from "../../uiComponents/TitleBreadcrumbs";

export const Members = () => {


  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className='gx-card'>
      <MembersTable />
    </Card>
  );
};