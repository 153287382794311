import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENT_BY_ID,
  CLEAR_APPOINTMENT_BY_ID,
  UPDATE_APPOINTMENT,
  CANCEL_APPOINTMENT,
  SET_ON_PROCESS,
  CONFIRM_APPOINTMENT,
  SET_BRANCH,
  FINISH_APPOINTMENT,
  GET_APPOINTMENT_BY_CUSTOMER,
} from '../types';
import { post, put, get, patch } from './Action';

const basePath = '/appointment';

// CREATE appoinment
export const createAppointment = (appointment) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/create`, appointment, createAppointmentDS)
  );
};

//GET Appointments
export const getAppointments = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getAppointmentsDS, true)
  );
};

// Get Appointment By Id
export const getAppointmentById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getAppointmentByIdDS));
};

//Get Appointmen By  Customer
export const getAppointmentByCustomer = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}/get-by-customer${queryParams}`, getAppointmentByCustomerDS)
  );
};

// Clear  Appointment By Id
export const clearAppointmentById = () => (dispatch) => {
  return dispatch(clearAppointmentByIdDS());
};

// Update Appointment
export const updateAppointment = (id, appointment) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, appointment, updateAppointmentDS)
  );
};

// Delete Appointment
export const cancelAppointment = (id, data) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/${id}/cancel`, data, cancelAppointmentDS)
  );
};

// Appointment on process
export const setOnProcess = (id, data) => async (dispatch) => {
  return await dispatch(
    patch(`${basePath}/${id}/set-on-process`, data, setOnProcessDS)
  );
};

// Finish appointment
export const finishAppointment = (id, data) => async (dispatch) => {
  return await dispatch(
    patch(`${basePath}/${id}/finish`, data, finishAppointmentDS)
  );
};

// Confirm appointment
export const confirmAppointment = (id, confirmMethod) => async (dispatch) => {
  return await dispatch(
    patch(`${basePath}/${id}/confirm`, confirmMethod, confirmAppointmentDS)
  );
};

export function setSelectedBranchId(state) {
  return (dispatch) => {
    dispatch(setSelectedBranchIdDS(state));
  };
}

const createAppointmentDS = (appointment) => ({
  type: CREATE_APPOINTMENT,
  payload: appointment,
});

const getAppointmentsDS = (appointments) => ({
  type: GET_APPOINTMENTS,
  payload: appointments.items,
});

const getAppointmentByIdDS = (appointment) => ({
  type: GET_APPOINTMENT_BY_ID,
  payload: appointment,
});

const getAppointmentByCustomerDS = (appointment) => ({
  type: GET_APPOINTMENT_BY_CUSTOMER,
  payload: appointment,
});

const clearAppointmentByIdDS = () => ({
  type: CLEAR_APPOINTMENT_BY_ID,
});

const updateAppointmentDS = (appointment) => ({
  type: UPDATE_APPOINTMENT,
  payload: appointment,
});

const cancelAppointmentDS = (appointment) => ({
  type: CANCEL_APPOINTMENT,
  payload: appointment,
});

const setOnProcessDS = (appoinment) => ({
  type: SET_ON_PROCESS,
  payload: appoinment,
});

const confirmAppointmentDS = (data) => ({
  type: CONFIRM_APPOINTMENT,
  payload: data,
});

const setSelectedBranchIdDS = (id) => ({
  type: SET_BRANCH,
  payload: { branch: id },
});

const finishAppointmentDS = (appoinment) => ({
  type: FINISH_APPOINTMENT,
  payload: appoinment,
});
