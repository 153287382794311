import { COLLAPSED_SIDER, DISABLED_ELEMENT, MODAL_VIEW } from "../types";


export function collapsedSidebar(state) {
    return (dispatch) => {
        dispatch(onCollapsedSider(state));
    }
}

export function modalViewAction(state) {
    return (dispatch) => {
        dispatch(viewModalAction(state))
    }
}

export function disabledElementAction(state) {
    return (dispatch) => {
        dispatch(disabledElmentState(state));
    }
}

// Open & Close Sidebar
const onCollapsedSider = (state) => ({
    type: COLLAPSED_SIDER,
    payload: state
});

// Modal View
const viewModalAction = (state) => ({
    type: MODAL_VIEW,
    payload: state
})

// Disabled element
const disabledElmentState = (state) => ({
    type: DISABLED_ELEMENT,
    payload: state
})

