import {
  CREATE_CABAIN_TYPE,
  DELETE_CABAIN_TYPE,
  GET_CABAIN_TYPES,
  GET_CABAIN_TYPE_BY_ID,
  UPDATE_CABAIN_TYPE,
  DATA_DELETED,
} from '../types';

import { post, get, put, remove } from './Action';

const basePath = '/cabain-type';

// Create CabainType
export const createCabainType = (cabainType) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/create`, cabainType, createCabainTypeDS)
  );
};

// Get CabainTypes
export const getCabainTypes = () => async (dispatch) => {
  return await dispatch(get(`${basePath}`, getCabainTypesDS, true));
};

// Get CabainType By Id
export const getCabainTypeById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getCabainTypeByIdDS));
};

// Edit CabainType
export const editCabainType = (id, cabainType) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, cabainType, editCabainTypeDS)
  );
};

// Delete CabainType
export const deleteCabainType = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteCabainTypeDS)
  );
  dispatch(getCabainTypeDeleteDS(id));
  return response;
};

const createCabainTypeDS = (cabainType) => ({
  type: CREATE_CABAIN_TYPE,
  payload: cabainType,
});

const getCabainTypesDS = (cabainTypes) => ({
  type: GET_CABAIN_TYPES,
  payload: cabainTypes.items,
});

const getCabainTypeByIdDS = (cabainType) => ({
  type: GET_CABAIN_TYPE_BY_ID,
  payload: cabainType,
});

const editCabainTypeDS = (cabainType) => ({
  type: UPDATE_CABAIN_TYPE,
  payload: cabainType,
});

export const getCabainTypeDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteCabainTypeDS = () => ({
  type: DELETE_CABAIN_TYPE,
});
