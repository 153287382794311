import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY_BY_ID,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  DATA_DELETED,
} from '../types';

import { post, get, put, remove } from './Action';

const basePath = '/category';

// Create New Category
export const createCategory = (category) => async (dispatch) => {
  return await dispatch(post(`${basePath}/create`, category, createCategoryDS));
};

// Get Categories
export const getCategories = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}${queryParams}`, getCategoriesDS, true)
  );
};

// Get Category By Id
export const getCategoryById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getCategoryByIdDS));
};

// Edit Category
export const updateCategory = (id, category) => async (dispatch) => {
  return await dispatch(
    put(`${basePath}/${id}/update`, category, updateCategoryDS)
  );
};

// Delete Category
export const deleteCategory = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteCategoryDS)
  );
  dispatch(getCategoryDeleteDS(id));
  return response;
};

const createCategoryDS = (category) => ({
  type: CREATE_CATEGORY,
  payload: category,
});

const getCategoriesDS = (categories) => ({
  type: GET_CATEGORIES,
  payload: categories.items,
});

const getCategoryByIdDS = (category) => ({
  type: GET_CATEGORY_BY_ID,
  payload: category,
});

const updateCategoryDS = (category) => ({
  type: UPDATE_CATEGORY,
  payload: category,
});

const getCategoryDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteCategoryDS = () => ({
  type: DELETE_CATEGORY,
});
