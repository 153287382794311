import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalViewAction } from '../appRedux/actions/Ui';

export const useCustomModal = (initialValue = null) => {
  const [catalogId, setCatalogId] = useState(initialValue);
  const [branchId, setBranchId] = useState(initialValue);
  const [productId, setProductIdId] = useState(initialValue);
  const [showModal, setShowModal] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) {
      dispatch(modalViewAction(showModal));
    }
  }, [dispatch, showModal]);

  const setModal = (id) => {
    setCatalogId(id);
    dispatch(modalViewAction(showModal));
  };

  const setModalByIds = (branchId, productId) => {
    setBranchId(branchId);
    setProductIdId(productId);
    dispatch(modalViewAction(showModal));
  };

  const modal = () => {
    dispatch(modalViewAction(showModal));
  };

  const closeModal = () => {
    dispatch(modalViewAction(false));
  };

  const resetModal = () => {
    setCatalogId(null);
  };

  return {
    catalogId,
    resetModal,
    setModal,
    showModal,
    setShowModal,
    modal,
    branchId,
    productId,
    setModalByIds,
    closeModal,
  };
};
