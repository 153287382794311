import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_DELETE,
  SAVE_IMAGE_PRODUCT_ID,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCTS,
  RESET_PRODUCT_BY_ID,
} from '../types';

const initialState = {
  products: [],
  productById: {},
  productDelete: null,
  productImage: {},
  productImageId: {},
};

export default function Product(state = initialState, action) {
  switch (action.type) {
    case CREATE_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        productById: action.payload,
      };
    case RESET_PRODUCT_BY_ID:
      return {
        ...state,
        productById: {},
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id
            ? (product = action.payload)
            : product
        ),
      };
    case GET_PRODUCT_DELETE:
      return {
        ...state,
        productDelete: action.payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== state.productDelete
        ),
      };
    case SAVE_IMAGE_PRODUCT_ID:
      let photoId = Object.assign(state.productImageId, { ...action.payload });
      return {
        ...state,
        productImageId: photoId,
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}
