export const roleConst = {
  logisticManager: 'Logística Gerente',
  branchManager: 'Sucursal Gerente',
  superAdmin: 'Super Administrador',
  medic: 'Médico',
  cosmetologist: 'Cosmetóloga',
  franchisee: 'Franquiciatario',
  opertationalManager: 'Gerente Operativo',
};

export const roleAliasConst = {
  doctor: 'doctor',
  cosmetologist: 'cosmetologist',
};

export const fullNavigationRoles = [
  roleConst.logisticManager,
  roleConst.superAdmin,
];

export const appointmentRoles = {
  Médico: roleConst.medic,
  Cosmetológico: roleConst.cosmetologist,
};

export const agendaAllAccessRoles = [roleConst.superAdmin];
