import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralExpenses } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { getDaysInMonth } from '../../../../util/getDaysInMonth';
import { generalExpensesReportConst } from '../../../../constants';

export const GeneralExpensesReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { generalExpenses } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [period, setPeriod] = useState();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(generalExpensesReportConst.title),
    sheet: generalExpensesReportConst.title,
  });
  const header = generalExpensesReportConst.header;
  let fullTotalAssortment = 0;
  let fullTotalTransfersReceived = 0;
  let fullTotalTransfersSent = 0;
  let fullTotalReturns = 0;
  let fullTotalPeriod = 0;

  useEffect(() => {
    if (filtersData.month)
      dispatch(getGeneralExpenses({ month: filtersData.month }));
  }, [dispatch, filtersData.month]);

  useEffect(() => {
    if (filtersData.month) {
      const [year, month] = filtersData.month.split('-');
      const daysInMonth = getDaysInMonth(year, month);
      setPeriod(`01/${month}/${year} - ${daysInMonth}/${month}/${year}`);
    }
  }, [filtersData.month]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          margin: '0 auto',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={generalExpensesReportConst.title}
          period={period}
          col1={8}
          col2={0}
          col3={12}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                colSpan={2}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {generalExpenses?.map((row, key) => {
            fullTotalAssortment += Number(row.totalAssortments);
            fullTotalTransfersReceived += Number(row.totalTransfersReceived);
            fullTotalTransfersSent += Number(row.totalTransfersSent);
            fullTotalReturns += Number(row.totalReturns);

            const periodTotal =
              Number(row.totalAssortments) +
              Number(row.totalTransfersReceived) -
              Number(row.totalTransfersSent) -
              Number(row.totalReturns);

            fullTotalPeriod += periodTotal;

            return (
              <tr key={key}>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row.branch}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalAssortments)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalTransfersReceived)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalTransfersSent)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalReturns)}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(periodTotal)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>TOTALES</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(fullTotalAssortment)}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(fullTotalTransfersReceived)}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(fullTotalTransfersSent)}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(fullTotalReturns)}
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {formatPrice(fullTotalPeriod)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
