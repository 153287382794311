import { SwapOutlined, ToolOutlined } from '@ant-design/icons';

export const stockConst = {
  EXPIRATION: {
    DANGER: 'row-danger',
    WARNING: 'row-warning',
    ALERT: 'row-alert',
    OKAY: 'row-okay',
  },
};

export const filters = {
  status: true,
  categories: true,
  branches: true,
  search: true,
  dates: true,
  productsCategories: true,
};

export const searchFilters = [
  { value: 'product.sku', label: 'SKU' },
  { value: 'product.name', label: 'Producto' },
  { value: 'product.brand', label: 'Marca' },
  { value: 'stock.expires_in', label: 'Fecha de vencimiento' },
];

export const searchInputInitialValues = {
  search: { filter: 'product.name' },
  status: '1',
};

export const stockAdjustmentTypeConst = [
  { value: 'drop', label: 'Baja' },
  { value: 'registration', label: 'Registro' },
];

export const stocktableOptConst = [
  {
    label: 'Ajuste',
    key: 'adjustment',
    privileges: 'edit',
    icon: <SwapOutlined />,
  },
  {
    label: 'Max/Min',
    key: 'max/min',
    privileges: 'approve',
    icon: <ToolOutlined />,
  },
];
