import { Tag } from 'antd';
import moment from 'moment';
import { timeFormat12, timeFormatComplete } from '../../../../constants';
import { lockConst } from '../../../../constants/Lock.const';
import { stringDate } from '../../../../util';

export const LockTime = ({ data }) => {
  let component = null;
  switch (data.type) {
    case lockConst.hour:
      component = data?.time?.map((time) => (
        <Tag key={data?.id + time}>{`${moment(time, [
          timeFormatComplete,
        ]).format(timeFormat12)}`}</Tag>
      ));
      break;
    case lockConst?.cabain:
      component = '-';
      break;
    case lockConst?.day:
      component = '-';
      break;
    case lockConst?.hoursRange:
      component = data?.time?.map((time) => (
        <Tag key={data?.id + time}>{`${moment(time, [
          timeFormatComplete,
        ]).format(timeFormat12)}`}</Tag>
      ));
      break;
    case lockConst?.daysRange:
      component = data?.time?.map((time) => (
        <Tag key={data?.id + time}>{stringDate(time)}</Tag>
      ));
      break;
    default:
      break;
  }

  return <>{component}</>;
};
