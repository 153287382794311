import { Card } from 'antd';
import { ProductForm } from '../../components/Products/ProductForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { useSelector } from 'react-redux';

export const Product = () => {
  const {
    privileges: { products },
  } = useSelector((state) => state.userAuth);
  return (
    <Card bordered={true} title={<TitleBreadcrumbs />} className='gx-card'>
      <ProductForm privileges={products} />
    </Card>
  );
};
