import { Col, Row, Table, Tag } from 'antd';
import { stringDate } from '../../../../util/formatTime';
import { formatPrice } from '../../../../util/formatPrice';

export const PurchaseOrderModalBody = ({ data = {} }) => {
  const { purchaseOrderProduct } = data;
  const columns = [
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product?.name,
    },
    {
      title: 'SKU',
      dataIndex: 'product',
      key: 'sku',
      render: (product) => product?.sku,
    },
    {
      title: 'Lote',
      dataIndex: 'allotment',
      key: 'allotment',
      render: (allotment) => allotment || '-',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (price) => formatPrice(price),
    },
    {
      title: 'Expiración',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      render: (expires) => stringDate(expires),
    },
  ];

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Número de factura</h4>
        </Col>
        <Col span={16}>{data?.invoiceNumber || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Proveedor</h4>
        </Col>
        <Col span={16}>{data?.provider?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>IVA</h4>
        </Col>
        <Col span={16}>{data?.tax || '-'}%</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Total</h4>
        </Col>
        <Col span={16}>{formatPrice(data?.amount) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Condiciones de pago</h4>
        </Col>
        <Col span={16}>{`${data?.provider?.payConditions} dias` || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Forma de pago</h4>
        </Col>
        <Col span={16}>{data?.payMethod || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Estado del pago</h4>
        </Col>
        <Col span={16}>
          {' '}
          <Tag color={data?.payStatus === 'Pendiente' ? 'yellow' : 'green'}>
            {data?.payStatus}
          </Tag>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Fecha de llegada</h4>
        </Col>
        <Col span={16}>{stringDate(data?.arrivedAt) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Expiración del crédito</h4>
        </Col>
        <Col span={16}>{stringDate(data?.creditExpiration) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Pagado en </h4>
        </Col>
        <Col span={16}>{stringDate(data?.payedAt) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Fecha de surtido</h4>
        </Col>
        <Col span={16}>{stringDate(data?.assortmentedAt) || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Comentarios</h4>
        </Col>
        <Col span={16}>{data?.comments || '-'}</Col>
      </Row>

      <br />

      <Table
        dataSource={purchaseOrderProduct}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 40, 60, 80],
        }}
      />
    </>
  );
};
