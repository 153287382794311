import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SettingsTable } from '../../components/Settings/SettingsTable';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Settings = () => {
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          navigate('/dashboard/configuraciones/editar');
        }}
      >
        <PlusOutlined />
        Editar
      </Button>
      <SettingsTable />
    </Card>
  );
};
