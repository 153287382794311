import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getScheduledAppointments } from '../../../../appRedux/actions/Reports';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { generalShcedulAppointmentsConst } from '../../../../constants';

export const GeneralShcedulAppointments = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { generalScheduledAppointments } = useSelector(
    (state) => state.reports
  );
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [branches, setBranches] = useState([]);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(generalShcedulAppointmentsConst.title),
    sheet: generalShcedulAppointmentsConst.title,
  });
  let total = 0;
  let branchTotal = 0;
  let totalAppointments = 0;

  useEffect(() => {
    if (filtersData.dates)
      dispatch(getScheduledAppointments({ dates: filtersData.dates }));
  }, [dispatch, filtersData.dates]);

  useEffect(() => {
    if (generalScheduledAppointments.length) {
      const branches = Object.keys(generalScheduledAppointments[0])
        .filter((item) => !['roleName', 'user'].includes(item))
        .sort();
      setBranches(branches);
    }
  }, [generalScheduledAppointments]);

  const getTotals = (branch) => {
    total += Number(branch);
  };

  const getTotalByBranch = (branch) => {
    for (const row of generalScheduledAppointments) {
      for (const item of Object.keys(row)) {
        if (item === branch) {
          branchTotal += Number(row[branch]);
        }
      }
    }
    totalAppointments += branchTotal;
  };

  return (
    <div>
      <ExportButton onDownload={onDownload} />

      <table ref={tableRef} style={{ overflow: 'scroll', display: 'block' }}>
        <ReportTableHeader
          user={fullname}
          role={role}
          title={generalShcedulAppointmentsConst.title}
          col1={26}
          col2={0}
          col3={30}
        />
        <tbody>
          <tr>
            <th
              colSpan={2}
              style={{ border: '1px solid black', padding: '15px' }}
            >
              PERSONAL
            </th>
            <th
              colSpan={2}
              style={{ border: '1px solid black', padding: '15px' }}
            >
              ROL
            </th>
            {branches?.map((item, key) => (
              <th
                key={key}
                colSpan={2}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item.toUpperCase()}
              </th>
            ))}
            <th style={{ border: '1px solid black' }}>TOTALES</th>
          </tr>
          {generalScheduledAppointments?.map((row, key) => {
            total = 0;
            branchTotal = 0;

            return (
              <tr key={key}>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.user}
                </td>
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '15px',
                  }}
                >
                  {row.roleName}
                </td>
                {branches?.map((branch, key) => {
                  getTotals(row[branch]);
                  return (
                    <td
                      key={key}
                      colSpan={2}
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                      }}
                    >
                      {row[branch]}
                    </td>
                  );
                })}
                <td
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {total}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={4}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              <h4>TOTALES</h4>
            </td>
            {branches?.map((branch, key) => {
              branchTotal = 0;
              getTotalByBranch(branch);

              return (
                <td
                  key={key}
                  colSpan={2}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: '#e6e9eb',
                    padding: '5px',
                  }}
                >
                  {branchTotal}
                </td>
              );
            })}
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '5px',
              }}
            >
              {totalAppointments}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
