import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAppliance, getAppliances } from '../../../../appRedux/actions';
import {
  ListTable,
  ModalView,
  RenderStatus,
  TableFilters,
  TableMenuOpt,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { ApplianceModalBody } from '../ApplianceModalBody';
import { Button } from 'antd';
import {
  applianceConst,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Appliance.const';
import { label } from '../../../../constants';

export const AppliancesTable = () => {
  const {
    privileges: { appliances: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();

  const onModalClick = (appliance) => {
    modal();
    setModalData(appliance);
  };

  const onDelete = (id) => {
    dispatch(deleteAppliance(id));
  };

  const columns = [
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name, appliance) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            onModalClick(appliance);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Descripción',
      sorter: true,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Marca',
      sorter: true,
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Modelo',
      sorter: true,
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Tipo',
      sorter: true,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (_, data) => <RenderStatus data={data} />,
      path: 'status',
      formatter: (value) => (value ? label.activeText : label.inactiveText),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          path="aparatos"
          privileges={privileges}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Aparatos'}
        getDataFunction={getAppliances}
      />
      <ModalView title={applianceConst.APPLIANCE}>
        <ApplianceModalBody data={modalData} />
      </ModalView>
    </>
  );
};
