import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, InputNumber, Row, Col } from 'antd';
import {
  formConfig,
  messages,
  oneColumnFormConfig,
  placeHolders,
  productStockColumnsFormConfig,
  sessionColumnFormConfig,
  threeColumnsFormConfig,
} from '../../../../constants/Forms';
import { CustomSelect, NumericInput } from '../../../uiComponents';
import { getTreatments } from '../../../../appRedux/actions';

export const TreatmentPackage = ({
  form = [],
  isPackage = false,
  treatmentTypeId,
  updatePrice = null,
}) => {
  const dispatch = useDispatch();
  const { treatments } = useSelector((state) => state.treatment);
  const [treatDictionary, setTreatDictionary] = useState([]);
  const itemConfig = isPackage
    ? productStockColumnsFormConfig
    : oneColumnFormConfig;
  const [newIndex, setNewIndex] = useState();
  const [newTreatment, setNewTreatment] = useState();

  useEffect(() => {
    if (treatmentTypeId) dispatch(getTreatments({ treatmentTypeId }));
  }, [dispatch, treatmentTypeId]);

  useEffect(() => {
    setTreatDictionary(
      treatments.reduce((obj, item) => ({ ...obj, [item.id]: item.areas }), {})
    );
  }, [treatments]);

  useEffect(() => {
    const formItems = form.getFieldValue('treatmentPackage');

    const selectedTreatment = treatments?.find(
      (value) => value?.id === newTreatment?.value
    );

    if (formItems[newIndex]) {
      formItems[newIndex].areasIds = undefined;
      formItems[newIndex].regularPrice =
        newIndex >= 0 && newTreatment ? selectedTreatment?.price : 0;
    }

    form.setFieldsValue({
      treatments: formItems,
    });
    if (updatePrice) updatePrice();
  }, [newIndex, newTreatment, form, treatments, updatePrice]);

  return (
    <>
      <Form.List {...formConfig} name="treatmentPackage">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => {
              const id =
                form.getFieldValue('treatmentPackage')[index]?.treatmentId
                  ?.value;
              return (
                <div key={Math.random()}>
                  <hr className="divider" />
                  <Row wrap>
                    <Col span={fields.length > 1 ? 23 : 24}>
                      {treatments && (
                        <Form.Item
                          {...oneColumnFormConfig}
                          label="Tratamiento"
                          name={[field.name, 'treatmentId']}
                          rules={[
                            {
                              required: true,
                              message: messages.required('Tratamiento'),
                            },
                          ]}
                        >
                          <CustomSelect
                            placeholder={placeHolders.treatment}
                            onChange={(treatment) => {
                              setNewIndex(field.name);
                              setNewTreatment(treatment);
                            }}
                            labelInValue={true}
                            options={treatments.map(({ id, name, sku }) => ({
                              value: id,
                              label: `${sku} - ${name}`,
                            }))}
                          ></CustomSelect>
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={1}>
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          className="danger-button-product"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row wrap>
                    <Col span={isPackage ? 20 : 24}>
                      <Form.Item
                        {...itemConfig}
                        label="Areas"
                        name={[field.name, 'areasIds']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Areas'),
                          },
                        ]}
                      >
                        <CustomSelect
                          mode="multiple"
                          placeholder={placeHolders.areas}
                          labelInValue={true}
                          options={
                            treatDictionary[id]?.map(({ id, name }) => {
                              return {
                                value: id,
                                label: name,
                              };
                            }) || []
                          }
                        ></CustomSelect>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      {isPackage && (
                        <Form.Item
                          {...sessionColumnFormConfig}
                          label="Sesiones"
                          name={[field.name, 'sessions']}
                          rules={[
                            {
                              required: true,
                              message: messages.required('Sesiones'),
                            },
                          ]}
                        >
                          <NumericInput
                            onBlur={() => {
                              updatePrice();
                            }}
                            min={1}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row wrap>
                    <Col span={8}>
                      <Form.Item
                        {...threeColumnsFormConfig}
                        label="Límite"
                        name={[field.name, 'limit']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Límite'),
                          },
                        ]}
                      >
                        <NumericInput min={1} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        {...threeColumnsFormConfig}
                        label="Precio regular"
                        name={[field.name, 'regularPrice']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Precio regular'),
                          },
                        ]}
                      >
                        <InputNumber
                          disabled
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          stringMode={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...threeColumnsFormConfig}
                        label="Precio"
                        name={[field.name, 'promotionPrice']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Precio'),
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          stringMode={true}
                          onBlur={() => {
                            updatePrice();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col span={12}></Col>
                </div>
              );
            })}

            <Button
              className="add-btn"
              onClick={() => {
                add();
              }}
              icon={<PlusOutlined />}
            >
              Agregar tratamiento
            </Button>
          </div>
        )}
      </Form.List>
    </>
  );
};
