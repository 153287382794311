import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { CouponTable } from '../../components/Coupon/CouponTable';

export const Coupons = () => {
  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          navigate('/dashboard/cupones/crear');
        }}
      >
        <PlusOutlined />
        Nuevo
      </Button>
      <CouponTable />
    </Card>
  );
};
