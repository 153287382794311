import React from 'react';
import { Layout } from 'antd';
import { QuickLoginForm } from '../../components/QuickLoginForm';
import { Loading } from '../../components/Loading';

export const QuickLogin = () => {
  // const { token } = useSelector(state => state.userAuth);

  return (
    <>
      <Loading />
      <Layout>
        <div className="bg-login">
          <QuickLoginForm />
        </div>
      </Layout>
    </>
  );
};
