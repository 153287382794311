import { GET_ALL_LOGS } from '../types';

const initialState = {
  logs: [],
};

export default function Log(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    default:
      return state;
  }
}
