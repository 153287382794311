import { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch } from 'react-redux';
import { createNote } from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
  noteType,
} from '../../../../constants';
import { UploadPhotos } from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { validatePhotos } from '../../../../util';

export const NoteModalBody = ({ customer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList] = useState([]);
  const { setShowModal } = useCustomModal();
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const onChangePhotos = (photos) => {};

  useEffect(() => {
    if ([200, 201].includes(response)) {
      setShowModal(false);
    }
  }, [response, dispatch, setShowModal]);

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    setResponse(
      await dispatch(
        createNote({
          ...formValues,
        })
      )
    );
    setIsLoading(false);
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form autoComplete="off" onFinish={onSubmit} form={form} {...formConfig}>
        <Form.Item
          name="noteType"
          label="Tipo de nota"
          rules={[
            {
              required: true,
              message: messages.required('Tipo de nota'),
            },
          ]}
        >
          <Select
            placeholder={placeHolders.noteType}
            options={noteType}
          ></Select>
        </Form.Item>

        <Form.Item
          name="title"
          label="Titulo"
          rules={[
            {
              required: true,
              message: messages.required('Titulo'),
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          name="note"
          label="Nota"
          rules={[
            {
              required: true,
              message: messages.required('Nota'),
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item initialValue={customer} hidden={true} name="customerId">
          <Input />
        </Form.Item>

        <Form.Item
          {...formConfig}
          label="Foto"
          name="photos"
          rules={[
            () => ({
              validator(_, value) {
                return validatePhotos(_, value);
              },
            }),
          ]}
        >
          <UploadPhotos onChange={onChangePhotos} limit={1} files={fileList} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-form"
          loading={isLoading}
        >
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
