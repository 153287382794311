import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  NumericInput,
  CustomSelect,
  UploadPhotos,
  CancelButton,
} from '../../../uiComponents';
import {
  Button,
  Form,
  Input,
  Select,
  Card,
  Switch,
  TimePicker,
  InputNumber,
} from 'antd';
import {
  createTreatment,
  updateTreatment,
  getTreatmentById,
  getAppliances,
  getAreas,
  getTreatmentTypes,
  getAllConsents,
  getCategories,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../../constants/Forms';
import { TreatmentProducts } from '../TreatmentProducts';
import DefaultImage from '../../../../assets/images/ICONO-AZUL-100x100.png';
import { appliances as applianceType } from '../../../../constants/Appliance.const';
import './styles.css';
import TextArea from 'antd/lib/input/TextArea';
import { setTimeFormat, validatePhotos } from '../../../../util';
import { timeFormat } from '../../../../constants';

export const TreatmentForm = () => {
  const { id } = useParams();
  const { treatmentById } = useSelector((state) => state.treatment);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { treatmentTypes } = useSelector((state) => state.treatmentType);
  const { appliances } = useSelector((state) => state.appliances);
  const { areas } = useSelector((state) => state.area);
  const { consents } = useSelector((state) => state.consent);
  const [response, setResponse] = useState();
  const [term, setTerm] = useState();
  const [assessment, setAssessment] = useState(false);
  const [consent, setConsent] = useState(false);
  const [active, setActive] = useState(true);
  const [treatmentType, setTreatmentType] = useState('Médico');
  const [treatmentTypeId, setTreatmentTypeId] = useState(null);
  const [treatmentAreas, setTreatmentsAreas] = useState(null);
  const [fileList, setFileList] = useState([]);
  const { categories } = useSelector((state) => state.category);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getTreatmentById(id));
    }
    dispatch(getTreatmentTypes());
    dispatch(getAllConsents());
    dispatch(getAppliances({ applianceType, active: '1' }));
    dispatch(getCategories({ type: 'treatment' }));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      navigate('/dashboard/tratamientos');
    }
  }, [navigate, response]);

  useEffect(() => {
    if (treatmentTypeId) {
      form.resetFields(['areasIds']);
      dispatch(getAreas({ treatmentTypeId }));
    }
  }, [dispatch, treatmentTypeId, form]);

  useEffect(() => {
    if (areas) setTreatmentsAreas(areas);
  }, [areas]);

  useEffect(() => {
    if (id && treatmentById) {
      setAssessment(treatmentById?.assessment);
      setConsent(treatmentById?.consent === 0 ? false : true);
      setActive(treatmentById?.active);
      setTerm(treatmentById?.term);
      setTreatmentTypeId(treatmentById?.treatmentType?.id);
      setTreatmentType(treatmentById?.treatmentType?.name);

      setFileList([
        {
          uid: treatmentById?.photo?.id,
          name: treatmentById?.photo?.name,
          status: 'done',
          url: treatmentById?.photo
            ? `${process.env.REACT_APP_API}/v1/photo/thumbnail/${treatmentById?.photo?.name}`
            : DefaultImage,
        },
      ]);
    }
  }, [treatmentById, id]);

  useEffect(() => {
    if (treatmentById && id) {
      treatmentById.applianceIds = treatmentById?.appliance?.map(
        (appliance) => appliance.id
      );

      setTerm(treatmentById?.term);

      if (treatmentAreas)
        treatmentById.areasIds = treatmentById?.areas?.map((area) => area?.id);

      form.setFieldsValue({
        ...treatmentById,
        categories: treatmentById?.categories?.map((category) => category?.id),
        treatmentTypeId: treatmentById?.treatmentType?.id,
        consentId: treatmentById?.treatmentConsent?.id,
        term: setTimeFormat(treatmentById?.term),
      });
      setListItems(treatmentById);
      form.setFieldsValue({ products: treatmentById.treatmentProduct });
    }
  }, [form, treatmentById, id, treatmentAreas]);

  const onChangePhotos = (photos) => {};

  const setListItems = ({ treatmentProduct }) => {
    treatmentProduct?.forEach((row) => {
      row.productId = { value: row?.product?.id, label: row?.product?.name };
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true)
    const { assessment, consent, active } = values;
    let products = [];

    if (values?.products?.length > 0) {
      products = values?.products?.map((product) => ({
        ...product,
        productId: product.productId.value || product.productId,
      }));
    }

    const newValues = {
      ...values,
      term: term,
      applianceIds:
        values?.applianceIds?.length > 0 ? values?.applianceIds : null,
      assessment: assessment ? 1 : 0,
      consent: consent ? 1 : 0,
      active: id ? (active ? 1 : 0) : 1,
      productsIds: products?.length > 0 ? products : null,
    };

    if (id) {
      setResponse(await dispatch(updateTreatment(id, newValues)));
    } else {
      setResponse(await dispatch(createTreatment(newValues)));
    }
    setIsLoading(false)
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form id="onSubmit" autoComplete="off" onFinish={onSubmit} form={form}>
          <Form.Item
            {...formConfig}
            label="Foto"
            rules={[
              {
                required: id ? false : true,
                message: messages.required('Foto'),
              },
              () => ({
                validator(_, value) {
                  return validatePhotos(_, value);
                },
              }),
            ]}
            name="photos"
          >
            <UploadPhotos
              onChange={onChangePhotos}
              limit={1}
              files={fileList}
            />
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="sku"
            label="SKU"
            rules={[
              { required: true, message: messages.required('SKU') },
              { min: 4, message: messages.validation.sku },
              { max: 10, message: messages.validation.sku },
            ]}
          >
            <Input disabled={id ? true : false} />
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="name"
            label="Nombre"
            rules={[
              { required: true, message: messages.required('Nombre') },
              { max: 80, message: messages.lenght },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="treatmentTypeId"
            label="Tipos de tratamiento"
            rules={[
              {
                required: true,
                message: messages.required('Tipos de tratamiento'),
              },
            ]}
          >
            <Select
              placeholder={placeHolders.treatmentType}
              allowClear
              onChange={(value, { label }) => {
                setTreatmentTypeId(value);
                setTreatmentType(label);
              }}
              options={treatmentTypes.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            ></Select>
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="description"
            label="Descripción"
            rules={[
              { required: true, message: messages.required('Descripción') },
            ]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="categories"
            label="Categorías"
            rules={[
              {
                required: true,
                message: messages.required('Categorías '),
              },
            ]}
          >
            <CustomSelect
              mode="multiple"
              placeholder={placeHolders.categories}
              allowClear
              options={categories.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            ></CustomSelect>
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="price"
            label="Precio"
            rules={[{ required: true, message: messages.required('Precio') }]}
          >
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              stringMode={true}
            />
          </Form.Item>

          {treatmentType === 'Médico' && (
            <Form.Item
              {...formConfig}
              name="medicalFee"
              label="Comisión"
              rules={[
                {
                  required: true,
                  message: messages.required('Comisión'),
                },
              ]}
            >
              <NumericInput />
            </Form.Item>
          )}

          <Form.Item
            {...formConfig}
            name="points"
            label="Puntos"
            rules={[{ required: true, message: messages.required('Puntos') }]}
          >
            <NumericInput style={{ width: '100%' }} />
          </Form.Item>
          {treatmentType !== 'Valoración' && areas && (
            <Form.Item
              {...formConfig}
              name="areasIds"
              label="Areas"
              rules={[{ required: true, message: messages.required('Areas') }]}
            >
              <CustomSelect
                mode="multiple"
                placeholder={placeHolders.aplication}
                allowClear
                options={treatmentAreas?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          <Form.Item
            {...formConfig}
            name="consent"
            label="Requiere consentimiento"
          >
            <Switch
              checked={consent}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={setConsent}
            />
          </Form.Item>

          {consent && (
            <Form.Item
              {...formConfig}
              name="consentId"
              label="Consentimientos"
              rules={[
                {
                  required: true,
                  message: messages.required('Consentimiento'),
                },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.consent}
                allowClear
                options={consents.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}
          {treatmentType !== 'Valoración' && (
            <Form.Item {...formConfig} name="assesment" label="Valoración">
              <Switch
                checked={assessment}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setAssessment}
              />
            </Form.Item>
          )}
          {id && (
            <Form.Item {...formConfig} name="active" label="Activo">
              <Switch
                checked={active}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setActive}
              />
            </Form.Item>
          )}

          <Form.Item
            {...formConfig}
            name="term"
            label="Duración"
            rules={[
              {
                required: true,
                message: messages.required('Duración'),
              },
            ]}
          >
            <TimePicker
              format={timeFormat}
              minuteStep={30}
              onChange={(_, timeString) => setTerm(timeString)}
            />
          </Form.Item>

          {treatmentType !== 'Valoración' && appliances && (
            <Form.Item {...formConfig} name="applianceIds" label="Aparatos">
              <CustomSelect
                mode="multiple"
                allowClear
                options={appliances.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                placeholder={placeHolders.appliances}
              ></CustomSelect>
            </Form.Item>
          )}

          {treatmentType !== 'Valoración' && <TreatmentProducts form={form} />}

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            form="onSubmit"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/tratamientos" />
      </Card>
    </>
  );
};
