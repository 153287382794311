import { Layout } from 'antd';
import Auxiliary from 'util/Auxiliary';
import UserProfile from './UserProfile';
import './styles.css';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import { useSelector } from 'react-redux';

const { Header } = Layout;

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const width = useSelector(({ common }) => common.width);
  const { branch } = useSelector((state) => state.userAuth);

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width) ? (
        <div className="gx-linebar gx-mr-3">
          {/* <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          /> */}
        </div>
      ) : null}
      <h4> {branch.toUpperCase()}</h4>

      <ul className="gx-header-notifications gx-ml-auto">
        <UserProfile />
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-notify"></li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default Topbar;
