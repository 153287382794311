export const branchStockReportConst = {
  title: 'REPORTE DE INVENTARIO',
  header: [
    'SKU',
    'CATEGORIA',
    'PRODUCTO',
    'STOCK',
    'LOTE',
    'CADUCIDAD',
    'PRECIO UNITARIO',
    'SUB TOTAL',
    'CHECK',
  ],
};

export const branchExpensesReportConst = {
  title: 'REPORTE DE GASTO DE PRODUCTO POR SUCURSAL',
  header: [
    '#',
    'CADUCIDAD',
    'LOTE',
    'SKU',
    'PRODUCTO',
    'CANTIDAD',
    'COSTO UNITARIO',
    'COSTO TOTAL',
    'CHECK',
  ],
};

export const generalInventoryReportConst = {
  title: 'REPORTE GENERAL DE INVENTARIOS',
  header: ['#', 'SUCURSAL', 'MONTO TOTAL EN STOCK'],
};

export const generalExpensesReportConst = {
  title: 'REPORTE GENERAL DE GASTO DE PRODUCTO GENERAL',
  header: [
    'SUCURSAL',
    'RESURTIDOS +',
    'TRANSFERENCIAS RECIBIDAS +',
    'TRANSFERENCIAS ENVIADAS -',
    'DEVOLUCIONES A C.O -',
    'GASTO DEL PERIODO',
  ],
};

export const profitabilityByBranchReportConst = {
  title: 'REPORTE DE RENTABILIDAD POR SUCURSAL',
  header: [
    'CONCEPTO',
    'TIPO',
    'SUCURSAL',
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
    'TOTAL ANUAL',
  ],
};

export const occupancyPercentageReportByBranchConst = {
  title: 'REPORTE DE PORCENTAJE DE OCUPACIÓN POR SUCURSAL',
  header: [
    'SUCURSALES',
    'SI',
    'NO',
    'BUZON',
    'WAHTSAPP',
    'TOTAL',
    '%CONFIRMADAS',
  ],
};

export const generalCustomerReportConst = {
  title: 'REPORTE GENERAL DE CLIENTES',
  header: [
    '#',
    'ID DE CLIENTE',
    'SUCURSAL DE REGISTRO',
    'FECHA DE REGISTRO',
    'FECHA DE NACIMIENTO',
    'EDAD',
    'NOMBRE',
    'APELLIDO PATERNO',
    'APELLIDO MATERNO',
    'PUNTOS',
    'TELEFONO',
    'CELULAR',
    'CORREO ELECTRONICO',
    'GÉNERO',
    'ESTADO CIVIL',
    'NACIONALIDAD',
    'LISTA NEGRA',
    'STATUS',
  ],
};

export const generalAppointmentReportConst = {
  title: 'REPORTE GENERAL DE CITAS AGENDADAS',
  header: [
    'SUCURSALES',
    'PRESENCIAL',
    'LLAMADA',
    'REDES SOCIALES',
    'APP',
    'ECOMMERCE',
    'TOTAL OPERACION',
    'TOTAL SUCURSAL',
  ],
};

export const generalCosmetologicFeesReportConst = {
  title: 'REPORTE GENERAL DE COMISIONES COSMETOLOGICAS POR COSMETOLOGA',
  header: ['COSMETOLOGA', 'PORCENTAJE DE COMISION', 'COMISION GENERADA'],
};

export const generalShcedulAppointmentsConst = {
  title: 'REPORTE DE CITAS AGENDADAS POR PERSONAL',
};

export const individualCommissionMedicalReportConst = {
  title: 'REPORTE INDIVIDUAL DE COMISIONES MEDICAS DETALLADO',
  header: [
    'ORDEN',
    'SUCURSAL',
    'FECHA',
    'HORA',
    'PACIENTE',
    'TRATAMIENTO',
    'COMISION',
  ],
};

export const cosmetologicFeesByCosmetologistReportConst = {
  title: 'REPORTE INDIVIDUAL DESGLOSADO DE COMISIONES COSMETOLOGICAS',
  header: [
    'ORDEN',
    'SUCURSAL',
    'FECHA',
    'HORA',
    'PACIENTE',
    'PRODUCTO',
    'COMISION',
    'TOTAL DE VENTA',
  ],
};

export const dailySalesReportConst = {
  title: 'REPORTE DE VENTAS DIARIAS POR SUCURSAL/GENERALES',
  header: [
    'SUCURSAL',
    'MES',
    'FECHA',
    'RESPONSABLE',
    'MXN TX',
    'DLS TX',
    'DLS A PESOS',
    'TOTAL EFECTIVO TX',
    'PAGOS EN LINEA',
    'PAGOS EN APP',
    'TDC',
    'TDD',
    'TOTAL DE CORTE DE TX',
    'MXN PRODUCTO',
    'DLS PRODUCTO',
    'DLS A PESOS',
    'TOTAL CORTE DE PRODUCTO',
    'CORTE TOTAL',
    'TIPO DE CAMBIO',
  ],
};

export const monthlySalesConst = {
  title: 'Reporte de ventas mensuales',
  header: [
    'SUCURSAL',
    'MES',
    'MXN TX',
    'DLS TX',
    'TOTAL EFECTIVO TX',
    'PAGOS EN LINEA',
    'PAGOS EN APP',
    'TDC',
    'TDD',
    'TOTAL DE CORTE DE TX',
    'MXN PRODUCTO',
    'DLS PRODUCTO',
    'TOTAL DE CORTE DE PRODUCTO',
    'CORTE TOTAL',
  ],
};

export const dailyCashClosingReportConst = {
  title: 'REPORTE DE CORTES DIARIOS POR SUCURSAL/GENERALES',
  header: [
    'SUCURSAL',
    'MES',
    'FECHA',
    'RESPONSABLE',
    'MXN TX',
    'DLS TX',
    'DLS A PESOS',
    'TOTAL EFECTIVO TX',
    'PAGOS EN LINEA',
    'PAGOS EN APP',
    'TDC',
    'TDD',
    'TOTAL DE CORTE DE TX',
    'MXN PRODUCTO',
    'DLS PRODUCTO',
    'DLS A PESOS',
    'TOTAL CORTE DE PRODUCTO',
    'CORTE TOTAL',
    'TIPO DE CAMBIO',
  ],
};

export const monthDictionay = {
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre',
};

export const monthsArray = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const profitabilityConcepts = {
  assortment: 'RESURTIDO',
  received: 'RECEPCIÓN',
  acquisition: 'ADQUISICIÓN DE MERCANCÍA (C.O)',
  isr: 'ISR',
  medicalCommission: 'COMISIÓN MÉDICA',
  cosmetologyCommissions: 'COMISIÓN COSMETOLOGICA',
  consignment: 'PRODUCTO EN CONSIGNACIÓN',
  returns: 'DEVOLUCIÓN A C.O',
  shipping: 'ENVÍOS',
  individualSales: 'VENTA A PARTICULAR (C.O)',
  tpv: 'TPV',
  cash: 'EFECTIVO',
  onlinePay: 'PAGOS EN LÍNEA',
  appPay: 'PAGO EN APP',
};

export const profitabilityTypes = {
  expenses: 'EGRESO',
  income: 'INGRESO',
};

export const reportLabel = {
  generalInventory: 'Reporte de inventario General',
  branchInventory: 'Reporte de inventario',
  productConsumption: 'Reporte de gastos de producto',
  productConsumptionBranch: 'Reporte de gastos de producto por sucursal',
  generalCustomer: 'Reporte general de clientes',
  generalAppointment: 'Reporte general de citas',
  occupancyPercentageByBranch:
    'Reporte de porcentaje de ocupacion por sucursal',
  generalCosmetologicFees:
    'Reporte general de comisiones cosmetologicas por cosmetologa',
  cosmetologicFeesByCosmetologist:
    'Reporte de comisiones cosmetologicas por cosmetologa',
  medicalCommissionsByBranchReport:
    'Reporte general de comisiones medicas por sucursal',
  generalShcedulAppointments: 'Reporte de citas agendadas por personal',
  individualCommissionMedical:
    'Reporte individual de comisiones medicas detallado',
  dailySalesReport: 'Reporte de ventas diarias por sucursal/generales',
  monthlySales: 'Reporte de ventas mensuales',
  dailyCashClosingReport: 'Reporte de cortes diarios por sucursal/generales',
  profitabilityReport: 'Reporte de rentabilidad',
};

export const reportOptions = [
  {
    value: reportLabel.profitabilityReport,
    label: reportLabel.profitabilityReport,
  },
  {
    value: reportLabel.productConsumptionBranch,
    label: reportLabel.productConsumptionBranch,
  },
  {
    value: reportLabel.productConsumption,
    label: reportLabel.productConsumption,
  },
  { value: reportLabel.generalInventory, label: reportLabel.generalInventory },
  { value: reportLabel.branchInventory, label: reportLabel.branchInventory },
  { value: reportLabel.generalCustomer, label: reportLabel.generalCustomer },
  {
    value: reportLabel.generalAppointment,
    label: reportLabel.generalAppointment,
  },
  {
    value: reportLabel.occupancyPercentageByBranch,
    label: reportLabel.occupancyPercentageByBranch,
  },
  {
    value: reportLabel.generalCosmetologicFees,
    label: reportLabel.generalCosmetologicFees,
  },
  {
    value: reportLabel.cosmetologicFeesByCosmetologist,
    label: reportLabel.cosmetologicFeesByCosmetologist,
  },
  {
    value: reportLabel.medicalCommissionsByBranchReport,
    label: reportLabel.medicalCommissionsByBranchReport,
  },
  {
    value: reportLabel.generalShcedulAppointments,
    label: reportLabel.generalShcedulAppointments,
  },
  {
    value: reportLabel.individualCommissionMedical,
    label: reportLabel.individualCommissionMedical,
  },
  {
    value: reportLabel.dailySalesReport,
    label: reportLabel.dailySalesReport,
  },
  {
    value: reportLabel.monthlySales,
    label: reportLabel.monthlySales,
  },
  {
    value: reportLabel.dailyCashClosingReport,
    label: reportLabel.dailyCashClosingReport,
  },
];

export const reportFilter = {
  dates: 'dates',
  branch: 'branch',
  medic: 'medic',
  cosmetologist: 'cosmetologist',
  month: 'month',
  year: 'year',
  profitability: 'profitability',
};

export const profitabilityOptions = [
  { value: 'All', label: 'Todos' },
  { value: 'Expenses', label: 'Egresos' },
  { value: 'Incoming', label: 'Ingresos' },
];
