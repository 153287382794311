import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { LoginForm } from '../../components/LoginForm';
import './styles.css';

export const Login = () => {
  const { token, path } = useSelector((state) => state.userAuth);
  return (
    <>
      {token ? (
        <Navigate to={`/dashboard/${path}`} />
      ) : (
        <>
          <Loading />
          <Layout>
            <div className="bg-login">
              <LoginForm />
            </div>
          </Layout>
        </>
      )}
    </>
  );
};
