import { Col, Row, Space, Table, Tag, Typography } from 'antd';
import { stringDate } from '../../../../util';
import { couponTypeDictionary } from '../../../../constants';
import { formatPrice } from '../../../../util/formatPrice';
import { RenderStatus } from '../../../uiComponents';
import './styles.css';

export const CouponModal = ({ data = {} }) => {
  const { Text } = Typography;
  const price =
    couponTypeDictionary[data?.type] === 'Tratamiento'
      ? data?.treatment?.price
      : data?.product?.salePrice;

  const finalPrice = price - price * (data?.discount / 100);

  const columns = [
    {
      title: 'Orden',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => stringDate(created_at) || '-',
    },
    {
      title: 'Cliente',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => (
        <>
          {customer?.name} {customer?.lastName} {customer?.motherLastName}
        </>
      ),
    },
    {
      title: 'Sucursal',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch.name,
    },
    {
      title: 'Gerente',
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <>
          {user?.name} {user?.lastName} {user?.motherLastName}
        </>
      ),
    },
  ];
  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Código</h4>
        </Col>
        <Col span={16}>{data?.code}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Usos</h4>
        </Col>
        <Col span={16}>
          {data?.limit}
          <Text type="danger">{` - ${data?.used} usos`}</Text>
          <Text type="success">{` = ${
            data?.limit - data?.used
          } restante`}</Text>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data?.name}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Descripción</h4>
        </Col>
        <Col span={16}>{data?.description || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Vigencia</h4>
        </Col>
        <Col span={16}>{stringDate(data?.expireDate)}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Válido en</h4>
        </Col>
        <Col span={16}>
          <Space wrap>
            {data?.branches.map((branch) => (
              <Tag key={`branch-${branch.name}`}>{`${branch.name}`}</Tag>
            ))}
          </Space>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Tipo</h4>
        </Col>
        <Col span={16}>{couponTypeDictionary[data?.type]}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Producto / Tratamiento</h4>
        </Col>
        <Col span={16}>
          {couponTypeDictionary[data?.type] === 'Tratamiento'
            ? data?.treatment?.name
            : data?.product?.name}
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Precio Regular</h4>
        </Col>
        <Col span={16}>
          {formatPrice(
            couponTypeDictionary[data?.type] === 'Tratamiento'
              ? data?.treatment?.price
              : data?.product?.salePrice
          )}
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Precio con descuento</h4>
        </Col>
        <Col span={16}>
          {formatPrice(finalPrice)}
          <Text type="success">{` (${data?.discount}%)`}</Text>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Status</h4>
        </Col>
        <Col span={16}>
          <RenderStatus data={data} />
        </Col>
      </Row>

      <Table
        pagination={{
          defaultPageSize: 3,
        }}
        dataSource={[...data?.orders, ...data?.cashOrders]}
        columns={columns}
      />
    </>
  );
};
