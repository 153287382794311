import React from 'react'
import { Button,  Card } from "antd"
import { OrderTable } from '../../../components/ProductOrder/OrderTable'
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";


export const ReorderList = () => {
 const navigate = useNavigate();
  return (
    <Card 
    title="Personal"
    bordered={true}
    className="gx-card"
    >
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          navigate("/dashboard/resurtidos/crear");
        }}
      >
        <PlusOutlined />
        Nuevo
      </Button>
     <OrderTable/>
    </Card>
  )
}
