import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { getCustomersTotalspurchase } from '../../../../appRedux/actions';
import { customerConst } from '../../../../constants/Customer.const';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { phoneMask } from '../../../../util';
import { MemberModalOrderHistory } from '../MemberModal';
import { formatPrice } from '../../../../util/formatPrice';
import moment from 'moment';
import { dateFormat } from '../../../../constants';
import {
  searchFilters,
  filters,
  dictionaryScaleConst,
  searchInputInitialValues,
} from '../../../../constants/MembersConst';

export const MembersTable = () => {
  const {
    privileges: { members: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const { modal } = useCustomModal();
  const [customerId, setCustomerId] = useState();
  const [dates, setDates] = useState({});

  useState(() => {
    const startDate = moment().subtract(365, 'days').format(dateFormat);
    const endDate = moment().format(dateFormat);
    setDates({ startDate, endDate });
  });

  const showModal = (customerId) => {
    setCustomerId(customerId);
    modal();
  };

  const columns = [
    {
      title: 'ID Miembro',
      sorter: true,
      dataIndex: 'customerId',
      key: 'customerId',
      render: (customerId, customer) => (
        <Button
          type="link"
          htmlType="button"
          onClick={() => showModal(customer.id)}
        >
          {customerId}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => name?.toUpperCase(),
    },
    {
      title: 'Apellido Paterno',
      sorter: true,
      dataIndex: 'lastName',
      key: 'lastName',
      render: (lastName) => lastName?.toUpperCase(),
    },
    {
      title: 'Apellido Materno',
      sorter: true,
      dataIndex: 'motherLastName',
      key: 'motherLastName',
      render: (motherLastName) =>
        motherLastName?.toUpperCase() ? motherLastName?.toUpperCase() : '-',
    },
    {
      title: 'Correo',
      sorter: true,
      dataIndex: 'email',
      key: 'email',
      render: (email) => (email ? email : '-'),
    },
    {
      title: 'Género',
      sorter: true,
      dataIndex: 'gender',
      key: 'gender',
      render: (gender) => gender?.toUpperCase(),
    },
    {
      title: 'Celular',
      sorter: true,
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (mobilePhone) => phoneMask(mobilePhone),
    },
    {
      title: 'Compras ',
      dataIndex: 'total',
      key: 'total',
      render: (total) => formatPrice(total),
    },
    {
      title: 'Escala',
      dataIndex: 'loyaltyScale',
      key: 'loyaltyScale',
      render: (loyaltyScale) => dictionaryScaleConst[loyaltyScale],
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        params={{ dates }}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
        blackListParam={true}
      />
      <ListTable
        columns={columns}
        name={customerConst.members}
        getDataFunction={getCustomersTotalspurchase}
      />
      <ModalView title={customerConst.members} width={700}>
        <MemberModalOrderHistory customerId={customerId} />
      </ModalView>
    </>
  );
};
