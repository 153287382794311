import { queryPathBuilder } from '../../util/queryPathBuilder';
import { GET_ALL_DIARIES } from '../types';
import { get } from './Action';

const basePath = '/diary';

//Get all diaries
export const getAllDiaries = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getDiariesDS, true));
};

const getDiariesDS = (diaries) => ({
  type: GET_ALL_DIARIES,
  payload: diaries.items,
});
