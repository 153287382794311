import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appointmentStatus } from '../../../../constants';
import { dateFormat } from '../../../../constants/TimeConst';
import { hexToRGB, stringDate } from '../../../../util';
import { StatusModalBody } from '../StatusModalBody';
import { CustomerModal } from '../../Customer/CustomerModalBody';
import { CancelAppointment } from '../CancelAppointment';
import { clearMissedAppointments } from '../../../../appRedux/actions';
import { useDispatch } from 'react-redux';

export const PopoverHeader = ({ appointment, privileges }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [child, setChild] = useState();
  const [width, setWidth] = useState();
  const [modalTittle, setModalTitle] = useState();
  const { customer } = appointment;
  const currentDateTime = `${appointment?.date} ${appointment?.endTime}`;
  const currentDate = moment().format(dateFormat);
  const isAfter = moment(currentDateTime).isAfter(moment());
  const isSame = moment(appointment?.date).isSame(currentDate);
  const { inProcess, scheduled } = appointmentStatus;
  const color = `rgb(${hexToRGB(appointmentStatus[appointment?.status].color)}`;
  const allowedStatus = [scheduled?.alias, inProcess?.alias].includes(
    appointment?.status
  );

  const onModalOpen = (value, modal) => {
    setIsModalOpen(value);

    switch (modal) {
      case 'status':
        setWidth(700);
        setModalTitle(appointment?.treatment?.name);
        setChild(
          <StatusModalBody
            setIsModalOpen={setIsModalOpen}
            appointmentId={appointment?.id}
          />
        );

        break;
      case 'history':
        setWidth(1400);
        setModalTitle('Historial de cliente');
        setChild(<CustomerModal data={customer} />);

        break;
      case 'cancel':
        setModalTitle('Cancelar cita');
        setWidth(700);
        setChild(
          <CancelAppointment
            id={appointment?.id}
            setIsModalOpen={setIsModalOpen}
            purchasedProductId={appointment?.purchasedProduct?.id}
          />
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Space
        size={isAfter && allowedStatus ? 30 : 60}
        style={{
          display: 'flex',
          marginBottom: 8,
          justifyContent: 'start',
          color: `rgb(${hexToRGB(
            appointmentStatus[appointment?.status].color
          )})`,
        }}
        align="baseline"
      >
        <span>{stringDate(appointment?.date)}</span>
        <div className="appointment buttons">
          <Button
            icon={<HistoryOutlined />}
            style={{ color }}
            type="link"
            onClick={() => {
              onModalOpen(true, 'history');
            }}
          />
          {privileges.includes('edit') && (
            <Button
              icon={<EditOutlined />}
              type="link"
              style={{
                color: `rgb(${hexToRGB(
                  appointmentStatus[appointment?.status].color
                )})`,
              }}
              onClick={() => {
                navigate(`/dashboard/agenda/${appointment?.id}`);
              }}
            />
          )}
          {allowedStatus && (
            <>
              {isSame && privileges.includes('edit') && (
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  style={{ color }}
                  onClick={() => {
                    onModalOpen(true, 'status');
                  }}
                />
              )}
              {privileges.includes('delete') && (
                <Button
                  icon={<DeleteOutlined />}
                  type="link"
                  style={{
                    color: `rgb(${hexToRGB(
                      appointmentStatus[appointment?.status].color
                    )})`,
                  }}
                  onClick={() => {
                    onModalOpen(true, 'cancel');
                  }}
                />
              )}
            </>
          )}
        </div>
      </Space>

      <Modal
        title={modalTittle}
        width={width}
        destroyOnClose={true}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(!isModalOpen)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        afterClose={() => dispatch(clearMissedAppointments())}
      >
        {child}
      </Modal>
    </>
  );
};
