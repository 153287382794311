import * as types from '../types';
import axiosInstance from '../../util/api/AxiosInstance';
import { logout } from './UserAuth';
import { hideLoading, showLoading } from './Loading';

export const post =
  (url, data, success, fullResponse = false) =>
  async (dispatch, getState) => {
    dispatch(showLoading());
    try {
      const config = getConfig(getState);
      const response = await axiosInstance.post(url, data, config);
      if (success) dispatch(success(response.data));
      if (url !== '/auth/sign-in')
        dispatch({ type: types.SET_SUCCESS, success: response?.statusText });
      dispatch(hideLoading());
      return fullResponse ? response : response?.status;
    } catch (error) {
      dispatch(processError(dispatch, error));
      dispatch(hideLoading());
    }
  };

export const get =
  (url, success, fullResponse = false) =>
  async (dispatch, getState) => {
    dispatch(showLoading());
    try {
      const response = await axiosInstance.get(url, getConfig(getState));
      if (success) dispatch(success(response.data));
      dispatch(hideLoading);
      return fullResponse ? response : response?.status;
    } catch (error) {
      dispatch(processError(dispatch, error));
      dispatch(hideLoading());
    }
  };

export const put =
  (url, data, success, fullResponse = false) =>
  async (dispatch, getState) => {
    dispatch(showLoading());
    try {
      const response = await axiosInstance.put(url, data, getConfig(getState));
      if (success) dispatch(success(response.data));
      dispatch({ type: types.SET_SUCCESS, success: response.statusText });
      dispatch(hideLoading());
      return fullResponse ? response : response.status;
    } catch (error) {
      dispatch(processError(dispatch, error));
      dispatch(hideLoading());
    }
  };

export const patch =
  (url, data, success, fullResponse = false) =>
  async (dispatch, getState) => {
    dispatch(showLoading());
    try {
      const response = await axiosInstance.patch(
        url,
        data,
        getConfig(getState)
      );
      if (success) dispatch(success(response.data));
      dispatch({ type: types.SET_SUCCESS, success: response.statusText });
      dispatch(hideLoading());
      return fullResponse ? response : response.status;
    } catch (error) {
      dispatch(processError(dispatch, error));
      dispatch(hideLoading());
    }
  };

// Using Remove, because delete is not allowed in stric_mode
export const remove = (url, success) => async (dispatch, getState) => {
  dispatch(showLoading());
  try {
    const response = await axiosInstance.delete(url, getConfig(getState));
    if (success) dispatch(success(response.data));
    dispatch({ type: types.SET_SUCCESS, success: response.statusText });
    dispatch(hideLoading());
    return response.status;
  } catch (error) {
    dispatch(processError(dispatch, error));
    dispatch(hideLoading());
  }
  dispatch(hideLoading());
};

const processError = (dispatch, error) => async () => {
  if (error.status === 401 && window.location.pathname !== '/quick-login')
    dispatch(logout());
  dispatch({ type: types.SET_ERROR, error: error });
};

const getConfig = (getState) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const { token } = getState().userAuth;
  if (token) config.headers['Authorization'] = `Bearer ${token}`;

  return config;
};
