import { Card } from 'antd';
import { AppointmentForm } from '../../components/Agenda/AppointmentForm';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';

export const Appointment = () => {
  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      <AppointmentForm />
    </Card>
  );
};
