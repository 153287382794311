import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralInventory } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { formatFileName } from '../../../../util/strings';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { generalInventoryReportConst } from '../../../../constants';

export const GeneralInventoryReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { generalInventory } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [header, setHeader] = useState([]);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(generalInventoryReportConst.title),
    sheet: generalInventoryReportConst.title,
  });

  let total = 0;

  useEffect(() => {
    let dates = [''];
    if (filtersData.dates) {
      dates = filtersData.dates;
    }
    dispatch(getGeneralInventory({ dates }));
    const header = generalInventoryReportConst.header;
    setHeader(header);
  }, [dispatch, filtersData.dates]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />
      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          margin: '0 auto',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={generalInventoryReportConst.title}
          col1={5}
          col2={0}
          col3={10}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                colSpan={3}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>
          {generalInventory?.map((row, key) => {
            total = Number(row.totalAmount) + total;

            return (
              <tr key={key}>
                <td
                  colSpan={3}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  {key + 1}
                </td>
                <td
                  colSpan={3}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {row.name}
                </td>
                <td
                  colSpan={3}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {formatPrice(row.totalAmount)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>MONTO TOTAL DE INVENTARIOS</h4>
            </td>
            <td
              colSpan={3}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(total)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
