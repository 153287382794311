import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListTable,
  ModalView,
  TableMenuOpt,
  TableFilters,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { stringDate } from '../../../../util/formatTime';
import { getUsers, deleteUser } from '../../../../appRedux/actions/User';
import { UserModal } from '../UserModal';
import {
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/User.const';
import { UserStatus } from '../UserStatus';

export const UserTable = ({ privileges = [] }) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState({});
  const { catalogId, setModal } = useCustomModal();

  const getModalData = useCallback(() => {
    const [modalData] = users?.filter((product) => product.id === catalogId);
    return modalData;
  }, [users, catalogId]);
  useEffect(() => {
    setModalTitle(
      `${getModalData()?.name} ${getModalData()?.lastName} ${
        getModalData()?.motherLastName
      }`
    );
    setModalData(getModalData());
  }, [getModalData]);

  const onDelete = (id) => {
    dispatch(deleteUser(id));
  };

  const columns = [
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'name',
      key: 'name',
      render: (name, { id, lastName, motherLastName }) => (
        <a
          href="#/"
          onClick={() => {
            setModal(id);
          }}
        >
          {`${name} ${lastName} ${motherLastName}`}
        </a>
      ),
      path: ['name', 'lastName', 'motherLastName'],
    },
    {
      title: 'Número de empleado',
      sorter: true,
      dataIndex: 'employeeNumber',
      key: 'employeeNumber',
      render: (_, record) => record.kardex?.employeeNumber,
      path: 'kardex.employeeNumber',
    },
    {
      title: 'Correo electrónico',
      sorter: true,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Sucursal Actual',
      sorter: true,
      dataIndex: 'mainBranch',
      key: 'mainBranch',
      render: (_, record) => record.kardex?.mainBranch?.name,
      path: 'kardex.mainBranch.name',
    },
    {
      title: 'Puesto/Rol',
      sorter: true,
      dataIndex: 'jobPosition',
      key: 'jobPosition',
      render: (_, record) =>
        `${record.kardex?.jobPosition?.name} / ${record.role?.role}`,
      path: ['kardex.jobPosition.name', 'role.role'],
      formatter: (values = []) => values.join(' / '),
    },
    {
      title: 'Contrato Actual',
      sorter: true,
      dataIndex: 'signs',
      key: 'signs',
      render: (_, record) => {
        const signs = record.kardex?.signatures || [];
        const lastSign = signs.reduce(
          (prev, current) => (prev.number > current.number ? prev : current),
          1
        );
        return lastSign !== 1
          ? `Contrato ${
              lastSign.number === 4 ? 'Final' : lastSign.number
            } - ${stringDate(lastSign.date)}`
          : '';
      },
      path: 'kardex.signatures',
      formatter: (value) => {
        const signs = value || [];
        const lastSign = signs.reduce(
          (prev, current) => (prev.number > current.number ? prev : current),
          1
        );
        return lastSign !== 1
          ? `Contrato ${
              lastSign.number === 4 ? 'Final' : lastSign.number
            } - ${stringDate(lastSign.date)}`
          : '';
      },
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <UserStatus status={status} />,
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          path="personal"
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
          privileges={privileges}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Usuarios'}
        getDataFunction={getUsers}
      />
      <ModalView title={modalTitle} width={1200} privileges={privileges}>
        <UserModal data={modalData} />
      </ModalView>
    </>
  );
};
