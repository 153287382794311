import { Col, Row, Tabs, Card } from 'antd';
import { MainData } from './MainData';
import { UserData } from './UserData';
import { InitData } from './InitData';
import { PersonalData } from './PersonalData';
import { AddressData } from './AddressData';
import { ContactData } from './ContactData';
import { JobReferencesData } from './JobReferencesData';
import { ContractsData } from './ContractsData';
import './styles.css';

export const UserModal = ({ data = {}, privileges = [] }) => {
  const tabs = [
    { name: 'Usuario', key: 'user', component: <UserData data={data} /> },
    { name: 'Iniciales', key: 'init', component: <InitData data={data} /> },
    {
      name: 'Personales',
      key: 'personalData',
      component: <PersonalData data={data} />,
    },
    {
      name: 'Domicilio',
      key: 'address',
      component: <AddressData data={data} />,
    },
    {
      name: 'Contactos',
      key: 'contact',
      component: <ContactData data={data} />,
    },
    {
      name: 'Referencias',
      key: 'jobReference',
      component: <JobReferencesData data={data} />,
    },
    {
      name: 'Contratos',
      key: 'signs',
      component: <ContractsData data={data} />,
    },
  ];
  return (
    <>
      <div className='site-card-wrapper'>
        <Row gutter={16}>
          <Col span={9}>
            <MainData data={data} />
          </Col>
          <Col span={15}>
            <Card bordered={true}>
              <Tabs type='line'>
                {tabs.map(({ name, key, component: Component }) => {
                  return (
                    <Tabs.TabPane tab={name} key={key}>
                      {Component}
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
