import { customerConst } from '../../../constants';
import { Tag } from 'antd';

export const PurchasedProductsStatus = ({ status }) => {
  let color = '';

  switch (status) {
    case 'onProcess':
      color = 'cyan';
      break;
    case 'finished':
      color = 'green';
      break;
    case 'refunded':
      color = 'red';
      break;
    default:
      color = 'geekblue';
      break;
  }

  return <Tag color={color}>{customerConst[status]}</Tag>;
};
