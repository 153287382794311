import { useState, useEffect } from 'react';
import { Button, Form, Input, Card, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editCabainType,
  createCabainType,
  getCabainTypeById,
  getColors,
  getTreatmentTypes,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
} from '../../../../constants';
import { CirclePicker } from 'react-color';
import { CancelButton, NumericInput } from '../../../uiComponents';
import './styles.css';

export const CabainTypesForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const editMode = id;
  const [form] = Form.useForm();
  const { cabainTypeById } = useSelector((state) => state.cabainType);
  const { treatmentTypes } = useSelector((state) => state.treatmentType);
  const { colors } = useSelector((state) => state.color);
  const [isLoading, setIsLoading] = useState(false);

  const [res, setRes] = useState();

  useEffect(() => {
    dispatch(getColors());
    if (editMode) dispatch(getCabainTypeById(id));
    dispatch(getTreatmentTypes());
  }, [dispatch, editMode, id]);

  useEffect(() => {
    if (res && (res === 201 || res === 200))
      navigate('/dashboard/tipos-cabina');
  }, [navigate, res]);

  if (id && cabainTypeById) {
    cabainTypeById.color = cabainTypeById?.color?.color?.replace('#', '');
    cabainTypeById.color = `#${cabainTypeById?.color}`;
    cabainTypeById.treatmentTypeId = cabainTypeById?.treatmentType?.id;
    form.setFieldsValue(cabainTypeById);
  }

  const onHandledSubmit = async (values) => {
    setIsLoading(true)
    values.color =
      typeof values.color === 'object'
        ? values.color.hex.replace('#', '')
        : values.color.replace('#', '');
    if (editMode) {
      setRes(await dispatch(editCabainType(id, values)));
    } else {
      setRes(await dispatch(createCabainType(values)));
    }
    setIsLoading(false)
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form
        {...formConfig}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onHandledSubmit}
        form={form}
      >
        {editMode && (
          <Form.Item label="Color Actual">
            <div
              style={{
                width: '28px',
                height: '28px',
                borderRadius: '15px',
                backgroundColor: cabainTypeById.color,
              }}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Tipo"
          name="treatmentTypeId"
          rules={[
            {
              required: true,
              message: messages.required('Tipo'),
            },
          ]}
        >
          <Select
            placeholder={placeHolders.treatmentType}
            options={treatmentTypes?.map(({ id, appointmentName }) => ({
              value: id,
              label: appointmentName,
            }))}
          ></Select>
        </Form.Item>

        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: messages.required('Nombre'),
            },
          ]}
        >
          <Input className="input-adt" />
        </Form.Item>

        <Form.Item
          label="Orden"
          name="order"
          rules={[
            {
              required: true,
              message: messages.required('Orden'),
            },
          ]}
        >
          <NumericInput min={1} />
        </Form.Item>

        <Form.Item
          label="Color"
          name="color"
          rules={[
            {
              required: true,
              message: messages.required('Color'),
            },
          ]}
        >
          <CirclePicker
            colors={colors
              .filter((color) => !color.cabainType)
              .map((color) => `#${color.color}`)}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" className="btn-form" loading={isLoading}>
          {buttonLabel.save}
        </Button>
      </Form>
      <CancelButton path="/dashboard/tipos-cabina" />
    </Card>
  );
};
