import { REHYDRATE } from 'redux-persist';
import jwt_decode from 'jwt-decode';
import {
  AUTH_SIGNIN,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_RESET_PASSWORD,
  AUTH_LOGOUT_AWAY,
  AUTH_QUICK_LOGIN,
} from '../types';

const initialState = {
  user_id: null,
  username: null,
  fullname: null,
  privileges: null,
  branchId: null,
  branch: null,
  token: null,
  refresh_token: null,
  role: null,
  path: null,
  expires_at: null,
  quick_login: false,
  photo: null,
};

export default function signIn(state = initialState, action) {
  const tokenDecoded = action.payload?.token
    ? jwt_decode(action.payload?.token)
    : null;

  switch (action.type) {
    case REHYDRATE:
      return state;
    case AUTH_SIGNIN:
      return {
        ...state,
        userId: tokenDecoded.userId,
        username: tokenDecoded.username,
        fullname: tokenDecoded.fullname,
        privileges: tokenDecoded.privileges,
        branchId: tokenDecoded.branchId,
        branch: tokenDecoded.branch,
        role: tokenDecoded.role,
        path: tokenDecoded.path,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
        refresh_token_exp: action.payload.refresh_token_exp,
        token_exp: action.payload.token,
        quick_login: action.payload.quick_login,
        photo: tokenDecoded.photo,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        user_id: null,
        username: null,
        fullname: null,
        privileges: null,
        branchId: null,
        branch: null,
        role: null,
        path: null,
        token: null,
        refresh_token: null,
        refresh_token_exp: null,
        token_exp: null,
        quick_login: false,
        photo: null,
      };
    case AUTH_LOGOUT_AWAY: {
      return {
        ...state,
        user_id: null,
        username: null,
        fullname: null,
        privileges: null,
        role: null,
        path: null,
        token: null,
        photo: null,
      };
    }
    case AUTH_QUICK_LOGIN: {
      return {
        ...state,
        userId: tokenDecoded.userId,
        username: tokenDecoded.username,
        fullname: tokenDecoded.fullname,
        privileges: tokenDecoded.privileges,
        branchId: tokenDecoded.branchId,
        branch: tokenDecoded.branch,
        role: tokenDecoded.role,
        path: tokenDecoded.path,
        token: action.payload.token,
        token_exp: action.payload.token,
        quick_login: action.payload.quick_login,
        photo: tokenDecoded.photo,
      };
    }
    case AUTH_REFRESH_TOKEN:
      return {
        ...state,
        bearer_token: action.payload.bearer_token,
        expires_at: action.payload.expires_at,
      };
    case AUTH_RESET_PASSWORD: // NOTHING TO DO
    default:
      return { ...state };
  }
}
