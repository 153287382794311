import { Col, Row, Image, Space, Tag, Typography } from 'antd';
import { types } from '../../../../constants/Product.const';
import { formatPrice } from '../../../../util/formatPrice';

export const ProductModal = ({ data = {}, privileges = [] }) => {
  const { Text } = Typography;
  const type = types[data?.productType?.name];
  const realCost = (Number(data.cost) + (data.cost * data.tax) / 100).toFixed(
    2
  );

  return (
    <>
      <Row className="row">
        <Col span={8}>
          <h4>Aprobación</h4>
        </Col>
        <Col span={16}>
          <>
            {data.approved_at ? (
              <Tag color="success">Aprobado</Tag>
            ) : data.rejected_at ? (
              <Tag color="error">Rechazado</Tag>
            ) : (
              <Tag color="warning">Pendiente</Tag>
            )}
          </>
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Fotos</h4>
        </Col>
        <Col span={16}>
          <Image.PreviewGroup>
            {data.photos?.map((photo) => (
              <Image
                width={80}
                src={`${process.env.REACT_APP_API}/v1/product/photo/${photo.name}`}
                key={`photo-${photo.name}`}
              />
            ))}
          </Image.PreviewGroup>
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Tipo</h4>
        </Col>
        <Col span={16}>{data.productType?.name}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>SKU</h4>
        </Col>
        <Col span={16}>{data.sku}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data.name}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Marca</h4>
        </Col>
        <Col span={16}>{data.brand}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Código de barras</h4>
        </Col>
        <Col span={16}>{data.barCode}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Desripción</h4>
        </Col>
        <Col span={16}>{data.description}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Contenido</h4>
        </Col>
        <Col span={16}>{`${data.content} ${data.measureUnit}${
          data.content > 1 ? '(s)' : ''
        } / ${data.contentType}`}</Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Costo</h4>
        </Col>
        <Col span={16}>
          {`$${data.cost}`} + {`${data.tax}% IVA `}
          <Text type="success">{`($${realCost})`}</Text>
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Precio franquicia</h4>
        </Col>
        <Col span={16}>
          {' '}
          {data?.franchisePrice ? formatPrice(data?.franchisePrice) : '-'}{' '}
        </Col>
      </Row>
      {['cosmetólogo', 'venta'].includes(type) &&
        privileges.includes('approve') && (
          <Row className="row">
            {/* Only applies for cosmetólogo y venta */}
            <Col span={8}>
              <h4>Gastos indirectos</h4>
            </Col>
            <Col span={16}>
              {data.indirectExpenses ? `$${data.indirectExpenses}` : '-'}
            </Col>
          </Row>
        )}
      {privileges.includes('approve') && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Costo a franquicia</h4>
            </Col>
            <Col span={16}>
              {data.additionalCostFranchise ? (
                <>
                  (${realCost}
                  {data.indirectExpenses
                    ? ` + $ ${data.indirectExpenses} +`
                    : ''}
                  {data.additionalCostFranchise
                    ? ` + $ ${data.additionalCostFranchise.toFixed(2)}`
                    : ''}{' '}
                  ) +{data.franchiseTax}% IVA
                  <Text type="success">
                    {formatPrice(data?.franchisePrice)}
                  </Text>
                </>
              ) : (
                '-'
              )}
            </Col>
          </Row>
        </>
      )}
      {type === 'cosmetólogo' && privileges.includes('approve') && (
        <>
          <Row className="row">
            {/* Only applies for cosmetólogo */}
            <Col span={8}>
              <h4>Porcentaje de utilidad</h4>
            </Col>
            <Col span={16}>
              {data.profitPorcentage ? `${data.profitPorcentage}%` : '-'}
            </Col>
          </Row>
          <Row className="row">
            {/* Only applies for cosmetólogo */}
            <Col span={8}>
              <h4>Precio de venta</h4>
            </Col>
            <Col span={16}>{data.salePrice ? `$${data.salePrice}` : '-'}</Col>
          </Row>
          <Row className="row">
            {/* Only applies for cosmetólogo */}
            <Col span={8}>
              <h4>Puntos</h4>
            </Col>
            <Col span={16}>{data.points ? `${data.points}` : '-'}</Col>
          </Row>
        </>
      )}
      <Row className="row">
        <Col span={8}>
          <h4>Proveedores</h4>
        </Col>
        <Col span={16}>
          <Space>
            {data.providers?.map((provider) => (
              <Tag key={`provider-${provider.name}`}>{`${provider.name}`}</Tag>
            ))}
          </Space>
        </Col>
      </Row>
      <Row className="row">
        <Col span={8}>
          <h4>Categorías</h4>
        </Col>
        <Col span={16}>
          <Space wrap>
            {data.categories?.map((category) => (
              <Tag key={`category-${category.name}`}>{`${category.name}`}</Tag>
            ))}
          </Space>
        </Col>
      </Row>
    </>
  );
};
