import { queryPathBuilder } from '../../util/queryPathBuilder';
import { GET_ALL_SALES, REFUND_SALE } from '../types';
import { get, post } from './Action';

const basePath = '/order';

export const getSales = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getSalesDS, true));
};

export const refundSale = (orderNumber, params) => async (dispatch) => {
  return await dispatch(
    post(`${basePath}/${orderNumber}/refund`, params, refundSaleDS)
  );
};

const getSalesDS = (sales) => ({
  type: GET_ALL_SALES,
  payload: sales.items,
});

const refundSaleDS = (sale) => ({
  type: REFUND_SALE,
  payload: sale,
});
