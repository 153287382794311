import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Row, Col, Input } from 'antd';
import { getProducts } from '../../../../appRedux/actions/Product';
import {
  messages,
  placeHolders,
  productStockColumnsFormConfig,
  stockColumnFormConfig,
  threeColumnsFormConfig,
} from '../../../../constants/Forms';
import { CustomSelect, NumericInput } from '../../../uiComponents';
import './style.css';

export const SaleOrderProducts = ({
  form = [],
  disabled = false,
  stockByBranch = [],
}) => {
  const formProducts = form.getFieldValue('saleOrderProducts');
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const [selectedItems, setSelectedItems] = useState(
    formProducts?.map((item) => item?.stockId)
  );

  const filteredOptions = stockByBranch.filter(
    (product) =>
      !selectedItems?.map((item) => item?.value).includes(product.stockId)
  );

  useEffect(() => {
    dispatch(
      getProducts({
        withDeleted: 'false',
        status: '1',
        isApproved: '1',
      })
    );
  }, [dispatch]);

  const onProductChange = (product = null, productData) => {
    setSelectedItems(
      form.getFieldValue('saleOrderProducts')?.map((item) => item?.productStock)
    );

    const formItems = form.getFieldValue('saleOrderProducts');
    const selectedProducts = formItems?.map((item) => item?.productStock);
    const index = selectedProducts.findIndex(
      (item) => item?.key === product?.key
    );

    if (formItems[index] && index >= 0) {
      formItems[index].stock = productData?.stock ?? 0;
      formItems[index].productId = productData?.productId ?? 0;
      form.setFieldsValue({
        products: formItems,
      });
    }
  };

  const getSubtotal = (index) => {
    const formItems = form.getFieldValue('saleOrderProducts');
    if (formItems[index] && index >= 0) {
      const quantity = formItems[index]?.quantity || 0;
      const price = formItems[index]?.price || 0;
      const subtotal = Number(quantity) * Number(price);
      formItems[index].subtotal = subtotal || 0;

      form.setFieldsValue({
        products: formItems,
      });
    }
  };

  return (
    <>
      <Form.List disabled name="saleOrderProducts">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <>
                <hr className="divider" />
                <Row wrap>
                  {products && (
                    <Col span={20}>
                      <Form.Item
                        {...productStockColumnsFormConfig}
                        label="Producto"
                        name={[field.name, 'productStock']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Producto'),
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder={placeHolders.product}
                          onChange={onProductChange}
                          labelInValue={true}
                          options={filteredOptions.map((product) => ({
                            value: product?.stockId,
                            label: `SKU: ${product?.sku} - Lote: ${product?.allotment} - ${product?.name}`,
                            productId: product?.productId,
                            stock: product?.stock,
                          }))}
                        ></CustomSelect>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={fields.length > 1 ? 3 : 4}>
                    <Form.Item
                      {...stockColumnFormConfig}
                      label="Stock"
                      initialValue={0}
                      name={[field.name, 'stock']}
                    >
                      <NumericInput disabled={true} min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        className="danger-button-product"
                        onClick={() => {
                          remove(field.name);
                          onProductChange();
                        }}
                      />
                    )}
                  </Col>
                </Row>

                <Row wrap>
                  <Col span={8}>
                    <Form.Item
                      {...threeColumnsFormConfig}
                      label="Cantidad"
                      name={[field.name, 'quantity']}
                      dependencies={[field.name, 'stock']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Cantidad'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              value <=
                              getFieldValue('saleOrderProducts')[field.name]
                                ?.stock
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('No se cuenta con suficiente stock')
                            );
                          },
                        }),
                      ]}
                    >
                      <NumericInput
                        className={'input-w100'}
                        min={0}
                        disabled={disabled}
                        onChange={() => getSubtotal(field.name)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...threeColumnsFormConfig}
                      label="Precio"
                      name={[field.name, 'price']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Precio'),
                        },
                      ]}
                    >
                      <NumericInput
                        className={'input-w100'}
                        min={1}
                        onChange={() => getSubtotal(field.name)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      {...threeColumnsFormConfig}
                      label="Subtotal"
                      name={[field.name, 'subtotal']}
                    >
                      <Input className={'input-w100'} min={1} disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ))}

            <Button
              className="add-btn"
              onClick={() => {
                add();
                onProductChange();
              }}
              icon={<PlusOutlined />}
              disabled={disabled}
            >
              Agregar producto
            </Button>
          </div>
        )}
      </Form.List>
    </>
  );
};
