import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  SAVE_IMAGE_PRODUCT_ID,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCTS,
  REJECT_PRODUCT,
  DATA_DELETED,
} from '../types';

import { post, get, put, remove } from './Action';
import { apiTypes } from '../../constants';
import { queryPathBuilder } from '../../util/queryPathBuilder';

const basePath = '/product';

// Create Product
export const createProduct = (product) => async (dispatch) => {
  const photos = product.photos.map((photo) => photo.originFileObj);
  delete product.photos;
  const productObj = await dispatch(
    post(`${basePath}/create`, product, createProductDS, true)
  );
  if (productObj) await dispatch(createPhotos(photos, productObj?.data?.id));
  return productObj?.status;
};

// Get Products
export const getProducts = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(get(`${basePath}${queryParams}`, getProductsDS, true));
};

// Get All Products
export const getAllProducts = (params) => async (dispatch) => {
  const { providerId, orderType } = !!params && params;
  let path = '?';

  if (providerId) {
    path += `providerId=${providerId}`;
  }

  if (orderType) {
    path += `orderType=${orderType}`;
  }

  return await dispatch(get(`${basePath}/all${path}`, getAllProductsDS));
};

// Get Product By Id
export const getProductById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getProductByIdDS));
};

// Edit Product
export const editProduct =
  (id, product, photosToDelete) => async (dispatch) => {
    const photos = product.photos.map((photo) => photo.originFileObj);
    const productType = apiTypes[product.productType];
    delete product.photos;
    delete product.productType;

    if (photosToDelete.length > 0) await dispatch(deletePhotos(photosToDelete));
    if (photos.length > 0) await dispatch(createPhotos(photos, id));
    return await dispatch(
      put(
        `${basePath}/${id}/update-${productType}-product`,
        product,
        editProductDS
      )
    );
  };

// Reject Product
export const rejectProduct = (id) => async (dispatch) => {
  return await dispatch(put(`${basePath}/${id}/reject`, {}, rejectProductDS));
};

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deleteProductDS)
  );
  dispatch(getProductDeleteDS(id));
  return response;
};

// Create Photos
export const createPhotos = (files, productId) => async (dispatch) => {
  let photos = new FormData();
  for (const image of files) {
    photos.append('image', image);
  }
  return await dispatch(
    post(`${basePath}/${productId}/photos`, photos, createPhotosDS)
  );
};

// Delete Photos
export const deletePhotos = (photos) => async (dispatch) => {
  photos.forEach(
    async (photo) =>
      await dispatch(remove(`${basePath}/photo/${photo}/delete`, null))
  );
};

const createProductDS = (product) => ({
  type: CREATE_PRODUCT,
  payload: product,
});

const getProductsDS = (products) => ({
  type: GET_PRODUCTS,
  payload: products.items,
});

const getAllProductsDS = (products) => ({
  type: GET_ALL_PRODUCTS,
  payload: products,
});

const getProductByIdDS = (product) => ({
  type: GET_PRODUCT_BY_ID,
  payload: product,
});

const editProductDS = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product,
});

export const getProductDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deleteProductDS = () => ({
  type: DELETE_PRODUCT,
});

const rejectProductDS = () => ({
  type: REJECT_PRODUCT,
});

// SAVE ID IMAGE
const createPhotosDS = (id) => ({
  type: SAVE_IMAGE_PRODUCT_ID,
  payload: id,
});
