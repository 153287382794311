export const applianceConst = {
  APPLIANCE: 'Aparatos',
};

export const applianceStatusDiccionary = {
  working: 'Funcionando',
  damege: 'Averiado',
  failings: 'Con fallas',
  onRepair: 'En reparación',
};

export const statusDescriptions = [
  {
    value: applianceStatusDiccionary.working,
    label: applianceStatusDiccionary.working,
  },
  {
    value: applianceStatusDiccionary.damege,
    label: applianceStatusDiccionary.damege,
  },
  {
    value: applianceStatusDiccionary.failings,
    label: applianceStatusDiccionary.failings,
  },
  {
    value: applianceStatusDiccionary.onRepair,
    label: applianceStatusDiccionary.onRepair,
  },
];

export const appliances = 'Aparatología';
export const computerEquipment = 'Cómputo';

export const types = [
  { value: appliances, lable: appliances },
  { value: computerEquipment, lable: computerEquipment },
];

export const searchFilters = [
  { value: 'appliance.name', label: 'Nombre' },
  { value: 'appliance.brand', label: 'Marca' },
  { value: 'appliance.model', label: 'Módelo' },
];

export const filters = {
  search: true,
  status: true,
  applianceType: true,
};

export const searchInputInitialValues = {
  search: { filter: 'appliance.name' },
  status: '1',
};
