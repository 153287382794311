export const applianceConst = {
  APPLIANCE: 'Aparatos',
};

export const searchFilters = [
  { value: 'appliance.name', label: 'Nombre' },
  { value: 'branchAppliance.sku', label: 'SKU' },
  { value: 'appliance.brand', label: 'Marca' },
  { value: 'appliance.model', label: 'Módelo' },
  { value: 'branchAppliance.number', label: 'Número' },
];

export const filters = {
  search: true,
  branches: true,
  applianceStatus: true,
  status: true,
  applianceType: true,
};

export const searchInputInitialValues = {
  search: { filter: 'appliance.name' },
  status: '1',
};
