import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Card, Button } from 'antd';
import {
  createSaleOrder,
  updateSaleOrder,
  getStockByBranchId,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  modalButtons,
  orderConfirmModal,
  regex,
  masks,
} from '../../../../constants';
import TextArea from 'antd/lib/input/TextArea';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CancelButton } from '../../../uiComponents';
import { SaleOrderProducts } from '../SaleOrderProducts';
import './styles.css';
import { MaskedInput } from 'antd-mask-input';
import { unmaskPhone } from '../../../../util';

export const SaleOrderForm = ({ privileges }) => {
  const { id } = useParams();
  const { saleOrderById } = useSelector((state) => state.saleOrder);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { stockByBranch } = useSelector((state) => state.stock);

  useEffect(() => {
    dispatch(getStockByBranchId());
  }, [dispatch]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      navigate('/dashboard/ordenes-de-venta');
    }
  }, [navigate, response]);

  useEffect(() => {
    if (saleOrderById && id) {
      form.setFieldsValue(saleOrderById);
      setListItems(saleOrderById);
      form.setFieldsValue({
        saleOrderProducts: saleOrderById.saleOrderProduct,
      });
    }
  }, [form, id, saleOrderById]);

  const setListItems = ({ saleOrderProduct }) => {
    saleOrderProduct?.forEach((product) => {
      product.productId = product?.id;
    });
  };

  const showOrderConfirm = async (values) => {
    confirm({
      title: orderConfirmModal.title,
      icon: <ExclamationCircleOutlined />,
      content: orderConfirmModal.body,
      okText: modalButtons.ok,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          resolve(onSubmit(values));
        });
      },
      onCancel() {},
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    values.saleOrderProducts = values?.saleOrderProducts?.map((product) => ({
      ...product,
      stockId: product.productStock.value,
    }));

    values.phone = unmaskPhone(values.phone);
    values.rfc = values.rfc !== '' ? values.rfc : null;

    if (id) {
      setResponse(await dispatch(updateSaleOrder(id, values)));
    } else {
      setResponse(await dispatch(createSaleOrder(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          name="dynamic_form_item"
          id="onSubmit"
          initialValues={{ saleOrderProducts: [''] }}
          autoComplete="off"
          onFinish={showOrderConfirm}
          form={form}
        >
          <Form.Item
            {...formConfig}
            name="name"
            label="Nombre"
            rules={[
              { required: true, message: messages.required('Razón Social') },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...formConfig}
            label="RFC"
            name="rfc"
            rules={[{ pattern: regex.rfc, message: messages.validation.rfc }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...formConfig}
            label="Teléfono"
            name="phone"
            rules={[
              {
                required: true,
                message: messages.required('Teléfono'),
              },
            ]}
          >
            <MaskedInput mask={masks.phone} />
          </Form.Item>

          <Form.Item
            {...formConfig}
            label="Número de nota"
            name="note"
            rules={[
              { required: true, message: messages.required('Número de nota') },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...formConfig} label="Observaciones" name="comments">
            <TextArea />
          </Form.Item>

          <SaleOrderProducts form={form} stockByBranch={stockByBranch} />

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            form="onSubmit"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/ordenes-de-venta" />
      </Card>
    </>
  );
};
