export const searchInputInitialValues = {
  search: { filter: 'kardex.employeeNumber' },
};

export const searchFilters = [
  { value: 'user.name', label: 'Médico' },
  { value: 'kardex.employeeNumber', label: 'No. de empleado' },
];

export const searchFiltersCosmetology = [
  { value: 'user.name', label: 'Cosmetóloga' },
  { value: 'kardex.employeeNumber', label: 'No. de empleado' },
];

export const filters = {
  search: true,
  branches: true,
  dates: true,
};

export const filtersMedical = {
  dates: true,
};

export const filterNoApprove = {
  search: true,
  dates: true,
};

export const salesConst = {
  DETAIL: 'Detalle',
};

export const commissionType = {
  MEDICAL: 'Médica',
  COSMETOLOGIC: 'Cosmetológica',
};

export const commissionConst = {
  MEDICAL_MODAL_TITLE: 'Comisiones médicas',
  COSMETOLOGY_MODAL_TITLE: 'Comisiones cosmetológicas',
  COMMISSION_AGENT_UPDATE: 'Actualizar comisionista',
};
