import { Dropdown, Menu } from 'antd';
import { cashSalesTableMenuOpt } from '../../../../constants/CashSales';

export const CashSaleTableOptions = ({ id, action = {}, isRefund }) => {
  const { updateSeller } = !!action && action;
  // Descomentar cuando se inserten priviligios de comisiones
  // let options = appointmentsTableMenuOpt().filter((opt) =>
  //   privileges.includes(opt.privilege)
  // );

  const options = isRefund
    ? cashSalesTableMenuOpt.filter((opt) => opt.key !== 'edit')
    : cashSalesTableMenuOpt;
  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'edit':
        updateSeller.getModal(...updateSeller.params);
        break;
      case 'invoice':
        isRefund
          ? window.open(
              `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/cash-order/${id}/ticket/refund`,
              '_blank',
              'noopener,noreferrer'
            )
          : window.open(
              `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/cash-order/${id}/ticket`,
              '_blank',
              'noopener,noreferrer'
            );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown.Button
        disabled={!(cashSalesTableMenuOpt.length > 0)}
        overlay={renderMenu(id)}
        trigger={['click']}
        placement="bottom"
      />
    </>
  );
};
