import { FINISH_PURCHASED_PRODUCT } from '../types';

const initialState = {
  purchasedProduct: {},
};

export default function PurchasedProduct(state = initialState, action) {
  switch (action.type) {
    case FINISH_PURCHASED_PRODUCT:
      return {
        ...state,
        purchasedProduct: action.payload,
      };

    default:
      return state;
  }
}
