import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import {
  createPackage,
  getCategories,
  getPackageById,
  getSubsidiaries,
  getTreatmentTypes,
  updatePackage,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  dateFormat,
  formConfig,
  messages,
  payMethods,
  timeFormat,
  placeHolders,
  dateFormatMx,
} from '../../../../constants';
import {
  CancelButton,
  CustomSelect,
  NumericInput,
  UploadPhotos,
} from '../../../uiComponents';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TreatmentPackage } from '../TreatmentPackage';
import moment from 'moment/moment';
import { setTimeFormat } from '../../../../util/formatTime';
import DefaultImage from '../../../../assets/images/ICONO-AZUL-100x100.png';
import { treatmentType } from '../../../../constants/Package.const';
import { validatePhotos } from '../../../../util';

export const PackageForm = ({ path }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { treatmentTypes } = useSelector((state) => state.treatmentType);
  const { categories } = useSelector((state) => state.category);
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const [response, setResponse] = useState();
  const [schedule, setSchedule] = useState(false);
  const [time, setTime] = useState(false);
  const [cabainTime, setCabainTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [treatmentTypeId, setTreatmentTypeId] = useState();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { packageById } = useSelector((state) => state.packages);
  const { pathname } = useLocation();
  const [setBranchId] = useState();
  const [fileList, setFileList] = useState([]);
  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isPackage = pathname.includes('paquetes');

  useEffect(() => {
    if (id) dispatch(getPackageById(id));
    dispatch(getTreatmentTypes());
    dispatch(getCategories({ type: 'treatment' }));
    dispatch(getSubsidiaries({ status: '1' }));
  }, [dispatch, id]);

  useEffect(() => {
    if (packageById && id) {
      setSchedule(packageById.validBySchedule);
      setTime(packageById.validByTime);
      setTreatmentTypeId(packageById?.treatmentType?.id);
      setListItems(packageById);
      setCabainTime(packageById?.cabainTime);
      setStartDate(packageById?.startDate);
      setEndDate(packageById?.endDate);
      setStartTime(packageById?.startTime);
      setEndTime(packageById?.endTime);
      setStatus(packageById?.status);
      setFileList([
        {
          uid: packageById?.photo?.id,
          name: packageById?.photo?.name,
          status: 'done',
          url: packageById?.photo
            ? `${process.env.REACT_APP_API}/v1/photo/thumbnail/${packageById?.photo?.name}`
            : DefaultImage,
        },
      ]);
      form.setFieldsValue({
        ...packageById,
        treatmentTypeId: packageById?.treatmentType?.id,
        categoryId: packageById?.category?.id,
        cabainTime: setTimeFormat(packageById.cabainTime),
        branchesIds: packageById?.branches?.map((branch) => branch.id),
        startDate: packageById.startDate ? moment(packageById.startDate) : null,
        endDate: packageById.endDate ? moment(packageById.endDate) : null,
        startTime: packageById.startTime
          ? setTimeFormat(packageById.startTime)
          : null,
        endTime: packageById.endTime
          ? setTimeFormat(packageById.endTime)
          : null,
      });
    }
  }, [packageById, form, id]);

  const setListItems = ({ treatmentPackage }) => {
    treatmentPackage?.forEach((item) => {
      item.areasIds = item?.areas?.map((area) => ({
        value: area.id,
        label: area.name,
      }));
      item.treatmentId = {
        value: item?.treatment?.id,
        label: item?.treatment?.name,
      };
    });
  };

  const onChangePhotos = (photos) => {};

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      const destinyPath = isPackage ? 'paquetes' : 'promociones';
      navigate(`/dashboard/${destinyPath}`);
    }
  }, [navigate, response, isPackage]);

  const onTreatmentTypeChange = (id) => {
    setTreatmentTypeId(id);
    form.setFieldsValue({ treatmentPackage: [{}] });
  };

  const onSubmit = async (formValues) => {
    setIsLoading(true);
    formValues.cabainTime = cabainTime;
    formValues.startTime = startTime;
    formValues.endTime = endTime;

    formValues.treatmentPackage = formValues?.treatmentPackage?.map(
      (treatment) => ({
        ...treatment,
        treatmentId: treatment.treatmentId.value,
        areasIds: treatment.areasIds.map((area) => area.value),
      })
    );

    if (!isPackage) formValues.type = 'Promotion';

    if (id) {
      const { sku, ...data } = formValues;
      setResponse(await dispatch(updatePackage(id, data)));
    } else {
      setResponse(await dispatch(createPackage(formValues)));
    }
    setIsLoading(false);
  };

  const updatePrice = () => {
    let regularPrice = 0;
    let price = 0;
    const treatmentPackages = form.getFieldValue('treatmentPackage');

    for (const treatmentPackage of treatmentPackages) {
      regularPrice +=
        (Number(treatmentPackage?.regularPrice) || 0) *
        (Number(treatmentPackage?.sessions) || 1);
      price +=
        (Number(treatmentPackage?.promotionPrice) || 0) *
        (Number(treatmentPackage?.sessions) || 1);
    }
    form.setFieldsValue({
      regularPrice: regularPrice.toString(),
      price: price.toString(),
    });
  };
  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          initialValues={{ treatmentPackage: [{}] }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            {...formConfig}
            label="Foto"
            rules={[
              {
                required: id ? false : true,
                message: messages.required('Foto'),
              },
              () => ({
                validator(_, value) {
                  return validatePhotos(_, value);
                },
              }),
            ]}
            name="photos"
          >
            <UploadPhotos
              onChange={onChangePhotos}
              limit={1}
              files={fileList}
            />
          </Form.Item>

          <Form.Item
            name="sku"
            label="SKU"
            {...formConfig}
            rules={[
              { required: true, message: messages.required('SKU') },
              {
                min: 4,
                message: messages.validation.sku,
              },
              {
                max: 12,
                message: messages.validation.sku,
              },
            ]}
          >
            <Input disabled={id ? true : false} />
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[{ required: true, message: messages.required('Nombre') }]}
            name="name"
            label="Nombre"
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[
              { required: true, message: messages.required('treatmentType') },
            ]}
            name="treatmentTypeId"
            label="Tipo de tratamiento"
          >
            <Select
              placeholder={placeHolders.treatmentType}
              onChange={onTreatmentTypeChange}
              options={treatmentTypes
                ?.filter((type) => type.name !== treatmentType.valoration)
                ?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
            />
          </Form.Item>

          <Form.Item
            name="categoryId"
            label="Categoría"
            {...formConfig}
            rules={[
              { required: true, message: messages.required('Categoría') },
            ]}
          >
            <CustomSelect
              placeholder={placeHolders.category}
              options={categories?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[
              { required: true, message: messages.required('Descripción') },
            ]}
            name="description"
            label="Descripción"
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="points"
            label="Puntos"
            rules={[{ required: true, message: messages.required('Puntos') }]}
          >
            <NumericInput style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item {...formConfig} name="price" label="Precio">
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              stringMode={true}
              disabled
            />
          </Form.Item>

          <Form.Item {...formConfig} name="regularPrice" label="Precio Regular">
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              stringMode={true}
              disabled
            />
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[
              { required: true, message: messages.required('Tiempo cabina') },
            ]}
            name="cabainTime"
            label="Tiempo cabina"
          >
            <TimePicker
              format={timeFormat}
              minuteStep={30}
              onChange={(_, timeString) => setCabainTime(timeString)}
            />
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[
              { required: true, message: messages.required('Sucursales') },
            ]}
            name="branchesIds"
            label="Válido en"
          >
            <CustomSelect
              placeholder={placeHolders.branch}
              mode="multiple"
              onChange={setBranchId}
              options={subsidiaries?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </Form.Item>

          <Form.Item
            {...formConfig}
            rules={[
              { required: true, message: messages.required('Tipo de pago') },
            ]}
            name="paymentType"
            label="Tipo de pago"
          >
            <CustomSelect
              mode="multiple"
              placeholder={placeHolders.payMethod}
              options={payMethods}
            />
          </Form.Item>

          <Form.Item {...formConfig} name="status" label="Activar">
            <Switch
              checked={status}
              onChange={setStatus}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            ></Switch>
          </Form.Item>

          <Form.Item
            {...formConfig}
            name="validBySchedule"
            label="¿Por tiempo limitado?"
          >
            <Switch
              checked={schedule}
              onChange={setSchedule}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            ></Switch>
          </Form.Item>

          {schedule && (
            <>
              <Form.Item
                {...formConfig}
                name="startDate"
                label="Fecha de inicio"
                rules={[
                  {
                    required: true,
                    message: messages.required('Fecha de inicio'),
                  },
                ]}
              >
                <DatePicker format={dateFormatMx} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                {...formConfig}
                name="endDate"
                label="Fecha de finalización"
                rules={[
                  {
                    required: true,
                    message: messages.required('Fecha de finalización'),
                  },
                ]}
              >
                <DatePicker format={dateFormatMx} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                {...formConfig}
                name="validByTime"
                label="¿Por horarios?"
              >
                <Switch
                  checked={time}
                  onChange={setTime}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Form.Item>
            </>
          )}

          {schedule && time && (
            <>
              <Form.Item
                {...formConfig}
                name="startTime"
                minuteStep={30}
                label="Hora de inicio"
                rules={[
                  {
                    required: true,
                    message: messages.required('Hora de inicio'),
                  },
                ]}
              >
                <TimePicker
                  {...formConfig}
                  format={timeFormat}
                  onChange={(_, timeString) => setStartTime(timeString)}
                />
              </Form.Item>

              <Form.Item
                {...formConfig}
                name="endTime"
                label="Horario de finalización"
                rules={[
                  {
                    required: true,
                    message: messages.required('Fecha de finalización'),
                  },
                ]}
              >
                <TimePicker
                  {...formConfig}
                  minuteStep={30}
                  format={timeFormat}
                  onChange={(_, stringTime) => setEndTime(stringTime)}
                />
              </Form.Item>
            </>
          )}

          <TreatmentPackage
            form={form}
            isPackage={isPackage}
            treatmentTypeId={treatmentTypeId}
            updatePrice={updatePrice}
          />

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path={path} />
      </Card>
    </>
  );
};
