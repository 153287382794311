import {
  GET_MEDICAL_COMMISSIONS,
  GET_MEDICAL_COMMISSION_BY_USER,
  UPDATE_COMMISSION_AGENT,
} from '../types';

const initialState = {
  medicalCommissions: [],
  medicalCommissionsByUser: [],
};

export default function Commission(state = initialState, action) {
  switch (action.type) {
    case GET_MEDICAL_COMMISSIONS:
      return {
        ...state,
        medicalCommissions: action.payload,
      };
    case GET_MEDICAL_COMMISSION_BY_USER:
    case UPDATE_COMMISSION_AGENT:
      return {
        ...state,
        medicalCommissionsByUser: action.payload,
      };
    default:
      return state;
  }
}
