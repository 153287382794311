import React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Row, Col } from 'antd';
import LogoLogin from '../../../assets/images/LOGO-AZUL-300x97.png';
import { quickLogin } from '../../../appRedux/actions/QuickLogin';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import {
  buttonLabel,
  lengthValidation,
  messages,
  placeHolders,
} from '../../../constants/Forms';
import './styles.css';

export const QuickLoginForm = () => {
  const dispatch = useDispatch();

  const {
    refresh_token: refreshToken,
    branchId,
    token,
    path,
  } = useSelector((state) => state.userAuth);

  const onHandledSubmit = async (values) => {
    dispatch(quickLogin({ pin: Number(values.pin), refreshToken, branchId }));
  };

  return (
    <>
      {token ? (
        <Navigate to={`/dashboard/${path}`} />
      ) : (
        <>
          <Row>
            <Col className="col-login" span={24}>
              <div className="login-container">
                <div className="logo-login">
                  <img alt="Moldearte Logo" src={LogoLogin} />
                </div>
                <div className="login-body">
                  <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onHandledSubmit}
                  >
                    <Form.Item
                      name="pin"
                      type="number"
                      rules={[
                        { required: true, message: messages.required('pin') },
                        { min: 4, message: lengthValidation('pin', '4') },
                        { max: 4, message: lengthValidation('pin', '4') },
                      ]}
                    >
                      <Input
                        size="large"
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder={placeHolders.pin}
                        style={{ width: '100%' }}
                        autoFocus={true}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginTop: 0 }}>
                      <Link className="login-form-forgot" to="/login">
                        {buttonLabel.login}
                      </Link>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        {buttonLabel.keepSession}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
