import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TitleBreadcrumbs } from '../../uiComponents/TitleBreadcrumbs';
import { SaleOrderTable } from '../../components/SaleOrder/SaleOrderTable';
import { useSelector } from 'react-redux';

export const SaleOrders = () => {
  const {
    privileges: { saleOrder: privileges = [] },
  } = useSelector((state) => state.userAuth);

  const navigate = useNavigate();

  return (
    <Card title={<TitleBreadcrumbs />} bordered={true} className="gx-card">
      {privileges.includes('create') && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            navigate('/dashboard/ordenes-de-venta/crear');
          }}
        >
          <PlusOutlined />
          Nuevo
        </Button>
      )}
      <SaleOrderTable privileges={privileges} />
    </Card>
  );
};
