import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Switch } from 'antd';
import { UploadPhotos, CustomSelect } from '../../../../uiComponents';
import {
  placeHolders,
  messages,
  lengthValidation,
  minLengthValidation,
  formConfig,
} from '../../../../../constants';
import { getAllRoles } from '../../../../../appRedux/actions';
import DefaultImage from '../../../../../assets/images/ICONO-AZUL-100x100.png';
import { useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { validatePhotos } from '../../../../../util';

export const UserStepForm = ({ editMode, userById }) => {
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const onChangePhotos = (photos) => {};
  const { roles } = useSelector((state) => state.role);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (id && userById) {
      setStatus(userById?.status === 'activo' ? true : false);
      setFileList([
        {
          uid: userById?.kardex?.photo?.id,
          name: userById?.kardex?.photo?.name,
          status: 'done',
          url: userById?.kardex?.photo
            ? `${process.env.REACT_APP_API}/v1/user/photo/thumbnail/${userById?.kardex?.photo?.name}`
            : DefaultImage,
        },
      ]);
    }
  }, [id, userById]);

  useEffect(() => {}, [fileList]);

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  return (
    <>
      <Form.Item
        {...formConfig}
        label="Foto"
        rules={[
          {
            required: id ? false : true,

            message: messages.required('Foto'),
          },
          () => ({
            validator(_, value) {
              return validatePhotos(_, value);
            },
          }),
        ]}
        name="photos"
      >
        <UploadPhotos onChange={onChangePhotos} limit={1} files={fileList} />
      </Form.Item>

      <Form.Item
        label="Nombre"
        name="name"
        rules={[
          { required: true, message: messages.required('Nombre') },
          { max: 50, message: lengthValidation('Nombre', 50) },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Apellido paterno"
        name="lastName"
        rules={[
          { required: true, message: messages.required('Apellido paterno') },
          { min: 2, message: minLengthValidation('Apellido paterno', 2) },
          { max: 50, message: lengthValidation('Apellido paterno', 50) },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Apellido materno"
        name="motherLastName"
        rules={[
          { required: true, message: messages.required('Apellido materno') },
          { min: 2, message: minLengthValidation('Apellido materno', 2) },
          { max: 50, message: lengthValidation('Apellido materno', 50) },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Correo electrónico / Usuario"
        name="username"
        rules={[
          {
            required: true,
            message: messages.required('Correo electrónico'),
          },
          {
            type: 'email',
            message: messages.required('Correo electrónico'),
          },
          { max: 100, message: lengthValidation('Correo electrónico', 100) },
        ]}
      >
        <Input placeholder={placeHolders.email} />
      </Form.Item>
      <Form.Item
        label="Rol"
        name="roleId"
        rules={[{ required: true, message: messages.required('Rol') }]}
      >
        <CustomSelect
          placeholder={placeHolders.role}
          options={roles?.map((role) => ({
            label: role.role,
            value: role.id,
          }))}
        ></CustomSelect>
      </Form.Item>
      {id && (
        <Form.Item label="Status" name="status">
          <Switch
            checked={status}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={setStatus}
          />
        </Form.Item>
      )}
    </>
  );
};
