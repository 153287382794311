import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, Select, Card, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  createAppliance,
  editAppliance,
  getApplianceById,
} from '../../../../appRedux/actions';
import {
  cardConfig,
  formConfig,
  messages,
  buttonLabel,
  types,
} from '../../../../constants';
import { CancelButton } from '../../../uiComponents';
import TextArea from 'antd/lib/input/TextArea';
import './styles.css';

export const ApplianceForm = () => {
  const { id } = useParams();
  const { applianceById } = useSelector((state) => state.appliances);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) dispatch(getApplianceById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      navigate('/dashboard/aparatos');
    }
  }, [navigate, response]);

  useEffect(() => {
    if (id && applianceById) {
      setStatus(applianceById.status);

      form.setFieldsValue(applianceById);
    }
  }, [form, applianceById, id]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (id) {
      setResponse(await dispatch(editAppliance(id, values)));
    } else {
      setResponse(await dispatch(createAppliance(values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            name="type"
            label="Tipo de aparato"
            rules={[
              { required: true, message: messages.required('Tipo de aparato') },
            ]}
          >
            <Select
              placeholder="Selecciona un tipo de aparato"
              options={types.map(({ value, label }) => ({ value, label }))}
            ></Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Nombre"
            rules={[
              {
                required: true,
                message: messages.required('Nombre'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="brand"
            label="Marca"
            rules={[
              {
                required: true,
                message: messages.required('Marca'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="model"
            label="Modelo"
            rules={[
              {
                required: true,
                message: messages.required('Modelo'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Descripción">
            <TextArea />
          </Form.Item>

          {id && (
            <Form.Item {...formConfig} name="status" label="Estado">
              <Switch
                checked={status}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={setStatus}
              />
            </Form.Item>
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            loading={isLoading}
          >
            {buttonLabel.save}
          </Button>
        </Form>
        <CancelButton path="/dashboard/aparatos" />
      </Card>
    </>
  );
};
