import { Form, DatePicker } from 'antd';
import {
  messages,
  disableDaysAfterToday,
  dateFormat,
  placeHolders,
} from '../../../../../constants';

export const ContractsStepForm = () => {
  return (
    <>
      <Form.Item
        name="sign1"
        label="Firma de contrato 1"
        rules={[
          { required: true, message: messages.required('Firma de contrato 1') },
        ]}
      >
        <DatePicker
          className="gx-w-100"
          placeholder={placeHolders.date}
          format={dateFormat}
          disabledDate={disableDaysAfterToday}
        />
      </Form.Item>
      <Form.Item name="sign2" label="Firma de contrato 2">
        <DatePicker
          className="gx-w-100"
          placeholder={placeHolders.date}
          format={dateFormat}
          disabledDate={disableDaysAfterToday}
        />
      </Form.Item>
      <Form.Item name="sign3" label="Firma de contrato 3">
        <DatePicker
          className="gx-w-100"
          placeholder={placeHolders.date}
          format={dateFormat}
          disabledDate={disableDaysAfterToday}
        />
      </Form.Item>
      <Form.Item name="sign4" label="Firma de contrato permanente">
        <DatePicker
          className="gx-w-100"
          placeholder={placeHolders.date}
          format={dateFormat}
          disabledDate={disableDaysAfterToday}
        />
      </Form.Item>
    </>
  );
};
