import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Card,
  Switch,
  Divider,
  PageHeader,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCabainTypes,
  getSubsidiaries,
  updateCabain,
  createCabain,
  getCabainById,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  daysDictionary,
  formConfig,
  initialOpenState,
  lengthValidation,
  messages,
  placeHolders,
  weekdaysConst,
} from '../../../../constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { setTimeFormat } from '../../../../util/formatTime';
import { CancelButton, CustomSelect } from '../../../uiComponents';
import TextArea from 'antd/lib/input/TextArea';
import { Schedules } from '../../../uiComponents/Schedules';
import './styles.css';

export const CabainForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [response, setResponse] = useState();
  const { cabainById } = useSelector((state) => state.cabain);
  const { cabainTypes } = useSelector((state) => state.cabainType);
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const [status, setStatus] = useState(true);
  const [open, setOpen] = useState(initialOpenState);
  const [timeString, setTimeString] = useState({});
  const [branchId, setBranchId] = useState();
  const [branchSchedules, setBranchSchedules] = useState();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (id) {
      dispatch(getCabainById(id));
    }
    dispatch(getCabainTypes());
    dispatch(getSubsidiaries({ status: '1' }));
  }, [dispatch, id]);

  useEffect(() => {
    if (response && [200, 201].includes(response)) {
      navigate('/dashboard/cabinas');
    }
  }, [navigate, response]);

  useEffect(() => {
    if (!id && subsidiaries && branchId) {
      const selectedBranch = subsidiaries?.find(
        (branch) => branch.id === branchId
      );
      setBranchSchedules(selectedBranch?.schedules);

      const days = setSchedules(selectedBranch)
      form.setFieldsValue({
        ...days,
      });
    }
  }, [subsidiaries, branchId, form, id]);

  useEffect(() => {
    if (id && subsidiaries && branchId) {
      const selectedBranch = subsidiaries?.find(
        (branch) => branch.id === branchId
      );
      setBranchSchedules(selectedBranch?.schedules);

    }
  }, [subsidiaries, branchId, form, id]);

  useEffect(() => {
    if (id && cabainById && cabainById?.schedules) {
      const days = setSchedules(cabainById);
      setBranchId(cabainById?.branch?.id);

      form.setFieldsValue({
        ...cabainById,
        ...days,
        ...{
          typeId: cabainById?.type?.id,
          branchId: cabainById?.branch?.id,
        },
      });
    }
  }, [form, id, cabainById]);

  useEffect(() => {
    setStatus(cabainById.status);
  }, [cabainById]);

  const setSchedules = (branch) => {
    const days = {};
    let schedules = [];
    let isOpen = {};
    for (const schedule of branch?.schedules) {
      const [objectKey] = Object.keys(weekdaysConst[schedule?.day]);
      const opening = schedule?.opening;
      const closing = schedule?.closing;

      if (opening && closing) {
        days[objectKey] = ![opening, closing].includes('00:00:00')
          ? [setTimeFormat(opening), setTimeFormat(closing)]
          : [];

        schedules = { ...schedules, ...{ [objectKey]: [opening, closing] } };
        isOpen = {
          ...isOpen,
          ...{ [objectKey]: days[objectKey].length > 0 ? true : false },
        };
      }

      schedules.length < 1 ? setOpen(initialOpenState) : setOpen(isOpen);
    }

    setTimeString(schedules);

    return days;
  };


  const onOpenChange = (day) => {
    form.setFieldsValue({
      [day]: [],
    });

    setTimeString({
      ...timeString,
      ...{
        [day]: [],
      },
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true)
    values.schedule = Object.keys(timeString).map((key) => {
      if (timeString[key].length < 1)
        return {
          day: daysDictionary[key],
          opening: '00:00',
          closing: '00:00',
        };
      return {
        day: daysDictionary[key],
        opening: timeString[key][0],
        closing: timeString[key][1],
      };
    });

    if (id) {
      setResponse(await dispatch(updateCabain(id, values)));
    } else {
      setResponse(await dispatch(createCabain(values)));
    }
    setIsLoading(false)
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      <Form
        {...formConfig}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: messages.required('Nombre'),
            },
          ]}
        >
          <Input className="input-adt" />
        </Form.Item>

        <Form.Item
          label="Tipo"
          name="typeId"
          rules={[
            {
              required: true,
              message: messages.required('Tipo'),
            },
          ]}
        >
          <Select
            placeholder={placeHolders.type}
            options={cabainTypes.map(({ id, name }) => {
              return { label: name, value: id };
            })}
          ></Select>
        </Form.Item>

        <Form.Item
          label="Sucursal"
          name="branchId"
          rules={[
            {
              required: true,
              message: messages.required('Sucursal'),
            },
          ]}
        >
          <CustomSelect
            placeholder={placeHolders.branch}
            onChange={setBranchId}
            options={subsidiaries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          ></CustomSelect>
        </Form.Item>

        {id && (
          <Form.Item name="status" label="Status">
            <Switch
              checked={status}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={setStatus}
            ></Switch>
          </Form.Item>
        )}

        <Form.Item
          label="Usuario"
          name="description"
          rules={[{ max: 80, message: lengthValidation('Usuario', 80) }]}
        >
          <TextArea className="input-adt" />
        </Form.Item>

        <PageHeader className="site-page-header" title="Horarios" />
        <Divider />

        <Schedules
          open={open}
          setOpen={setOpen}
          onOpenChange={onOpenChange}
          timeString={timeString}
          setTimeString={setTimeString}
          branchSchedules={branchSchedules}
        />

        <Button type="primary" htmlType="submit" className="btn-form" loading={isLoading}>
          {buttonLabel.save}
        </Button>
      </Form>
      <CancelButton path="/dashboard/cabinas" />
    </Card>
  );
};
