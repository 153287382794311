export const agendaConst = {
  promotion: 'promotions',
  packages: 'packages',
  purchasedProducts: 'purchasedProducts',
  newTreatments: 'newTreatments',
};

export const agendaOptionsConst = [
  { value: agendaConst.promotion, label: 'Promociones' },
  { value: agendaConst.packages, label: 'Paquetes' },
  { value: agendaConst.purchasedProducts, label: 'Tratamientos adquiridos' },
  { value: agendaConst.newTreatments, label: 'Nuevos tratamientos' },
];

export const appointmentMethodConst = [
  { value: 'Cita presencial', label: 'Presencial' },
  { value: 'Llamada teléfonica', label: 'Llamada teléfonica' },
  { value: 'Redes sociales', label: 'Redes Sociales' },
];

export const appoinmentTypeConst = [
  { value: 'Valoración Médica', label: 'Valoración Médica' },
  { value: 'Cabina Médica', label: 'Cabina Médica' },
  { value: 'Cabina Cosmetologica', label: 'Cabina Cosmetologica' },
];
