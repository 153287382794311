import {
  GET_ALL_DAILY_CASH_CLOSING,
  GET_ALL_DAILY_CASH_CLOSING_BY_BRANCH,
} from '../types';

const initialState = {
  dailyCashClosing: [],
  dailyCashClosingByBranch: [],
};

export default function DailyCashClosing(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DAILY_CASH_CLOSING:
      return {
        ...state,
        dailyCashClosing: action.payload,
      };
    case GET_ALL_DAILY_CASH_CLOSING_BY_BRANCH:
      return {
        ...state,
        dailyCashClosingByBranch: action.payload,
      };
    default:
      return state;
  }
}
