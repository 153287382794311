import { GET_AREAS } from '../types';

const initialState = {
  areas: [],
};

export default function Area(state = initialState, action) {
  switch (action.type) {
    case GET_AREAS:
      return {
        ...state,
        areas: action.payload,
      };
    default:
      return state;
  }
}
