import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  CREATE_PACKAGE,
  GET_PACKAGES,
  GET_PACKAGE_BY_ID,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  SAVE_PACKAGE_IMAGE_ID,
  DATA_DELETED,
} from '../types';

import { post, get, put, remove } from './Action';

const basePath = '/package';

// Create New Package
export const createPackage = (pack) => async (dispatch) => {
  const [{ originFileObj: photo }] = !!pack.photos && pack.photos;

  const response = await dispatch(
    post(`${basePath}/create`, pack, createPackageDS, true)
  );

  if (response && photo) await dispatch(createPhoto(photo, response?.data?.id));

  return response?.status;
};

// Get Packages
export const getPackages = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);

  return await dispatch(get(`${basePath}${queryParams}`, getPackagesDS, true));
};

// Get Package By Id
export const getPackageById = (id) => async (dispatch) => {
  return await dispatch(get(`${basePath}/${id}`, getPackageByIdDS));
};

// Edit Package
export const updatePackage = (id, pack) => async (dispatch) => {
  const photo = pack?.photos?.length > 0 ? pack.photos[0].originFileObj : null;

  const response = await dispatch(
    put(`${basePath}/${id}/update`, pack, updatePackageDS, true)
  );

  if (response && photo) await dispatch(createPhoto(photo, response?.data?.id));

  return response?.status;
};

// Delete Package
export const deletePackage = (id) => async (dispatch) => {
  const response = await dispatch(
    remove(`${basePath}/${id}/delete`, deletePackageDS)
  );
  dispatch(getPackageDeleteDS(id));
  return response;
};

export const createPhoto = (file, packageId) => async (dispatch) => {
  let photo = new FormData();
  photo.append('image', file);
  return await dispatch(
    post(`${basePath}/${packageId}/photo`, photo, createPhotosDS)
  );
};

const createPackageDS = (pack) => ({
  type: CREATE_PACKAGE,
  payload: pack,
});

const getPackagesDS = (packages) => ({
  type: GET_PACKAGES,
  payload: packages.items,
});

const getPackageByIdDS = (pack) => ({
  type: GET_PACKAGE_BY_ID,
  payload: pack,
});

const updatePackageDS = (pack) => ({
  type: UPDATE_PACKAGE,
  payload: pack,
});

const getPackageDeleteDS = (id) => ({
  type: DATA_DELETED,
  payload: id,
});

const deletePackageDS = () => ({
  type: DELETE_PACKAGE,
});

// SAVE ID IMAGE
const createPhotosDS = (id) => ({
  type: SAVE_PACKAGE_IMAGE_ID,
  payload: id,
});
