import { Button } from 'antd';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useSelector } from 'react-redux';
import { formatPrice } from '../../../../util/formatPrice';
import { getMedicalCommissions } from '../../../../appRedux/actions';
import {
  commissionConst,
  filterNoApprove,
  filters,
  filtersMedical,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/Commissions.const';
import { mainBranch } from '../../../../constants/Branch.const';
import { useState } from 'react';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { UpdateCommissionAgent } from '../../Agenda/UpdateCommissionAgent';
import { roleAliasConst, roleConst } from '../../../../constants';
import { MedicalCommissionModalBody } from '../MedicalCommissionModalBody';
import { stringDate, stringTime } from '../../../../util';

export const CommissionMedical = () => {
  const {
    privileges: { medic_comission: medicalPrivileges = [] },
    role,
  } = useSelector((state) => state.userAuth);
  const { branchId: loggedBranchId, branch: branchLogged } = useSelector(
    (state) => state.userAuth
  );
  const [title, setTitle] = useState({});
  const { modal } = useCustomModal();
  const [child, setChild] = useState();
  const getCommissions = getMedicalCommissions;
  const approvePrivilege = medicalPrivileges.includes('approve');
  const editPrivilige = medicalPrivileges.includes('edit');
  let applicabeFilter;

  if (roleConst.medic === role) {
    applicabeFilter = filtersMedical;
  } else if (!approvePrivilege) {
    applicabeFilter = filterNoApprove;
  } else {
    applicabeFilter = filters;
  }

  const onModalClick = (userId, modalType, user) => {
    if (modalType === 'updateCommissionAgent') {
      setTitle(commissionConst.COMMISSION_AGENT_UPDATE);
      setChild(<UpdateCommissionAgent appointment={userId} />);
      modal();
    } else if (!modalType && roleAliasConst.doctor) {
      setTitle(
        `${user?.name} ${user?.lastName} ${user?.motherLastName} - ${commissionConst.MEDICAL_MODAL_TITLE}`
      );
      setChild(
        <MedicalCommissionModalBody id={userId} privileges={editPrivilige} />
      );
      modal();
    }
  };

  const columns = [
    {
      title: 'No. de empleado',
      sorter: true,
      dataIndex: 'userNumber',
      key: 'employeeNumber',
      render: (_, { user }) =>
        roleConst.medic === role ? (
          user?.kardex?.employeeNumber
        ) : (
          <Button
            type="link"
            htmlType="button"
            onClick={() => {
              const selectedUser = user;
              onModalClick(selectedUser?.id, undefined, selectedUser);
            }}
          >
            {user?.kardex?.employeeNumber}
          </Button>
        ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'user',
      key: 'name',
      render: (user) => user?.name,
    },
    {
      title: 'Apellido paterno',
      sorter: true,
      dataIndex: 'userlastName',
      key: 'lastName',
      render: (_, data) => data?.user?.lastName,
    },
    {
      title: 'Apellido materno',
      sorter: true,
      dataIndex: 'userMotherLastName',
      key: 'motherLastName',
      render: (_, data) => data?.user?.motherLastName,
    },
    {
      title: 'Comisión',
      dataIndex: 'total',
      key: 'total',
      render: (total) => formatPrice(total),
    },
  ];

  if (roleConst.medic === role)
    columns.push(
      {
        title: 'Numero de cita',
        dataIndex: 'appointment',
        key: 'appointment',
        render: (appointment) => appointment?.appointmentNumber,
      },
      {
        title: 'Sucursal',
        dataIndex: 'appointment',
        key: 'appointment',
        render: (appointment) => appointment?.branch?.name,
      },
      {
        title: 'Fecha',
        dataIndex: 'appointment',
        key: 'date',
        render: (appointment) => stringDate(appointment?.date),
      },
      {
        title: 'Hora',
        dataIndex: 'appointment',
        key: 'startTime',
        render: (appointment) => stringTime(appointment?.startTime),
      }
    );

  return (
    <>
      <TableFilters
        privileges={medicalPrivileges}
        searchFilters={searchFilters}
        filters={applicabeFilter}
        searchInputInitialValues={searchInputInitialValues}
        loggedBranchId={loggedBranchId}
        initialParams={
          !approvePrivilege && branchLogged !== mainBranch
            ? { branchId: loggedBranchId }
            : undefined
        }
      />
      <ListTable
        columns={columns}
        name={'Comisiones'}
        getDataFunction={getCommissions}
      />
      <ModalView title={title} width={1000}>
        {child}
      </ModalView>
    </>
  );
};
