import {
  CREATE_CABAIN,
  GET_CABAINS,
  GET_CABAIN_BY_ID,
  UPDATE_CABAIN,
  GET_CABAIN_DELETE,
  DELETE_CABAIN,
} from '../types';

const initialState = {
  cabains: [],
  cabainById: {},
  cabainDelete: null,
};

export default function Cabain(state = initialState, action) {
  switch (action.type) {
    case CREATE_CABAIN:
      return {
        ...state,
        cabains: [...state.cabains, action.payload],
      };
    case GET_CABAINS:
      return {
        ...state,
        cabains: action.payload,
      };
    case GET_CABAIN_BY_ID:
      return {
        ...state,
        cabainById: action.payload,
      };
    case UPDATE_CABAIN:
      return {
        ...state,
        cabains: state.cabains.map((cabain) =>
          cabain.id === action.payload.id ? (cabain = action.payload) : cabain
        ),
      };
    case GET_CABAIN_DELETE:
      return {
        ...state,
        cabainDelete: action.payload,
      };

    case DELETE_CABAIN:
      return {
        ...state,
        cabains: state.cabains.filter(
          (cabain) => cabain.id !== state.cabainDelete
        ),
      };

    default:
      return state;
  }
}
