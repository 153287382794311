import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Row, Col } from 'antd';
import { getProducts } from '../../../../appRedux/actions';
import {
  formConfig,
  messages,
  placeHolders,
  productStockColumnsFormConfig,
  sessionColumnFormConfig,
} from '../../../../constants/Forms';
import { CustomSelect, NumericInput } from '../../../uiComponents';
import { productTypeConst } from '../../../../constants';

export const TreatmentProducts = ({ form = [] }) => {
  const formProducts = form.getFieldValue('products');
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const [selectedItems, setSelectedItems] = useState(
    formProducts?.map((item) => item?.productId)
  );

  const filteredOptions = products.filter(
    (product) => !selectedItems?.map((item) => item?.value).includes(product.id)
  );

  useEffect(() => {
    dispatch(
      getProducts({
        status: '1',
        isApproved: '1',
        excludeType: productTypeConst.Input,
        withDeleted: 'false',
      })
    );
  }, [dispatch]);

  const onProductChange = () => {
    setSelectedItems(
      form.getFieldValue('products')?.map((item) => item?.productId)
    );
  };

  return (
    <>
      <Form.List {...formConfig} disabled name="products">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <>
                <hr className="divider" />
                <Row wrap>
                  <Col span={19}>
                    {products && (
                      <Form.Item
                        {...productStockColumnsFormConfig}
                        label="Producto"
                        name={[field.name, 'productId']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Producto'),
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder={placeHolders.product}
                          onChange={onProductChange}
                          labelInValue={true}
                          options={filteredOptions.map(({ id, name, sku }) => ({
                            value: id,
                            label: `${sku} - ${name}`,
                          }))}
                        ></CustomSelect>
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...sessionColumnFormConfig}
                      label="Cantidad"
                      name={[field.name, 'quantity']}
                      dependencies={[field.name, 'stock']}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Cantidad'),
                        },
                      ]}
                    >
                      <NumericInput min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <MinusCircleOutlined
                      className="danger-button-product"
                      onClick={() => {
                        remove(field.name);
                        onProductChange();
                      }}
                    />
                  </Col>
                </Row>
              </>
            ))}

            <Button
              className="add-btn"
              onClick={() => {
                add();
                onProductChange();
              }}
              icon={<PlusOutlined />}
            >
              Agregar producto
            </Button>
          </div>
        )}
      </Form.List>
    </>
  );
};
