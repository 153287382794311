export const searchFilters = [
  { value: 'customer.name', label: 'Nombre' },
  { value: 'customer.last_name', label: 'Apellido paterno' },
  { value: 'customer.customer_id', label: 'Id de cliente' },
  { value: 'customer.phone', label: 'Teléfono' },
  { value: 'customer.mobilePhone', label: 'Celular' },
  { value: 'customer.email', label: 'Correo electrónico' },
];

export const filters = {
  search: true,
  status: true,
  gender: true,
  maritalStatus: true,
  blackList: true,
};

export const searchInputInitialValues = {
  search: { filter: 'customer.name' },
  status: '1',
  blackList: '0',
};

export const genders = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Femenino', label: 'Femenino' },
  { value: 'Otro', label: 'Otro' },
];

export const maritalStatus = [
  { value: 'Soltero', label: 'Soltero' },
  { value: 'Casado', label: 'Casado' },
  { value: 'Viudo', label: 'Viudo' },
  { value: 'Divorciado', label: 'Divorciado' },
  { value: 'Otro', label: 'Otro' },
];

export const customerConst = {
  pending: 'Pendiente',
  finished: 'Terminado',
  onProcess: 'En proceso',
  appointmentNumber: 'appointmentNumber',
  memberTittle: 'Detalle de puntos',
  ordersDetails: 'Desglose de compras',
  members: 'Miembros',
  customers: 'Clientes',
  weMissYou: 'We miss you',
  loyalty: 'Sistema de lealtad',
  pagination: { defaultPageSize: 5, pageSizeOptions: [5, 15, 30, 45, 60] },
  blackList: { label: 'Lista negra', value: '1' },
  regularCustomer: { label: 'Cliente regular', value: '0' },
  orders: 'Compras',
  cashOrders: 'Orden en efectivo',
  refunded: 'Devolución',
};
