import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBranchAppliance,
  getBranchAppliances,
} from '../../../../appRedux/actions';
import {
  ListTable,
  ModalView,
  TableFilters,
  TableMenuOpt,
} from '../../../uiComponents';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { BranchApplianceModalBody } from '../BranchApplianceModalBody';
import { Button } from 'antd';
import {
  applianceConst,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/BranchAppliance.const';
import { applianceStatus } from '../ApplianceStatus';

export const BranchAppliancesTable = () => {
  const {
    privileges: { appliances: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const { modal } = useCustomModal();

  const onModalClick = (appliance) => {
    modal();
    setModalData(appliance);
  };

  const onDelete = (id) => {
    dispatch(deleteBranchAppliance(id));
  };

  const columns = [
    {
      title: 'SKU',
      sorter: true,
      dataIndex: 'sku',
      key: 'sku',
      render: (sku, appliance) => (
        <Button
          type="link"
          htmlType="button"
          href="#/"
          onClick={() => {
            onModalClick(appliance);
          }}
        >
          {sku}
        </Button>
      ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'appliance',
      key: 'name',
      render: (appliance) => appliance?.name,
      path: 'appliance.name',
    },
    {
      title: 'Descripción',
      dataIndex: 'appliance',
      key: 'description',
      render: (appliance) => appliance?.description,
      path: 'appliance.description',
    },
    {
      title: 'Marca',
      dataIndex: 'appliance',
      key: 'brand',
      render: (appliance) => appliance?.brand,
      path: 'appliance.brand',
    },
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      render: (_, { branch }) => branch?.name,
      path: 'branch.name',
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'statusDescription',
      key: 'statusDescription',
      render: (status) => applianceStatus(status),
    },
    {
      title: 'Opciones',
      key: 'id',
      render: (_, { id }) => (
        <TableMenuOpt
          id={id}
          path="aparatos-sucursal"
          privileges={privileges}
          action={{
            onDelete: {
              action: onDelete,
              params: [id],
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'Aparatos'}
        getDataFunction={getBranchAppliances}
      />
      <ModalView title={applianceConst.APPLIANCE}>
        <BranchApplianceModalBody data={modalData} />
      </ModalView>
    </>
  );
};
